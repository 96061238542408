import "../../../styles/game.css";
import * as React from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Common_foot from '../common/Common_foot';
import Common_gameReport from '../common/Common_gameReport';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import { height } from "@mui/system";
import clsx from 'clsx';
import Checkbox from '@mui/material/Checkbox';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    margin: 0,
    border: 'none',
    borderRadius: '8px!important',
    backgroundColor: '#1E293B!important',
    marginBottom: 24,
    '&:before': {
        display: 'none',
    },
    '&:first-of-type': {},
    '&:last-of-type': {
        marginBottom: 0,
    },
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function SportProject(props) {

    const [selectedTab2, setSelectedTab2] = useState(true);
    const [selectedTab3, setSelectedTab3] = useState(true);
    const [selectedTab4, setSelectedTab4] = useState(true);
    const [selectedTab5, setSelectedTab5] = useState(true);
    const [selectedTab6, setSelectedTab6] = useState(true);
    const [selectedTab7, setSelectedTab7] = useState(true);
    const [selectedTab8, setSelectedTab8] = useState(false);
    const [selectedTab9, setSelectedTab9] = useState(false);
    const [selectedTab10, setSelectedTab10] = useState(false);
    const [selectedTab11, setSelectedTab11] = useState(false);
    const [selectedTab12, setSelectedTab12] = useState(false);
    const [selectedTab13, setSelectedTab13] = useState(false);

    const [selectedTab, setSelectedTab] = useState(0);
    function handleTabChange(event, value) {
        setSelectedTab(value);
    }

    const [selectedTab1, setSelectedTab1] = useState(0);
    function handleTabChange1(event, value) {
        setSelectedTab1(value);
    }

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    var data1 = ["World Cup(3)", "Int.Friendly Games(3)"]
    const [tmpWidth1, setTmpWidth1] = useState('100%');
    useEffect(() => {
        switch (data1.length) {
            case 0:
                setTmpWidth('100%')
                break;
            case 1:
                setTmpWidth('100%')
                break;
            case 2:
                setTmpWidth('49%')
                break;
            case 3:
                setTmpWidth('32.4%')
                break;
            case 4:
                setTmpWidth('24.15%')
                break;
            case 5:
                setTmpWidth('19.1%')
                break;
            default:
                setTmpWidth('19%')
                break;
        }
    }, []);


    var data2 = ["Premier League(20)", "championship(20)", "League One(21)", "League Two(6)", "EFL Cup(20)", "EFL Cup(20)", "FA Cup(21)"]
    const [tmpWidth, setTmpWidth] = useState('100%');
    useEffect(() => {
        switch (data2.length) {
            case 0:
                setTmpWidth('100%')
                break;
            case 1:
                setTmpWidth('100%')
                break;
            case 2:
                setTmpWidth('49%')
                break;
            case 3:
                setTmpWidth('32.4%')
                break;
            case 4:
                setTmpWidth('24.15%')
                break;
            case 5:
                setTmpWidth('19.1%')
                break;
            default:
                setTmpWidth('19%')
                break;
        }
    }, []);

    return (
        <div className="flex w-full container">
            <div className="flex flex-col sm:flex-row flex-auto sm:items-center betDiColor min-w-0 p-10 md:p-32 pb-0 md:pb-0">
                <div className="flex flex-col flex-auto align-item">

                    <div className="flex grow shrink-0 flex-col items-center container ">
                        <div className="activitybanner  text-24 mb-20">Basketball</div>
                        <motion.div
                            variants={container}
                            initial="hidden"
                            animate="show"
                            className="activitybanner"
                        >
                            <motion.div variants={item} className="min-w-full">
                                <div className="zuQiu">
                                    <div className="promotion-list activitybanner marginJuZhong">
                                        <div>
                                            <Tabs
                                                value={selectedTab}
                                                onChange={handleTabChange}
                                                indicatorColor="primary"
                                                textColor="inherit"
                                                variant="scrollable"
                                                scrollButtons={false}
                                                className="min-h-40  backGround-foot borderRadius"
                                                classes={{ indicator: 'flex justify-center bg-transparent w-full h-full' }}
                                                TabIndicatorProps={{
                                                    children: (
                                                        <Box
                                                            sx={{ bgcolor: 'text.disabled' }}
                                                            className="w-full h-full rounded-full gamesListBtnBackground-Color opacity-100"
                                                        />
                                                    ),
                                                }}
                                            >
                                                <Tab
                                                    className="text-14 font-semibold min-h-40  Zindex  flex"
                                                    disableRipple
                                                    label="Live & Upcoming"
                                                />
                                                <Tab
                                                    className="text-14 font-semibold min-h-40  Zindex"
                                                    disableRipple
                                                    label="Outrights"
                                                />
                                                <Tab
                                                    className="text-14 font-semibold min-h-40  Zindex  flex"
                                                    disableRipple
                                                    label="All Basketball"
                                                />
                                            </Tabs>
                                        </div>

                                        <div className="displayPhoneSwitch1">
                                            <Tabs
                                                value={selectedTab1}
                                                onChange={handleTabChange1}
                                                indicatorColor="primary"
                                                textColor="inherit"
                                                variant="scrollable"
                                                scrollButtons={false}
                                                className="min-h-32 borderRadius"
                                                classes={{ indicator: 'flex justify-center bg-transparent w-full h-full' }}
                                                TabIndicatorProps={{
                                                    children: (
                                                        <Box
                                                            sx={{ bgcolor: 'text.disabled' }}
                                                            className="w-full h-full rounded-full btnGreenColor opacity-100 "
                                                        />
                                                    ),
                                                }}
                                            >
                                                <Tab
                                                    className="text-18 font-semibold min-h-32  Zindex  "
                                                    disableRipple
                                                    label="1X2"
                                                />
                                                <Tab
                                                    className="text-18 font-semibold min-h-32  Zindex "
                                                    disableRipple
                                                    label="HDP"
                                                />
                                                <Tab
                                                    className="text-18 font-semibold min-h-32  Zindex  "
                                                    disableRipple
                                                    label="O/U"
                                                />
                                                <Tab
                                                    className="text-18 font-semibold min-h-32  Zindex  "
                                                    disableRipple
                                                    label="HT 1X2"
                                                />
                                                <Tab
                                                    className="text-18 font-semibold min-h-32  Zindex  "
                                                    disableRipple
                                                    label="HT HDP"
                                                />
                                                <Tab
                                                    className="text-18 font-semibold min-h-32 Zindex  "
                                                    disableRipple
                                                    label="HT O/U"
                                                />
                                            </Tabs>
                                        </div>

                                        <div className="displayPhoneSwitch mt-20">
                                            <div className="width-120  h-32  backGround-foot   borderRadius btnPointer txtBrightness">
                                                <div className="flex align-item justifyContent ">
                                                    <div className="text-16 font-weight500" style={{ lineHeight: "32px", marginLeft: "12px" }}> HT 1X2</div>
                                                    <LazyLoadImage className="height-24" src="assets/images/index/jianTou.png"></LazyLoadImage>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="mt-20">
                                    {selectedTab === 0 && <div className="flex flex-wraptestRed">
                                        <StyledAccordion
                                            component={motion.div}
                                            variants={item}
                                            classes={{
                                                root: 'FaqPage-panel shadow',
                                            }}
                                            className="zuQiu"
                                            expanded={selectedTab4}
                                            onChange={() => {
                                                setSelectedTab4(!selectedTab4)
                                            }}
                                            style={{}}
                                        >
                                            <AccordionSummary
                                                expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                className="walletDi sportTitleborderRadius-8 mr-12"
                                                style={{ maxHeight: "32px" }}
                                            >
                                                <div className="promotion-list1 items-center flex-grow " style={{ width: '100%' }}>
                                                    <div className="flex items-center">
                                                        <div className="font-medium">
                                                            <Typography className="text-16 font-medium fontColor font-weight500 ml-10" >USA</Typography>
                                                        </div>
                                                    </div>
                                                    <div className={clsx('yuanTips circle txtBlackColor btnGreenColor text-align mr-12 font-weight500 ', selectedTab4 && 'displayNone')}>6</div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ padding: "0px 12px 0px 12px" }} className="sportMainKuangColor sportTitleborderRadius2-8">


                                                {selectedTab === 0 && <div className="flex flex-wraptestRed mb-12">
                                                    <StyledAccordion
                                                        component={motion.div}
                                                        variants={item}
                                                        classes={{
                                                            root: 'FaqPage-panel shadow',
                                                        }}
                                                        className="zuQiu"
                                                        expanded={selectedTab2}
                                                        onChange={() => {
                                                            setSelectedTab2(!selectedTab2)
                                                        }}
                                                        style={{}}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                            className="backGroundColorNode sportTitleborderRadius-8"
                                                            style={{ maxHeight: "32px" }}
                                                        >
                                                            <div className="promotion-list1 items-center flex-grow " style={{ width: '100%' }}>
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">
                                                                        <Typography className="text-16 font-medium fontColor font-weight500" >NBA</Typography>
                                                                    </div>
                                                                </div>
                                                                <div className={clsx('yuanTips circle txtBlackColor btnGreenColor text-align mr-12 font-weight500 ', selectedTab2 && 'displayNone')}>3</div>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ padding: "0" }} className="backGroundColorNode sportTitleborderRadius2-8">
                                                            <div>
                                                                <div style={{ borderTop: "1px solid #1E2633" }} className="mb-7"></div>
                                                                <div className="promotion-list1">

                                                                    <div className="sportPhoneWidth">
                                                                        <div className="promotion-list1 mx-16 mt-8 fontColor " >
                                                                            <div className="fontColor  text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                            <div className="displayPhoneSwitch3">
                                                                                <LazyLoadImage className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                                <div className="height-24 mx-4 text-14 font-weight500 lineHeght-24">+120</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500">Morocco</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500">Portugal</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500 ">MAR(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.22</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">POR(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.22</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+120</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">

                                                                    <div className="sportPhoneWidth">
                                                                        <div className="promotion-list1 mx-16 mt-8 fontColor">
                                                                            <div className="fontColor text-12 font-weight500">Sun,Dec 11 3:00 AM</div>
                                                                            <div className="displayPhoneSwitch3">
                                                                                <LazyLoadImage className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                                <div className="height-24 mx-4 text-14 font-weight500 lineHeght-24">+120</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500">Englend</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500">France</div>
                                                                    </div>

                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+100</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">

                                                                    <div className="sportPhoneWidth">
                                                                        <div className="promotion-list1 mx-16 mt-8 fontColor">
                                                                            <div className="fontColor  text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                            <div className="displayPhoneSwitch3">
                                                                                <LazyLoadImage className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                                <div className="height-24 mx-4 text-14 font-weight500 lineHeght-24">+100</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500">Argentina</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500">Croatia</div>
                                                                    </div>

                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ARG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.42</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">CRO(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.74</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+160</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionDetails>
                                                    </StyledAccordion>
                                                </div>}


                                                {selectedTab === 0 && <div className="flex flex-wraptestRed mb-12">
                                                    <StyledAccordion
                                                        component={motion.div}
                                                        variants={item}
                                                        classes={{
                                                            root: 'FaqPage-panel shadow',
                                                        }}
                                                        className="zuQiu"
                                                        expanded={selectedTab3}
                                                        onChange={() => {
                                                            setSelectedTab3(!selectedTab3)
                                                        }}
                                                        style={{}}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                            className="backGroundColorNode sportTitleborderRadius-8"
                                                            style={{ maxHeight: "32px" }}
                                                        >
                                                            <div className="promotion-list1 items-center flex-grow " style={{ width: '100%' }}>
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">
                                                                        <Typography className="text-16 font-medium fontColor font-weight500" >NCAA,Regular</Typography>
                                                                    </div>
                                                                </div>
                                                                <div className={clsx('yuanTips circle txtBlackColor btnGreenColor text-align mr-12 font-weight500 ', selectedTab3 && 'displayNone')}>3</div>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ padding: "0" }} className="backGroundColorNode sportTitleborderRadius2-8">
                                                            <div>
                                                                <div style={{ borderTop: "1px solid #1E2633" }} className="mb-7"></div>
                                                                <div className="promotion-list1">

                                                                    <div className="sportPhoneWidth">
                                                                        <div className="promotion-list1 mx-16 mt-8 fontColor">
                                                                            <div className="fontColor text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                            <div className="displayPhoneSwitch3">
                                                                                <LazyLoadImage className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 mx-4 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                                <div className="height-24 mx-4 text-14 font-weight500 lineHeght-24">+80</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500">Oklahoma City Thunder</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500">Boston Celtics</div>
                                                                    </div>

                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500 ">Oklahoma City Thunder</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">4.22</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">Boston Celtics</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">3.00</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+40</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500">Sun,Dec 11 3:00 AM</div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Englend</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">France</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Milwaukee Bucks</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Washington Wizards</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">Milwaukee Bucks</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.42</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">Washington Wizards</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.74</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+39</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionDetails>
                                                    </StyledAccordion>
                                                </div>}

                                            </AccordionDetails>
                                        </StyledAccordion>
                                    </div>}
                                </div>

                                <div className="mt-20">
                                    {selectedTab === 0 && <div className="flex flex-wraptestRed">
                                        <StyledAccordion
                                            component={motion.div}
                                            variants={item}
                                            classes={{
                                                root: 'FaqPage-panel shadow',
                                            }}
                                            className="zuQiu"
                                            expanded={selectedTab5}
                                            onChange={() => {
                                                setSelectedTab5(!selectedTab5)
                                            }}
                                            style={{}}
                                        >
                                            <AccordionSummary
                                                expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                className="walletDi sportTitleborderRadius-8 mr-12"
                                                style={{ maxHeight: "32px" }}
                                            >
                                                <div className="promotion-list1 items-center flex-grow " style={{ width: '100%' }}>
                                                    <div className="flex items-center">
                                                        <div className="font-medium">
                                                            <Typography className="text-16 font-medium fontColor font-weight500 ml-10" >International</Typography>
                                                        </div>
                                                    </div>
                                                    <div className={clsx('yuanTips circle txtBlackColor btnGreenColor text-align mr-12 font-weight500 ', selectedTab5 && 'displayNone')}>6</div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ padding: "0px 12px 0px 12px" }} className="sportMainKuangColor sportTitleborderRadius2-8">


                                                {selectedTab === 0 && <div className="flex flex-wraptestRed mb-12">
                                                    <StyledAccordion
                                                        component={motion.div}
                                                        variants={item}
                                                        classes={{
                                                            root: 'FaqPage-panel shadow',
                                                        }}
                                                        className="zuQiu"
                                                        expanded={selectedTab6}
                                                        onChange={() => {
                                                            setSelectedTab6(!selectedTab6)
                                                        }}
                                                        style={{}}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                            className="backGroundColorNode sportTitleborderRadius-8"
                                                            style={{ maxHeight: "32px" }}
                                                        >
                                                            <div className="promotion-list1 items-center flex-grow " style={{ width: '100%' }}>
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">
                                                                        <Typography className="text-16 font-medium fontColor font-weight500" >Euroleague</Typography>
                                                                    </div>
                                                                </div>
                                                                <div className={clsx('yuanTips circle txtBlackColor btnGreenColor text-align mr-12 font-weight500 ', selectedTab6 && 'displayNone')}>3</div>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ padding: "0" }} className="backGroundColorNode sportTitleborderRadius2-8">
                                                            <div>
                                                                <div style={{ borderTop: "1px solid #1E2633" }} className="mb-7"></div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Morocco</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Portugal</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500 ">MAR(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.22</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">POR(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.22</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+120</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500">Sun,Dec 11 3:00 AM</div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Englend</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">France</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Argentina</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Croatia</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ARG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.42</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">CRO(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.74</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+160</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionDetails>
                                                    </StyledAccordion>
                                                </div>}


                                                {selectedTab === 0 && <div className="flex flex-wraptestRed mb-12">
                                                    <StyledAccordion
                                                        component={motion.div}
                                                        variants={item}
                                                        classes={{
                                                            root: 'FaqPage-panel shadow',
                                                        }}
                                                        className="zuQiu"
                                                        expanded={selectedTab7}
                                                        onChange={() => {
                                                            setSelectedTab7(!selectedTab7)
                                                        }}
                                                        style={{}}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                            className="backGroundColorNode sportTitleborderRadius-8"
                                                            style={{ maxHeight: "32px" }}
                                                        >
                                                            <div className="promotion-list1 items-center flex-grow " style={{ width: '100%' }}>
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">
                                                                        <Typography className="text-16 font-medium fontColor font-weight500" >NCAA,Regular</Typography>
                                                                    </div>
                                                                </div>
                                                                <div className={clsx('yuanTips circle txtBlackColor btnGreenColor text-align mr-12 font-weight500 ', selectedTab7 && 'displayNone')}>3</div>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ padding: "0" }} className="backGroundColorNode sportTitleborderRadius2-8">
                                                            <div>
                                                                <div style={{ borderTop: "1px solid #1E2633" }} className="mb-7"></div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Oklahoma City Thunder</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Boston Celtics</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500 ">Oklahoma City Thunder</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">4.22</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">Boston Celtics</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">3.00</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+40</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500">Sun,Dec 11 3:00 AM</div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Englend</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">France</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Milwaukee Bucks</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Washington Wizards</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">Milwaukee Bucks</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.42</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">Washington Wizards</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.74</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+39</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionDetails>
                                                    </StyledAccordion>
                                                </div>}
                                            </AccordionDetails>
                                        </StyledAccordion>
                                    </div>}
                                </div>

                                <div className="zuQiu flex mt-20 height-24 items-center">
                                    <LazyLoadImage className="height-24 btnPointer txtBrightness mr-12" src="assets/images/icon/10011.png"></LazyLoadImage>
                                    <div>Alphabetical</div>
                                </div>

                                <div className="mt-20">
                                    {selectedTab === 0 && <div className="flex flex-wraptestRed">
                                        <StyledAccordion
                                            component={motion.div}
                                            variants={item}
                                            classes={{
                                                root: 'FaqPage-panel shadow',
                                            }}
                                            className="zuQiu"
                                            expanded={selectedTab8}
                                            onChange={() => {
                                                setSelectedTab8(!selectedTab8)
                                            }}
                                            style={{}}
                                        >
                                            <AccordionSummary
                                                expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                className="walletDi sportTitleborderRadius-8 mr-12"
                                                style={{ maxHeight: "32px" }}
                                            >
                                                <div className="promotion-list1 items-center flex-grow " style={{ width: '100%' }}>
                                                    <div className="flex items-center">
                                                        <div className="font-medium">
                                                            <Typography className="text-16 font-medium fontColor font-weight500 ml-10" >Australia</Typography>
                                                        </div>
                                                    </div>
                                                    <div className={clsx('yuanTips circle txtBlackColor btnGreenColor text-align mr-12 font-weight500 ', selectedTab8 && 'displayNone')}>6</div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ padding: "0px 12px 0px 12px" }} className="sportMainKuangColor sportTitleborderRadius2-8">


                                                {selectedTab === 0 && <div className="flex flex-wraptestRed mb-12">
                                                    <StyledAccordion
                                                        component={motion.div}
                                                        variants={item}
                                                        classes={{
                                                            root: 'FaqPage-panel shadow',
                                                        }}
                                                        className="zuQiu"
                                                        expanded={selectedTab9}
                                                        onChange={() => {
                                                            setSelectedTab9(!selectedTab9)
                                                        }}
                                                        style={{}}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                            className="backGroundColorNode sportTitleborderRadius-8"
                                                            style={{ maxHeight: "32px" }}
                                                        >
                                                            <div className="promotion-list1 items-center flex-grow " style={{ width: '100%' }}>
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">
                                                                        <Typography className="text-16 font-medium fontColor font-weight500" >Euroleague</Typography>
                                                                    </div>
                                                                </div>
                                                                <div className={clsx('yuanTips circle txtBlackColor btnGreenColor text-align mr-12 font-weight500 ', selectedTab9 && 'displayNone')}>3</div>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ padding: "0" }} className="backGroundColorNode sportTitleborderRadius2-8">
                                                            <div>
                                                                <div style={{ borderTop: "1px solid #1E2633" }} className="mb-7"></div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Morocco</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Portugal</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500 ">MAR(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.22</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">POR(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.22</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+120</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500">Sun,Dec 11 3:00 AM</div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Englend</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">France</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Argentina</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Croatia</div>
                                                                    </div>
                                                                    <div className="mx-16">
                                                                        <div className="fontColor mx-16 mt-12 mr-80 text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24" style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 min-w-224 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ARG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.42</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-224 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">CRO(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.74</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight">+160</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionDetails>
                                                    </StyledAccordion>
                                                </div>}


                                                {selectedTab === 0 && <div className="flex flex-wraptestRed mb-12">
                                                    <StyledAccordion
                                                        component={motion.div}
                                                        variants={item}
                                                        classes={{
                                                            root: 'FaqPage-panel shadow',
                                                        }}
                                                        className="zuQiu"
                                                        expanded={selectedTab10}
                                                        onChange={() => {
                                                            setSelectedTab10(!selectedTab10)
                                                        }}
                                                        style={{}}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                            className="backGroundColorNode sportTitleborderRadius-8"
                                                            style={{ maxHeight: "32px" }}
                                                        >
                                                            <div className="promotion-list1 items-center flex-grow " style={{ width: '100%' }}>
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">
                                                                        <Typography className="text-16 font-medium fontColor font-weight500" >NCAA,Regular</Typography>
                                                                    </div>
                                                                </div>
                                                                <div className={clsx('yuanTips circle txtBlackColor btnGreenColor text-align mr-12 font-weight500 ', selectedTab10 && 'displayNone')}>3</div>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ padding: "0" }} className="backGroundColorNode sportTitleborderRadius2-8">
                                                            <div>
                                                                <div style={{ borderTop: "1px solid #1E2633" }} className="mb-7"></div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Oklahoma City Thunder</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Boston Celtics</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500">Sun,Dec 11 3:00 AM</div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Englend</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">France</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Milwaukee Bucks</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Washington Wizards</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionDetails>
                                                    </StyledAccordion>
                                                </div>}
                                            </AccordionDetails>
                                        </StyledAccordion>
                                    </div>}
                                </div>

                                <div className="mt-20">
                                    {selectedTab === 0 && <div className="flex flex-wraptestRed">
                                        <StyledAccordion
                                            component={motion.div}
                                            variants={item}
                                            classes={{
                                                root: 'FaqPage-panel shadow',
                                            }}
                                            className="zuQiu"
                                            expanded={selectedTab11}
                                            onChange={() => {
                                                setSelectedTab11(!selectedTab11)
                                            }}
                                            style={{}}
                                        >
                                            <AccordionSummary
                                                expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                className="walletDi sportTitleborderRadius-8 mr-12"
                                                style={{ maxHeight: "32px" }}
                                            >
                                                <div className="promotion-list1 items-center flex-grow " style={{ width: '100%' }}>
                                                    <div className="flex items-center">
                                                        <div className="font-medium">
                                                            <Typography className="text-16 font-medium fontColor font-weight500 ml-10" >Brazil</Typography>
                                                        </div>
                                                    </div>
                                                    <div className={clsx('yuanTips circle txtBlackColor btnGreenColor text-align mr-12 font-weight500 ', selectedTab11 && 'displayNone')}>6</div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ padding: "0px 12px 0px 12px" }} className="sportMainKuangColor sportTitleborderRadius2-8">


                                                {selectedTab === 0 && <div className="flex flex-wraptestRed mb-12">
                                                    <StyledAccordion
                                                        component={motion.div}
                                                        variants={item}
                                                        classes={{
                                                            root: 'FaqPage-panel shadow',
                                                        }}
                                                        className="zuQiu"
                                                        expanded={selectedTab12}
                                                        onChange={() => {
                                                            setSelectedTab12(!selectedTab12)
                                                        }}
                                                        style={{}}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                            className="backGroundColorNode sportTitleborderRadius-8"
                                                            style={{ maxHeight: "32px" }}
                                                        >
                                                            <div className="promotion-list1 items-center flex-grow " style={{ width: '100%' }}>
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">
                                                                        <Typography className="text-16 font-medium fontColor font-weight500" >Euroleague</Typography>
                                                                    </div>
                                                                </div>
                                                                <div className={clsx('yuanTips circle txtBlackColor btnGreenColor text-align mr-12 font-weight500 ', selectedTab12 && 'displayNone')}>3</div>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ padding: "0" }} className="backGroundColorNode sportTitleborderRadius2-8">
                                                            <div>
                                                                <div style={{ borderTop: "1px solid #1E2633" }} className="mb-7"></div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Morocco</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Portugal</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500">Sun,Dec 11 3:00 AM</div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Englend</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">France</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Argentina</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Croatia</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionDetails>
                                                    </StyledAccordion>
                                                </div>}


                                                {selectedTab === 0 && <div className="flex flex-wraptestRed mb-12">
                                                    <StyledAccordion
                                                        component={motion.div}
                                                        variants={item}
                                                        classes={{
                                                            root: 'FaqPage-panel shadow',
                                                        }}
                                                        className="zuQiu"
                                                        expanded={selectedTab13}
                                                        onChange={() => {
                                                            setSelectedTab13(!selectedTab13)
                                                        }}
                                                        style={{}}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<FuseSvgIcon>heroicons-outline:chevron-down</FuseSvgIcon>}
                                                            className="backGroundColorNode sportTitleborderRadius-8"
                                                            style={{ maxHeight: "32px" }}
                                                        >
                                                            <div className="promotion-list1 items-center flex-grow " style={{ width: '100%' }}>
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">
                                                                        <Typography className="text-16 font-medium fontColor font-weight500" >NCAA,Regular</Typography>
                                                                    </div>
                                                                </div>
                                                                <div className={clsx('yuanTips circle txtBlackColor btnGreenColor text-align mr-12 font-weight500 ', selectedTab13 && 'displayNone')}>3</div>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ padding: "0" }} className="backGroundColorNode sportTitleborderRadius2-8">
                                                            <div>
                                                                <div style={{ borderTop: "1px solid #1E2633" }} className="mb-7"></div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Oklahoma City Thunder</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Boston Celtics</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500">Sun,Dec 11 3:00 AM</div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Englend</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">France</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="promotion-list1">
                                                                    <div>
                                                                        <div className="fontColor mx-16 mt-12 text-12 font-weight500"><span className="mr-8 fontColor font-weight500" style={{ display: 'inline-block', height: '18px', lineHeight: "18px", cursor: "default" }}>Wed,</span><span className="mr-8 font-weight500" style={{ display: 'inline-block' }}>jan 04</span><span className="fontColor font-weight500" style={{ display: 'inline-block' }}>9:00 AM</span></div>
                                                                        <div className="text-12 mx-16 mt-12 font-weight500 txtFootColor btnPointer">Milwaukee Bucks</div>
                                                                        <div className="text-12 mx-16 mt-6 font-weight500 txtFootColor btnPointer">Washington Wizards</div>
                                                                    </div>
                                                                    <div className="phoneMx-16 phone100Bai">
                                                                        <div className="fontColor mx-16 mt-12  text-12 font-weight500" style={{ textAlign: "center" }}>Winner(Incl.Overtime)</div>
                                                                        <div className="flex mt-4 mb-12">
                                                                            <div className="height-52 w-24 displayPhoneSwitch2" style={{
                                                                                alignItems: 'center',
                                                                                flexWrap: 'wrap'
                                                                            }}>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10003.png"></LazyLoadImage>
                                                                                <LazyLoadImage className="height-24 btnPointer txtBrightness" src="assets/images/icon/10010.png"></LazyLoadImage>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mx-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">ENG(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">-0.32</div>
                                                                            </div>
                                                                            <div className="height-52 phoneMW-222 borderRadius-5 backGround-foot mr-10 txtBrightness btnPointer">
                                                                                <div className="mx-8 mt-8 text-12 font-weight500">FRA(0)</div>
                                                                                <div className="mx-8 mt-6 text-12 font-weight500 txtGreenColor">+0.11</div>
                                                                            </div>
                                                                            <div className="height-52 min-w-60 pt-16 text-12 font-weight500 textAlignRight displayPhoneSwitch1">+3</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionDetails>
                                                    </StyledAccordion>
                                                </div>}
                                            </AccordionDetails>
                                        </StyledAccordion>
                                    </div>}
                                </div>

                            </motion.div>
                        </motion.div>
                    </div>

                    <Common_gameReport />
                    <Common_foot />
                    <div className="mt-32  promotion-list colorGameListTitle mb-20 displayPhoneSwitch1">
                        <div>FuniBet 2022- ALL Rights Reserved</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SportProject;
