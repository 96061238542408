const locale = {
    home_StakeNFT: 'Kumita ng libre ang stake NFT',
    home_everyday: 'Araw-araw',
    home_Beton: 'Mag-bet',
    home_mining: 'Mag-mina',
    home_shareholder: 'Maging shareholder',
    home_Stake: 'stake',
    home_dividend: 'Magkuha ng dividend',
    home_Banker: 'Maging isa sa mga partners',
    home_SIGNUPAND: 'SIGN UP & ',
    home_GET: 'GET',
    home_UPTO: 'UP TO ',
    home_FuniHash: 'Laro sa blockchain',
    home_Option: 'Option',
    
    home_Reward: ' Reward',
    home_Reward1: 'Reward',
    home_signUpNow: 'sign up ngayon',
    home_Casino: 'Casino',
    home_Sports: 'Sports',
    home_PROMOTION: 'Promosyon',
    home_Checkin: 'check in',
    home_Bouns: 'Bonus',
    home_CheckinBouns: 'check in',
    home_SPIN: 'Ispin',
    home_Relief: 'Tulong',
    home_Share: 'Ibahagi',
    home_Deposit: 'Deposit',
    home_Fund: 'Pondo',
    home_VIPClub: 'VIP Club',
    home_FastAndEasy: 'Mabilis at madaling bumili ng crypto upang makatanggap ng bonus na USDT sa deposito',
    home_Game: 'Laro',
    home_User: 'User',
    home_Time: 'Oras',
    home_BetAmount: 'Halaga ng pusta',
    home_Multiplier: 'Multiplying Factor',
    home_Payout: 'Payout',
    home_JoinOur: 'Sumali sa aming komunidad ng mga sports fanatics, crypto enthusiasts at mga manlalaro.',
    home_JOIN: 'Sumali',
    home_LICENSES: 'Lisensya',
    home_OurPlatforms: 'Mga Plataporma Namin',
    home_Marketplac: 'Pamilihan',
    home_Howtoearn: 'Paano kumita',
    home_Games: 'Laro',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'Investor Deck',
    home_News: 'Balita',
    home_Aboutsus: 'Tungkol sa amin',
    home_Industry: 'Industriya',
    home_Roadmap: 'Plano ng Daan',
    home_Partner: 'Mga Kasosyo',
    home_FAQs: 'Mga Madalas Itanong',
    home_Affiliate: 'Kaakibat',
    home_Stayintouch: 'Manatili sa Kontakt',
    home_ChatRoom: 'Chat Room',
    home_Selectacontact: 'Pumili ng kontak upang magsimula ng pakikipag-usap. ',
    home_BetSlip: 'Tiket ng Pagsusugal',
    home_Typeyourmessage: 'Ilagay ang iyong mensahe',
    home_AcceptAnyOdds: 'Tanggapin ang anumang mga odds',
    home_ClearAll: 'Burahin lahat',
    home_Live: 'Live',
    home_Winner: 'Nanalo',
    home_EstPayout: 'Inaasahang Pagbabayad',
    home_TotalOdds: 'Kabuuang Halaga ng Pagsusugal',
    home_RegistertoBet: 'Magrehistro upang makapagsugal',
    home_Single: 'Isang bagay na pagsusugal',
    home_Multi: 'Maramihang pagsusugal',
    home_Checkineveryweek: 'Magtsek sa bawat linggo upang makakuha ng kaugnay na gantimpala. ',
    home_Day: 'Araw',
    home_Sunday: 'Linggo',
    home_Monday: 'Lunes',
    home_Tuesday: 'Martes',
    home_Wednesday: 'Miyerkules',
    home_Thursday: 'Huwebes',
    home_Friday: 'Biyernes',
    home_Saturday: 'Sabado',
    home_FreeSpin: 'Libreng Pagpindot',
    home_Turntheturntable: 'Pindutin ang turntable araw-araw upang makakuha ng USGT na premyo.',
    home_DepositFift: 'Deposit',
    home_ANYAMOUNT: 'Makakatanggap ka ng premyong pambungad sa anumang halaga ng iyong unang deposito.',
    home_DEPOSIT: 'Deposit',
    home_First: 'Unang',
    home_ONLY: 'Lamang',
    home_1ST: '1ST',
    home_2ND: '2ND',
    home_3TH: '3TH',
    home_ANYAMOUNT: 'Kahit na ANONG HALAGA!',
    home_EXTRA: 'Dagdag na',
    home_Relief: 'limos',
    home_Getrelief: 'Mag-apply ng Tulong Pinansyal at magkaroon ng pagkakataon na magbago ng kapalaran.',
    home_YOUCANCLAIM: 'Kapag ang iyong balanse ay hindi umaabot sa',
    home_YOUCANReceive: ', pwede kang humiling ng Tulong Pinansyal at maaring magdagdag ng',
    home_threeTimes: '3 na beses',
    home_everydayTimes: '/bawat araw!',
    home_Times: 'kada beses:',
    home_TotalAssets: 'Kabuuang Aset',
    home_receive: 'Tanggapin',
    home_TODAY: 'Ngayon',
    home_Share: 'Ibahagi',
    home_PLATFORMSHARING: 'Ibahagi sa ibang platform',
    home_Sharewith: 'Ibahagi ang FuniBet para kumita',
    home_SHARINGGIFT: 'Mga regalong ibahagi',
    home_CODE: 'Code na ibahagi',
    home_Selectplatform: 'Pumili ng platform para sa pagbahagi:',
    home_Fund: 'Pondo',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
    home_BuyFund: 'Bumili',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'Natanggap na',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'Natatanging mga Benepisyo',
    home_Asawayof: 'Bilang pagpapahayag ng aming pasasalamat at pagpapahalaga sa aming mga manlalarong VIP, inaanyayahan namin kayo na sumali sa aming VIP Club, kung saan mayroong mga masasarap na regalo, libreng produkto, mas mataas na cashback, at natatanging mga tampok. Huwag palampasin ang lahat ng kasiyahan at pagkamangha! ',
    home_Yourcurrent: 'Your current Total wager',
    home_WhyUpgrade: 'Bakit kailangan mong mag-upgrade bilang VIP? ',
    home_VIPhave: 'Para sa mga VIP, mayroong 24*7 na espesyal na tagapamahala. Bukod sa mga bonus at deposito, nakakatanggap sila ng pinakamataas na level ng mga spins at mga bonus kada linggo/o buwan. Mabilis din ang paraan ng pagdedeposito at pagwiwithdraw ng pera. Nakakasali rin sila sa exclusive na player events at maaaring magbigay ng feedback at feature requests sa FuniBet team. ',
    home_UpgradeYour: 'I-upgrade ang iyong VIP level',
    home_ASaFunibet: "Bilang miyembro ng FUNIBet VIP Club, mas maraming bonus at mga regalo ang maaari mong makuha habang naglalaro. Kapag Elite VIP member ka na, mas magagandang benepisyo ang maaari mong makamit. May espesyal na VIP privileges ang FUNIBET para sa'yo.",
    home_YOUCANREACH: 'Maaari kang mag-level up hanggang sa',
    home_LEVELS: 'levels',
    home_eachthehigh: 'Ang bawat isa ay makakatanggap ng 25% cashback bonus at mag-eenjoy ng lahat ng benepisyo ng platform. Sumali sa FUNIBET at mag-enjoy ng pinakamahusay na gaming experience at premium na serbisyo! ',
    home_Cashback: 'Cashback',
    home_Nowater: '1. Walang karagdagang bayad sa mga laro',
    home_Moregrowth: '2. Mas maraming Growth Fund',
    home_Increasein: '3. Mas maraming mga benepisyo',
    home_Doublethenumber: '4. Dobol ng bilang ng spins sa wheel',
    home_Doublethesign: '5. Dobol ng bilang ng mga sign-up',
    home_Doublesharingrewards: '6. Dobol ng mga sharing reward',
    home_DoubletheAffiliate: '7. Dobol ng mga affiliate reward',
    home_Rechargerewardamount: '8. 1.5 ang halaga ng reward bawat deposito.',
    home_ABOUTVIPCLUB: 'ABOUT VIP CLUB',
    home_HightopRank: 'High top Rank has 25% cashback bonus and the best benefits. Stay with BeDao to receive the best gaming experience and high level of service!',
    home_Beingamember: 'Being a member of the VIP club means that you have access to:',
    home_PersonalVIPmanager: 'Personal VIP manager',
    home_Highercahbackrewards: 'Higher cahback rewards',
    home_Otheradditionalperks: 'Other additional perks',
    home_Insights: 'Mga pananaw',
    home_WeeklyandMonthlyBonuses: 'Mga bonus kada linggo at buwan',
    home_Showhighgaming: 'Ipakita ang mga mataas na aktibidad sa paglalaro at maging imbitado sa aming VIP Club.',
    home_BindMobile: 'Konektado',
    home_Pleasecheckthe: 'Mangyaring suriin ang USGT na nakukuha mula sa laro sa iyong wallet.',
    home_BINDMOBILENUMBER: 'I-konek ang numero',
    home_Get1USDT: 'Makakuha ng 1 USDT',
    home_Bind: 'Mag-bind',
    home_GiftCode: 'Gift Code',
    home_EnteryourGiftCode: 'Enter your Gift Code',
    home_EnterCode: 'Magpasok ng code',
    home_Paste: 'I-paste',
    home_confirms: 'confirms',
    home_BuyCrypto: 'Bumili',
    home_SignedIn: 'Naka-sign in',
    home_CommissionRules: 'patakaran',
    home_AffiliateTerms: 'klawsula',
    home_AffiliateDashboard: 'Dashboard ng Affiliate',
    home_AFFILIATE: 'Affiliate',
    

    
    home_COMMISSONREADYEVERYDAY: 'Patakaran sa Commission',
    home_MYREFERRALCODE: 'Aking referral code',
    home_MYREFERRALLink: 'Aking referral link',
    home_AffiliateReward: 'Mga gantimpala',
    home_EXTRA: 'Karagdag',
    home_USDREWARDS: 'Mga gantimpalang USD',
    home_USDRewardsRules: 'Mga patakaran sa gantimpalang USD',
    home_JUSAREFERRALAWAY: 'Isang referral lamang',
    home_NewlyAvailableRewards: 'Bagong mga gantimpala',
    home_ReceivedMao: 'Natanggap na:',
    home_Locked: 'Nakakandado:',
    home_Yourcurrentfriends: 'Panatilihin ang aktibo ng iyong mga kaibigan upang makatanggap ka ng karagdagang mga gantimpala',
    home_Withdraw: 'Magwithdraw',
    home_MY: 'Aking',
    home_COMMISSIONREWARDS: 'Mga Pabuya sa Komisyon',
    home_CommissionRewardRules: 'Mga Patakaran sa Pabuya sa Komisyon',
    home_NewlyAvailableCommissionRewards: 'Bagong Pabuya sa Komisyon',
    home_CommissionYouhaveReceivedinTotal: 'Natanggap na Komisyon:',
    home_REWARDSSENTTODATE: 'Kabuuang Premyo',
    home_USDrewardssenttodate: 'Kabuuang Dolyar na Pabuya',
    home_Commissionsenttodatet: 'Petsa ng pagpapadala ng komisyon',
    home_Areyouablogger: 'May espesyal na alok kami para sa mga blogger na mayroong maraming mambabasa. Makipag-ugnay sa aming manager upang malaman ang mga kondisyon ng aming programa ng kasosyo',
    home_onlythoseusers: 'Mahalagang paalala: Tanging ang mga user na na-aprubahan ng administrator matapos ang kahilingan ng administrator ang pwedeng makilahok sa programa na ito.',
    home_notice: 'Paunawa',
    home_bindPhone: 'I-bind ang Telepono',
    home_SignIn: 'sign in',
    home_SignUp: 'sign up',
    home_SignOut: 'sign out',
    home_CookiesPolicy: 'Patakaran sa Cookies',
    home_isUsingCookies: 'Ang Funibet.com ay gumagamit ng cookies para sa mas magandang serbisyo. Sa paggamit mo ng website, sumasang-ayon ka sa paggamit ng cookies. Tignan ang aming privacy policy at terms of service.',
    home_SETTING: 'Mga Setting',
    home_LiveSupport: 'Live na Suporta',
    home_Accept: 'Sang-ayon',
    home_FrequentlyAskedQuestions: 'Madalas na Itinanong na mga Tanong',
    home_SiteUnnderConstruction: 'Nasa Ilalim ng Konstruksyon ang Site',
    home_ComingSoon: 'Magkakaroon na ng Malapit',
    home_Wallet: 'Wallet',
    home_UserId: 'ID: ',
    home_UserName: 'Pangalan: ',
    home_turnTimes: 'Bilang ng Pagikot:',
    home_Bonusincreased: "Dagdag na Bonus +",
    home_bonus: 'Bonus',
    home_SPINBONUSTOTAL: 'SPIN BONUS TOTAL',
    home_WIN: 'WIN:',
    home_In: 'in',
    home_FirstDepositBonus: 'First Deposit Bonus',
    home_Hi: 'Hi',
    home_welcomeAboard: 'Maligayang Pagbabalik',
    home_Outoftime: 'Tapos na ang mga pagkakataon ngayong araw',
    
    game_search: 'Paghahanap',
    game_SortBy: 'I-uri ayon sa',
    game_Created: 'Petsa ng paglikha',
    game_Lobby: 'Lobby',
    game_LiveCasino: 'Live na Casino',
    game_FeaturedSlots: 'Natatanging Slots',
    game_GameShows: 'Mga Game Show',
    game_FuniBetOriginals: 'Mga orihinal na laro ng FuniBet',
    game_BigWins: 'Malalaking Panalo',
    game_LcukyWins: 'Masuwerteng Panalo',
    game_Challenges: 'Mga Hamon',
    game_Description: 'Paglalarawan',
    game_Slots: 'Slots',
    game_Rank: 'Ranggo',
    game_User: 'User',
    game_Date: 'Petsa',
    game_BetAmount: 'Halaga ng Taya',
    game_lucky: 'Pinakamalaking Multiplier',
    game_Multiplier: 'Multiplier',
    game_Payout: 'Payout',
    game_Recommend: 'Rekomendado',
    game_Providers: 'Mga Tagapagbigay',
    game_startGame: 'Simulan',
    game_balance: 'Balanse',
    game_provider: 'Label',
    game_Selectyourdisplay: 'Pumili ng iyong ipinapakita na balanse',
    game_Balancein: 'Balanse sa',
    game_FunPlay: 'Fun Play',
    game_RealPlay: 'Mode ng Real Play',
    game_DispLaying: "Pagpapakita",
    game_LoadMore: "Magdagdag ng higit pa",
    game_NoData: 'Wala munang laro ng ganitong uri',
    
    message_SigningIn:"Pumapasok gamit ang JWT",
    message_CourseSaved:"Naisilid ang Kurso",
    message_PleaseBind:"Kailangan mong i-bind ang iyong telepono upang makilahok sa aktibidad. Gusto mo bang i-bind ito?",
    message_UserData:"Naisilid ang data ng user gamit ang API",
    message_Success:"tagumpay",
    message_VIPlevel:"Ang VIP level ay mas mababa kaysa V3",
    message_ListOrder:"Naisilid ang Listahan ng Order",
    message_CardOrder :"Naisilid ang Order ng Card",
    message_HiHow:"Kumusta ka?", 
    
    Funibet_web: 'Funibet-Web3.0,Krypto,Pusta,Sports,E-sports,Mga Slot,Live na Casino,Poker',

    home_ThereAre: "Walang mga abiso sa ngayon.",

    play_no_balance: "Ang kasalukuyang balanse ng pera ay hindi sapat, mangyaring magpalit ng pera o mag-recharge",
    
    home_News_1: 'Balita',
    home_News_2: 'Kaganapan',
    home_News_3: 'Crypto',
    home_News_4: 'Gaming',
    
    home_SuggRep_1: 'Mga Mungkahi at Ulat',
    home_SuggRep_2: 'Mga Mungkahi at Ulat',
    home_SuggRep_3: 'Ibahagi ang iyong puna upang mapabuti!',
    home_SuggRep_4: 'I-ulat ang website na nagbebenta ng in-game.',
    home_SuggRep_5: 'Maglagay ng mga mungkahi o magsumite ng ulat',
    home_SuggRep_6: 'Halimbawa ng screenshot',
    home_SuggRep_7: 'Gantimpala para sa mga tinanggap na mungkahi at ulat!',
    home_SuggRep_8: 'ipasa',
    
    home_subscription_1: 'Gusto mo bang mag-subscribe sa push notifications?',
    home_subscription_2: 'Maaari kang mag-unsubscribe sa anumang oras.',
    home_subscription_3: 'WonderPush ng Web push',
    home_subscription_4: 'Sandali lang',
    home_subscription_5: 'Subscribe',
    
    home_Affiliate_1: 'Makipag-ugnayan sa amin',
    home_Affiliate_2: 'Bawat kaibigan na imbitahin mo, makakakuha ka ng 10U, mas marami kang imbitahin, mas marami kang makukuha!',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'Email',
    
    home_banner_1: 'Crypto Gaming',
    home_banner_2: '100+ crypto & 50+ na suportadong NFT',
    home_banner_3: 'Decentralized Betting',
    home_banner_4: 'Smart contract betting mula sa decentralized wallet',
    home_banner_5: 'Bet 2 Earn',
    home_banner_6: 'Magtaya, magmina, at mamuhunan para sa patuloy na kita',
    home_banner_7: 'Web3.0 + Casino',
    home_banner_8: 'Ang player ay nagiging Banker & Owner',
    
    home_EventDetails: 'Detalye',

    home_vip_1: "1.Kumita ng kita sa pamamagitan ng pag-sign in+",
    home_vip_2: "2.Kumita ng kita sa pamamagitan ng pag-ikot+",
    home_vip_3: "3.Kumita ng kita sa pamamagitan ng pagbabahagi+",
    home_vip_4: "4.Kumita ng kita sa pamamagitan ng pagrecharge+",
    home_vip_5: "5.Buksan ang chat, maaaring magpadala ng mga mensahe araw-araw",
    home_vip_6: "Mga mensahe",

    
    ERROR_628: 'Ang gift code ay nakuha na',
    ERROR_629: 'Ang gift code ay lubos na nakuha na',
    ERROR_630: 'Hindi aktibo ang gift code',
    ERROR_631: 'Nalipas na ang gift code',
    ERROR_633: 'Hindi magamit ang gift code ng ibang ahente',

    home_Maintaining_1: "Pananatili",
    home_Maintaining_2: "Ang tampok na ito ay nasa pananatili",
    home_Maintaining_3:'END',
    home_Maintaining_4:'START',

    home_Slots: 'Mga Slot',
    home_LiveCasino: 'Live na Casino',
    home_CountryFiat: 'Bansa & Fiat',
    home_CryptNFTs: 'Kripto at NFT',

    home_GoTo:'Pumunta sa',

    home_gameout_1: "Nasa ibang laro ka ba ngayon? Gusto mo bang lumabas?",
    home_gameout_2: "Kanselahin",

     home_BuyFund1:'Gusto mo bang bumili ng growth fund?',

     home_BetFor:'Taya para sa',

     tongzhi_1: "Mungkahi",
     tongzhi_2: "Tugon sa mungkahi at kinatawan.",
     tongzhi_3: "Magaling! Ito ay kamangha-mangha.",
     tongzhi_4: "Naghihintay ng pagsusuri.",
     tongzhi_5: "Pagsusuri ay inaprubahan.",
     tongzhi_6: "Paghahanda ng bayad.",
     tongzhi_7: "Matagumpay na naisagawa ang bayad.",
     tongzhi_8: "Pagsusuri ay hindi inaprubahan.",
     tongzhi_9: "Nabigo ang bayad.",
     tongzhi_10: "Nabigo ang pag-urong.",
     tongzhi_11: "Bayad na.",
     tongzhi_12: "Tapos na.",
     tongzhi_13: "Nabigo ang pagproseso.",
     tongzhi_14: "Bumili",
     tongzhi_15: "Hanggang",
     tongzhi_16: "Mag-withdraw",
     tongzhi_17: "Kriptocurrency",
     tongzhi_18: "Piso",
     tongzhi_19: "I-download ",
     tongzhi_20: "Ang login ay maaaring gantimpalaan",
     tongzhi_21: "I-download",
     tongzhi_22: "Walang iOS na bersyon na available sa ngayon",
     tongzhi_23: "Magpadala",
     tongzhi_24: "Magpadala ng Tips",

     home_banner_9: "Karera ng Kabayo",
     home_banner_10: "Pakikipaglaban ng Manok",
     home_banner_11: "Nakakabighani at Nakakakilig, Manalo ng Malalaking Premyo",
     home_banner_12: "Mainit ang dugo at puno ng fighting spirit",
     

     home_wanfa_1:"Problema sa Laro",
     home_wanfa_2:"Problema sa Paglilipat",
     home_wanfa_3:"Ibang Problema",
     play_not_balance: "Hindi maaaring gamitin ang eUSDT sa pagsusugal sa mga palakasan. Gusto mo bang magpalit ng pera? ",
     qieHuan_balance: "Ang balanse sa kasalukuyang piniling pera ay hindi sapat para maglaro. Gusto mo bang lumipat sa ibang pera?",
     Provided_by: "Mula sa",

     error_0: "Nabigo sa pag-claim ng gantimpala",
     error_1: "Nabigo sa pagkuha ng data",
     error_2: "Hindi nakapag-check-in",
     error_3: "Nabigo ang pag-ikot ng turntable",
     error_4: "Nabigo sa pagkuha ng tulong pinansiyal",
     error_5: "Nabigo ang pagbahagi",
     error_6: "Nabigo ang pagbili ng pondo",
     error_7: "Hindi makuha ang impormasyon ng laro",
     error_8: "Hindi maaaring subukan ang laro na ito",
     error_10: "Nabigo ang limitasyon ng paglipat",
     error_11: "Nabigo ang operasyon",
     error_12: "Nabigo ang pagpapadala ng verification code",
     error_13: "Naratating na ang limitasyon sa pagpapadala",
     error_15: "",
     error_16: "",
     error_17: "",
     error_18: "",
     error_19: "",
     error_20: "",
     error_21: "",
     error_22: "",
     error_23: "",
     error_24: "",
     error_25: "",
     error_26: "",
     error_27: "",
     error_28: "",
     error_29: "",
     error_30: "",
     error_31: "",
     error_32: "",
     error_33: "",
     error_34: "",

    };
    
    export default locale;
    
    
