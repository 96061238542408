const locale = {
    home_StakeNFT: 'NFT பதிவு செய்து பணம் சம்பாதிக்க',
    home_everyday: 'ஒவ்வொரு நாளும்',
    home_Beton: 'பந்தயம் வை',
    home_mining: 'கட்டுமானம் அளிக்கவும்',
    home_shareholder: 'முதலிடர் ஆக இரு',
    home_Stake: 'பங்கு',
    home_dividend: 'லாபங்கள் பிரிக்கவும்',
    home_Banker: 'கூட்டமைப்பாளராக இருக்க',
    home_SIGNUPAND: 'SIGN UP & ',
    home_GET: 'GET',
    home_UPTO: 'UP TO ',
    home_FuniHash: 'புதிய தொகுப்புகள் கேம்',
    home_Option: 'விருப்பங்கள்',
    
    home_Reward: ' Reward',
    home_Reward1: 'Reward',
    home_signUpNow: 'இப்போது பதிவு செய்க',
    home_Casino: 'கஸினோ',
    home_Sports: 'விளையாட்டு',
    home_PROMOTION: 'தளம்',
    home_Checkin: 'பதிவு',
    home_Bouns: 'போனஸ்',
    home_CheckinBouns: 'Check in Bouns',
    home_SPIN: 'ஸ்பின்',
    home_Relief: 'உதவி',
    home_Share: 'பகிர்',
    home_Deposit: 'டெபாசிட்',
    home_Fund: 'திட்டம்',
    home_VIPClub: 'VIP கிளப்',
    home_FastAndEasy: 'முடிவு செய்யப்பட்ட கிரிப்டோ கரன்சியில் அட்டகாசம் வரும் விருது.',
    home_Game: 'விளையாட்டு',
    home_User: 'பயனர்',
    home_Time: 'நேரம்',
    home_BetAmount: 'பணம் பண்ணுங்கள்',
    home_Multiplier: 'மூல்டிப்ளையர்',
    home_Payout: 'கட்டணம்',
    home_JoinOur: 'எங்கள் விளையாட்டு குழுவில் விளையாடுவதற்கு இணையுங்கள்',
    home_JOIN: 'சேர்',
    home_LICENSES: 'உரிமங்கள்',
    home_OurPlatforms: 'எங்கள் தளங்கள்',
    home_Marketplac: 'சந்தை',
    home_Howtoearn: 'சம்பாதிக்க எப்படி',
    home_Games: 'விளையாட்டுகள்',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'முதன்மை மூலம்',
    home_News: 'செய்திகள்',
    home_Aboutsus: 'எங்களை பற்றி',
    home_Industry: 'தொழில்துறை',
    home_Roadmap: 'வழிகாட்டும் வழி',
    home_Partner: 'சகோதரர்கள்',
    home_FAQs: 'முக்கிய வினாக்கள்',
    home_Affiliate: 'சகோதர உறுப்பினர்',
    home_Stayintouch: 'தொடர்பு கொள்ளுங்கள்',
    home_ChatRoom: 'அரட்டை அறுவை',
    home_Selectacontact: 'ஒரு தொடர்புறுத்தல் தேர்வு செய்க',
    home_BetSlip: 'பந்து கைப்படுத்தல்',
    home_Typeyourmessage: 'உங்கள் செய்தி தட்டச்சு செய்க',
    home_AcceptAnyOdds: 'எந்த விகிதமையும் ஏற்க',
    home_ClearAll: 'அனைத்தையும் நீக்கு',
    home_Live: 'நிகழ்நேரம்',
    home_Winner: 'வெற்றி',
    home_EstPayout: 'எக்ஸ்டிமேட் பேய்',
    home_TotalOdds: 'மொத்த பந்து மதிப்பு',
    home_RegistertoBet: 'பந்து செய்ய பதிவுசெய்யவும்',
    home_Single: 'ஒற்றை பந்துகள்',
    home_Multi: 'பல பந்துகள்',
    home_Checkineveryweek: 'குறிப்பிட்ட நாட்கள் வரை பதிவு செய்து, வெகு பரிசுகள் பெறுங்கள்.',
    home_Day: 'நாட்கள்',
    home_Sunday: '1வது நாள்',
    home_Monday: '2வது நாள்',
    home_Tuesday: '3வது நாள்',
    home_Wednesday: '4வது நாள்',
    home_Thursday: '5வது நாள்',
    home_Friday: '6வது நாள்',
    home_Saturday: '7வது நாள்',
    home_FreeSpin: 'புதுமையான சுழற்சி',
    home_Turntheturntable: 'தினசரி சுழற்சியை நடத்தி USGT விருதுக்கு பெறுங்கள்.',
    home_DepositFift: 'டெபாசிட்',
    home_ANYAMOUNT: 'உங்களுக்கு அந்த தொகையில் உள்ளது',
    home_DEPOSIT: 'தொகை',
    home_First: 'முதல்',
    home_ONLY: 'மட்டும்',
    home_1ST: '1வது',
    home_2ND: '2வது',
    home_3TH: '3வது',
    home_ANYAMOUNT: 'எந்த தொகையும்!',
    home_EXTRA: 'கூடுதல்',
    home_Relief: 'உதவி',
    home_Getrelief: 'விரும்பினால் தருகிறோம் தங்கம் பெற அனுமதி.',
    home_YOUCANCLAIM: 'உங்கள் மொத்த இருப்பு',
    home_YOUCANReceive: 'குறைந்த தொகையில் உதவி கிடைக்கும்.',
    home_threeTimes: ' 3 முறைகள்',
    home_everydayTimes: ' / ஒவ்வொரு நாளும்!',
    home_Times: 'முறை:',
    home_TotalAssets: 'மொத்த சொத்துக்கள்',
    home_receive: 'பெறுக',
    home_TODAY: 'இன்று',
    home_Share: 'பகிரு',
    home_PLATFORMSHARING: 'பிளாட்ஃபார்ம் பகிர்ந்து கொள்ளுங்கள்',
    home_Sharewith: 'பெற FuniBet ஐப் பகிரவும்',
    home_SHARINGGIFT: 'பகிர்ந்து கொள்ளும் பரிசுகள்',
    home_CODE: 'பகிர் குறியீடு',
    home_Selectplatform: 'பகிர்ந்து கொள்ளுங்கள் தளம் தேர்வு செய்யவும்:',
    home_Fund: 'வளர்ச்சி',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
    home_BuyFund: 'வாங்க',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'பெறப்பட்டது',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'சிறந்த பயனாளர் விருப்பங்கள்',
    home_Asawayof: 'உங்கள் விஐபி வீரர்களுக்கு FuniBet தொகுதி உங்கள் கனவுகளைத் தெரிவிக்க மற்றும் மகிழ்ச்சியை அளிக்க உதவுகின்றது. பெற்றிகள், மதிப்புக்கள் மற்றும் சிறப்பு கருத்துக்கள் உள்ளன. உங்கள் VIP மார்க்கெட் விருப்பங்களை மறக்காதீர்கள்! ',
    home_Yourcurrent: 'Your current Total wager',
    home_WhyUpgrade: 'எப்படி ஒரு VIP ஆக மேம்படுத்த வேண்டும்?',
    home_VIPhave: 'VIP காரணமாக 24*7 நிறைவு சேவையாக தெரியும். பாஸ்வேர்கள் மற்றும் செலுத்துதல் போன்ற அடிப்படை சேவைகளைப் பெறுகின்றன. புதிய முன்னெச்சரிக்கைகளை பெறுவதும் முன்னுரிமை பெறுவதும் VIP பேர்கரு சந்திப்புகளில் கிடைக்கும். FuniBet துறைகள் புதிய திறனுக்கும் முன்னெச்சரிக்கைகளுக்கும் பிரதிபலிக்க இயலும். ',
    home_UpgradeYour: 'உங்கள் VIP அளவை மேம்படுத்துங்கள்',
    home_ASaFunibet: 'FUNIBet VIP உறுப்பினராக இருந்து பெறும் பெரும் வெகுமதிகளும் அருமைகளும் முழுமையாக அலங்கரிக்கலாம். பல பதிவுகள் செய்ய அல்லது VIP மதிப்பை இயக்குவதன் மூலம் அதிக அருமைகளை பெற முடியும். ',
    home_YOUCANREACH: 'நீங்கள் உங்கள் அளவை மேம்படுத்தலாம்',
    home_LEVELS: 'நிலைகள்',
    home_eachthehigh: 'ஒவ்வொருவரும் 25% பணத்திருப்பு பரிசு பெறலாம், மேலும் அங்கு உள்ள எல்லா பயன்பாட்டின் நன்மைகளையும் பகிரலாம். FUNIBET விளையாடுவதற்கு சிறந்த அனுபவம் மற்றும் உயர் சேவையை பகிருங்கள்!',
    home_Cashback: 'பணம் திரும்பல்',
    home_Nowater: '1.No water pumping in the game',
    home_Moregrowth: '2.More growth funds',
    home_Increasein: '3.Increase in the number of benefits',
    home_Doublethenumber: '4.Double the number of turntables',
    home_Doublethesign: '5.Double the sign in reward',
    home_Doublesharingrewards: '6.Double sharing rewards',
    home_DoubletheAffiliate: '7.Double the Affiliate reward',
    home_Rechargerewardamount: '8.Recharge reward amount X 1.5',
    home_ABOUTVIPCLUB: 'ABOUT VIP CLUB',
    home_HightopRank: 'High top Rank has 25% cashback bonus and the best benefits. Stay with BeDao to receive the best gaming experience and high level of service!',
    home_Beingamember: 'Being a member of the VIP club means that you have access to:',
    home_PersonalVIPmanager: 'Personal VIP manager',
    home_Highercahbackrewards: 'Higher cahback rewards',
    home_Otheradditionalperks: 'Other additional perks',
    home_Insights: 'தலைமை',
    home_WeeklyandMonthlyBonuses: 'வாராந்திர மற்றும் மாத போனஸ்',
    home_Showhighgaming: 'VIP செயல்பாடுகளுக்கு அழைப்புகள் உள்ளன.',
    home_BindMobile: 'இண்டைப்பு',
    home_Pleasecheckthe: 'விளையாட்டில் பெற்ற USGT ஐ உங்கள் பணப்பருவில் சரிபார்க்கவும்।',
    home_BINDMOBILENUMBER: 'எண் இணைக்குக',
    home_Get1USDT: '1USDT ஐ பெறுங்கள்',
    home_Bind: 'பிணைப்பது',
    home_GiftCode: 'பரிசு குறியீடு',
    home_EnteryourGiftCode: 'Enter your Gift Code',
    home_EnterCode: 'குறியீடை உள்ளிடுக',
    home_Paste: 'ஒட்டு',
    home_confirms: 'உறுதி',
    home_BuyCrypto: 'வாங்க',
    home_SignedIn: 'பதிவு செய்யப்பட்டது',
    home_CommissionRules: 'கமிஷன் விதிகள்',
    home_AffiliateTerms: 'சகோதர விதிகள்',
    home_AffiliateDashboard: 'சகோதர டாஷ்போர்டு',
    home_AFFILIATE: 'சகோதரர்',
    

    
    home_COMMISSONREADYEVERYDAY: 'திட்டமிடப்பட்ட முடிவு நாள் வருகின்றது.',
    home_MYREFERRALCODE: 'என் பரிந்துரை குறியீடு',
    home_MYREFERRALLink: 'என் பரிந்துரை இணைப்பு',
    home_AffiliateReward: 'உறுதிப்படுத்தப்பட்ட',
    home_EXTRA: 'கூடுதல்',
    home_USDREWARDS: 'டாலர் விருதுகள்',
    home_USDRewardsRules: 'டாலர் விருதுகள் விதிமுறைகள்',
    home_JUSAREFERRALAWAY: 'ஒரு பரிந்துரை மட்டுமே',
    home_NewlyAvailableRewards: 'புதிய விருதுகள் கிடைக்கும்',
    home_ReceivedMao: 'பெற்றவுடன்:',
    home_Locked: 'பூட்டப்பட்டது:',
    home_Yourcurrentfriends: 'உங்கள் நண்பர்கள் செயலிக்கும் வரை நீங்கள் விருதுகளை திறக்க முடியாது.',
    home_Withdraw: 'திரும்பவும்',
    home_MY: 'என்',
    home_COMMISSIONREWARDS: 'கமிஷன் வெகுமதி',
    home_CommissionRewardRules: 'கமிஷன் வெகுமதி விதிகள்',
    home_NewlyAvailableCommissionRewards: 'புதிய கமிஷன் வெகுமதிகள்',
    home_CommissionYouhaveReceivedinTotal: 'கமிஷன் பெற்றது:',
    home_REWARDSSENTTODATE: 'பரிசுகள் தேதியின் பரிமாணம்',
    home_USDrewardssenttodate: 'அப் தேதிக்கு வெளியிடப்பட்ட அமெரிக்க டாலர் பரிசுகள்',
    home_Commissionsenttodatet: 'கமிஷன் அனுப்பப்பட்ட தேதி',
    home_Areyouablogger: 'நீ பிளாக்கர் ஆகின்றாயா? நாங்கள் பெரிய படிக்கையுள்ளவர்களையும் பிரபல அனுபவமுள்ளவர்களையும் கொண்டு உள்ளீர்களா? நாங்கள் கடிதம் வழங்கி பரிந்துரைகள் வழங்குகின்றோம்.',
    home_onlythoseusers: 'முக்கிய அறிவிப்பு: நிர்வாகத்தரால் கோரியுள்ளவர்களும் நிர்வாகத்தரம் அங்கீகரிக்கப்பட்ட பயனர்களும் மட்டுமே இந்த திட்டத்தில் பங்கேற்க முடியும்.',
    home_notice: 'அறிவிப்பு',
    home_bindPhone: 'போன் பிணைப்பு',
    home_SignIn: 'உள்நுழைக',
    home_SignUp: 'பதிவுபெறுக',
    home_SignOut: 'வெளியேறு',
    home_CookiesPolicy: 'குக்கீகள் கொள்கை',
    home_isUsingCookies: 'Funibet.com சிறந்த சேவையை வழங்குகின்றது மற்றும் உங்கள் குக்கிகள் உறுதிப்படுத்தப்பட்டுள்ளன. தரவரித்தல் கொள்கைக்கு பார்வையிடவும். ',
    home_SETTING: 'அமைப்பு',
    home_LiveSupport: 'நிகழ்வை ஆன்லைனில் உதவி',
    home_Accept: 'ஏற்கனவே',
    home_FrequentlyAskedQuestions: 'எப்போதும் கேள்விகள்',
    home_SiteUnnderConstruction: 'தளம் கட்டமைக்கப்படுகிறது',
    home_ComingSoon: 'விரைவில் வரும்',
    home_Wallet: 'Wallet',
    home_UserId: 'ஐடி: ',
    home_UserName: 'பெயர்: ',
    home_turnTimes: 'சுழற்சி மட்டுமே:',
    home_Bonusincreased: "வெகுமதி+",
    home_bonus: 'போனஸ்',
    home_SPINBONUSTOTAL: 'மொத்த போன்ஸ்',
    home_WIN: 'WIN:',
    home_In: 'in',
    home_FirstDepositBonus: 'First Deposit Bonus',
    home_Hi: 'வணக்கம்',
    home_welcomeAboard: 'மீண்டும் வருக',
    home_Outoftime: 'இன்று சமயம் முடிவு செய்யப்பட்டது',
    
    game_search: 'தேடு',
    game_SortBy: 'வகைப்படுத்து',
    game_Created: 'உருவாக்கப்பட்ட நேரம்',
    game_Lobby: 'லாபி',
    game_LiveCasino: 'நேரடி காசினோ',
    game_FeaturedSlots: 'சிறப்பு ஸ்லாட்டுகள்',
    game_GameShows: 'விளையாட்டு காட்சிகள்',
    game_FuniBetOriginals: 'FuniBet மூல விளைவுகள்',
    game_BigWins: 'பெரிய வெற்றிகள்',
    game_LcukyWins: 'பாக்கிய வெற்றிகள்',
    game_Challenges: 'முறைகள்',
    game_Description: 'விளக்கம்',
    game_Slots: 'ஸ்லாட்டுகள்',
    game_Rank: 'வரிசை',
    game_User: 'பயனர்',
    game_Date: 'தேதி',
    game_BetAmount: 'பணம்',
    game_lucky: 'அதிகமான கூடு',
    game_Multiplier: 'மூலகம்',
    game_Payout: 'விளைவு',
    game_Recommend: 'பரிந்துரைக்கிறோம்',
    game_Providers: 'வழங்குநர்கள்',
    game_startGame: 'Start',
    game_balance: 'மீதுள்ள தொகை',
    game_provider: 'குறிச்சொற்கள்',
    game_Selectyourdisplay: 'உங்கள் காண்பிக்கையை தேர்ந்தெடுக்கவும்',
    game_Balancein: 'மீதுள்ள தொகை',
    game_FunPlay: 'டெமோ',
    game_RealPlay: 'உணர்வு முறை',
    game_DispLaying: "காட்டு",
    game_LoadMore: "மேலும் ஏற்றுக",
    game_NoData: 'இந்த வகையான விளையாட்டு விளக்கப்படவில்லை',
    
    message_SigningIn: "JWT மூலம் உள்நுழைக்கும்",
    message_CourseSaved: "பாடநெறிகள் சேமிக்கப்பட்டன",
    message_PleaseBind: "சேய்த்திருந்து பங்கேற்று விரும்பும்போது, உங்கள் கைபேசி பிண்ணை பிண்ணல் வேண்டும். பிண்ணுவீரா?",
    message_UserData: "API மூலம் பயனர் தரவுகள் சேமிக்கப்பட்டன",
    message_Success: "வெற்றி",
    message_VIPlevel: "விஐபி நிலை V3 க்குள் குறைவாக உள்ளது",
    message_ListOrder: "பட்டியல் ஆர்டர் சேமிக்கப்பட்டது",
    message_CardOrder: "அட்டை ஆர்டர் சேமிக்கப்பட்டது",
    message_HiHow: "வணக்கம், நீங்கள் எப்படி உள்ளீர்கள்?",

    Funibet_web: 'Funibet-வலைப்பேசி 3.0,கிரிப்டோ,பந்தயம்,விளையாட்டு,மொழியக் காட்சிகள்,ஸ்லாட்கள்,நேரடி காசினோ, போக்கர்',

    home_ThereAre:"இப்போது அறிவிகள் எதுவும் இல்லை.",

    play_no_balance: "தற்போதைய நாணய மீது பொருட்குறைவு உள்ளது, முறையிடவும் பணம் ரீசார்ஜ் செய்யவும்",
    
    home_News_1:'செய்திகள்',
    home_News_2:'நிகழ்வு',
    home_News_3:'கிரிப்டோ',
    home_News_4:'கேமிங்',

    home_SuggRep_1:'பரிந்துரைகள் மற்றும் புகாரளிகள்',
    home_SuggRep_2:'பரிந்துரைகள் மற்றும் புகாரளிகள்',
    home_SuggRep_3:'மேம்படுத்த கருத்து பகிரவும்!',
    home_SuggRep_4:'விளையாட்டில் வாடிக்கையாளர்களுக்கு வலைத்தளம் புகுபதிகை செய்யுங்கள்.',
    home_SuggRep_5:'பரிந்துரைகள் உள்ளிடவும் அல்லது புகாரளிகள் சமர்ப்பிக்கவும்',
    home_SuggRep_6:'மாதிரி படம்',
    home_SuggRep_7:'ஏற்கனவே ஏற்றுக்கொள்ளப்பட்ட பரிந்துரைகள் மற்றும் புகாரளிகளுக்கு விருது!',
    home_SuggRep_8:'சமர்ப்பிக்கவும்',

    home_subscription_1: 'புஷ் அறிவிப்புகளுக்கு இணைந்துகொள்வதற்கு விரும்புகிறீர்களா?',
    home_subscription_2: 'நீங்கள் எப்போதும் விரும்பாதபடி விடலாம்.',
    home_subscription_3: 'WonderPush விழிப்பு',
    home_subscription_4: 'பின்னர்',
    home_subscription_5: 'உறுதி',

    home_Affiliate_1: 'எங்களை தொடர்பு கொள்ள',
    home_Affiliate_2: 'உங்களுக்கு உதவுவதற்கு ஒவ்வொரு நண்பருக்கும் 10 யு கிடைக்கும், நீங்கள் அதிகம் அழைத்தால் அதிகம் கிடைக்கும்!',
    home_Affiliate_3: 'தொலைநூல்',
    home_Affiliate_4: 'மின்னஞ்சல்',

    home_banner_1:'கிரிப்டோ விளையாட்டு',
    home_banner_2:'100+ கிரிப்டோ மற்றும் 50+ NFT கட்டுமானங்கள் ஆதரிக்கப்படுகின்றன',
    home_banner_3:'மாறும் பந்தயம்',
    home_banner_4:'மாறும் பந்தயம் மூலம் மாறும் வளையாட்டு',
    home_banner_5:'பந்தயம் செய்து வருக',
    home_banner_6:'திறக்கும் லாபத்திற்கு பந்தயம் செய்யுங்கள்',
    home_banner_7:'வெப்3.0 + கஸினோ',
    home_banner_8:'நீங்கள் பங்குகாரரும் பொருளாளரும் ஆகும்',
    
    home_EventDetails: 'Details',

    home_vip_1: "1.பதிவு செய்து வருமானத்தைப் பெறுங்கள்+",
    home_vip_2: "2.சுழற்றி வருமானத்தைப் பெறுங்கள்+",
    home_vip_3: "3.பகிர்வு மூலம் வருமானத்தைப் பெறுங்கள்+",
    home_vip_4: "4.மீட்டர் மூலம் வருமானத்தைப் பெறுங்கள்+",
    home_vip_5: "5.அனுபவிப்பதற்கு அனுபவம் மிக்க நாளாக அனுப்ப முடியும்",
    home_vip_6: "செய்திகள்",

    
    ERROR_628: 'பரிசு குறியீடு ஏற்கனவே பயன்படுத்தப்பட்டுள்ளது',
    ERROR_629: 'பரிசு குறியீடு முழுமையாக பயன்படுத்தப்பட்டது',
    ERROR_630: 'பரிசு குறியீடு செயலில்லை',
    ERROR_631: 'பரிசு குறியீடு காலாவதியாகிவிட்டது',
    ERROR_633: 'மற்ற முதன்மையாளரின் பரிசு குறியீடைப் பயன்படுத்த முடியாது',

    home_Maintaining_1: "பராமரிப்பு",
    home_Maintaining_2: "இந்த அம்சம் பராமரிப்பில் உள்ளது",
    home_Maintaining_3: "முடிவு",
    home_Maintaining_4: "தொடங்கு",

    home_Slots: 'ஸ்லாட்டுகள்',
    home_LiveCasino: 'நேரடி காசினோ',
    home_CountryFiat:'Country & Fiat',
    home_CryptNFTs:'Crypt & NFTs',

    home_GoTo:'Go To',

    home_gameout_1: "நீங்கள் தற்போது மற்ற விளையாட்டில் உள்ளீர்களா? வெளியேற விரும்புகிறீர்களா?",
    home_gameout_2: "ரத்துசெய்",

     home_BuyFund1:'வளர்ச்சி நிதியை வாங்க வேண்டுமா?',

     home_BetFor:'பந்தயம் செய்',

     tongzhi_1: "வார்த்தைகள்",
     tongzhi_2: "வார்த்தைகள் மற்றும் பிரதிநிதி பதில்.",
     tongzhi_3: "நன்றாக செய்தது! இது அதிசயம்.",
     tongzhi_4: "மதிப்பீட்டிற்கு காத்திருக்கின்றது.",
     tongzhi_5: "மதிப்பீடு அனுமதிக்கப்பட்டது.",
     tongzhi_6: "கட்டணத் திட்டம்.",
     tongzhi_7: "கட்டணம் வெற்றிகரமாக முடிந்தது.",
     tongzhi_8: "மதிப்பீடு அனுமதிக்கப்படவில்லை.",
     tongzhi_9: "கட்டணம் தோல்வியடைந்தது.",
     tongzhi_10: "பணம் விளையாடாதது.",
     tongzhi_11: "செலுத்தப்பட்டது.",
     tongzhi_12: "முடிந்தது.",
     tongzhi_13: "செயல்பாடு தோல்வியடைந்தது.",
     tongzhi_14: "வாங்க",
     tongzhi_15: "வரை",
     tongzhi_16: "வெளியேற்ற",
     tongzhi_17: "குறிப்புமுகம்",
     tongzhi_18: "வரப்பொருள்",
     tongzhi_19: "பதிவிறக்க",
     tongzhi_20: "உள்நுழைந்தால் விருதுக்கள் பெற முடியும்",
     tongzhi_21: "பதிவிறக்க",
     tongzhi_22: "தற்போது iOS பதிப்பு கிடைக்கவில்லை",
     tongzhi_23: "அனுப்ப",
     tongzhi_24: "குறிப்புகள் அனுப்பு",
     tongzhi_23: "Gönder",
     tongzhi_24: "İpuçları Gönder",
    
     home_banner_9: "குதிக்குத்தி",
     home_banner_10: "கோழி சந்தை",
     home_banner_11: "முக்கியமாக உழைக்கின்றது, பெரும் விருதுகள் வாங்குங்கள்",
     home_banner_12: "உழைப்பதில் ஆக்கத்துடன் நிரம்பிய",
     
     home_wanfa_1:"விளையாட்",
     home_wanfa_2:"பரிமாற்ற சிக்கல்",
     home_wanfa_3:"மற்ற பிரச்சினை",
     play_not_balance: "கிரிக்கெட் விதிமுகமாக eUSDT உபயோகிப்பது முடியாது. நீங்கள் பரிமாற்ற விரும்புகின்றீர்களா? ",
     qieHuan_balance: "தற்போதைய தேர்ந்தெடுத்த நாணயத்தில் அதிகமாக இருந்தால் விளக்க கேம் ஆட முடியாது. மற்ற நாணயத்திற்கு மாற்ற விரும்புகிறீர்களா?",
     Provided_by: "இருந்து",
     
     error_0: "வெற்றியை பெறுவதில் தோல்வி",
     error_1: "தரவுகளைப் பெறுவதில் தோல்வி",
     error_2: "செக் இன் தோல்வி",
     error_3: "முனையாக முடியவில்லை சுழற்சி",
     error_4: "நிதி உதவியை பெற தோல்வி",
     error_5: "பகிரும் தோல்வி",
     error_6: "நிதி வாங்குவது தோல்வி ஏற்பட்டது",
     error_7: "விளையாட்டு தகவலைப் பெற முடியாது",
     error_8: "இந்த விளையாட்டை விளக்க முடியாது",
     error_10: "கடன் மீது அனுப்பு தோல்வி",
     error_11: "செயல்பாடு தோல்வியுற்றது",
     error_12: "சரிபார்த்தல் குறியீட்டை அனுப்ப தோல்வியுற்றது",
     error_13: "அனுப்புவதில் வரம்பு அடையாளம் அடையப்பட்டுள்ளது",
     error_15: "",
     error_16: "",
     error_17: "",
     error_18: "",
     error_19: "",
     error_20: "",
     error_21: "",
     error_22: "",
     error_23: "",
     error_24: "",
     error_25: "",
     error_26: "",
     error_27: "",
     error_28: "",
     error_29: "",
     error_30: "",
     error_31: "",
     error_32: "",
     error_33: "",
     error_34: "",
    };
    
    export default locale;
    
    
