import axios from 'axios'
import history from '@history'

const service = axios.create({
    timeout: 50000, // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        if (!config.headers['Finger-Nft-Token']) {
            config.headers['Finger-Nft-Token'] = (
                window.localStorage.getItem('Authorization') ||
                window.localStorage.getItem(
                    `Authorization-${
                        window.sessionStorage.getItem('openAppId') || 0
                    }-${
                        window.sessionStorage.getItem('openIndex') || 0
                    }`
                )
            ) || '';
        }

        if (!config.headers['Wallet-OpenApp-Id']) {
            config.headers['Wallet-OpenApp-Id'] = window.sessionStorage.getItem(
                'openAppId'
            ) || '6436951541b60d250c692481';
            // ) || '64915ebf60b24e97a4584544';
        }

        if (!config.headers['Wallet-OpenApp-Index']) {
            config.headers['Wallet-OpenApp-Index'] = window.sessionStorage.getItem(
                'openAppIndex'
            ) || '0';
        }

        return config;
    },
    err => Promise.reject(err)
)


// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.errno === 501) {
            setTimeout(() => {
                // localStorage.removeItem('Authorization');
                // window.localStorage.removeItem(
                //     `Authorization-${
                //         window.sessionStorage.getItem('openAppId') || 0
                //     }-${
                //         window.sessionStorage.getItem('openIndex') || 0
                //     }`
                // );
            }, 500);
            return res;
        }else {
            return res;
        }
    }, error => {
        return {
            errno: 400,
            errmsg: error.message,
        }
    }
)

export default service
