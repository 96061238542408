const locale = {
    home_StakeNFT: 'NFT ставки - бесплатно ',
    home_everyday: 'каждый день',
    home_Beton: 'Ставьте на ',
    home_mining: 'майнинг',
    home_shareholder: ' Чтобы стать акционером',
    home_Stake: 'Ставьте',
    home_dividend: ' Получайте дивиденды',
    home_Banker: ' Чтобы стать Банкиром',
    home_SIGNUPAND: 'SIGN UP & ',
    home_GET: 'GET',
    home_UPTO: 'UP TO ',
    home_FuniHash: 'Доказуемо честно',
    home_Option: 'Опция',
    
    home_Reward: ' Reward',
    home_Reward1: 'Reward',
    home_signUpNow: 'Зарегистрируйтесь',
    home_Casino: 'Казино',
    home_Sports: 'Спорт',
    home_PROMOTION: 'Акция',
    home_Checkin: 'Регистрация',
    home_Bouns: 'бонус',
    home_CheckinBouns: 'Check in Bouns',
    home_SPIN: 'Крутить',
    home_Relief: 'Разрядка',
    home_Share: 'Поделиться',
    home_Deposit: 'Депозит',
    home_Fund: 'Фонд',
    home_VIPClub: 'VIP-клуб',
    home_FastAndEasy: 'Быстрый и простой способ покупки криптовалюты с бонусом на депозит до USDT',
    home_Game: 'Игра',
    home_User: 'Пользователь',
    home_Time: 'Время',
    home_BetAmount: 'Сумма ставки',
    home_Multiplier: 'Множитель',
    home_Payout: 'Выплата',
    home_JoinOur: 'Присоединяйтесь к нам - любители спорта, крипто и азартных игр',
    home_JOIN: 'Участие',
    home_LICENSES: 'ЛИЦЕНЗИИ',
    home_OurPlatforms: 'Наши платформы',
    home_Marketplac: 'Маркетплейс',
    home_Howtoearn: 'Как заработать',
    home_Games: 'Игры',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'Инвесторский доклад',
    home_News: 'Новости',
    home_Aboutsus: 'О нас',
    home_Industry: 'Индустрия',
    home_Roadmap: 'Дорожная карта',
    home_Partner: 'Партнер',
    home_FAQs: 'Часто задаваемые вопросы',
    home_Affiliate: 'Партнерская программа',
    home_Stayintouch: 'Оставайтесь на связи',
    home_ChatRoom: 'Чат',
    home_Selectacontact: 'Выберите контакт, чтобы начать разговор.',
    home_BetSlip: 'Купон ставок',
    home_Typeyourmessage: 'Введите сообщение',
    home_AcceptAnyOdds: 'Принимать любые коэффициенты',
    home_ClearAll: 'Очистить всё',
    home_Live: 'Лайв',
    home_Winner: 'Победитель',
    home_EstPayout: 'Предполагаемый выигрыш',
    home_TotalOdds: 'Общий коэффициент',
    home_RegistertoBet: 'Зарегистрируйтесь, чтобы делать ставки',
    home_Single: 'Одиночные',
    home_Multi: 'Множественные',
    home_Checkineveryweek: 'Заходите каждую неделю и получайте соответствующие награды.',
    home_Day: 'День',
    home_Sunday: 'Воскресенье',
    home_Monday: 'Понедельник',
    home_Tuesday: 'Вторник',
    home_Wednesday: 'Среда',
    home_Thursday: 'Четверг',
    home_Friday: 'Пятница',
    home_Saturday: 'Суббота',
    home_FreeSpin: 'Бесплатный спин',
    home_Turntheturntable: '.',
    home_DepositFift: 'депозит',
    home_ANYAMOUNT: 'ЛЮБАЯ СУММА ПЕРВОГО ДЕПОЗИТА ДАСТ ВАМ',
    home_DEPOSIT: 'ДЕПОЗИТ',
    home_First: 'Первый',
    home_1ST: '1-й',
    home_2ND: '2-й',
    home_3TH: '3-й',
    home_ANYAMOUNT: 'ЛЮБАЯ СУММА!',
    home_EXTRA: 'ДОПОЛНИТЕЛЬНО',
    home_ONLY: 'ТОЛЬКО',
    home_Relief: 'Разрядка',
    home_PLATFORMSHARING: 'PLATFORM SHARING',
    home_Getrelief: 'Get a chance to turn your luck around.',
    home_YOUCANCLAIM: 'When your balance is less than ',
    home_YOUCANReceive: ', you can receive relief and supplement your balance to ',
    home_threeTimes: ' 3 times',
    home_Times: 'times',
    home_everydayTimes: '/day!',
    home_TotalAssets: 'Total Assets',
    home_receive: 'receive',
    home_TODAY: 'TODAY',
    home_Share: 'Share',
    home_Sharewith: 'Share FuniBet every day and get ',
    home_SHARINGGIFT: 'SHARING GIFT PACKAGE',
    home_CODE: 'CODE',
    home_Selectplatform: 'Select a platform to share:',
    home_Fund: 'Funds',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
    home_BuyFund: 'покупать',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'статус',
    home_Received: 'Получено',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'Эксклюзивные преимущества',
    home_Asawayof: 'В знак благодарности и признательности нашим VIP-игрокам FuniBet приглашает вас присоединиться к нашему клубу VIP, где вы найдете множество подарков, розыгрышей, более высокий кешбэк и уникальные возможности. Не упустите возможность насладиться всем этим веселым и удивительным!',
    home_Yourcurrent: 'Your current Total wager',
    home_WhyUpgrade: 'Почему обновлять до VIP?',
    home_VIPhave: 'У VIP есть внимательное обслуживание 24 часа в сутки, 7 дней в неделю от эксклюзивного хоста. В дополнение к основному кешбэку и пополнению, VIP также наслаждаются наивысшим уровнем спина, еженедельного / ежемесячного бонуса и самого быстрого канала для депозита и вывода. У VIP есть возможность принять участие в конференции обмена лучшими игроками и улучшить казино, предложив новые функции и возможности команде FuniBet.',
    home_UpgradeYour: 'Обновите свой уровень VIP',
    home_ASaFunibet: 'В качестве члена клуба FUNIBet VIP вы получаете эксклюзивный доступ к щедрым вознаграждениям и персональным подаркам, но это еще не все. Чем больше вы играете, тем больше роскошных преимуществ вы получите, поднимаясь по VIP-рангам, чтобы стать членом Elite VIP, где нет ограничений на индивидуально настроенные VIP-привилегии, которыми FUNIBET будет вас одаривать.',
    home_YOUCANREACH: 'ВЫ МОЖЕТЕ ДОСТИГНУТЬ',
    home_LEVELS: 'УРОВНЕЙ',
    home_eachthehigh: 'Достигните высокого топ-ранга, чтобы получить бонус кэшбэка 25% и открыть все преимущества платформы. Оставайтесь с FUNIBET, чтобы получить лучший игровой опыт и отличное обслуживание!',
    home_Cashback: 'Кешбэк',
    home_Nowater: '1. Нет накачки воды в игре',
    home_Moregrowth: '2.Больше средств для роста',
    home_Increasein: '3.Увеличение количества льгот',
    home_Doublethenumber: '4.Удвоение количества поворотных таблиц',
    home_Doublethesign: '5.Удвоение награды за вход в систему',
    home_Doublesharingrewards: '6.Удвоение вознаграждений за обмен',
    home_DoubletheAffiliate: '7.Удвоение вознаграждения партнера',
    home_Rechargerewardamount: '8.Увеличение вознаграждения за пополнение счета на 1,5',
    home_ABOUTVIPCLUB: 'ABOUT VIP CLUB',
    home_HightopRank: 'High top Rank has 25% cashback bonus and the best benefits. Stay with BeDao to receive the best gaming experience and high level of service!',
    home_Beingamember: 'Being a member of the VIP club means that you have access to:',
    home_PersonalVIPmanager: 'Personal VIP manager',
    home_Highercahbackrewards: 'Higher cahback rewards',
    home_Otheradditionalperks: 'Other additional perks',
    home_Insights: 'Привилегии',
    home_WeeklyandMonthlyBonuses: 'Еженедельные и ежемесячные бонусы',
    home_Showhighgaming: 'Показать высокую игровую активность и получить приглашение в наш VIP-клуб.',
    home_BindMobile: 'Привязать',
    home_Pleasecheckthe: 'Пожалуйста, проверьте полученные вами USGT из игры в своем кошельке.',
    home_BINDMOBILENUMBER: 'Привязать номер телефона',
    home_Get1USDT: 'Получите 1USDT',
    home_Bind: 'Привязать',
    home_GiftCode: 'Код подарка',
    home_EnteryourGiftCode: 'Введите свой код подарка',
    home_EnterCode: 'Ввести код',
    home_Paste: 'Вставить',
    home_confirms: 'confirms',
    home_BuyCrypto: 'покупать',
    home_SignedIn: 'Вход выполнен',
    home_Wallet: 'Wallet',
    home_turnTimes: 'Количество ходов: ',
    home_bonus: ' бонус',
    home_FirstDepositBonus: 'First Deposit Bonus',
    home_Hi: 'Здравствуйте, ',
    home_welcomeAboard: ' добро пожаловать на борт',
    

    
    home_CommissionRules: 'комиссии',
    home_AffiliateTerms: 'партнерской программы',
    home_AffiliateDashboard: 'Панель партнера',
    home_AFFILIATE: 'ПАРТНЕРСТВО',
    home_COMMISSONREADYEVERYDAY: 'КОМИССИОННЫЕ ГОТОВЫ КАЖДЫЙ ДЕНЬ',
    home_MYREFERRALCODE: 'МОЙ РЕФЕРАЛЬНЫЙ КОД',
    home_MYREFERRALLink: 'МОЯ РЕФЕРАЛЬНАЯ ССЫЛКА',
    home_AffiliateReward: 'Награда за партнерство',
    home_EXTRA: 'ДОПОЛНИТЕЛЬНО',
    home_USDREWARDS: ' ДОЛЛАРОВ США НАГРАД',
    home_USDRewardsRules: 'Правила получения долларовых наград',
    home_JUSAREFERRALAWAY: 'ВСЕГО ОДИН РЕФЕРАЛ',
    home_NewlyAvailableRewards: 'Недавно доступные награды',
    home_ReceivedMao: 'Получено:',
    home_Locked: 'Заблокировано:',
    home_Yourcurrentfriends: 'Ваши активные друзья откроют вам больше наград',
    home_Withdraw: 'Вывод',
    home_MY: 'МОИ',
    home_COMMISSIONREWARDS: 'НАГРАДЫ ЗА КОМИССИЮ',
    home_CommissionRewardRules: 'Правила получения наград за комиссию',
    home_NewlyAvailableCommissionRewards: 'Недавно доступные награды',
    home_CommissionYouhaveReceivedinTotal: 'Комиссия в общей сложности:',
    home_REWARDSSENTTODATE: 'Общий призовой фонд',
    home_USDrewardssenttodate: 'Общая сумма денежного вознаграждения',
    home_Commissionsenttodatet: 'Комиссия отправлена до',
    home_Areyouablogger: 'Для блогеров с большой аудиторией у нас есть уникальная партнерская программа с более крупными вознаграждениями за рефералов. Свяжитесь с нами для обсуждения условий: affiliate@beDao.io.',
    home_onlythoseusers: 'Важно: только те пользователи, которые прошли требования менеджера и только после утверждения менеджером, смогут участвовать в программе.',
    home_notice: 'Уведомление',
    home_bindPhone: 'Привязать телефон',
    home_SignIn: 'Войти',
    home_SignUp: 'Регистрация',
    home_SignOut: 'Выйти',
    home_CookiesPolicy: 'Политика использования файлов cookie',
    home_isUsingCookies: 'Funibet.com использует файлы cookie, чтобы обеспечивать лучшее обслуживание каждому пользователю. Используя наш сайт, вы соглашаетесь на использование файлов cookie. Пожалуйста, ознакомьтесь с Политикой конфиденциальности и Условиями использования.',
    home_SETTING: 'Настройки',
    home_LiveSupport: 'Онлайн-поддержка',
    home_Accept: 'Принять',
    home_FrequentlyAskedQuestions: 'Часто задаваемые вопросы',
    home_SiteUnnderConstruction: 'Сайт находится в стадии разработки',
    home_ComingSoon: 'Скоро',
    home_UserId: 'ID: ',
    home_UserName: 'Имя: ',
    home_Bonusincreased: "Бонус увеличен +",
    home_SPINBONUSTOTAL: 'SPIN BONUS TOTAL',
    home_WIN: 'WIN: ',
    home_In: 'в ',
    home_Outoftime: 'Лимит на сегодня исчерпан',
    
    game_search: 'поиск',
    game_SortBy: 'Сортировать по',
    game_Created: 'Создано',
    game_Lobby: 'Лобби',
    game_LiveCasino: 'Лайв казино',
    game_FeaturedSlots: 'Популярные слоты',
    game_GameShows: 'ТВ-игры',
    game_FuniBetOriginals: 'FuniBet оригиналы',
    game_BigWins: 'Крупные выигрыши',
    game_LcukyWins: 'Счастливые выигрыши',
    game_Challenges: 'Вызовы',
    game_Description: 'Описание',
    game_Slots: 'Слоты',
    game_Rank: 'Ранг',
    game_User: 'Пользователь',
    game_Date: 'Дата',
    game_BetAmount: 'Ставка',
    game_lucky: 'Макс. множитель',
    game_Multiplier: 'Множитель',
    game_Payout: 'Выплата',
    game_Recommend: 'Рекомендуем',
    game_Providers: 'Провайдеры',
    game_startGame: 'начать',
    game_balance: 'Баланс',
    game_provider: 'Тег',
    game_Selectyourdisplay: 'Выберите отображение баланса',
    game_Balancein: 'Баланс в',
    game_FunPlay: 'попробуй игру',
    game_RealPlay: 'Режим игры на реальные деньги',
    game_DispLaying: "Отображение",
    game_LoadMore: "Загрузить еще",
    game_NoData: 'Временно нет игр этого типа',
    
    message_SigningIn: "Вход с JWT",
    message_CourseSaved: "Курс сохранен",
    message_PleaseBind: "Чтобы участвовать в активности, вам необходимо привязать телефон. Хотите его привязать?",
    message_UserData: "Данные пользователя сохранены с помощью api",
    message_Success: "успех",
    message_VIPlevel: "Уровень VIP меньше V3",
    message_ListOrder: "Список заказов сохранен",
    message_CardOrder: "Заказ карт сохранен",
    message_HiHow: "Привет, как дела?",

    Funibet_web: 'Funibet-Веб 3.0,Криптовалюты,Ставки,Спорт,Киберспорт,Слоты,Живое казино,Покер',

    home_ThereAre: "Сейчас нет уведомлений.",

    play_no_balance: "Текущий баланс валюты недостаточен, пожалуйста, переключитесь на другую валюту или пополните баланс.",
    
    home_News_1: 'Новости',
    home_News_2: 'События',
    home_News_3: 'Крипто',
    home_News_4: 'Игры',
    
    home_SuggRep_1: 'Предл. и отзывы',
    home_SuggRep_2: 'Предложения и отчеты',
    home_SuggRep_3: 'Поделитесь отзывами, чтобы улучшить сервис!',
    home_SuggRep_4: 'Сообщить о продаже внутриигровых предметов.',
    home_SuggRep_5: 'Введите предложения или отправьте отчет',
    home_SuggRep_6: 'Пример скриншота',
    home_SuggRep_7: 'Награда за принятые предложения и отчеты!',
    home_SuggRep_8: 'Отправить',
    
    home_subscription_1: 'Хотели бы вы подписаться на push-уведомления?',
    home_subscription_2: 'Вы всегда можете отписаться в любое время.',
    home_subscription_3: 'Web push от WonderPush',
    home_subscription_4: 'Позже',
    home_subscription_5: 'Подписка',
    
    home_Affiliate_1: 'Связаться с нами',
    home_Affiliate_2: 'Каждый друг, которого вы приглашаете, дает вам 10U, чем больше вы приглашаете, тем больше вы получаете!',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'Email',
    
    home_banner_1: 'Криптоигры',
    home_banner_2: 'Поддержка более 100 криптовалют и более 50 NFT',
    home_banner_3:'Decentralized Betting',
    home_banner_4:'Smart contract betting from decentralized wallet',
    home_banner_5:'Bet 2 Earn',
    home_banner_6:'Bet, mine, and invest for steady profits',
    home_banner_7: 'Web3.0 + Казино',
    home_banner_8: 'Игрок является также банкиром и владельцем',

    home_EventDetails: 'понимать',

    home_vip_1: "1.Получайте доход за регистрацию+",
    home_vip_2: "2.Получайте доход за вращение+",
    home_vip_3: "3.Получайте доход за публикацию+",
    home_vip_4: "4.Получайте доход за пополнение+",
    home_vip_5: "5.Открыт чат, можно отправлять сообщения ежедневно",
    home_vip_6: "Сообщения",

    
    ERROR_628: 'Подарочный код уже был использован',
    ERROR_629: 'Подарочный код полностью использован',
    ERROR_630: 'Подарочный код неактивен',
    ERROR_631: 'Подарочный код истек',
    ERROR_633: 'Нельзя использовать подарочный код другого агента',

    home_Maintaining_1: "Обслуживание",
    home_Maintaining_2: "Эта функция находится на обслуживании",
    home_Maintaining_3: "КОНЕЦ",
    home_Maintaining_4:'НАЧИНАТЬ',
    
    home_Slots: 'Слоты',
    home_LiveCasino: 'Живое казино',
    home_CountryFiat: 'Страна и фиат',
    home_CryptNFTs: 'Криптовалюты и NFT',

    home_GoTo:'Перейти к',
    
    home_gameout_1: "Вы сейчас находитесь в другой игре? Хотите выйти?",
    home_gameout_2: "Отмена",

     home_BuyFund1:'Хотите ли вы купить фонд роста?',

     home_BetFor:'Ставка на',

     tongzhi_1: "Предложение",
     tongzhi_2: "Ответ на предложение и представителя.",
     tongzhi_3: "Отлично сделано! Это потрясающе.",
     tongzhi_4: "Ожидание проверки.",
     tongzhi_5: "Проверка одобрена.",
     tongzhi_6: "Обработка платежа.",
     tongzhi_7: "Платеж успешно завершен.",
     tongzhi_8: "Проверка не одобрена.",
     tongzhi_9: "Платеж не удался.",
     tongzhi_10: "Снятие средств не удалось.",
     tongzhi_11: "Оплачено.",
     tongzhi_12: "Завершено.",
     tongzhi_13: "Сбой обработки.",
     tongzhi_14: "Покупка",
     tongzhi_15: "До",
     tongzhi_16: "Вывод",
     tongzhi_17: "Криптовалюта",
     tongzhi_18: "Фиатная валюта",
     tongzhi_19: "Скачать ",
     tongzhi_20: "Вход может быть вознагражден",
     tongzhi_21: "Скачать",
     tongzhi_22: "На данный момент нет доступной версии iOS",
     tongzhi_23: "Отправить",
     tongzhi_24: "Отправить советы",
    
     home_banner_9: "Скачки",
     home_banner_10: "Бой петухов",
     home_banner_11: "Захватывающе и волнующе, выиграйте большие призы",
     home_banner_12: "Кровь кипит и полна боевого духа",
     
     home_wanfa_1:"Проблема игрового процесса",
     home_wanfa_2:"Проблема с переводом",
     home_wanfa_3:"Другая проблема",
     play_not_balance: "Ставки на спорт с использованием eUSDT невозможны. Хотите изменить валюту?",
     qieHuan_balance: "Баланс в выбранной валюте недостаточен для игры. Хотите перейти на другую валюту?",
     Provided_by: "Из",

     error_0: "Ошибка при получении награды",
     error_1: "Ошибка при получении данных",
     error_2: "Ошибка при регистрации",
     error_3: "Ошибка при вращении плиты",
     error_4: "Не удалось получить финансовую помощь",
     error_5: "Ошибка при обмене",
     error_6: "Ошибка покупки фонда",
     error_7: "Невозможно получить информацию об игре",
     error_8: "Невозможно опробовать эту игру",
     error_10: "Сбой при переводе лимита",
     error_11: "Ошибка операции",
     error_12: "Ошибка при отправке кода подтверждения",
     error_13: "Достигнут лимит отправки",
     error_15: "",
     error_16: "",
     error_17: "",
     error_18: "",
     error_19: "",
     error_20: "",
     error_21: "",
     error_22: "",
     error_23: "",
     error_24: "",
     error_25: "",
     error_26: "",
     error_27: "",
     error_28: "",
     error_29: "",
     error_30: "",
     error_31: "",
     error_32: "",
     error_33: "",
     error_34: "",
    };
    
    export default locale;
    
    
