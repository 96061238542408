import "../../../styles/index.css";
import "../../../styles/xianStyle.css";
import utils from '../../util/tools/utils'
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import { useState, useEffect, useRef, forwardRef, default as React } from 'react';
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import history from '@history';
import { useDispatch, useSelector } from 'react-redux';
import {
    queryBaseEnsureConfig,
    takeBaseEnsure
} from "../../store/activity/activityThunk";
import { selectUserData } from "../../store/user";
import { countUserAllAssets } from "../../store/user/userThunk";
import AwardPop from '../../components/AwardPop';
import AnimateModal from '../../components/FuniModal';
import { selectUserSetting } from "app/store/user/userSetting";
import { selectUserProperties } from "app/store/user/userProperties";


function Relief(props) {
    const open = props.open;
    const onClose = props.onClose;

    const { t } = useTranslation('mainPage');
    const dispatch = useDispatch();
    const [baseEnsureConfig, setBaseEnsureConfig] = useState({});
    const userData = useSelector(selectUserData);
    const userSetting = useSelector(selectUserSetting);
    const userProperties = useSelector(selectUserProperties);
    const [canTakeTimes, setCanTakeTimes] = useState(0);
    const [userAssets, setUserAssets] = useState(0);
    const [receiveStatus, setReceiveStatus] = useState(0);
    const [countDown, setCountDown] = useState(0);
    const ref = useRef(0);


    const [popAward, setPopAward] = useState(false);
    const [rewardBalance, setRewardBalance] = useState({
        balance: 0,
        symbol: 'eUSDT'
    });

    // IntervalTime:3600
    // ScoreAmount:"1000"
    // ScoreCondition:"5000"
    // StartTime:43200
    // TakeTimes:5

    //当天开始时间
    const curStartTime = () => {
        let time = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        return Math.ceil(time / 1000);
    };

    //  秒数转化为时分秒
    const formatSeconds = (value) => {
        //  秒
        let second = parseInt(value);
        //  分
        let minute = 0;
        //  小时
        let hour = 0;
        //  天
        //  let day = 0
        //  如果秒数大于60，将秒数转换成整数
        if (second > 60) {
            //  获取分钟，除以60取整数，得到整数分钟
            minute = parseInt(second / 60);
            //  获取秒数，秒数取佘，得到整数秒数
            second = parseInt(second % 60);
            //  如果分钟大于60，将分钟转换成小时
            if (minute > 60) {
                //  获取小时，获取分钟除以60，得到整数小时
                hour = parseInt(minute / 60);
                //  获取小时后取佘的分，获取分钟除以60取佘的分
                minute = parseInt(minute % 60)
                //  如果小时大于24，将小时转换成天
                //  if (hour > 23) {
                //    //  获取天数，获取小时除以24，得到整天数
                //    day = parseInt(hour / 24)
                //    //  获取天数后取余的小时，获取小时除以24取余的小时
                //    hour = parseInt(hour % 24)
                //  }
            }
        }

        let result = '' + parseInt(second) + '';
        if (minute > 0) {
            result = '' + parseInt(minute) + ':' + result
        }
        if (hour > 0) {
            result = '' + parseInt(hour) + ':' + result
        }
        //  if (day > 0) {
        //    result = '' + parseInt(day) + '天' + result
        //  }
        return result
    };


    // 计算当前状态
    const dealWithStatus = () => {
        // 开始时间（0点 + startTime） + 领取次数 （配置文件takeTimes - getSetting.properties.CanTakeTimes） * 间隔时间（秒） = 下一次领取的时间
        let startTime = 0 + baseEnsureConfig.StartTime + (baseEnsureConfig.TakeTimes - canTakeTimes) * baseEnsureConfig.IntervalTime;
        startTime = curStartTime() + startTime;
        let curTime = Math.ceil(Date.now() / 1000);

        console.log(userAssets, 'userAssets......')
        if (userAssets >= (baseEnsureConfig.ScoreCondition / 100)) { // 余额大于领取条件,不可领取
            setReceiveStatus(1);
        } else if (canTakeTimes <= 0) { //今日已全部领取,不可领取
            setReceiveStatus(2);
        } else if (curTime < startTime) {
            setReceiveStatus(3);
            let tmpCountDown = startTime - curTime;
            setCountDown(tmpCountDown);

            // 如果存在定时器,先把定时器清空
            if (ref.current) {
                clearInterval(ref.current);
            }

            ref.current = setInterval(() => {
                if (tmpCountDown <= 0) {
                    clearInterval(ref.current);
                    setReceiveStatus(0);
                } else {
                    setCountDown(tmpCountDown--)
                }
            }, 1000);
        }

        setTimeout(() => {
            console.log('ReceiveStatus', receiveStatus);
        }, 5000)

    };

    // 获取配置
    const handleQueryBaseEnsureConfig = () => {
        dispatch(queryBaseEnsureConfig()).then((res) => {
            let result = res.payload;
            if (result) {
                setBaseEnsureConfig(result.data);

                let walletAmount = result.wallet.Crypto + result.wallet.Fiat;
                let gameAmount = result.score.cashCoupon + result.score.insureScore + result.score.score;
                // 需要计算汇率额金额(如果是法币需要计算,虚拟币不用计算)
                let currencyScore = result.score.currencyScore;

                let userAmount = walletAmount + gameAmount / 100; // 缺少计算(currencyScore)
                setUserAssets(userAmount);

                // dispatch(countUserAllAssets()).then((userRes) => {
                //     let asstes = userRes.payload || 0;
                //     setUserAssets(asstes);
                // });
            }

        });
    };

    // 领取救济金
    const doTakeBaseEnsure = () => {
        dispatch(takeBaseEnsure()).then((res) => {
            let result = res.payload;
            if (result) {
                setRewardBalance({
                    balance: result.AddScore / 100,
                    symbol: 'eUSDT'
                });
                setPopAward(true);
                setCanTakeTimes(result.RemainTimes);
                handleQueryBaseEnsureConfig();
                // AddScore:"1112"
                // CashCoupon:"5000"
                // InsureScore:"0"
                // NextTime:46800
                // RemainTimes:4
                // Score:"0"
            }
        })
    };

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    useEffect(() => {
        if (userProperties?.properties?.CanTakeTimes) {
            setCanTakeTimes(userProperties?.properties?.CanTakeTimes);
        }
    }, [userProperties]);

    useEffect(() => {
        if (JSON.stringify(baseEnsureConfig) !== '{}' && JSON.stringify(userProperties.properties) !== '{}') {
            setTimeout(() => {
                dealWithStatus();
            }, 300)
        }
    }, [canTakeTimes, baseEnsureConfig]);


    useEffect(() => {
        if (open) {
            handleQueryBaseEnsureConfig();
        } else {
            if (ref.current) {
                clearInterval(ref.current);
            }
        }
    }, [open]);

    // useEffect(() => {
    //     if (countDown === 0) {
    //         setReceiveStatus(0);
    //     }
    // }, [countDown]);

    return (
        <>
            <AnimateModal className="reliefDi funbetTxt" closeClass="closeBtnRelief" open={open} onClose={onClose}>
                <motion.div
                    variants={container}
                    initial="hidden"
                    animate="show"
                    className="imgHidden" style={{ height: "100%", width: "100%", maxWidth: "360px" }}>
                    <div className="positionAb faGuang turnAni2 " style={{ top: "0px", bottom: "0px", left: "-16px", right: "0px" }}></div>
                    <motion.div variants={item} className="positionAb titleRelief titleTxt" style={{ fontSize: "30px", top: "0px", bottom: "0px", left: "0px", right: "0px", margin: "0 auto" }}>
                        {t("home_Relief")}
                    </motion.div>
                    <motion.div variants={item} className="positionAb bigXcRelief activityMoveRightZero" style={{ top: "114px", bottom: "0px", left: "0px", right: "0px" }}>
                        <div className="bigXcReliefTitle titleTxt2" style={{
                            margin: "114px 0px 0px -40px"
                        }}>
                            ${baseEnsureConfig?.ScoreAmount && (baseEnsureConfig?.ScoreAmount / 100)}USD
                        </div>
                    </motion.div>

                    <motion.div variants={item}>
                        <div className="positionAb text-align bannerRelief activityMoveLeftZero titleTxt2" style={{ top: "66px", bottom: "0px", left: "0px", right: "0px" }}>
                            {t("home_Getrelief")}
                        </div>
                    </motion.div>

                    <motion.div variants={item}
                        className="positionAb "
                        style={{ top: "390px", bottom: "0px", left: "0px", right: "0px", margin: "0 auto", height: "70px", width: "316px", overflow: "hidden", color: "#ffffff", fontSize: "15px" }}
                    >
                        {t("home_YOUCANCLAIM")}
                        <span className='sigin-txt-yellow fontBold'>
                            ${baseEnsureConfig?.ScoreAmount && (baseEnsureConfig?.ScoreAmount / 100)}
                        </span>
                        {t("home_YOUCANReceive")}
                        <span className='sigin-txt-yellow fontBold'>
                            ${baseEnsureConfig?.ScoreAmount && (baseEnsureConfig?.ScoreAmount / 100)}
                        </span>
                        ,
                        <span className='sigin-txt-yellow fontBold'>
                            {baseEnsureConfig?.TakeTimes} times
                        </span>
                        {t("home_everydayTimes")}
                    </motion.div>

                    <motion.div variants={item} className="positionAb " style={{ top: "460px", bottom: "0px", left: "0px", right: "0px", margin: "0 auto" }}>
                        <div className="containerSpinBtn align-item flex justifyContent">
                            {
                                receiveStatus === 0 &&
                                <div className="btn">
                                    <a
                                        onClick={() => {
                                            doTakeBaseEnsure()
                                        }}
                                        style={{
                                            fontSize: "20px",
                                            color: "#ffffff"
                                        }}
                                        className="flex items-center justify-center titleTxt"
                                    >
                                        {t("home_Times")} {canTakeTimes}
                                    </a>
                                </div>
                            }

                            {
                                receiveStatus === 1 &&
                                <div className="funbetTxt" style={{
                                    fontSize: '20px',
                                    fontWeight: 700
                                }}>
                                    {t("home_TotalAssets")} &gt; ${baseEnsureConfig?.ScoreCondition && (baseEnsureConfig?.ScoreCondition / 100)}
                                </div>
                            }

                            {
                                receiveStatus === 2 &&
                                <div className="funbetTxt" style={{
                                    fontSize: '20px',
                                    fontWeight: 700
                                }}>
                                    {t("home_Outoftime")}
                                </div>
                            }

                            {
                                receiveStatus === 3 &&
                                <div className="btn btn-disable">
                                    <a style={{ fontSize: "20px", color: "#A5A5A5" }} className="flex items-center justify-center spin-a-rata-disable">
                                        <img src="assets/images/activity/naoZhong.png" alt="" className="mr-4" />
                                        {formatSeconds(countDown)}
                                    </a>
                                </div>
                            }
                        </div>
                    </motion.div>
                </motion.div>
            </AnimateModal>

            <AwardPop
                open={popAward}
                onClose={() => {
                    setPopAward(false);
                }}
                symbol={rewardBalance.symbol}
                symbolImg={`assets/images/symbol/${rewardBalance.symbol}.png`}
                balance={rewardBalance.balance}
            />
        </>
    );
}
export default Relief;


