import * as React from 'react';
import { useState, useEffect } from 'react';
import history from '@history';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData } from "../../../store/user";
import { userProfile } from "../../../store/user/userThunk";
import { selectCasinoData } from "../../../store/casino";
import { useLocation } from 'react-router-dom';
import { getChat } from "../../../theme-layouts/shared-components/chatPanel/store/chatSlice"
import { openChatPanel } from '../../../theme-layouts/shared-components/chatPanel/store/stateSlice';
function PhoneMenu(props) {
    const location = useLocation();
    const goRouter = (route) => {
        history.push(route);
    };

    const routerName = () => {
        return location.pathname
    };
    const user = useSelector(selectUserData);
    const dispatch = useDispatch();

    const casinoData = useSelector(selectCasinoData);


    const languageMap = {
        "en": "cd5fa417-b667-482d-b208-798d9da3213c",
        "de": "a7806ced-03f1-4197-8b30-00bdd463366b",
        "fr": "f4ad15d9-5a24-463a-88ea-6189d6bb3a53",
        "in": "780d0111-5e5c-4694-8d1d-0ea421971fbf",
        "br": "1eaa3213-ece2-4ba6-8e15-eb36ca388f50",
        "tr": "efae92cc-3bd1-4c6a-a395-b6760c69bd55",
        "ph": "bde636a7-c3d2-4bff-939a-aab11df1516b",
        "jp": "6d80a6f6-2884-4ac4-9c73-06b82c220017",
        "pl": "35190d23-036e-44ef-b545-cc744c626edd",
        "vn": "b018c194-68ec-4915-ab56-e9f3bd2d98db",
        "kr": "b7c355e9-e003-467e-82d2-4f6978c1a696",
        "id": "cfa07b7c-93d1-42e7-9592-493d9efc78ae",
        "es": "00feeb63-c83a-4655-a37e-a07da10cfa1c",
        "ru": "142abf21-e635-4a7d-9330-e57f66adcdbe",
        "fl": "e4f255a3-b5dd-45a7-975f-c399604a399a",
        "th": "ab4f712d-d712-41a8-b567-be4c66c349a4",
        "ae": "ab4f712d-d712-41a8-b567-be4c66c349a5",
        "my": "ab4f712d-d712-41a8-b567-be4c66c349a6",
        "bd": "ab4f712d-d712-41a8-b567-be4c66c349a7",
        "mm": "ab4f712d-d612-41a8-b767-be4z76c349a4",
        "pak": "ab9f719d-d692-49a8-b767-be4z76c349a4",
        "hk": "ab4f712d-d712-41a8-b567-be4c66c349a3",
        "cn": "ab4f712d-d712-41a8-b567-be4z66c349a4"
    };
    const [beforeTab, setBeforeTab] = useState(window.localStorage.getItem('phoneBeforeTab') || 'home');
    const [currentTab, setCurrentTab] = useState(window.localStorage.getItem('phoneTab') || 'home');
    const [phoneBackShow, setPhoneBackShow] = useState(false);
    const changePhoneTab = (tab) => {
        setBeforeTab(currentTab)
        setCurrentTab(tab)
        window.localStorage.setItem('phoneTab', tab);
        window.localStorage.setItem('phoneBeforeTab', currentTab);
    }

    // const loginZheZhao = document.getElementById('loginZheZhao');
    // const loginZheZhao1 = document.getElementsByTagName("body")[0];

    const onLogin = () => {
        FuniBox.Tools.default.openWallet('login', {}, function () {
            // loginZheZhao.style.display = 'none'
            // loginZheZhao1.classList.remove('bodyLock');
            const token = FuniBox.Tools.default.getToken();
            if (token && token !== 'undefined') {
                FuniBox.Tools.default.getUserInfo().then((res) => {
                    if (res) {
                        dispatch(userProfile({
                            token: token,
                            userData: res
                        }));
                    }
                });
            }
        })
    };


    // useEffect(() => {
    //     if (location.pathname.indexOf("game") != -1 || location.pathname.indexOf("gameOther") != -1 || location.pathname.indexOf("play") != -1) {
    //         setCurrentTab('games');
    //     }
    // }, []);

    useEffect(() => {
        let pathName = routerName();
        if (pathName.indexOf('game') !== -1 || location.pathname.indexOf("gameOther") !== -1 || location.pathname.indexOf("play") !== -1) {
            changePhoneTab('games')
            setPhoneBackShow(true)
        } else if (pathName.indexOf('home') !== -1) {
            changePhoneTab('home')
            setPhoneBackShow(false)
        } else if (pathName.indexOf('sport') !== -1) {
            changePhoneTab('sports')
        } else if (pathName.indexOf("vip") !== -1) {
            setPhoneBackShow(true)
        } else if (pathName.indexOf("fund") !== -1) {
            setPhoneBackShow(true)
        } else if (pathName.indexOf("Affiliate") !== -1) {
            setPhoneBackShow(true)
        } else if (pathName.indexOf("faqs") !== -1) {
            setPhoneBackShow(true)
        }else if (pathName.indexOf("documentation") !== -1) {
            setPhoneBackShow(true)
        }else if (pathName.indexOf("Course") !== -1) {
            setPhoneBackShow(true)
        }
    });


    //监听其他地方该值的变化
    useEffect(() => {

        // if (window.localStorage.getItem('phoneTab')) {
        //     setCurrentTab(window.localStorage.getItem('phoneTab'));
        // }

        const homeBtn = document.getElementsByClassName("daoHangText-16")[0];
        const gamesBtn = document.getElementsByClassName("daoHangText-16")[1];
        const sportsBtn = document.getElementsByClassName("daoHangText-16")[2];
        const chatBtn = document.getElementsByClassName("daoHangText-16")[3];
        const walletBtn = document.getElementsByClassName("daoHangText-16")[4];


        const homeXianBtn = document.getElementsByClassName("daoHangXian")[0];
        const gamesXianBtn = document.getElementsByClassName("daoHangXian")[1];
        const sportsXianBtn = document.getElementsByClassName("daoHangXian")[2];
        const chatXianBtn = document.getElementsByClassName("daoHangXian")[3];
        const walletXianBtn = document.getElementsByClassName("daoHangXian")[4];


        const homeIconBtn = document.getElementsByClassName("phoneDaoHangIcon")[0];
        const gamesIconBtn = document.getElementsByClassName("phoneDaoHangIcon")[1];
        const sportsIconBtn = document.getElementsByClassName("phoneDaoHangIcon")[2];
        const chatIconBtn = document.getElementsByClassName("phoneDaoHangIcon")[3];
        const walletIconBtn = document.getElementsByClassName("phoneDaoHangIcon")[4];


        const clickDaoHang = document.querySelectorAll(".phoneDaoHangNoClick");
        clickDaoHang.forEach((link) => link.addEventListener("click", handleClick));

        function handleClick() {
            homeBtn.classList.remove('phoneDaoHangTxtMove', 'phoneDaoHangTxtMove2');
            gamesBtn.classList.remove('phoneDaoHangTxtMove', 'phoneDaoHangTxtMove2');
            sportsBtn.classList.remove('phoneDaoHangTxtMove', 'phoneDaoHangTxtMove2');
            chatBtn.classList.remove('phoneDaoHangTxtMove', 'phoneDaoHangTxtMove2');
            walletBtn.classList.remove('phoneDaoHangTxtMove', 'phoneDaoHangTxtMove2');

            homeXianBtn.classList.remove('phoneDaoHangXianMove', 'phoneDaoHangXianMove2');
            gamesXianBtn.classList.remove('phoneDaoHangXianMove', 'phoneDaoHangXianMove2');
            sportsXianBtn.classList.remove('phoneDaoHangXianMove', 'phoneDaoHangXianMove2');
            chatXianBtn.classList.remove('phoneDaoHangXianMove', 'phoneDaoHangXianMove2');
            walletXianBtn.classList.remove('phoneDaoHangXianMove', 'phoneDaoHangXianMove2');

            homeIconBtn.classList.remove('phoneDaoHangIconMove');
            gamesIconBtn.classList.remove('phoneDaoHangIconMove');
            sportsIconBtn.classList.remove('phoneDaoHangIconMove');
            chatIconBtn.classList.remove('phoneDaoHangIconMove');
            walletIconBtn.classList.remove('phoneDaoHangIconMove');
        }

        if (currentTab === 'games') {
            clickDaoHang.forEach(link => link.classList.remove('phoneDaoHangClick'));
            clickDaoHang[1].classList.add('phoneDaoHangClick');
            gamesBtn.classList.add('phoneDaoHangTxtMove');
            gamesXianBtn.classList.add('phoneDaoHangXianMove');
            gamesIconBtn.classList.add('phoneDaoHangIconMove');
        } else if (currentTab === 'sports') {
            clickDaoHang.forEach(link => link.classList.remove('phoneDaoHangClick'));
            clickDaoHang[2].classList.add('phoneDaoHangClick');
            sportsBtn.classList.add('phoneDaoHangTxtMove');
            sportsXianBtn.classList.add('phoneDaoHangXianMove');
            sportsIconBtn.classList.add('phoneDaoHangIconMove');
        } else {
            clickDaoHang.forEach(link => link.classList.remove('phoneDaoHangClick'));
            clickDaoHang[0].classList.add('phoneDaoHangClick');
            homeXianBtn.classList.add('phoneDaoHangXianMove');
            homeBtn.classList.add('phoneDaoHangTxtMove');
            homeIconBtn.classList.add('phoneDaoHangIconMove');
        }

        // if (beforeTab === 'games') {
        //     if (beforeTab != currentTab) {
        //         gamesBtn.classList.add('phoneDaoHangTxtMove2');
        //         gamesXianBtn.classList.add('phoneDaoHangXianMove2');
        //     }
        // } else if (beforeTab === 'sports') {
        //     if (beforeTab != currentTab) {
        //         sportsBtn.classList.add('phoneDaoHangTxtMove2');
        //         sportsXianBtn.classList.add('phoneDaoHangXianMove2');
        //     }
        // } else {
        //     if (beforeTab != currentTab) {
        //         homeBtn.classList.add('phoneDaoHangTxtMove2');
        //         homeXianBtn.classList.add('phoneDaoHangXianMove2');
        //     }
        // }

    }, [window.localStorage.getItem('phoneTab')]);

    //监听其他地方该值的变化
    useEffect(() => {
        if (window.localStorage.getItem('phoneBackTab') == "yes") {
            setPhoneBackShow(true)
        } else {
            setPhoneBackShow(false)
        }
    }, [window.localStorage.getItem('phoneBackTab')]);


    // function returnBackBtn() {
    //     let pathName = routerName();
    //     if (pathName.indexOf('game') !== -1 || location.pathname.indexOf("gameOther") !== -1 || location.pathname.indexOf("play") !== -1) {
    //         setPhoneBackShow(true)
    //     } else {
    //         setPhoneBackShow(false)
    //     }
    // }


    return (
        <div
            className="flex justify-center maoBoLi align-item funbetTxt borderRadius"
            style={{
                width: '98vw',
                position: 'fixed',
                bottom: "3px",
                right: "1vw",
                left: "1vw",
                height: '5.2rem',
                zIndex: 998
            }}
        >

            {phoneBackShow && <div
                className=""
                onClick={() => {
                    history.back();
                }}
            >
                <img className='' style={{ width: "26px", height: "26px", marginRight: "8px" }} src={currentTab === 'bcak' ? `${React.$imgUrl}/images/home/back-selected.png` : `${React.$imgUrl}/images/home/back.png`} alt="" />
            </div>
            }

            <div
                className="flex justifyContent overflowHidden phoneDaoHangNoClick"
                onClick={() => {
                    window.localStorage.setItem('phoneBackTab', 'no');
                    changePhoneTab('home')
                    goRouter('/home')
                }}
            >
                <img className='phoneDaoHangIcon phoneDaoHangIconMove' style={{ width: "26px", height: "26px", marginRight: "8px" }} src={currentTab === 'home' ? `${React.$imgUrl}/images/home/home-selected.png` : `${React.$imgUrl}/images/home/home.png`} alt="" />
                <div className='text-16' >
                    <div className='daoHangText-16  phoneDaoHangTxtMove'>HOME</div>
                    <div className='borderRadius daoHangXian  phoneDaoHangXianMove' style={{ height: "3px", backgroundColor: "#14C2A3", }}></div>
                </div>
            </div>

            <div
                className="flex justifyContent overflowHidden phoneDaoHangNoClick"
                onClick={() => {
                    window.localStorage.setItem('phoneBackTab', 'yes');
                    changePhoneTab('games')
                    goRouter(`/game/${casinoData.recommondTag}`)
                }}
            >
                <img className='phoneDaoHangIcon phoneDaoHangIconMove' style={{ width: "26px", height: "26px", marginRight: "8px" }} src={currentTab === 'games' ? `${React.$imgUrl}/images/home/games-selected.png` : `${React.$imgUrl}/images/home/games.png`} alt="" />
                <div className='text-16'>
                    <div className='daoHangText-16 phoneDaoHangTxtMove'>GAMES</div>
                    <div className='borderRadius daoHangXian phoneDaoHangXianMove ' style={{ height: "3px", backgroundColor: "#14C2A3", }}></div>
                </div>
            </div>

            <div
                className="flex justifyContent overflowHidden phoneDaoHangNoClick"
                onClick={() => {
                    changePhoneTab('sports')
                    goRouter('/sport/europeanView')

                }}
            >
                <img className='phoneDaoHangIcon phoneDaoHangIconMove' style={{ width: "26px", height: "26px", marginRight: "8px" }} src={currentTab === 'sports' ? `${React.$imgUrl}/images/home/sports-selected.png` : `${React.$imgUrl}/images/home/sports.png`} alt="" />
                <div className='text-16'>
                    <div className='daoHangText-16 phoneDaoHangTxtMove'>SPORTS</div>
                    <div className='borderRadius daoHangXian phoneDaoHangXianMove' style={{ height: "3px", backgroundColor: "#14C2A3", }}></div>
                </div>
            </div>

            <div
                className="flex justifyContent overflowHidden phoneDaoHangNoClick"
                onClick={() => {
                    changePhoneTab('home')
                    dispatch(openChatPanel())
                    dispatch(getChat(languageMap[localStorage.getItem("lang")]))
                }}
            >
                <img className='phoneDaoHangIcon phoneDaoHangIconMove' style={{ width: "26px", height: "26px", marginRight: "8px" }} src={`${React.$imgUrl}/images/home/chat.png`} alt="" />
                <div className='text-16'>
                    <div className='daoHangText-16 phoneDaoHangTxtMove'>CHAT</div>
                    <div className='borderRadius daoHangXian phoneDaoHangXianMove' style={{ height: "3px", backgroundColor: "#14C2A3", }}></div>
                </div>
            </div>
            <div
                className="flex justifyContent overflowHidden phoneDaoHangNoClick"
                onClick={() => {
                    if (user.token) {
                        FuniBox.Tools.default.openWallet('wallet', {}, function () {
                        })
                    } else {
                        onLogin()
                    }
                }}
            >
                <img className='phoneDaoHangIcon phoneDaoHangIconMove' style={{ width: "26px", height: "26px", marginRight: "8px" }} src={`${React.$imgUrl}/images/home/wallet.png`} alt="" />
                <div className='text-16'>
                    <div className='daoHangText-16 phoneDaoHangTxtMove'>WALLET</div>
                    <div className='borderRadius daoHangXian phoneDaoHangXianMove' style={{ height: "3px", backgroundColor: "#14C2A3", }}></div>
                </div>
            </div>

        </div>
    );

}
export default PhoneMenu;
