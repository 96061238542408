import { createAsyncThunk } from '@reduxjs/toolkit';
import React from "react";
import history from '@history';
import { showMessage } from 'app/store/fuse/messageSlice';
import { showMaintainPage } from "../config/maintainConfig";
import country from "../../json/country.json";
import { saveChannel } from "app/store/config/channels";

// 获取Casino游戏类型
export const getCasinoType = createAsyncThunk(
    'casino/getCasinoType',
    async (settings, { dispatch, getState }) => {
        const tagGroup = settings.tagGroup ?? '';
        const res = await React.$api("gamecenter.getCasinoType", {
            tagGroup
        });
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_7'), code: 2 }));
            return false
        }
    }
);

// 获取Casino游戏类型下的列表信息
export const getCasinoGames = createAsyncThunk(
    'casino/getCasinoGames',
    async (settings, { dispatch, getState }) => {
        const casinoID = settings.casinoID || '';
        const mainTagID = settings.mainTagID || '';
        const channel = settings.channel || '';
        const gameId = settings.gameId || '';
        const res = await React.$api("gamecenter.getCasinoGames", {
            casinoID,
            mainTagID,
            channel,
            gameId,
        });
        const { casino, user, userSetting, userLoginCountry, channels } = getState();
        if (res.errno === 0) {
            let resultData = [];
            let lagnNoSupportData = [];
            res.data.forEach((item) => {
                let tmpItem = {...item, allTag: {}};
                let isOneCurrencyShow = true;
                let isCountryShow = false;
                let isFiatShow = false;
                let isLagnSupport = false;

                for(let key  in item.extendTags){
                    var tmpTagName =  item.extendTags[key].map((tag)=>{return tag.tagName});
                    if (key == casino.tagConfig.tagToPlay) {
                        tmpItem.allTag.tags = tmpTagName;

                    } else if (key == casino.tagConfig.tagLang) {
                        if (!tmpItem.allTag.lang) {
                            tmpItem.allTag.lang = tmpTagName;
                        } else {
                            tmpItem.allTag.lang.push(...tmpTagName);
                        }

                    } else if (key == casino.tagConfig.tagCurrency) {
                        if (!tmpItem.allTag.currency) {
                            tmpItem.allTag.currency = tmpTagName;
                        } else {
                            tmpItem.allTag.currency.push(...tmpTagName);
                        }

                    } else if (key == casino.tagConfig.tagCountry) {
                        if (!tmpItem.allTag.country) {
                            tmpItem.allTag.country = tmpTagName;
                        } else {
                            tmpItem.allTag.country.push(...tmpTagName);
                        }

                    } else if (key == casino.tagConfig.tagSymbol) {
                        if (!tmpItem.allTag.symbol) {
                            tmpItem.allTag.symbol = tmpTagName;
                        } else {
                            tmpItem.allTag.symbol.push(...tmpTagName);
                        }

                    } else if (casino.tagConfig.tagLangGroup[0] <= key && key <= casino.tagConfig.tagLangGroup[1]) {
                        tmpTagName = [ ...new Set(tmpTagName.join(',').split(',')) ];
                        if (!tmpItem.allTag.lang) {
                            tmpItem.allTag.lang = tmpTagName;
                        } else {
                            tmpItem.allTag.lang.push(...tmpTagName);
                        }

                    } else if (casino.tagConfig.tagCurrencyGroup[0] <= key && key <= casino.tagConfig.tagCurrencyGroup[1]) {
                        tmpTagName = [ ...new Set(tmpTagName.join(',').split(',')) ];
                        if (!tmpItem.allTag.currency) {
                            tmpItem.allTag.currency = tmpTagName;
                        } else {
                            tmpItem.allTag.currency.push(...tmpTagName);
                        }

                    } else if (casino.tagConfig.tagCountryGroup[0] <= key && key <= casino.tagConfig.tagCountryGroup[1]) {
                        tmpTagName = [ ...new Set(tmpTagName.join(',').split(',')) ];
                        if (!tmpItem.allTag.country) {
                            tmpItem.allTag.country = tmpTagName;
                        } else {
                            tmpItem.allTag.country.push(...tmpTagName);
                        }

                    } else if (casino.tagConfig.tagSymbolGroup[0] <= key && key <= casino.tagConfig.tagSymbolGroup[1]) {
                        tmpTagName = [ ...new Set(tmpTagName.join(',').split(',')) ];
                        if (!tmpItem.allTag.symbol) {
                            tmpItem.allTag.symbol = tmpTagName;
                        } else {
                            tmpItem.allTag.symbol.push(...tmpTagName);
                        }

                    }
                }

                // 供应商只支持一个币种，但是当前选择币种不是该币种则不显示
                if (channels.channelList.length > 0) {
                    let allChannels = {}
                    channels.channelList.map((channel) => {
                        allChannels[channel.channelName] = channel
                    })

                    if (allChannels[tmpItem.gameChannel]?.noSuptMultCurrency) {
                        if (
                            userSetting.setting.currencyType === 2 &&
                            allChannels[tmpItem.gameChannel].useCurrency &&
                            userSetting.setting.fiatCode !== allChannels[tmpItem.gameChannel].useCurrency
                        ) {
                            isOneCurrencyShow = false
                        }
                    }
                }

                // 国家不支持则不显示
                if (tmpItem.allTag.country && tmpItem.allTag.country.length > 0) {
                    if (userLoginCountry.currentCountryData.countryCode) {
                        if (tmpItem.allTag.country.indexOf('*') > -1) {
                            isCountryShow = true;
                        } else if (tmpItem.allTag.country.indexOf(userLoginCountry.currentCountryData.countryCode) > -1) {
                            isCountryShow = true;
                        }
                    } else {
                        if (tmpItem.allTag.country.indexOf('*') > -1) {
                            isCountryShow = true;
                        }
                    }
                }

                // 币种不支持则不显示
                if (tmpItem.allTag.currency && tmpItem.allTag.currency.length > 0) {
                    if (userSetting?.setting?.currencyType === 2) {
                        if (tmpItem.allTag.currency.indexOf('*') > -1) {
                            isFiatShow = true;
                        } else if (tmpItem.allTag.currency.indexOf(userSetting?.setting?.fiatCode) > -1) {
                            isFiatShow = true;
                        }
                    } else {
                        isFiatShow = true;
                    }
                }

                // 语言不支持则排序到最后
                var tmpLangArr = [];
                Object.keys(country).map((item, index)=>{
                    if (country[item].langCode === window.localStorage.getItem('lang')) {
                        tmpLangArr.push(item)
                    }
                })
                if (tmpItem.allTag.lang && tmpItem.allTag.lang.length > 0) {
                    if (tmpItem.allTag.lang.indexOf('*') > -1) {
                        isLagnSupport = true;
                    } else {
                        tmpLangArr.map((item)=>{
                            if (tmpItem.allTag.lang.indexOf(item) > -1) {
                                isLagnSupport = true;
                            }
                        })
                    }
                }

                var isShow = (isCountryShow && isFiatShow && isOneCurrencyShow);
                if (isShow && isLagnSupport) {
                    resultData.push(tmpItem)
                } else if (isShow && !isLagnSupport) {
                    lagnNoSupportData.push(tmpItem)
                }
                // arr.map((item)=>{return item.a})
            });

            return resultData.concat(lagnNoSupportData)
        } else {
            dispatch(showMessage({ message: t('error_7'), code: 2 }));
            return false
        }
    }
);

// 根据标签获取列表信息
export const getTagCasinoGames = createAsyncThunk(
    'casino/getTagCasinoGames',
    async (settings, { dispatch, getState }) => {
        const { casino, user, userSetting, userLoginCountry, channels } = getState();
        const tagID = settings.tagID || '';
        const res = await React.$api("gamecenter.getTagCasinoGames", {
            tagID: tagID
        });

        if (res.errno === 0) {
            let resultData = [];
            let lagnNoSupportData = [];
            res.data.forEach((item) => {
                let tmpItem = {...item, allTag: {}};
                let isOneCurrencyShow = true;
                let isCountryShow = false;
                let isFiatShow = false;
                let isLagnSupport = false;

                for(let key  in item.extendTags){
                    var tmpTagName =  item.extendTags[key].map((tag)=>{return tag.tagName});
                    if (key == casino.tagConfig.tagToPlay) {
                        tmpItem.allTag.tags = tmpTagName;

                    } else if (key == casino.tagConfig.tagLang) {
                        if (!tmpItem.allTag.lang) {
                            tmpItem.allTag.lang = tmpTagName;
                        } else {
                            tmpItem.allTag.lang.push(...tmpTagName);
                        }

                    } else if (key == casino.tagConfig.tagCurrency) {
                        if (!tmpItem.allTag.currency) {
                            tmpItem.allTag.currency = tmpTagName;
                        } else {
                            tmpItem.allTag.currency.push(...tmpTagName);
                        }

                    } else if (key == casino.tagConfig.tagCountry) {
                        if (!tmpItem.allTag.country) {
                            tmpItem.allTag.country = tmpTagName;
                        } else {
                            tmpItem.allTag.country.push(...tmpTagName);
                        }

                    } else if (key == casino.tagConfig.tagSymbol) {
                        if (!tmpItem.allTag.symbol) {
                            tmpItem.allTag.symbol = tmpTagName;
                        } else {
                            tmpItem.allTag.symbol.push(...tmpTagName);
                        }

                    } else if (casino.tagConfig.tagLangGroup[0] <= key && key <= casino.tagConfig.tagLangGroup[1]) {
                        tmpTagName = [ ...new Set(tmpTagName.join(',').split(',')) ];
                        if (!tmpItem.allTag.lang) {
                            tmpItem.allTag.lang = tmpTagName;
                        } else {
                            tmpItem.allTag.lang.push(...tmpTagName);
                        }

                    } else if (casino.tagConfig.tagCurrencyGroup[0] <= key && key <= casino.tagConfig.tagCurrencyGroup[1]) {
                        tmpTagName = [ ...new Set(tmpTagName.join(',').split(',')) ];
                        if (!tmpItem.allTag.currency) {
                            tmpItem.allTag.currency = tmpTagName;
                        } else {
                            tmpItem.allTag.currency.push(...tmpTagName);
                        }

                    } else if (casino.tagConfig.tagCountryGroup[0] <= key && key <= casino.tagConfig.tagCountryGroup[1]) {
                        tmpTagName = [ ...new Set(tmpTagName.join(',').split(',')) ];
                        if (!tmpItem.allTag.country) {
                            tmpItem.allTag.country = tmpTagName;
                        } else {
                            tmpItem.allTag.country.push(...tmpTagName);
                        }

                    } else if (casino.tagConfig.tagSymbolGroup[0] <= key && key <= casino.tagConfig.tagSymbolGroup[1]) {
                        tmpTagName = [ ...new Set(tmpTagName.join(',').split(',')) ];
                        if (!tmpItem.allTag.symbol) {
                            tmpItem.allTag.symbol = tmpTagName;
                        } else {
                            tmpItem.allTag.symbol.push(...tmpTagName);
                        }

                    }
                }

                // 供应商只支持一个币种，但是当前选择币种不是该币种则不显示
                if (channels.channelList.length > 0) {
                    let allChannels = {}
                    channels.channelList.map((channel) => {
                        allChannels[channel.channelName] = channel
                    })

                    if (allChannels[tmpItem.gameChannel]?.noSuptMultCurrency) {
                        if (
                            userSetting.setting.currencyType === 2 &&
                            allChannels[tmpItem.gameChannel].useCurrency &&
                            userSetting.setting.fiatCode !== allChannels[tmpItem.gameChannel].useCurrency
                        ) {
                            isOneCurrencyShow = false
                        }
                    }
                }

                // 国家不支持则不显示
                if (tmpItem.allTag.country && tmpItem.allTag.country.length > 0) {
                    if (userLoginCountry.currentCountryData.countryCode) {
                        if (tmpItem.allTag.country.indexOf('*') > -1) {
                            isCountryShow = true;
                        } else if (tmpItem.allTag.country.indexOf(userLoginCountry.currentCountryData.countryCode) > -1) {
                            isCountryShow = true;
                        }
                    }
                }

                // 币种不支持则不显示
                if (tmpItem.allTag.currency && tmpItem.allTag.currency.length > 0) {
                    if (userSetting?.setting?.currencyType === 2) {
                        if (tmpItem.allTag.currency.indexOf('*') > -1) {
                            isFiatShow = true;
                        } else if (tmpItem.allTag.currency.indexOf(userSetting?.setting?.fiatCode) > -1) {
                            isFiatShow = true;
                        }
                    } else {
                        isFiatShow = true;
                    }
                }

                // 语言不支持则排序到最后
                var tmpLangArr = [];
                Object.keys(country).map((item, index)=>{
                    if (country[item].langCode === window.localStorage.getItem('lang')) {
                        tmpLangArr.push(item)
                    }
                })
                if (tmpItem.allTag.lang && tmpItem.allTag.lang.length > 0) {
                    if (tmpItem.allTag.lang.indexOf('*') > -1) {
                        isLagnSupport = true;
                    } else {
                        tmpLangArr.map((item)=>{
                            if (tmpItem.allTag.lang.indexOf(item) > -1) {
                                isLagnSupport = true;
                            }
                        })
                    }
                }

                var isShow = (isCountryShow && isFiatShow && isOneCurrencyShow);
                if (isShow && isLagnSupport) {
                    resultData.push(tmpItem)
                } else if (isShow && !isLagnSupport) {
                    lagnNoSupportData.push(tmpItem)
                }
                // arr.map((item)=>{return item.a})
            });
            return resultData.concat(lagnNoSupportData)
        } else {
            dispatch(showMessage({ message: t('error_7'), code: 2 }));
            return false
        }
    }
);

// 按主类获取游戏列表
export const getCategoryGames = createAsyncThunk(
    'casino/getCategoryGames',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("gamecenter.getCategoryGames");

        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_7'), code: 2 }));
            return false
        }
    }
);

// 获取开始游戏链接
export const startGame = createAsyncThunk(
    'casino/startGame',
    async (settings, { dispatch, getState }) => {
        const gameId = settings.gameId || '';
        const language = settings.language || 'en';
        const amount = settings.amount || '';
        const channel = settings.channel || '';

        const res = await React.$api("oauth.startGame", {
            gameId,
            language,
            amount,
            channel,
        });

        if (res.errno === 0) {
            return res.data
        } else {
            let time = new Date();
            if (res.data && res.data.code == 500) {
                dispatch(showMaintainPage({
                    isShow: true,
                    startTime: res.data.startDate,
                    endTime: res.data.endDate,
                }));
            } else {
                dispatch(showMessage({ message: t('error_7'), code: 2 }));
            }
            return false
        }
    }
);

// 试玩
export const demoGame = createAsyncThunk(
    'casino/demoGame',
    async (settings, { dispatch, getState }) => {
        const gameId = settings.gameId || '';
        const language = settings.language || 'en';
        const channel = settings.channel || '';

        const res = await React.$api("oauth.demoGame", {
            gameId,
            language,
            channel,
        });

        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_8'), code: 2 }));
            return false
        }
    }
);

// 获取游戏记录/gamecenter/getTagCasinoGameRecord
export const getTagCasinoGameRecord = createAsyncThunk(
    'casino/getTagCasinoGameRecord',
    async (settings, { dispatch, getState }) => {
        const gameChannel = settings.gameChannel || '';
        const gameTag = settings.gameTag || '';
        const actionType = settings.actionType || '';
        const startTime = settings.startTime || ''; //yyyy-mm-dd hh:mm:ss
        const endTime = settings.endTime || '';

        const res = await React.$api("gamecenter.getTagCasinoGameRecord", {
            gameChannel,
            gameTag,
            actionType,
            startTime,
            endTime,
        });

        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_7'), code: 2 }));
            return false
        }
    }
);

// 获取排名
export const getRankList = createAsyncThunk(
    'casino/getRankList',
    async (settings, { dispatch, getState }) => {
        const gameKindType = settings.gameKindType || '';
        const sortType = settings.sortType || '';
        const groupType = settings.groupType || '';

        const res = await React.$api("gamecenter.getRankList", {
            gameKindType,
            sortType,
            groupType,
        });

        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_1'), code: 2 }));
            return false
        }
    }
);

// 获取体育投注记录
export const sportBetRecord = createAsyncThunk(
    'casino/sportBetRecord',
    async (settings, { dispatch, getState }) => {

        const res = await React.$api("sport.sportBetRecord");

        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_1'), code: 2 }));
            return false
        }
    }
);

// 获取供应商列表
export const getChannelList = createAsyncThunk(
    'casino/getChannelList',
    async (settings, { dispatch, getState }) => {
        const { channels } = getState();

        if (channels.channelList.length != 0) {
            return channels.channelList
        }
        const res = await React.$api("gamecenter.getChannelList");

        if (res.errno === 0) {
            dispatch(saveChannel(res.data))
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_1'), code: 2 }));
            return false
        }
    }
);