const locale = {
    home_StakeNFT: 'Zakładaj NFT i zdobywaj darmowe ',
    home_everyday: 'codziennie',
    home_Beton: 'Postaw na ',
    home_mining: 'kopanie',
    home_shareholder: ' Aby stać się udziałowcem',
    home_Stake: 'Zakład',
    home_dividend: ' Otrzymaj dywidendę z zysków',
    home_Banker: ' Aby stać się jednym z Bankierów',
    home_SIGNUPAND: 'SIGN UP & ',
    home_GET: 'GET',
    home_UPTO: 'UP TO ',
    home_FuniHash: 'Provable Fairness',
    home_Option: 'Opcja',
    
    home_Reward: '  Reward',
    home_Reward1: ' Reward',
    home_signUpNow: 'Inscreva-se agora',
    home_Casino: 'Cassino',
    home_Sports: 'Esportes',
    home_PROMOTION: 'PROMOÇÃO',
    home_Checkin: 'Check-in',
    home_Bouns: 'bônus',
    home_CheckinBouns: 'Bônus de check-in',
    home_SPIN: 'GIRO',
    home_Relief: 'Alívio',
    home_Share: 'Compartilhe',
    home_Deposit: 'Depósito',
    home_Fund: 'Fundo',
    home_VIPClub: 'Clube VIP',
    home_FastAndEasy: 'Forma rápida e fácil de comprar criptomoeda com bônus de depósito em USDT',
    home_Game: 'Jogo',
    home_User: 'Użytkownik',
    home_Time: 'Czas',
    home_BetAmount: 'Kwota zakładu',
    home_Multiplier: 'Mnożnik',
    home_Payout: 'Wypłata',
    home_JoinOur: 'Dołącz do naszej społeczności fanów sportu, entuzjastów kryptowalut i graczy',
    home_JOIN: 'DOŁĄCZ',
    home_LICENSES: 'LICENCJE',
    home_OurPlatforms: 'Nasze platformy',
    home_Marketplac: 'Rynek',
    home_Howtoearn: 'Jak zarabiać',
    home_Games: 'Gry',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'Prezentacja dla inwestorów',
    home_News: 'Aktualności',
    home_Aboutsus: 'O nas',
    home_Industry: 'Branża',
    home_Roadmap: 'Plan działań',
    home_Partner: 'Partner',
    home_FAQs: 'FAQ',
    home_Affiliate: 'Partnerzy',
    home_Stayintouch: 'Zostan w kontakcie',
    home_ChatRoom: 'Czat',
    home_Selectacontact: 'Wybierz kontakt, aby rozpocząć rozmowę.',
    home_BetSlip: 'Kupon zakładów',
    home_Typeyourmessage: 'Wpisz swoją wiadomość',
    home_AcceptAnyOdds: 'Akceptuj dowolne kursy',
    home_ClearAll: 'Wyczyść wszystko',
    home_Live: 'Na żywo',
    home_Winner: 'Zwycięzca',
    home_EstPayout: 'Szacunkowa wypłata',
    home_TotalOdds: 'Łączne kursy',
    home_RegistertoBet: 'Zarejestruj się, aby obstawiać',
    home_Single: 'Pojedynczy',
    home_Multi: 'Kombinacje',
    home_Checkineveryweek: 'Zaloguj się codziennie przez cały tydzień i otrzymaj nagrody.',
    home_Day: 'Dzień',
    home_Sunday: 'Niedziela',
    home_Monday: 'Poniedziałek',
    home_Tuesday: 'Wtorek',
    home_Wednesday: 'Środa',
    home_Thursday: 'Czwartek',
    home_Friday: 'Piątek',
    home_Saturday: 'Sobota',
    home_FreeSpin: 'Darmowe Spiny',
    home_Turntheturntable: 'Obróć kołem codziennie, aby otrzymać nagrodę USGT.',
    home_DepositFift: 'Depósito',
    home_ANYAMOUNT: 'DOWOLNA KWOTA PIERWSZEJ WPŁATY OZNACZA PREZENT',
    home_DEPOSIT: 'WPŁAĆ',
    home_First: 'Pierwszy',
    home_1ST: '1Szy',
    home_2ND: '2Gi',
    home_3TH: '3Ci',
    home_ANYAMOUNT: 'Dowolna kwota!',
    home_EXTRA: 'Dodatkowy',
    home_ONLY: 'Tylko',
    home_Relief: 'Ulga',
    home_PLATFORMSHARING: 'Udzielenie platformy',
    home_Getrelief: 'Miej szansę wyjść z dołka.',
    home_YOUCANCLAIM: 'Gdy saldo jest mniejsze niż ',
    home_YOUCANReceive: ', możesz otrzymać ulgę i uzupełnić saldo do ',
    home_threeTimes: ' 3 razy',
    home_Times: 'razy:',
    home_everydayTimes: '/dzień!',
    home_TotalAssets: 'Łączne środki',
    home_receive: 'otrzymaj',
    home_TODAY: 'DZISIAJ',
    home_Share: 'Udostępnij',
    home_Sharewith: 'Share FuniBet every day and get ',
    home_SHARINGGIFT: 'PAKIET PREZENTÓW ZA UDOSTĘPNIANIE',
    home_CODE: 'KOD',
    home_Selectplatform: 'Wybierz platformę do udostępnienia:',
    home_Fund: 'Fundusz',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
    home_BuyFund: 'kupować',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'Otrzymano',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'Wyłączne korzyści',
    home_Asawayof: 'Jako sposób na wyrażenie naszej wdzięczności i szacunku dla naszych graczy VIP, FuniBet zaprasza Cię do dołączenia do naszego Klubu VIP, gdzie znajdziesz mnóstwo prezentów, nagród, wyższych zwrotów gotówki i unikalnych funkcji. Nie przegap wszystkich ekscytujących i niesamowitych atrakcji!',
    home_Yourcurrent: 'Twoja obecna łączna stawka',
    home_WhyUpgrade: 'Dlaczego ulepszyć do VIP?',
    home_VIPhave: 'VIP mają 24-godzinna usługę udzielaną przez wyłącznego gospodarza. Oprócz podstawowego zwrotu gotówki i doładowania, VIP cieszą się najwyższym poziomem spinów, bonusami tygodniowymi/miesięcznymi oraz najszybszym kanałem do wpłaty i wypłaty. VIP mają możliwość uczestniczenia w konferencjach wymiany najlepszych graczy i rozwijania kasyna poprzez wprowadzanie nowych funkcji i funkcjonalności dla zespołu FuniBet.',
    home_UpgradeYour: 'Ulepsz swój poziom VIP',
    home_ASaFunibet: 'Jako członek Klubu VIP FUNIBet, zyskujesz wyłączny dostęp do hojnych nagród i personalizowanych prezentów, ale to nie wszystko. Im więcej grasz, tym lepsze luksusowe korzyści otrzymasz, aw wyniku awansujesz na poziom Elite VIP, gdzie nie ma ograniczeń dla dopasowanych indywidualnie przywilejów VIP, które FUNIBET Tobie dostarczy.',
    home_YOUCANREACH: 'MOŻESZ Osiągnąć',
    home_LEVELS: 'POZIOMY',
    home_eachthehigh: 'Osiągnij wysoki szczyt Rank, aby uzyskać bonus cashback w wysokości 25% i otworzyć wszystkie korzyści platformy. Pozostań z FUNIBET, aby otrzymać najlepsze doświadczenia z grami i doskonałą obsługę!',
    home_Cashback: 'Cashback',
    home_Nowater: '1. Brak pompowania wody w grze',
    home_Moregrowth: '2. Więcej funduszy wzrostowych',
    home_Increasein: '3. Wzrost liczby korzyści',
    home_Doublethenumber: '4. Podwojenie liczby płyt z nagrodami',
    home_Doublethesign: '5. Podwojenie nagrody za logowanie',
    home_Doublesharingrewards: '6. Podwojenie nagród za udostępnianie',
    home_DoubletheAffiliate: '7. Podwojenie nagrody partnerskiej',
    home_Rechargerewardamount: '8. Kwota nagrody za doładowanie X 1,5',
    home_ABOUTVIPCLUB: 'ABOUT VIP CLUB',
    home_HightopRank: 'High top Rank has 25% cashback bonus and the best benefits. Stay with BeDao to receive the best gaming experience and high level of service!',
    home_Beingamember: 'Being a member of the VIP club means that you have access to:',
    home_PersonalVIPmanager: 'Personal VIP manager',
    home_Highercahbackrewards: 'Higher cahback rewards',
    home_Otheradditionalperks: 'Other additional perks',
    home_Insights: 'Wglądu',
    home_WeeklyandMonthlyBonuses: 'Bonusów tygodniowych i miesięcznych',
    home_Showhighgaming: 'Pokaż wysoką aktywność w grze i otrzymaj zaproszenie do naszego klubu VIP.',
    home_BindMobile: 'Powiązanie',
    home_Pleasecheckthe: 'Sprawdź USGT uzyskane z gry w swoim portfelu.',
    home_BINDMOBILENUMBER: 'Powiązanie numeru',
    home_Get1USDT: 'Zdobądź 1 USDT',
    home_Bind: 'Połącz',
    home_GiftCode: 'Kod prezentowy',
    home_EnteryourGiftCode: 'Wprowadź swój kod prezentowy',
    home_EnterCode: 'Wprowadź kod',
    home_Paste: 'Wklej',
    home_confirms: 'potwierdza',
    home_BuyCrypto: 'kupować',
    home_SignedIn: 'Zalogowany',
    home_Wallet: 'Portfel',
    home_turnTimes: 'Obroty: ',
    home_bonus: ' bonus',
    home_FirstDepositBonus: 'First Deposit Bonus',
    home_Hi: 'Cześć ',
    home_welcomeAboard: ' witaj na pokładzie',
    
    
    
    home_CommissionRules: 'Zasady prowizji',
    home_AffiliateTerms: 'Warunki partnerstwa',
    home_AffiliateDashboard: 'Panel partnerski',
    home_AFFILIATE: 'PARTNERSTWO',
    home_COMMISSONREADYEVERYDAY: 'PROWIZJA GOTOWA KAŻDEGO DNIA',
    home_MYREFERRALCODE: 'MÓJ KOD POLECAJĄCY',
    home_MYREFERRALLink: 'MÓJ LINK POLECAJĄCY',
    home_AffiliateReward: 'Nagroda partnerska',
    home_EXTRA: 'DODATKOWE',
    home_USDREWARDS: 'NAGRODY W USD',
    home_USDRewardsRules: 'Zasady nagród w USD',
    home_JUSAREFERRALAWAY: 'JEDNO POLECANIE OD CIEBIE',
    home_NewlyAvailableRewards: 'Nowo dostępne nagrody',
    home_ReceivedMao: 'Otrzymano:',
    home_Locked: 'Zablokowano:',
    home_Yourcurrentfriends: 'Twoi obecni przyjaciele pozostający aktywni odblokują dla Ciebie więcej nagród',
    home_Withdraw: 'Wypłata',
    home_MY: 'MOJE',
    home_COMMISSIONREWARDS: 'NAGRODY ZA PROWIZJĘ',
    home_CommissionRewardRules: 'Zasady nagród za prowizję',
    home_NewlyAvailableCommissionRewards: 'Nowo dostępne nagrody za prowizję',
    home_CommissionYouhaveReceivedinTotal: 'Prowizja łącznie:',
    home_REWARDSSENTTODATE: 'NAGRODY WYSŁANE DO DNIA',
    home_USDrewardssenttodate: 'Nagrody USD wysłane do tej pory',
    home_Commissionsenttodatet: 'Prowizja wysłana do daty',
    home_Areyouablogger: 'Jesteś blogerem, masz duże audytorium, wielu obserwujących? Mamy dla Ciebie specjalną ofertę - unikalny program partnerski z większymi nagrodami za polecanie. Skompilujemy indywidualny program z warunkami specjalnymi. Skontaktuj się z naszym menedżerem, aby omówić warunki affiliate@beDao.io',
    home_onlythoseusers: 'Ważne: tylko ci użytkownicy, którzy spełnili wymagania menedżera i tylko po zatwierdzeniu z menedżerem będą mogli wziąć udział w programie.',
    home_notice: 'Uwaga',
    home_bindPhone: 'Powiąż telefon',
    home_SignIn: 'Zaloguj się',
    home_SignUp: 'Zarejestruj się',
    home_SignOut: 'Wyloguj się',
    home_CookiesPolicy: 'Polityka plików cookie',
    home_isUsingCookies: 'Funibet.com korzysta z plików cookie, aby zapewnić lepszą obsługę każdemu użytkownikowi. Korzystając z naszej strony, zgadzasz się na używanie plików cookie. Prosimy zapoznać się z Polityką prywatności i Warunkami świadczenia usług.',
    home_SETTING: 'USTAWIENIE',
    home_LiveSupport: 'Wsparcie na żywo',
    home_Accept: 'Akceptuj',
    home_FrequentlyAskedQuestions: 'Najczęściej zadawane pytania',
    home_SiteUnnderConstruction: 'Strona w budowie',
    home_ComingSoon: 'Wkrótce',
    home_UserId: 'ID: ',
    home_UserName: 'Nazwa: ',
    home_Bonusincreased: 'Zwiększony bonus +',
    home_SPINBONUSTOTAL: 'SPIN BONUS TOTAL',
    home_WIN: 'WIN: ',
    home_In: 'w ',
    home_Outoftime: 'Dzisiejsza liczba prób została wyczerpana',
    
    game_search: 'szukaj',
    game_SortBy: 'Sortuj według',
    game_Created: 'Utworzono',
    game_Lobby: 'Lobby',
    game_LiveCasino: 'Kasyno na żywo',
    game_FeaturedSlots: 'Polecane sloty',
    game_GameShows: 'Show gier',
    game_FuniBetOriginals: 'FuniBet Oryginalne',
    game_BigWins: 'Duże wygrane',
    game_LcukyWins: 'Szczęśliwe wygrane',
    game_Challenges: 'Wyzwania',
    game_Description: 'Opis',
    game_Slots: 'Sloty',
    game_Rank: 'Ranga',
    game_User: 'Użytkownik',
    game_Date: 'Data',
    game_BetAmount: 'Kwota zakładu',
    game_lucky: 'Maksymalny mnożnik',
    game_Multiplier: 'Mnożnik',
    game_Payout: 'Wypłata',
    game_Recommend: 'Polecane',
    game_Providers: 'Dostawcy',
    game_startGame: 'początek',
    game_balance: 'Saldo',
    game_provider: 'Tag',
    game_Selectyourdisplay: 'Wybierz rodzaj salda',
    game_Balancein: 'Saldo w',
    game_FunPlay: 'próbny',
    game_RealPlay: 'Gra na prawdziwe pieniądze',
    game_DispLaying: "Wyświetlana",
    game_LoadMore: "Załaduj więcej",
    game_NoData: 'Brak gier tego rodzaju tymczasowo',
    
    message_SigningIn: "Logowanie za pomocą JWT",
    message_CourseSaved: "Kurs zapisany",
    message_PleaseBind: "Aby wziąć udział w aktywności, musisz połączyć telefon. Czy chcesz go połączyć?",
    message_UserData: "Dane użytkownika zapisane za pomocą API",
    message_Success: "sukces",
    message_VIPlevel: "Poziom VIP jest mniejszy niż V3",
    message_ListOrder: "Zamówienie listy zapisane",
    message_CardOrder: "Zamówienie karty zapisane",
    message_HiHow: "Cześć, jak się masz?",

    Funibet_web: 'Funibet-Web3.0,Kryptowaluty,Zakłady,Sporty,E-sporty,Automaty do gier,Kasyno na żywo,Poker',

    home_ThereAre: "Obecnie nie ma żadnych powiadomień.",

    play_no_balance: "Obecne saldo waluty jest niewystarczające, proszę przełączyć walutę lub doładować konto.",
    
    home_News_1: 'Wiadomości',
    home_News_2: 'Wydarzenie',
    home_News_3: 'Krypto',
    home_News_4: 'Gry',
    
    home_SuggRep_1: 'Sugestie i raporty',
    home_SuggRep_2: 'Sugestie i raporty',
    home_SuggRep_3: 'Podziel się opinią, aby poprawić jakość!',
    home_SuggRep_4: 'Zgłoś stronę sprzedającą w grze.',
    home_SuggRep_5: 'Wprowadź sugestię lub zgłoś raport',
    home_SuggRep_6: 'Przykładowy zrzut ekranu',
    home_SuggRep_7: 'Nagroda za przyjęte sugestie i raporty!',
    home_SuggRep_8: 'Zgłoś',
    
    home_subscription_1: 'Czy chcesz zapisać się na powiadomienia push?',
    home_subscription_2: 'Możesz zawsze zrezygnować w dowolnym momencie.',
    home_subscription_3: 'Web push przez WonderPush',
    home_subscription_4: 'Później',
    home_subscription_5: 'Zapisz się',
    
    home_Affiliate_1: 'Skontaktuj się z nami',
    home_Affiliate_2: 'Za każdego zaproszonego przyjaciela otrzymasz 10U, im więcej zaprosisz, tym więcej otrzymasz!',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'E-mail',

    home_banner_1: 'Gry kryptowalutowe',
    home_banner_2: 'Obsługa ponad 100 kryptowalut i ponad 50 NFT',
    home_banner_3:'Decentralized Betting',
    home_banner_4:'Smart contract betting from decentralized wallet',
    home_banner_5: 'Zakładaj i zarabiaj',
    home_banner_6: 'Zakładaj, kop i inwestuj dla stałych zysków',
    home_banner_7: 'Web3.0 + Kasyno',
    home_banner_8: 'Gracz jest również bankierem i właścicielem',

    home_EventDetails: 'Szczegóły',

    home_vip_1: "1.Zdobądź przychód przez zalogowanie się+",
    home_vip_2: "2.Zdobądź przychód przez obrót+",
    home_vip_3: "3.Zdobądź przychód przez udostępnianie+",
    home_vip_4: "4.Zdobądź przychód przez doładowanie+",
    home_vip_5: "5.Czat otwarty, można wysyłać wiadomości codziennie",
    home_vip_6: "Wiadomości",


    
    ERROR_628: 'Kod prezentu został już wykorzystany',
    ERROR_629: 'Kod prezentu został w pełni wykorzystany',
    ERROR_630: 'Kod prezentu jest nieaktywny',
    ERROR_631: 'Kod prezentu wygasł',
    ERROR_633: 'Nie można użyć kodu prezentowego innego agenta',

    home_Maintaining_1: "Konserwacja",
    home_Maintaining_2: "Ta funkcja jest w trakcie konserwacji",
    home_Maintaining_3:'END',
    home_Maintaining_4:'START',

    home_Slots: 'Automaty do gier',
    home_LiveCasino: 'Kasyno na żywo',
    home_CountryFiat: 'Kraj i waluta',
    home_CryptNFTs: 'Kryptowaluty i NFT',

    home_GoTo:'Przejdź do',

    home_gameout_1: "Czy obecnie grasz w inną grę? Czy chcesz wyjść?",
    home_gameout_2: "Anuluj",

     home_BuyFund1:'Czy chciałbyś kupić fundusz wzrostu?',
     
     home_BetFor:'Zakład na',

     tongzhi_1: "Sugestia",
     tongzhi_2: "Odpowiedz na sugestię i przedstawiciela.",
     tongzhi_3: "Dobra robota! To niesamowite.",
     tongzhi_4: "Oczekuje na przegląd.",
     tongzhi_5: "Przegląd zatwierdzony.",
     tongzhi_6: "Przetwarzanie płatności.",
     tongzhi_7: "Płatność zakończona pomyślnie.",
     tongzhi_8: "Przegląd niezatwierdzony.",
     tongzhi_9: "Płatność nieudana.",
     tongzhi_10: "Wypłata nieudana.",
     tongzhi_11: "Zapłacone.",
     tongzhi_12: "Zakończone.",
     tongzhi_13: "Przetwarzanie nieudane.",
     tongzhi_14: "Kupić",
     tongzhi_15: "Do",
     tongzhi_16: "Wypłata",
     tongzhi_17: "Kryptowaluta",
     tongzhi_18: "Waluta Fiat",
     tongzhi_19: "Pobierz ",
     tongzhi_20: "Logowanie może być nagradzane",
     tongzhi_21: "Pobierz",
     tongzhi_22: "Obecnie nie ma dostępnej wersji iOS",
     tongzhi_23: "Wyślij",
     tongzhi_24: "Wyślij wskazówki",

     home_banner_9: "Wyścigi konne",
     home_banner_10: "Walki kogutów",
     home_banner_11: "Ekscytujące i porywające, wygraj duże nagrody",
     home_banner_12: "Pełne pasji i gotowe do walki",
     
     home_wanfa_1:"Problem rozgrywki",
     home_wanfa_2:"Problem z transferem",
     home_wanfa_3:"Inny problem",
     play_not_balance: "Zakłady sportowe z użyciem eUSDT nie są możliwe. Czy chcesz zmienić walutę?",
     qieHuan_balance: "Saldo w wybranej walucie jest niewystarczające do gry. Czy chcesz przełączyć się na inną walutę?",
     Provided_by: "Z",

     error_0: "Nieudane odebranie nagrody",
     error_1: "Nie udało się pobrać danych",
     error_2: "Nieudane zameldowanie",
     error_3: "Nieudane obracanie talerza",
     error_4: "Niepowodzenie w otrzymaniu pomocy finansowej",
     error_5: "Nieudane udostępnianie",
     error_6: "Nieudane zakupu funduszu",
     error_7: "Nie można pobrać informacji o grze",
     error_8: "Nie można przetestować tej gry",
     error_10: "Niepowodzenie transferu limitu",
     error_11: "Niepowodzenie operacji",
     error_12: "Nie udało się wysłać kodu weryfikacyjnego",
     error_13: "Limit wysyłania osiągnięty",
     error_15: "",
     error_16: "",
     error_17: "",
     error_18: "",
     error_19: "",
     error_20: "",
     error_21: "",
     error_22: "",
     error_23: "",
     error_24: "",
     error_25: "",
     error_26: "",
     error_27: "",
     error_28: "",
     error_29: "",
     error_30: "",
     error_31: "",
     error_32: "",
     error_33: "",
     error_34: "",
    };
    
    export default locale;
    
    
