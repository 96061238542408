const locale = {
    home_StakeNFT: 'Cầm NFT kiếm thêm ',
    home_everyday: 'Hàng ngày',
    home_Beton: 'Đặt cược',
    home_mining: 'Đào tiền',
    home_shareholder: 'Trở thành cổ đông',
    home_Stake: 'Cầm cố',
    home_dividend: 'Nhận cổ tức',
    home_Banker: 'Trở thành đối tác',
    home_SIGNUPAND: 'SIGN UP & ',
    home_GET: 'GET',
    home_UPTO: 'UP TO ',
    home_FuniHash: 'Trò chơi blockchain',
    home_Option: 'Tùy chọn',

    home_Reward: ' Reward',
    home_Reward1: 'Reward',
    home_signUpNow: 'Đăng ký ngay',
    home_Casino: 'Sòng bạc',
    home_Sports: 'Thể thao',
    home_PROMOTION: 'Khuyến mãi',
    home_Checkin: 'Checkin',
    home_Bouns: 'Tiền thưởng',
    home_CheckinBouns: 'Check in Bouns',
    home_SPIN: 'Quay',
    home_Relief: 'Cứu trợ',
    home_Share: 'Chia sẻ',
    home_Deposit: 'Nạp tiền',
    home_Fund: 'Quỹ',
    home_VIPClub: 'Câu lạc bộ VIP',
    home_FastAndEasy: 'Nạp tiền thưởng đến USDT nhanh chóng và dễ dàng bằng cách mua tiền mã hóa',
    home_Game: 'Trò chơi',
    home_User: 'Người dùng',
    home_Time: 'Thời gian',
    home_BetAmount: 'Số tiền cược',
    home_Multiplier: 'Hệ số nhân',
    home_Payout: 'Thanh toán',
    home_JoinOur: 'Tham gia cộng đồng của chúng tôi với những người yêu thích thể thao, tiền điện tử và đánh bạc',
    home_JOIN: 'Tham gia',
    home_LICENSES: 'Giấy phép',
    home_OurPlatforms: 'Các nền tảng của chúng tôi',
    home_Marketplac: 'Chợ',
    home_Howtoearn: 'Làm thế nào để kiếm tiền',
    home_Games: 'Trò chơi',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'Bộ báo cáo nhà đầu tư',
    home_News: 'Tin tức',
    home_Aboutsus: 'Về chúng tôi',
    home_Industry: 'Ngành',
    home_Roadmap: 'Lộ trình',
    home_Partner: 'Đối tác',
    home_FAQs: 'Câu hỏi thường gặp',
    home_Affiliate: 'Liên kết',
    home_Stayintouch: 'Giữ liên lạc',
    home_ChatRoom: 'Phòng trò chuyện',
    home_Selectacontact: 'Chọn một liên hệ để bắt đầu trò chuyện. ',
    home_BetSlip: 'Phiếu đặt cược',
    home_Typeyourmessage: 'Nhập tin nhắn của bạn',
    home_AcceptAnyOdds: 'Chấp nhận bất kỳ tỷ lệ cược nào',
    home_ClearAll: 'Xóa tất cả',
    home_Live: 'Trực tiếp',
    home_Winner: 'Người thắng cuộc',
    home_EstPayout: 'Thanh toán ước tính',
    home_TotalOdds: 'Tổng tỷ lệ cược',
    home_RegistertoBet: 'Đăng ký để đặt cược',
    home_Single: 'Cược đơn',
    home_Multi: 'Cược nhiều',
    home_Checkineveryweek: 'Đăng nhập hàng tuần để nhận phần thưởng tương ứng.',
    home_Day: 'Ngày',
    home_Sunday: 'Ngày 1',
    home_Monday: 'Ngày 2',
    home_Tuesday: 'Ngày 3',
    home_Wednesday: 'Ngày 4',
    home_Thursday: 'Ngày 5',
    home_Friday: 'Ngày 6',
    home_Saturday: 'Ngày 7',
    home_FreeSpin: 'Quay miễn phí',
    home_Turntheturntable: 'Quay vòng quay mỗi ngày để nhận thưởng USGT.',
    home_DepositFift: 'Tiền gửi',
    home_ANYAMOUNT: 'Nhận thưởng cho khoản nạp tiền đầu tiên với bất kỳ số tiền nào.',
    home_DEPOSIT: 'Tiền gửi',
    home_First: 'Đầu tiên',
    home_ONLY: 'Chỉ cần',
    home_1ST: '1ST',
    home_2ND: '2ND',
    home_3TH: '3TH',
    home_ANYAMOUNT: 'BẤT KỲ SỐ TIỀN NÀO!',
    home_EXTRA: 'Thêm',
    home_Relief: 'Cứu trợ',
    home_Getrelief: 'Nhận tiền trợ cấp để có cơ hội đổi vận. ',
    home_YOUCANCLAIM: 'Khi số dư ít hơn',
    home_YOUCANReceive: ', bạn có thể nhận tiền trợ cấp để nạp số dư lên đến',
    home_threeTimes: ' 3 lần',
    home_everydayTimes: '/Mỗi ngày!',
    home_Times: 'Thời gian:',
    home_TotalAssets: 'Tổng tài sản',
    home_receive: 'Nhận',
    home_TODAY: 'Hôm nay',
    home_Share: 'Chia sẻ',
    home_PLATFORMSHARING: 'Chia sẻ trên các nền tảng khác',
    home_Sharewith: 'Chia sẻ FuniBet để kiếm tiền ',
    home_SHARINGGIFT: 'Gói quà chia sẻ',
    home_CODE: 'Mã chia sẻ',
    home_Selectplatform: 'Chọn nền tảng chia sẻ:',
    home_Fund: 'Quỹ',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
    home_BuyFund: 'Mua Quỹ',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'Đã nhận',
    home_noReceived: 'Chưa đạt',
    home_ExclusiveBenefits: 'Ưu đãi độc quyền',
    home_Asawayof: 'Nhằm thể hiện sự cảm kích và đánh giá của chúng tôi đối với người chơi VIP, FuniBet mời bạn tham gia vào câu lạc bộ VIP của chúng tôi, nơi có nhiều quà tặng, hàng hóa, hoàn tiền tiền mặt cao hơn và tính năng độc đáo. Đừng bỏ lỡ tất cả những niềm vui hấp dẫn và kỳ diệu! ',
    home_Yourcurrent: 'Your current Total wager',
    home_WhyUpgrade: 'Tại sao nên nâng cấp VIP? ',
    home_VIPhave: 'VIP được hưởng dịch vụ chăm sóc khách hàng độc quyền 24/7. Ngoài các khoản hoàn tiền cơ bản và nạp tiền, VIP còn được hưởng mức quay số cao nhất, tiền thưởng hàng tuần/hàng tháng và đường ống gửi/nhận tiền nhanh nhất. VIP có cơ hội tham gia các hội nghị giao lưu cao cấp của người chơi và xây dựng sòng bạc bằng cách đưa ra các tính năng và chức năng mới cho đội ngũ FUNIBet.',
    home_UpgradeYour: 'Nâng cấp VIP của bạn',
    home_ASaFunibet: 'Là thành viên của câu lạc bộ VIP FUNIBet, bạn có thể nhận được phần thưởng hậu hĩnh và quà tặng cá nhân hóa, nhưng đó không phải là tất cả. Bạn chơi càng nhiều, khi thăng cấp lên thành viên VIP ưu tú, bạn sẽ nhận được các phúc lợi sang trọng tốt hơn, FUNIBET sẽ cung cấp các đặc quyền VIP được tùy chỉnh cho bạn.',
    home_YOUCANREACH: 'Bạn có thể nâng cấp lên',
    home_LEVELS: 'cấp độ',
    home_eachthehigh: 'Mọi người đều có thể nhận được 25% tiền hoàn trả và tận hưởng tất cả các lợi ích của nền tảng. Đến với FUNIBET, tận hưởng trải nghiệm chơi game tốt nhất và dịch vụ chất lượng cao!',
    home_Cashback: 'Tiền cashback',
    home_Nowater: '1. Không còn thu phí khi chơi game',
    home_Moregrowth: '2. Quỹ tăng trưởng nhiều hơn',
    home_Increasein: '3. Số lượng phúc lợi tăng lên',
    home_Doublethenumber: '4. Số lượng vòng quay tăng gấp đôi',
    home_Doublethesign: '5. Số lần đăng nhập tăng gấp đôi',
    home_Doublesharingrewards: '6. Phần thưởng chia sẻ tăng gấp đôi',
    home_DoubletheAffiliate: '7. Phần thưởng đại lý tăng gấp đôi',
    home_Rechargerewardamount: '8. Số tiền nạp tăng lên 1,5 lần',
    home_ABOUTVIPCLUB: 'ABOUT VIP CLUB',
    home_HightopRank: 'High top Rank has 25% cashback bonus and the best benefits. Stay with BeDao to receive the best gaming experience and high level of service!',
    home_Beingamember: 'Being a member of the VIP club means that you have access to:',
    home_PersonalVIPmanager: 'Personal VIP manager',
    home_Highercahbackrewards: 'Higher cahback rewards',
    home_Otheradditionalperks: 'Other additional perks',
    home_Insights: 'Insights',
    home_WeeklyandMonthlyBonuses: 'Tiền thưởng hàng tuần và hàng tháng',
    home_Showhighgaming: 'Hiển thị các hoạt động chơi game cao cấp và nhận lời mời từ câu lạc bộ VIP của chúng tôi.',
    home_BindMobile: 'Liên kết số',
    home_Pleasecheckthe: 'Vui lòng kiểm tra số tiền USGT bạn nhận được từ trò chơi trong ví của mình.',
    home_BINDMOBILENUMBER: 'Liên kết số điện thoại',
    home_Get1USDT: 'Nhận 1 USDT',
    home_Bind: 'Liên kết',
    home_GiftCode: 'Mã quà tặng',
    home_EnteryourGiftCode: 'Vui lòng nhập mã quà tặng của bạn',
    home_EnterCode: 'Nhập mã',
    home_Paste: 'Dán',
    home_confirms: 'Xác nhận',
    home_BuyCrypto: 'Mua',
    home_SignedIn: 'Đã đăng nhập',
    home_CommissionRules: 'Quy tắc hoa hồng',
    home_AffiliateTerms: 'Điều khoản thành viên',
    home_AffiliateDashboard: 'Bảng điều khiển thành viên',
    home_AFFILIATE: 'Thành viên',


    home_COMMISSONREADYEVERYDAY: 'Tiền hoa hồng sẵn sàng mỗi ngày $1,000 chỉ với việc giới thiệu',
    home_MYREFERRALCODE: 'Mã giới thiệu của tôi',
    home_MYREFERRALLink: 'Liên kết giới thiệu của tôi',
    home_AffiliateReward: 'Phần thưởng liên kết',
    home_EXTRA: 'Thêm vào',
    home_USDREWARDS: 'Phần thưởng USD',
    home_USDRewardsRules: 'Quy tắc phần thưởng USD',
    home_JUSAREFERRALAWAY: 'Chỉ cần một lần giới thiệu',
    home_NewlyAvailableRewards: 'Phần thưởng mới nhất',
    home_ReceivedMao: 'Đã nhận:',
    home_Locked: 'Đã khóa:',
    home_Yourcurrentfriends: 'Bạn giữ cho bạn bè của mình hoạt động sẽ mở khóa nhiều phần thưởng hơn cho bạn',
    home_Withdraw: 'Rút tiền',
    home_MY: 'Của tôi',
    home_COMMISSIONREWARDS: 'Phần thưởng hoa hồng',
    home_CommissionRewardRules: 'Quy tắc phần thưởng hoa hồng',
    home_NewlyAvailableCommissionRewards: 'Phần thưởng hoa hồng mới nhất',
    home_CommissionYouhaveReceivedinTotal: 'Đã nhận hoa hồng:',
    home_REWARDSSENTTODATE: 'Phần thưởng đã gửi đến ngày',
    home_USDrewardssenttodate: 'Phần thưởng USD đã gửi đến ngày',
    home_Commissionsenttodatet: 'Ngày gửi hoa hồng',
    home_Areyouablogger: 'Chương trình liên kết đặc biệt với phần thưởng giới thiệu lớn hơn dành cho blogger và người theo dõi. Liên hệ với quản lý để biết thêm chi tiết.',
    home_onlythoseusers: 'Lưu ý quan trọng: Chỉ những người dùng được yêu cầu và được quản trị viên phê duyệt mới có thể tham gia chương trình này.',
    home_notice: 'Thông báo',
    home_bindPhone: 'Liên kết điện thoại',
    home_SignIn: 'Đăng nhập',
    home_SignUp: 'Đăng ký',
    home_SignOut: 'Đăng xuất',
    home_CookiesPolicy: 'Chính sách cookie',
    home_isUsingCookies: 'Funibet.com sử dụng cookie để cung cấp dịch vụ tốt hơn. Đồng ý sử dụng cookie khi truy cập trang web. Xem chính sách bảo mật và điều khoản dịch vụ.',
    home_SETTING: 'Cài đặt',
    home_LiveSupport: 'Hỗ trợ trực tuyến',
    home_Accept: 'Chấp nhận',
    home_FrequentlyAskedQuestions: 'Câu hỏi thường gặp',
    home_SiteUnnderConstruction: 'Trang web đang trong quá trình xây dựng',
    home_ComingSoon: 'Sắp ra mắt',
    home_Wallet: 'Ví tiền',
    home_UserId: 'ID:',
    home_UserName: 'Tên:',
    home_turnTimes: 'Lượt quay:',
    home_Bonusincreased: "phần thưởng+",
    home_bonus: 'Tiền thưởng',
    home_SPINBONUSTOTAL: 'SPIN BONUS TOTAL',
    home_WIN: 'WIN:',
    home_In: 'Trong',
    home_FirstDepositBonus: 'First Deposit Bonus',
    home_Hi: 'Xin chào',
    home_welcomeAboard: 'Chào mừng quay trở lại',
    home_Outoftime: 'Số lượt chơi hôm nay đã hết',


    game_search: 'Tìm kiếm',
    game_SortBy: 'Sắp xếp theo',
    game_Created: 'Ngày tạo',
    game_Lobby: 'Sảnh chính',
    game_LiveCasino: 'Sòng bạc trực tiếp',
    game_FeaturedSlots: 'Máy đánh bạc nổi bật',
    game_GameShows: 'Trò chơi truyền hình',
    game_FuniBetOriginals: 'Sản phẩm FuniBet gốc',
    game_BigWins: 'Thắng lớn',
    game_LcukyWins: 'Thắng lớn may mắn',
    game_Challenges: 'Thử thách',
    game_Description: 'Mô tả',
    game_Slots: 'Máy đánh bạc',
    game_Rank: 'Thứ hạng',
    game_User: 'Người dùng',
    game_Date: 'Ngày',
    game_BetAmount: 'Số tiền đặt cược',
    game_lucky: 'Tỉ lệ',
    game_Multiplier: 'Tỷ lệ nhân',
    game_Payout: 'Kết quả',
    game_Recommend: 'Đề xuất',
    game_Providers: 'Nhà cung cấp',
    game_startGame: 'bắt đầu',
    game_balance: 'Số dư',
    game_provider: 'Nhãn',
    game_Selectyourdisplay: 'Chọn hiển thị số dư của bạn',
    game_Balancein: 'Số dư',
    game_FunPlay: 'Chế độ giải trí',
    game_RealPlay: 'Chế độ tiền thật',
    game_DispLaying: "Hiển thị",
    game_LoadMore: "Tải thêm",
    game_NoData: 'Hiện tại không có trò chơi loại này tạm thời',

    message_SigningIn: "Đang đăng nhập với JWT",
    message_CourseSaved: "Khóa học đã được lưu",
    message_PleaseBind: "Để tham gia hoạt động, bạn cần phải kết nối điện thoại của bạn. Bạn có muốn kết nối không?",
    message_UserData: "Dữ liệu người dùng đã được lưu với api",
    message_Success: "thành công",
    message_VIPlevel: "Cấp VIP thấp hơn V3",
    message_ListOrder: "Đơn đặt hàng danh sách đã được lưu",
    message_CardOrder: "Đơn đặt hàng thẻ đã được lưu",
    message_HiHow: "Xin chào, bạn khỏe không?",

    Funibet_web: 'Funibet-Web3.0, Tiền điện tử, Cá cược, Thể thao, Thể thao điện tử, Máy đánh bạc, Sòng bạc trực tiếp, Poker',

    home_ThereAre: "Hiện tại không có thông báo nào.",

    play_no_balance: "Số dư tiền tệ hiện tại không đủ, vui lòng chuyển đổi tiền tệ hoặc nạp tiền",

    home_News_1: 'Tin tức',
    home_News_2: 'Sự kiện',
    home_News_3: 'Tiền điện tử',
    home_News_4: 'Trò chơi',

    home_SuggRep_1: 'Đề xuất và Báo cáo',
    home_SuggRep_2: 'Góp ý và Báo cáo',
    home_SuggRep_3: 'Chia sẻ phản hồi để cải thiện!',
    home_SuggRep_4: 'Báo cáo trang web bán trong game.',
    home_SuggRep_5: 'Nhập đề xuất hoặc gửi báo cáo',
    home_SuggRep_6: 'Ảnh chụp màn hình mẫu',
    home_SuggRep_7: 'Thưởng cho các đề xuất và báo cáo được áp dụng!',
    home_SuggRep_8: 'Gửi',

    home_subscription_1: 'Bạn có muốn đăng ký thông báo đẩy?',
    home_subscription_2: 'Bạn luôn có thể hủy đăng ký bất cứ lúc nào.',
    home_subscription_3: 'Đẩy web bởi WonderPush',
    home_subscription_4: 'Sau',
    home_subscription_5: 'Đăng ký',

    home_Affiliate_1: 'Liên hệ chúng tôi',
    home_Affiliate_2: 'Mỗi người bạn mời sẽ mang lại cho bạn 10U, bạn mời nhiều hơn, bạn sẽ nhận được nhiều hơn!',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'Email',

    home_banner_1: 'Trò chơi Crypto',
    home_banner_2: 'Hỗ trợ 100+ tiền điện tử & 50+ NFT',
    home_banner_3: 'Cược phi tập trung',
    home_banner_4: 'Cược thông minh từ ví phi tập trung',
    home_banner_5: 'Cược để kiếm tiền',
    home_banner_6: 'Cược, đào và đầu tư để có lợi nhuận ổn định',
    home_banner_7: 'Web3.0 + Casino',
    home_banner_8: 'Người chơi cũng là Chủ nhà băng & Chủ sở hữu',

    home_EventDetails: 'Chi tiết',

    home_vip_1: "1.Kiếm thu nhập thông qua việc đăng ký+",
    home_vip_2: "2.Kiếm thu nhập thông qua vòng quay+",
    home_vip_3: "3.Kiếm thu nhập thông qua việc chia sẻ+",
    home_vip_4: "4.Kiếm thu nhập thông qua việc nạp tiền+",
    home_vip_5: "5.Chat mở, có thể gửi tin nhắn hàng ngày",
    home_vip_6: "Tin nhắn",


    ERROR_628: 'Mã quà tặng đã được sử dụng',
    ERROR_629: 'Mã quà tặng đã được sử dụng hết',
    ERROR_630: 'Mã quà tặng không hoạt động',
    ERROR_631: 'Mã quà tặng đã hết hạn',
    ERROR_633: 'Không thể sử dụng mã quà tặng từ đại lý khác',

    home_Maintaining_1: "Bảo trì",
    home_Maintaining_2: "Tính năng này đang được bảo trì",
    home_Maintaining_3: "KẾT THÚC",
    home_Maintaining_4: "Bắt đầu",

    home_Slots: 'Máy đánh bạc',
    home_LiveCasino: 'Sòng bạc trực tiếp',
    home_CountryFiat: 'Quốc gia và Fiat',
    home_CryptNFTs: 'Tiền điện tử và NFT',

    home_GoTo: 'Đi đến',

    home_gameout: "Bạn có đang chơi game khác không? Bạn có muốn thoát không?",
    home_gameout: "Hủy",

    home_BuyFund1: 'Bạn có muốn mua quỹ tăng trưởng không?',

    home_BetFor: 'Cá cược cho',

    tongzhi_1: "Đề xuất",
    tongzhi_2: "Trả lời đề xuất và đại diện.",
    tongzhi_3: "Làm tốt lắm! Điều này tuyệt vời.",
    tongzhi_4: "Đang chờ xem xét.",
    tongzhi_5: "Xem xét đã được phê duyệt.",
    tongzhi_6: "Đang xử lý thanh toán.",
    tongzhi_7: "Thanh toán đã hoàn tất thành công.",
    tongzhi_8: "Xem xét không được phê duyệt.",
    tongzhi_9: "Thanh toán thất bại.",
    tongzhi_10: "Rút tiền thất bại.",
    tongzhi_11: "Đã thanh toán.",
    tongzhi_12: "Đã hoàn tất.",
    tongzhi_13: "Xử lý thất bại.",
    tongzhi_14: "Mua",
    tongzhi_15: "Đến",
    tongzhi_16: "Rút",
    tongzhi_17: "Tiền điện tử",
    tongzhi_18: "Tiền tệ Fiat",
    tongzhi_19: "Tải về ",
    tongzhi_20: "Đăng nhập có thể được thưởng",
    tongzhi_21: "Tải xuống",
    tongzhi_23: "Gửi",
    tongzhi_24: "Gửi Mẹo",

    home_banner_9: "Đua ngựa",
    home_banner_10: "Tranh đấu gà",
    home_banner_11: "Hấp dẫn và gay cấn, giành giải lớn",
    home_banner_12: "Nhiệt huyết và đầy tinh thần đấu tranh",
    tongzhi_22: "Hiện tại không có phiên bản iOS nào",

    home_wanfa_1: "Vấn đề về cách chơi",
    home_wanfa_2: "Vấn đề chuyển tiền",
    home_wanfa_3: "Vấn đề khác",
    play_not_balance: "Không thể đặt cược thể thao với eUSDT. Bạn có muốn đổi tiền không?",
    qieHuan_balance: "Số dư trong loại tiền bạn chọn hiện không đủ để chơi. Bạn có muốn chuyển sang loại tiền khác không?",
    Provided_by: "Từ",

    error_0: "Không thành công trong việc nhận thưởng",
    error_1: "Lấy dữ liệu không thành công",
    error_2: "Đăng ký thất bại",
    error_3: "Quay đĩa xoay thất bại",
    error_4: "Không thành công trong việc nhận viện trợ tài chính",
    error_5: "Chia sẻ thất bại",
    error_6: "Mua quỹ thất bại",
    error_7: "Không thể lấy thông tin trò chơi",
    error_8: "Không thể chơi trò chơi này",
    error_10: "Chuyển giới hạn thất bại",
    error_11: "Hoạt động thất bại",
    error_12: "Gửi mã xác minh không thành công",
    error_13: "Đã đạt đến giới hạn gửi đi",
    error_15: "",
    error_16: "",
    error_17: "",
    error_18: "",
    error_19: "",
    error_20: "",
    error_21: "",
    error_22: "",
    error_23: "",
    error_24: "",
    error_25: "",
    error_26: "",
    error_27: "",
    error_28: "",
    error_29: "",
    error_30: "",
    error_31: "",
    error_32: "",
    error_33: "",
    error_34: "",
};

export default locale;


