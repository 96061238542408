const locale = {
    home_StakeNFT: 'Daha Fazla Kazanmak İçin NFT Yatır',
    home_everyday: 'Her Gün',
    home_Beton: 'Bahis Yap',
    home_mining: 'Madencilik',
    home_shareholder: 'Hisse Sahibi Ol',
    home_Stake: 'Yatır',
    home_dividend: 'Kâr Payı Al',
    home_Banker: 'Ortaklardan Biri Ol',
    home_SIGNUPAND: 'SIGN UP & ',
    home_GET: 'GET',
    home_UPTO: 'UP TO ',
    home_FuniHash: 'Blockchain Oyunu',
    home_Option: 'Opsiyon',

    home_Reward: ' Reward',
    home_Reward1: 'Reward',
    home_signUpNow: 'Hemen kaydolun',
    home_Casino: 'Kumarhane',
    home_Sports: 'Sporlar',
    home_PROMOTION: 'Promosyonlar',
    home_Checkin: 'Giriş yap',
    home_Bouns: 'Bonus',
    home_CheckinBouns: 'Giriş bonusu',
    home_SPIN: 'Çarkıfelek',
    home_Relief: 'Yardım',
    home_Share: 'Paylaş',
    home_Deposit: 'Yatırım',
    home_Fund: 'Fon',
    home_VIPClub: 'VIP Kulübü',
    home_FastAndEasy: 'Hızlı ve kolay bir şekilde kripto para birimleri yatırarak USDT bonusu kazanın',
    home_Game: 'Oyunlar',
    home_User: 'Kullanıcı',
    home_Time: 'Zaman',
    home_BetAmount: 'Bahis miktarı',
    home_Multiplier: 'Çarpan',
    home_Payout: 'Ödeme',
    home_JoinOur: 'Spor tutkunları, kripto para hayranları ve kumarbaz topluluğumuza katılın',
    home_JOIN: 'Katılın',
    home_LICENSES: 'Lisanslar',
    home_OurPlatforms: 'Platformlarımız',
    home_Marketplac: 'Marketplace',
    home_Howtoearn: 'Nasıl kazanılır',
    home_Games: 'Oyunlar',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'Yatırımcı Platformu',
    home_News: 'Haberler',
    home_Aboutsus: 'Hakkımızda',
    home_Industry: 'Endüstri',
    home_Roadmap: 'Yol Haritası',
    home_Partner: 'Ortak',
    home_FAQs: 'Sıkça Sorulan Sorular',
    home_Affiliate: 'Üye',
    home_Stayintouch: 'Bağlantıda kalın',
    home_ChatRoom: 'Sohbet Odası',
    home_Selectacontact: 'Sohbet etmek için bir kişi seçin. ',
    home_BetSlip: 'Bahis kuponu',
    home_Typeyourmessage: 'Mesajınızı yazın',
    home_AcceptAnyOdds: 'Herhangi bir oranı kabul edin',
    home_ClearAll: 'Tümünü temizle',
    home_Live: 'Canlı',
    home_Winner: 'Kazanan',
    home_EstPayout: 'Tahmini ödeme',
    home_TotalOdds: 'Toplam oran',
    home_RegistertoBet: 'Bahis yapmak için kaydolun',
    home_Single: 'Tekli bahis',
    home_Multi: 'Kombine bahis',
    home_Checkineveryweek: 'Her hafta biriktirerek ödül kazanın.',
    home_Day: 'Gün',
    home_Sunday: '1. Gün',
    home_Monday: '2. Gün',
    home_Tuesday: '3. Gün',
    home_Wednesday: '4. Gün',
    home_Thursday: '5. Gün',
    home_Friday: '6. Gün',
    home_Saturday: '7. Gün',
    home_FreeSpin: 'Bedava Dönüş',
    home_Turntheturntable: 'Her gün çevirerek USGT ödülü kazanın.',
    home_DepositFift: 'Depozito',
    home_ANYAMOUNT: 'İlk yatırımınız için herhangi bir miktar',
    home_DEPOSIT: 'YATIR',
    home_First: 'İLK',
    home_ONLY: 'Sadece',
    home_1ST: '1',
    home_2ND: '2',
    home_3TH: '3',
    home_ANYAMOUNT: 'HERHANGİ BİR MİKTAR!',
    home_EXTRA: 'EKSTRA',
    home_Relief: 'Yardım',
    home_Getrelief: 'Acil yardım alarak geri dönmek için bir şans elde edin.',
    home_YOUCANCLAIM: 'Bakiye',
    home_YOUCANReceive: 'ihtiyacınız olduğunda, acil yardım alarak bakiyenizi tamamlayın.',
    home_threeTimes: ' 3 kez',
    home_everydayTimes: '/her gün!',
    home_Times: 'Zamanlar:',
    home_TotalAssets: 'Toplam Varlıklar',
    home_receive: 'Al',
    home_TODAY: 'Bugün',
    home_Share: 'Paylaş',
    home_PLATFORMSHARING: 'Başka platformlarda paylaş',
    home_Sharewith: 'FuniBet\'i her gün paylaşarak',
    home_SHARINGGIFT: 'Paylaşım hediyeleri',
    home_CODE: 'Paylaşım kodu',
    home_Selectplatform: 'Paylaşım platformunu seçin:',
    home_Fund: 'Büyüme',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'VIP seviyenizi yükseltin ve daha fazla fon bonusu alın.',
    home_BuyFund: 'Satın almak',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'Durum',
    home_Received: 'Alındı',
    home_noReceived: 'Ulaşılmadı',
    home_ExclusiveBenefits: 'Özel avantajlar',
    home_Asawayof: 'VIP oyuncularımıza teşekkür ve takdirimizi ifade etmek için, FuniBet sizi VIP Kulübümüze katılmaya davet ediyor. Orada zengin hediyeler, hediye ürünler, daha yüksek nakit geri ödeme ve benzersiz özellikler bulunmaktadır. Tüm heyecan verici ve şaşırtıcı eğlenceleri kaçırmayın!',
    home_Yourcurrent: 'Mevcut toplam yatırımınız',
    home_WhyUpgrade: 'Neden VIP olarak yükseltmelisiniz?',
    home_VIPhave: 'VIP\'ler, özel bir danışmana 24/7 hizmet sunan özel hizmetlerden yararlanır. Temel geri ödeme ve yatırma işlemlerinin yanı sıra, VIP\'ler en yüksek seviyede döndürmeler, haftalık / aylık bonuslar ve en hızlı para yatırma / çekme kanallarına sahiptir. VIP\'ler, en üst düzey oyuncu etkinliklerine katılma şansına sahiptir ve FuniBet ekibine yeni özellikler ve işlevler sunarak kumarhaneyi inşa etme fırsatı elde ederler.',
    home_UpgradeYour: 'VIP seviyenizi yükseltin',
    home_ASaFunibet: 'FUNIBet VIP kulüp üyesi olarak, büyük ödüller ve kişiselleştirilmiş hediyeler kazanabilirsiniz, ancak bu hepsi değil. Ne kadar çok oynarsanız, VIP seviyenizi yükselttiğinizde Elite VIP üyesi olarak daha iyi lüks avantajlar elde edersiniz. FUNIBET, size özel VIP ayrıcalıkları sunmak için özelleştirilmiş VIP imtiyazları sağlar.',
    home_YOUCANREACH: 'yükseltebilirsiniz',
    home_LEVELS: 'seviyeleri',
    home_eachthehigh: 'Herkes %25 geri ödeme bonusu ve platformun tüm avantajlarından yararlanabilir. FUNIBET\'e katılın, en iyi oyun deneyimini ve kaliteli hizmeti yaşayın!',
    home_Cashback: 'Nakit geri ödeme',
    home_Nowater: '1. Oyunlarda artık su kesintisi yok',
    home_Moregrowth: '2. Daha fazla büyüme fonu',
    home_Increasein: '3. Fayda sayısı artıyor',
    home_Doublethenumber: '4. Çark sayısı iki katına çıkıyor',
    home_Doublethesign: '5. İmza sayısı iki katına çıkıyor',
    home_Doublesharingrewards: '6. Paylaşım ödülleri iki katına çıkıyor',
    home_DoubletheAffiliate: '7. İştirak ödülleri iki katına çıkıyor',
    home_Rechargerewardamount: '8. Yükleme miktarı %50 artıyor',
    home_ABOUTVIPCLUB: 'VIP kulübü hakkında',
    home_HightopRank: 'Yüksek bir sıralamada %25 geri ödeme bonusu ve en iyi faydalar bulunur. BeDao\'da kalın, en iyi oyun deneyimini ve yüksek düzeyde hizmeti yaşayın!',
    home_Beingamember: 'VIP kulübü üyesi olmak, şunlara erişebileceğiniz anlamına gelir:',
    home_PersonalVIPmanager: 'Kişisel VIP yöneticisi',
    home_Insights: 'Öngörüler',
    home_Highercahbackrewards: 'Daha yüksek nakit geri ödeme',
    home_Otheradditionalperks: 'Diğer ek ödenekler',
    home_WeeklyandMonthlyBonuses: 'Haftalık ve aylık bonuslar',
    home_Showhighgaming: 'Yüksek oyun etkinliklerini gösterin ve VIP kulübümüzün davetiyelerini alın.',
    home_BindMobile: 'bağla',
    home_Pleasecheckthe: 'Lütfen oyunlardan kazandığınız USGT\'yi cüzdanınızda kontrol edin.',
    home_BINDMOBILENUMBER: 'Numarayı bağla',
    home_Get1USDT: '1 USDT alın',
    home_Bind: 'Bağlamak',
    home_GiftCode: 'Hediye kodu',
    home_EnteryourGiftCode: 'Lütfen hediye kodunuzu girin',
    home_EnterCode: 'Kodu girin',
    home_Paste: 'Yapıştır',
    home_confirms: 'Onayla',
    home_BuyCrypto: 'Satın almak',
    home_SignedIn: 'Giriş yapıldı',
    home_CommissionRules: 'Komisyon kuralları',
    home_AffiliateTerms: 'Ortaklık sözleşmesi',
    home_AffiliateDashboard: 'Ortaklık paneli',
    home_AFFILIATE: 'Ortaklık',


    home_COMMISSONREADYEVERYDAY: 'Her zaman hazır olan komisyonlar $1,000 sadece tavsiye ile',
    home_MYREFERRALCODE: 'Benim tavsiye kodum',
    home_MYREFERRALLink: 'Benim tavsiye bağlantım',
    home_AffiliateReward: 'Ortaklık ödülü',
    home_EXTRA: 'Ekstra',
    home_USDREWARDS: 'USD ödülleri',
    home_USDRewardsRules: 'USD ödül kuralları',
    home_JUSAREFERRALAWAY: 'Sadece bir tavsiye',
    home_NewlyAvailableRewards: 'Yeni ödüller',
    home_ReceivedMao: 'Alınan:',
    home_Locked: 'Kilitli:',
    home_Yourcurrentfriends: 'Mevcut arkadaşlarınız aktif kaldıkça daha fazla ödül kilidini açacak',
    home_Withdraw: 'Çekmek',
    home_MY: 'Benim',
    home_COMMISSIONREWARDS: 'Komisyon ödülleri',
    home_CommissionRewardRules: 'Komisyon ödül kuralları',
    home_NewlyAvailableCommissionRewards: 'Yeni komisyon ödülleri',
    home_CommissionYouhaveReceivedinTotal: 'Alınan komisyon:',
    home_REWARDSSENTTODATE: 'Şimdiye kadar gönderilen ödüller',
    home_USDrewardssenttodate: 'Şimdiye kadar gönderilen USD ödülleri',
    home_Commissionsenttodatet: 'Komisyon gönderme tarihi',
    home_Areyouablogger: 'Bir blogcu musunuz ve çok sayıda okuyucunuz, takipçiniz var mı? Özel bir ortaklık programı sunuyoruz ve daha büyük öneri ödülleri elde etmenizi sağlıyoruz. Özel şartlar içeren ayrı bir program oluşturacağız. Koşulları tartışmak için yöneticimize başvurun.',
    home_onlythoseusers: 'Önemli not: Bu programa katılmak için yalnızca yönetici tarafından istenilen ve yönetici tarafından onaylanan kullanıcılar kabul edilir.',
    home_notice: 'Bildiri',
    home_bindPhone: 'Telefonu Bağla',
    home_SignIn: 'Giriş',
    home_SignUp: 'Kayıt Ol',
    home_SignOut: 'Çıkış Yap',
    home_CookiesPolicy: 'Çerez Politikası',
    home_isUsingCookies: 'Funibet.com, her kullanıcıya daha iyi hizmet sunmak için çerezler kullanıyor. Sitemizi kullanarak çerez kullanımını kabul etmiş sayılırsınız. Gizlilik politikamızı ve hizmet şartlarını inceleyin. ',
    home_SETTING: 'Ayarlar',
    home_LiveSupport: 'Canlı Destek',
    home_Accept: 'Kabul Et',
    home_FrequentlyAskedQuestions: 'Sıkça Sorulan Sorular',
    home_SiteUnnderConstruction: 'Site Yapım Aşamasında',
    home_ComingSoon: 'Yakında',
    home_Wallet: 'Wallet',
    home_UserId: 'Kimlik: ',
    home_UserName: 'Ad: ',
    home_turnTimes: 'Döndürme Sayısı:',
    home_Bonusincreased: "Bonus artırıldı +",
    home_bonus: 'Bonus',
    home_SPINBONUSTOTAL: 'Toplam Dönüş Bonusu',
    home_WIN: 'WIN:',
    home_In: 'içinde',
    home_FirstDepositBonus: 'İlk Yatırım Bonusu',
    home_Hi: 'Merhaba',
    home_welcomeAboard: 'Tekrar hoş geldiniz',
    home_Outoftime: 'Bugünkü hakkınız tükendi',
    home_ThereAre: "Şu anda bildirim yok.",

    game_search: 'Ara',
    game_SortBy: 'Sırala',
    game_Created: 'Oluşturulma Tarihi',
    game_Lobby: 'Lobi',
    game_LiveCasino: 'Canlı Casino',
    game_FeaturedSlots: 'Öne Çıkan Slotlar',
    game_GameShows: 'Oyun Programları',
    game_FuniBetOriginals: 'FuniBet Orijinal Oyunları',
    game_BigWins: 'Büyük Kazançlar',
    game_LcukyWins: 'Şanslı Kazançlar',
    game_Challenges: 'Meydan Okumalar',
    game_Description: 'Açıklama',
    game_Slots: 'Slotlar',
    game_Rank: 'Sıralama',
    game_User: 'Kullanıcı',
    game_Date: 'Tarih',
    game_BetAmount: 'Bahis Tutarı',
    game_lucky: 'En Yüksek Çarpan',
    game_Multiplier: 'Çarpan',
    game_Payout: 'Sonuç',
    game_Recommend: 'Önerilen',
    game_Providers: 'Sağlayıcılar',
    game_startGame: 'başlamak',
    game_balance: 'Bakiye',
    game_provider: 'Etiket',
    game_Selectyourdisplay: 'Bakiye Görüntüleme Seçeneği',
    game_Balancein: 'Bakiye',
    game_FunPlay: 'Eğlence Modu',
    game_RealPlay: 'Gerçek Para Modu',
    game_DispLaying: "Gösterim",
    game_LoadMore: "Daha Fazla Yükle",
    game_NoData: 'Bu tür oyunlar geçici olarak mevcut değil',

    message_SigningIn: "JWT ile giriş yapılıyor",
    message_CourseSaved: "Kurs kaydedildi",
    message_PleaseBind: "Etkinliğe katılmak için telefonunuzu bağlamanız gerekiyor. Bağlamak ister misiniz?",
    message_UserData: "Kullanıcı verileri API ile kaydedildi",
    message_Success: "başarılı",
    message_VIPlevel: "VIP seviyesi V3'ten düşük",
    message_ListOrder: "Liste Siparişi Kaydedildi",
    message_CardOrder: "Kart Siparişi Kaydedildi",
    message_HiHow: "Merhaba, nasılsın?",

    Funibet_web: 'Funibet-Web3.0,Kripto,Bahis,Spor,E-sporlar,Slotlar,Canlı Casino,Poker',

    home_ThereAre: "Şu anda bildirim yok.",

    play_no_balance: "Mevcut para bakiyesi yetersiz, lütfen para birimini değiştirin veya yükleme yapın.",

    home_News_1: 'Haberler',
    home_News_2: 'Etkinlik',
    home_News_3: 'Kripto',
    home_News_4: 'Oyun',

    home_SuggRep_1: 'Öneriler ve Raporlar',
    home_SuggRep_2: 'Öneriler ve Raporlar',
    home_SuggRep_3: 'Geri bildirim paylaşın ve iyileştirin!',
    home_SuggRep_4: 'Oyun içi satış yapan web sitesini rapor edin.',
    home_SuggRep_5: 'Önerileri girin veya rapor gönderin',
    home_SuggRep_6: 'Örnek ekran görüntüsü',
    home_SuggRep_7: 'Adopte edilen öneriler ve raporlar için ödül!',
    home_SuggRep_8: 'gönder',

    home_subscription_1: 'Anlık bildirimlere abone olmak ister misiniz?',
    home_subscription_2: 'Dilediğiniz zaman aboneliğinizi iptal edebilirsiniz.',
    home_subscription_3: 'WonderPush gönderiyor',
    home_subscription_4: 'Daha sonra',
    home_subscription_5: 'Abone ol',

    home_Affiliate_1: 'Bizimle İletişime Geçin',
    home_Affiliate_2: 'Davet ettiğiniz her arkadaşınız size 10U kazandıracak, davet ettiğiniz kişi sayısı arttıkça daha fazla kazanacaksınız!',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'E-posta',

    home_banner_1: 'Kripto Oyun',
    home_banner_2: '100+ kripto ve 50+ NFT destekleniyor',
    home_banner_3: 'Merkeziyetsiz Bahis',
    home_banner_4: 'Merkeziyetsiz cüzdan üzerinden akıllı sözleşme bahisleri',
    home_banner_5: 'Bahis Yap Kazan',
    home_banner_6: 'Karlı kazançlar için bahis, madencilik ve yatırım yapın',
    home_banner_7: 'Web3.0 + Casino',
    home_banner_8: 'Oyuncu aynı zamanda Banker ve Sahip',

    home_EventDetails: 'Detaylar',

    home_vip_1: "1.Kazanç elde etmek için giriş yapın+",
    home_vip_2: "2.Kazanç elde etmek için döndürün+",
    home_vip_3: "3.Kazanç elde etmek için paylaşın+",
    home_vip_4: "4.Kazanç elde etmek için şarj edin+",
    home_vip_5: "5.Sohbet açık, günlük mesaj gönderilebilir",
    home_vip_6: "Mesajlar",


    ERROR_628: 'Hediye kodu zaten kullanılmış',
    ERROR_629: 'Hediye kodu tamamen kullanılmış',
    ERROR_630: 'Hediye kodu etkin değil',
    ERROR_631: 'Hediye kodu süresi dolmuş',
    ERROR_633: 'Başka bir yetkilinin hediye kodunu kullanamazsınız',

    home_Maintaining_1: "Bakım",
    home_Maintaining_2: "Bu özellik bakım altındadır",
    home_Maintaining_3: "SON",
    home_Maintaining_4: "SONBaşlangıç",

    home_Slots: 'Yuvalar',
    home_LiveCasino: 'Canlı Casino',
    home_CountryFiat: 'Ülke ve Fiat',
    home_CryptNFTs: 'Kripto Para ve NFT',

    home_GoTo: 'Go To',


    home_gameout: "Şu anda başka bir oyunda mısınız? Çıkmak istiyor musunuz?",
    home_gameout: "İptal",
    home_gameout_2: "iptal et",

    home_BuyFund1: 'Bir büyüme fonu satın almak ister misiniz?',

    home_BetFor: 'İçin bahse',

    tongzhi_1: "Öneri",
    tongzhi_2: "Öneriye ve temsilciye yanıt verin.",
    tongzhi_3: "Harika iş! Bu harika.",
    tongzhi_4: "İncelemeyi bekliyor.",
    tongzhi_5: "İnceleme onaylandı.",
    tongzhi_6: "Ödeme işlemi.",
    tongzhi_7: "Ödeme başarıyla tamamlandı.",
    tongzhi_8: "İnceleme onaylanmadı.",
    tongzhi_9: "Ödeme başarısız oldu.",
    tongzhi_10: "Çekme başarısız oldu.",
    tongzhi_11: "Ödendi.",
    tongzhi_12: "Tamamlandı.",
    tongzhi_13: "İşlem başarısız.",
    tongzhi_14: "Satın Al",
    tongzhi_15: "Kadar",
    tongzhi_16: "Çek",
    tongzhi_17: "Kripto Para",
    tongzhi_18: "Fiat Para",
    tongzhi_19: "indir",
    tongzhi_20: "Giriş ödüllendirilebilir",
    tongzhi_21: "Kurmak",
    tongzhi_23: "Gönder",
    tongzhi_24: "İpuçları Gönder",

    home_banner_9: "At Yarışı",
    home_banner_10: "Dövüş Horozu",
    home_banner_11: "Heyecan verici ve heyecan verici, büyük ödüller kazanın",
    home_banner_12: "Enerjik ve yüksek ruhlu",

    home_wanfa_1: "Oyun Oynama Sorunu",
    home_wanfa_2: "Transfer Sorunu",
    home_wanfa_3: "Diğer Sorun",
    play_not_balance: "eUSDT ile spor bahisleri mümkün değil. Para birimini değiştirmek ister misiniz?",
    qieHuan_balance: "Mevcut seçili para biriminde bakiye oyun oynamak için yetersiz. Başka bir para birimine geçmek ister misiniz?",
    Provided_by: "-den",
    error_0: "Ödülü alma başarısız oldu",
    error_1: "Veri alınamadı",
    error_2: "Giriş başarısız oldu",
    error_3: "Döner tabla döndürme başarısız oldu",
    error_4: "Mali yardım alımı başarısız oldu",
    error_5: "Paylaşım başarısız oldu",
    error_6: "Fon satın alma başarısız oldu",
    error_7: "Oyun bilgileri alınamıyor",
    error_8: "Bu oyunu oynayamazsınız",
    error_10: "Transfer limiti başarısız oldu",
    error_11: "İşlem başarısız oldu",
    error_12: "Doğrulama kodu gönderme başarısız oldu",
    error_13: "Gönderme limitine ulaşıldı",
    error_15: "",
    error_16: "",
    error_17: "",
    error_18: "",
    error_19: "",
    error_20: "",
    error_21: "",
    error_22: "",
    error_23: "",
    error_24: "",
    error_25: "",
    error_26: "",
    error_27: "",
    error_28: "",
    error_29: "",
    error_30: "",
    error_31: "",
    error_32: "",
    error_33: "",
    error_34: "",
};

export default locale;


