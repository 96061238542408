import { createAsyncThunk } from '@reduxjs/toolkit';
import React from "react";
import history from '@history';
import { showMessage } from 'app/store/fuse/messageSlice';
import { updateFaqs } from './faqs';
import { updateNews } from './news';
import faqs from "app/store/config/faqs";

// 获取IP信息
export const getIPExtendInfo = createAsyncThunk(
    'config/getIPExtendInfo',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("gamecenter.getIPExtendInfo");
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_1'), code: 2 }));
            return false
        }
    }
);

// 获取 Faq 列表
export const getFaqHelp = createAsyncThunk(
    'casino/getFaqHelp',
    async (settings, { dispatch, getState }) => {
        // const { faqs } = getState();
        // if (JSON.stringify(faqs.faqs) !== "{}") {
        //     return faqs.faqs
        // }
        const lang = settings.lang ?? 'en';
        const bFull = settings.bFull ?? false;
        const res = await React.$api("gamecenter.getFaqHelp", {
            bFull,
            lang,
        });
        if (res.errno === 0) {
            let faqs = {};
            res.data.map((item) => {
                if ((faqs[item.helpGroup])) {
                    faqs[item.helpGroup].push(item)
                } else {
                    faqs[item.helpGroup] = [item]
                }
            });
            dispatch(updateFaqs(faqs));
            return faqs
        } else {
            dispatch(showMessage({ message: t('error_1'), code: 2 }));
            return false
        }
    }
);

// 获取 新闻/公告
export const getNewsList = createAsyncThunk(
    'casino/getNewsList',
    async (settings, { dispatch, getState }) => {
        const { news } = getState();
        const classID = settings.classID ?? 1;
        const lang = settings.lang ?? 'en';
        const brush = settings.brush ?? false;

        const sendNews = async () => {
          const res = await React.$api("gamecenter.getNewsList", {
            classID,
            lang,
          });

          if (res.errno === 0) {
            return res.data
          } else {
            dispatch(showMessage({ message: t('error_1'), code: 2 }));
            return false
          }
        }

        if (classID == 2) {
          if (!brush && news.news.length > 0) {
            return news.news
          }
        }

        const result = await sendNews();
        // if (result) {
          // dispatch(updateNews(result));
        // }
        return result
    }
);

// 获取推广 config
export const getBusinessConfig = createAsyncThunk(
    'casino/getBusinessConfig',
    async (settings, { dispatch, getState }) => {
        return await React.$api("gamecenter.getBusinessConfig");
    }
);
