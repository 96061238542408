const locale = {
    home_StakeNFT: 'एनएफटी को स्टेक करके और कमाएँ',
    home_everyday: 'हर दिन',
    home_Beton: 'शर्त लगाएं',
    home_mining: 'माइनिंग करें',
    home_shareholder: 'शेयरहोल्डर बनें',
    home_Stake: 'स्टेक',
    home_dividend: 'लाभ बाँटे जाने के लिए',
    home_Banker: 'साझेदारों में से एक बनें',
    home_SIGNUPAND: 'अभी साइन अप करें',
    home_GET: 'प्राप्त करें',
    home_UPTO: 'कुल',
    home_FuniHash: 'ब्लॉकचेन गेम',
    home_Option: 'विकल्प',
    
    home_Reward: 'रिवॉर्ड',
    home_Reward1: ' रिवॉर्ड',
    home_signUpNow: 'अभी साइन अप करें',
    home_Casino: 'कैसीनो',
    home_Sports: 'खेल',
    home_PROMOTION: 'प्रचार',
    home_Checkin: 'चेक-इन',
    home_Bouns: 'बोनस',
    home_CheckinBouns: 'चेक-इन बोनस',
    home_SPIN: 'स्पिन',
    home_Relief: 'राहत',
    home_Share: 'शेयर',
    home_Deposit: 'जमा',
    home_Fund: 'फंड',
    home_VIPClub: 'वीआईपी क्लब',
    home_FastAndEasy: 'क्रिप्टोकरेंसी की खरीद और जमा करने के लिए ऊपर से त्वरित और सरल, USDT जमा बोनस तक',
    home_Game: 'खेल',
    home_User: 'उपयोगकर्ता',
    home_Time: 'समय',
    home_BetAmount: 'बेट राशि',
    home_Multiplier: 'मल्टीप्लायर',
    home_Payout: 'भुगतान',
    home_JoinOur: 'हमारे खेल शौकियों, क्रिप्टो उत्साहितों और जुआरियों की समुदाय में शामिल हों',
    home_JOIN: 'शामिल हों',
    home_LICENSES: 'लाइसेंस',
    home_OurPlatforms: 'हमारे प्लेटफ़ॉर्म',
    home_Marketplac: 'बाजार',
    home_Howtoearn: 'कैसे कमाएं',
    home_Games: 'खेल',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'निवेशक डेक',
    home_News: 'समाचार',
    home_Aboutsus: 'हमारे बारे में',
    home_Industry: 'उद्योग',
    home_Roadmap: 'मार्गदर्शिका',
    home_Partner: 'साथी',
    home_FAQs: 'अक्सर पूछे जाने वाले सवाल',
    home_Affiliate: 'सहयोगी',
    home_Stayintouch: 'संपर्क में रहें',
    home_ChatRoom: 'चैट कमरा',
    home_Selectacontact: 'बातचीत शुरू करने के लिए एक संपर्क का चयन करें।',
    home_BetSlip: 'बेट स्लिप',
    home_Typeyourmessage: 'अपना संदेश टाइप करें',
    home_AcceptAnyOdds: 'किसी भी ओड्ड्स को स्वीकार करें',
    home_ClearAll: 'सभी साफ करें',
    home_Live: 'लाइव',
    home_Winner: 'विजेता',
    home_EstPayout: 'अनुमानित भुगतान राशि',
    home_TotalOdds: 'कुल दांव',
    home_RegistertoBet: 'बेट करने के लिए रजिस्टर करें',
    home_Single: 'एकल दांव',
    home_Multi: 'मल्टीपल दांव',
    home_Checkineveryweek: 'प्रत्येक सप्ताह साइन अप करें, संबंधित पुरस्कार प्राप्त करें।',
    home_Day: 'दिन',
    home_Sunday: 'दिन 1',
    home_Monday: 'दिन 2',
    home_Tuesday: 'दिन 3',
    home_Wednesday: 'दिन 4',
    home_Thursday: 'दिन 5',
    home_Friday: 'दिन 6',
    home_Saturday: 'दिन 7',
    home_FreeSpin: 'फ्री स्पिन',
    home_Turntheturntable: 'प्रतिदिन फ्री स्पिन से USGT पुरस्कार प्राप्त करें।',
    home_DepositFift: 'जमा बोनस',
    home_ANYAMOUNT: 'आप जितनी भी राशि जमा करते हैं, पहली जमा के लिए बोनस प्राप्त कर सकते हैं।',
    home_DEPOSIT: 'जमा करें',
    home_First: 'पहली बार',
    home_ONLY: 'केवल',
    home_1ST: '1वीं',
    home_2ND: '2वीं',
    home_3TH: '3वीं',
    home_ANYAMOUNT: 'कोई भी राशि!',
    home_EXTRA: 'अतिरिक्त',
    home_Relief: 'राहत',
    home_Getrelief: 'राहत प्राप्त करें और फिर से खेलने के मौके पाएं।',
    home_YOUCANCLAIM: 'जब बैलेंस',
    home_YOUCANReceive: 'से कम हो जाए, तो आप राहत प्राप्त कर सकते हैं और अपने बैलेंस को',
    home_threeTimes: ' 3 बार',
    home_everydayTimes: '/हर दिन!',
    home_Times: 'बार:',
    home_TotalAssets: 'कुल धनराशि',
    home_receive: 'प्राप्त करें',
    home_TODAY: 'आज',
    home_Share: 'शेयर',
    home_PLATFORMSHARING: 'अन्य प्लेटफॉर्म पर साझा करें',
    home_Sharewith: 'हर दिन FuniBet साझा करने से प्राप्त करें',
    home_SHARINGGIFT: 'शेयरिंग उपहार',
    home_CODE: 'कोड साझा करें',
    home_Selectplatform: 'शेयर करने के लिए प्लेटफॉर्म चुनें:',
    home_Fund: 'ग्रोथ फंड',
    home_FundBonus: 'ग्रोथ फंड बोनस',
    home_Improveyour: 'अपने VIP स्तर को बढ़ाएं और हर दिन अधिक धन अवार्ड प्राप्त करें।',
    home_BuyFund: 'खरीदें',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'प्राप्त',
    home_noReceived: 'अपूर्ण',
    home_ExclusiveBenefits: 'विशेष लाभ',
    home_Asawayof: 'हम VIP खिलाड़ियों के प्रति हमारे आभार और सराहना व्यक्त करने के तरीके के रूप में, FuniBet आपको हमारे VIP क्लब में शामिल होने के लिए आमंत्रित करता है, वहाँ उपहार, उपहार, अधिक नकद वापसी और अनूठे सुविधाओं से भरी है। सभी उत्तेजनादायक और आश्चर्यजनक मज़ा न छोड़ें!',
    home_Yourcurrent: 'आपका वर्तमान कुल जमा',
    home_WhyUpgrade: 'VIP में अपग्रेड करने के कारण क्या हैं? ',
    home_VIPhave: 'VIP विशेषज्ञ सहायक द्वारा 24*7 विशेष सेवा का आनंद लें। निर्धारित की गई रिबेट और डिपॉजिट के अलावा, VIP को सर्वोच्च स्तर के घुमाव, हर सप्ताह / महीने के बोनस, और सबसे त्वरित जमा-निकासी चैनल भी उपलब्ध होते हैं। VIP उच्चतम स्तर के खिलाड़ियों के लिए शीर्ष खिलाड़ियों के संवाद से शामिल होने का मौका प्राप्त करते हैं, और नए सुविधाओं और विशेषताओं को गुणवत्ता जोड़ने के लिए FuniBet टीम को प्रस्ताव भी कर सकते हैं।',
    home_UpgradeYour: 'अपने VIP स्तर को अपग्रेड करें',
    home_ASaFunibet: 'FUNIBet VIP क्लब के सदस्य के रूप में, आप भारी इनाम और व्यक्तिगत उपहार प्राप्त कर सकते हैं, लेकिन यह सब कुछ नहीं है। जितना अधिक आप खेलेंगे, जब आप एक उच्च स्तर के VIP सदस्य बनेंगे, तब आपको बेहतर लग्जरी फायदे प्राप्त होंगे, FUNIBET आपको अपनी आवश्यकताओं के अनुसार विशेषाधिकार प्रदान करेगा।',
    home_YOUCANREACH: 'आप उन्नत वर्ग के लिए अपग्रेड कर सकते हैं',
    home_LEVELS: 'स्तरों',
    home_eachthehigh: 'हर व्यक्ति 25% कैशबैक बोनस प्राप्त कर सकता है और सभी फायदों का आनंद ले सकता है। FUNIBET में आकर, सर्वोत्तम खेल अनुभव और उच्च गुणवत्ता की सेवा का आनंद लें! ',
    home_Cashback: 'कैशबैक',
    home_Nowater: '1. खेल के बीच अब कोई जुर्माना नहीं होगा',
    home_Moregrowth: '2. अधिक विकास के लिए फंड',
    home_Increasein: '3. लाभों की संख्या बढ़ती है',
    home_Doublethenumber: '4. चक्र दोहरी संख्या में बढ़ जाते हैं',
    home_Doublethesign: '5. साइन इन की संख्या दोहरी होती है',
    home_Doublesharingrewards: '6. शेयर रिवॉर्ड दोहरा होता है',
    home_DoubletheAffiliate: '7. संबंधित पुरस्कार दोहरा होता है',
    home_Rechargerewardamount: '8. रिचार्ज रिवॉर्ड राशि 1.5 गुणा होती है',
    home_ABOUTVIPCLUB: 'VIP क्लब के बारे में',
    home_HightopRank: 'उच्च रैंकिंग में 25% कैशबैक बोनस और सर्वश्रेष्ठ लाभ होते हैं। BeDao में आवास करें, सर्वोत्तम गेमिंग अनुभव और उच्च स्तर की सेवा का आनंद लें! ',
    home_Beingamember: 'VIP क्लब का सदस्य बनना यह मतलब है कि आप इसके लिए उपलब्ध होंगे:',
    home_PersonalVIPmanager: 'निजी व्यवस्थापक',
    home_Insights: 'अध्यापन',
    home_Highercahbackrewards: 'अधिक कैशबैक रिवार्ड',
    home_Otheradditionalperks: 'अन्य अतिरिक्त सुविधाएं',
    home_WeeklyandMonthlyBonuses: 'साप्ताहिक और मासिक बोनस',
    home_Showhighgaming: 'उच्च गेमिंग गतिविधियों को दिखाएँ और हमारे VIP क्लब का आमंत्रण प्राप्त करें। ',
    home_BindMobile: 'मोबाइल बाइंड करें',
    home_Pleasecheckthe: 'कृपया अपने वॉलेट में खेल से प्राप्त USGT की जांच करें। ',
    home_BINDMOBILENUMBER: 'मोबाइल नंबर बाइंड करें',
    home_Get1USDT: '1 USDT प्राप्त करें',
    home_Bind: 'बाइंड करें',
    home_GiftCode: 'उपहार कोड',
    home_EnteryourGiftCode: 'अपना उपहार कोड दर्ज करें',
    home_EnterCode: 'कोड दर्ज करें',
    home_Paste: 'चिपकाएँ',
    home_confirms: 'पुष्टि करें',
    home_BuyCrypto: 'खरीदें',
    home_SignedIn: 'हस्ताक्षर किया गया',
    home_CommissionRules: 'कमीशन नियम',
    home_AffiliateTerms: 'संबंधित सदस्य नियम',
    home_AffiliateDashboard: 'सदस्य डैशबोर्ड',
    home_AFFILIATE: 'सदस्य',
    

    
    home_COMMISSONREADYEVERYDAY: 'कमीशन रिवॉर्ड्स',
    home_MYREFERRALCODE: 'मेरा सिफारिश कोड',
    home_MYREFERRALLink: 'मेरा सिफारिश लिंक',
    home_AffiliateReward: 'संबद्ध बदले',
    home_EXTRA: 'अतिरिक्त',
    home_USDREWARDS: 'डॉलर बदले',
    home_USDRewardsRules: 'डॉलर बदले के नियम',
    home_JUSAREFERRALAWAY: 'बस एक सिफारिश है',
    home_NewlyAvailableRewards: 'नवीनतम पुरस्कार',
    home_ReceivedMao: 'प्राप्त:',
    home_Locked: 'लॉक किया गया:',
    home_Yourcurrentfriends: 'आपके वर्तमान मित्र आपके लिए अधिक पुरस्कारों को अनलॉक करेंगे',
    home_Withdraw: 'निकास',
    home_MY: 'मेरा',
    home_COMMISSIONREWARDS: 'कमीशन बदले',
    home_CommissionRewardRules: 'कमीशन बदले के नियम',
    home_NewlyAvailableCommissionRewards: 'नए कमीशन बदले',
    home_CommissionYouhaveReceivedinTotal: 'प्राप्त कमीशन:',
    home_REWARDSSENTTODATE: 'अबतक भेजे गए पुरस्कार',
    home_USDrewardssenttodate: 'अबतक भेजे गए डॉलर पुरस्कार',
    home_Commissionsenttodatet: 'कमीशन भेजा गया तिथि',
    home_Areyouablogger: 'क्या आप एक ब्लॉगर हैं, जिसके पास बहुत से पाठक और अनुयायी हैं? हम आपके लिए एक विशेष पेशकश और बड़ी संदर्भ राशि वाली एक अलग संघ योजना प्रदान करते हैं। हम एक अलग सॉफ्टवेयर तैयार करेंगे जो विशेष शर्तों के साथ संचालित होगा। शर्तों की चर्चा करने के लिए हमारे प्रबंधक से संपर्क करें',
    home_onlythoseusers: 'महत्वपूर्ण सूचना: केवल वे उपयोगकर्ता ही इस योजना में भाग ले सकते हैं जिन्हें प्रबंधक द्वारा अनुरोध किया गया है और जिन्हें प्रबंधक द्वारा मंजूरी दी गई है। ',
    home_notice: 'जानकारी',
    home_bindPhone: 'फ़ोन बांधे',
    home_SignIn: 'साइन इन करें',
    home_SignUp: 'साइन अप करें',
    home_SignOut: 'लॉग आउट करें',
    home_CookiesPolicy: 'कुकीज़ नीति',
    home_isUsingCookies: 'Funibet.com कुकीज़ का उपयोग हर उपयोगकर्ता को बेहतर सेवा प्रदान करने के लिए करता है। हमारी वेबसाइट का उपयोग करना आपके द्वारा कुकीज़ का उपयोग करने की सहमति दर्शाता है। कृपया गोपनीयता नीति और सेवा की शर्तें देखें।',
    home_SETTING: 'सेटिंग',
    home_LiveSupport: 'लाइव सपोर्ट',
    home_Accept: 'सहमत',
    home_FrequentlyAskedQuestions: 'अक्सर पूछे जाने वाले सवाल',
    home_SiteUnnderConstruction: 'साइट निर्माणाधीन है',
    home_ComingSoon: 'जल्द आ रहा है',
    home_Wallet: 'वॉलेट',
    home_UserId: 'आईडी: ',
    home_UserName: 'नाम: ',
    home_turnTimes: 'बार घुमाएं:',
    home_Bonusincreased: "बोनस अतिरिक्त+",
    home_bonus: 'बोनस',
    home_SPINBONUSTOTAL: 'स्पिन बोनस कुल',
    home_WIN: 'जीत:',
    home_In: 'से',
    home_FirstDepositBonus: 'पहली जमा बोनस',
    home_Hi: 'नमस्ते',
    home_welcomeAboard: 'वापस स्वागत है',
    home_Outoftime: 'आज की गिनती ख़त्म हो चुकी है',
    
    game_search: 'खोजें',
    game_SortBy: 'वर्गीकरण',
    game_Created: 'तैयार की गई',
    game_Lobby: 'लॉबी',
    game_LiveCasino: 'लाइव कैसीनो',
    game_FeaturedSlots: 'फीचर्ड स्लॉट्स',
    game_GameShows: 'गेम शो',
    game_FuniBetOriginals: 'फनीबेट मूल गेम',
    game_BigWins: 'बड़ी जीत',
    game_LcukyWins: 'भाग्यशाली जीत',
    game_Challenges: 'चुनौतियाँ',
    game_Description: 'विवरण',
    game_Slots: 'स्लॉट्स',
    game_Rank: 'रैंक',
    game_User: 'उपयोगकर्ता',
    game_Date: 'तिथि',
    game_BetAmount: 'राशि',
    game_lucky: 'अधिकतम गुणांक',
    game_Multiplier: 'गुणक',
    game_Payout: 'भुगतान',
    game_Recommend: 'सुझाव',
    game_Providers: 'प्रदाता',
    game_startGame: 'शुरू करें',
    game_balance: 'शेष राशि',
    game_provider: 'टैग',
    game_Selectyourdisplay: 'अपनी डिस्प्ले राशि का चयन करें',
    game_Balancein: 'शेष राशि',
    game_FunPlay: 'फन प्ले मोड',
    game_RealPlay: 'रियल प्ले मोड',
    game_DispLaying: "प्रदर्शित करें",
    game_LoadMore: "और लोड करें",
    game_NoData: 'इस प्रकार के कोई खेल सामयिक नहीं हैं',
    
    message_SigningIn:"JWT के साथ साइन इन कर रहे हैं",
    message_CourseSaved:"कोर्स सहेजा गया",
    message_PleaseBind:"क्रियाकलाप में भाग लेने के लिए, आपको अपने फ़ोन को बांधना होगा। क्या आप बांधना चाहते हैं?",
    message_UserData:"उपयोगकर्ता डेटा एपीआई के साथ सहेजा गया",
    message_Success:"सफलता",
    message_VIPlevel:"वीआईपी स्तर V3 से कम है",
    message_ListOrder:"लिस्ट ऑर्डर सहेजा गया",
    message_CardOrder :"कार्ड ऑर्डर सहेजा गया",
    message_HiHow:"नमस्ते, आप कैसे हैं?",
    
    Funibet_web: 'Funibet-वेब 3.0, क्रिप्टो,बेट,खेल,ई-खेल,स्लॉट्स,लाइव कैसीनो,पोकर',

    home_ThereAre: "अभी कोई सूचनाएं नहीं हैं।",

    play_no_balance: "वर्तमान मुद्रा शेष अपर्याप्त है, कृपया मुद्रा बदलें या रीचार्ज करें",
    
    home_News_1: 'समाचार',
    home_News_2: 'इवेंट',
    home_News_3: 'क्रिप्टो',
    home_News_4: 'गेमिंग',
    
    home_SuggRep_1: 'सुझाव और रिपोर्ट',
    home_SuggRep_2: 'सुझाव और रिपोर्ट',
    home_SuggRep_3: 'सुधार के लिए फीडबैक साझा करें!',
    home_SuggRep_4: 'खेल में वेबसाइट बेचने की रिपोर्ट करें।',
    home_SuggRep_5: 'सुझाव दर्ज करें या रिपोर्ट सबमिट करें',
    home_SuggRep_6: 'उदाहरण स्क्रीनशॉट',
    home_SuggRep_7: 'अदोप्टेड सुझाव और रिपोर्ट के लिए इनाम!',
    home_SuggRep_8: 'सबमिट करें',
    
    home_subscription_1: 'क्या आप पुश सूचनाएं सब्सक्राइब करना चाहते हैं?',
    home_subscription_2: 'आप हमेशा किसी भी समय सदस्यता रद्द कर सकते हैं।',
    home_subscription_3: 'वेब पुश द्वारा WonderPush',
    home_subscription_4: 'बाद में',
    home_subscription_5: 'सदस्यता लें',
    
    home_Affiliate_1: 'हमसे संपर्क करें',
    home_Affiliate_2: 'हर दोस्त जो आप आमंत्रित करते हैं, आपको 10 यू मिलेंगे, जितने अधिक आमंत्रित करेंगे, उतना अधिक आप पाएंगे!',
    home_Affiliate_3: 'टेलीग्राम',
    home_Affiliate_4: 'ईमेल',

    home_banner_1: 'क्रिप्टो गेमिंग',
    home_banner_2: '100+ क्रिप्टो और 50+ NFTs समर्थित',
    home_banner_3: 'डीसेंट्रलाइज़्ड बेटिंग',
    home_banner_4: 'डीसेंट्रलाइज्ड वॉलेट से स्मार्ट कंट्रैक्ट बेटिंग',
    home_banner_5: 'बेट 2 कमाएं',
    home_banner_6: 'स्थिर लाभ के लिए बेट, माइन और निवेश करें',
    home_banner_7: 'Web3.0 + कैसीनो',
    home_banner_8: 'खिलाड़ी भी बैंकर और मालिक हैं',

    home_EventDetails: 'विवरण',

    home_vip_1: "1.साइन इन करके आय प्राप्त करें+",
    home_vip_2: "2.स्पिन करके आय प्राप्त करें+",
    home_vip_3: "3.साझा करके आय प्राप्त करें+",
    home_vip_4: "4.रिचार्ज करके आय प्राप्त करें+",
    home_vip_5: "5.चैट खुला हुआ है, रोज़ाना संदेश भेज सकते हैं",
    home_vip_6: "संदेश",
    
    ERROR_628: 'उपहार कोड पहले से ही प्राप्त कर लिया गया है',
    ERROR_629: 'उपहार कोड पूरी तरह से प्राप्त कर लिया गया है',
    ERROR_630: 'उपहार कोड सक्रिय नहीं है',
    ERROR_631: 'उपहार कोड की समयसीमा समाप्त हो गई है',
    ERROR_633: 'दूसरे एजेंट के उपहार कोड का उपयोग नहीं किया जा सकता',

    home_Maintaining_1: "रखरखाव",
    home_Maintaining_2: "यह सुविधा रखरखाव के तहत है",
    home_Maintaining_3: "समाप्त",
    home_Maintaining_4: "शुरू",

    home_Slots: 'स्लॉट्स',
    home_LiveCasino: 'लाइव कैसीनो',
    home_CountryFiat: 'देश और फिएट',
    home_CryptNFTs: 'क्रिप्ट और NFTs',

    home_GoTo:'जाओ',

    home_gameout_1: "क्या आप वर्तमान में किसी अन्य खेल में हैं? क्या आप बाहर निकलना चाहेंगे?",
    home_gameout_2: "रद्द करें",

     home_BuyFund1:'क्या आप विकास फंड खरीदना चाहेंगे?',

     home_BetFor:'के लिए शर्त',
    
     tongzhi_1: "सुझाव",
     tongzhi_2: "सुझाव और प्रतिनिधि का जवाब दें।",
     tongzhi_3: "बढ़िया काम! यह बहुत शानदार है।",
     tongzhi_4: "समीक्षा का इंतजार कर रहे हैं।",
     tongzhi_5: "समीक्षा मंजूर हो गई।",
     tongzhi_6: "भुगतान प्रोसेसिंग हो रहा है।",
     tongzhi_7: "भुगतान सफलतापूर्वक पूरा हुआ।",
     tongzhi_8: "समीक्षा मंजूर नहीं हुई।",
     tongzhi_9: "भुगतान असफल हुआ।",
     tongzhi_10: "निकासी असफल हुई।",
     tongzhi_11: "भुगतान हो गया।",
     tongzhi_12: "पूर्ण हो गया।",
     tongzhi_13: "प्रोसेसिंग असफल हुआ।",
     tongzhi_14: "खरीदें",
     tongzhi_15: "तक",
     tongzhi_16: "निकास",
     tongzhi_17: "क्रिप्टोकरेंसी",
     tongzhi_18: "फिएट मुद्रा",
     tongzhi_19: "डाउनलोड ",
     tongzhi_20: "लॉगिन करने पर पुरस्कृत किया जा सकता है",
     tongzhi_21: "डाउनलोड",
     tongzhi_22: "वर्तमान में iOS संस्करण उपलब्ध नहीं है",
     tongzhi_23: "भेजें",
     tongzhi_24: "सुझाव भेजें",

     home_banner_9: "घोड़ा दौड़",
     home_banner_10: "मुर्गी युद्ध",
     home_banner_11: "रोमांचक और उत्साहजनक, बड़ा इनाम जीतें",
     home_banner_12: "उत्साहित और उत्साहित",
     
     home_wanfa_1:"गेमप्ले समस्या ",
     home_wanfa_2:"हस्तांतरण समस्या",
     home_wanfa_3:"अन्य समस्या",
     play_not_balance: "eUSDT के साथ खेलने में समस्या हो रही है। क्या आपको मुद्रा बदलना है?",
     qieHuan_balance: "वर्तमान चयनित मुद्रा में शेष राशि खासकर खेलने के लिए पर्याप्त नहीं है। क्या आप किसी अन्य मुद्रा पर स्विच करना चाहेंगे?",
     Provided_by: "से",

     error_0: "इनाम प्राप्ति में विफलता",
     error_1: "डेटा प्राप्त करने में विफल",
     error_2: "चेक-इन विफल हुआ",
     error_3: "चक्रवात चक्र विफल हुआ",
     error_4: "वित्तीय सहायता प्राप्ति में विफलता",
     error_5: "साझा करने में विफलता",
     error_6: "फंड खरीदना विफल हुआ",
     error_7: "खेल की जानकारी प्राप्त नहीं की जा सकती",
     error_8: "इस खेल को नहीं खेल सकते",
     error_10: "हद ट्रांसफ़र विफल रहा",
     error_11: "ऑपरेशन असफल हुआ",
     error_12: "सत्यापन कोड भेजना विफल हुआ",
     error_13: "भेजने की सीमा पहुंच गई है",
     error_15: "",
     error_16: "",
     error_17: "",
     error_18: "",
     error_19: "",
     error_20: "",
     error_21: "",
     error_22: "",
     error_23: "",
     error_24: "",
     error_25: "",
     error_26: "",
     error_27: "",
     error_28: "",
     error_29: "",
     error_30: "",
     error_31: "",
     error_32: "",
     error_33: "",
     error_34: "",
    };
    
    export default locale;
    
    
