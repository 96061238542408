import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import MobileDetect from 'mobile-detect';

import './index.css';

const AnimateModal = (props) => {
  const { open, onClose, children, className, closeClass } = props;
  const nodeRef = useRef(null);
  const isMobileMedia = new MobileDetect(window.navigator.userAgent).mobile();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down(isMobileMedia ? 'lg' : 'sm'));
  useEffect(() => {
    if (open) {
      document.getElementsByTagName("body")[0].classList.add("bodyLock");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("bodyLock");
    }
  }, [open]);

  return createPortal(
    <CSSTransition nodeRef={nodeRef} in={open} timeout={200} classNames="animate" unmountOnExit>
      <div ref={nodeRef} className="funbetTxt">
        <div className="modalOverlay" onClick={onClose}></div>
        <div className={className + " animateWrapper"}>
          <img src="assets/images/index/closeBtn1.png" className={'animate-close-btn chaCloseBtn giftCha ' + closeClass} onClick={onClose} alt="close icon" />
          {children}
        </div>
      </div>
    </CSSTransition>,
    document.body,
  );
};
export default AnimateModal;

