const locale = {
    home_StakeNFT: 'نیفٹس کی رہائی کے لئے دانا دینے سے زیادہ کمائیں',
    home_everyday: 'ہر روز',
    home_Beton: 'شرط لگائیں',
    home_mining: 'کان کنی',
    home_shareholder: 'شیئر ہولڈر بنیں',
    home_Stake: 'دانا دینا',
    home_dividend: 'منافع حصول کریں',
    home_Banker: 'ایک شریک میں شامل ہوں',
    home_SIGNUPAND: 'ابھی رجسٹر کریں',
    home_GET: 'حاصل کریں',
    home_UPTO: 'کل',
    home_FuniHash: 'بلاک چین کا کھیل',
    home_Option: 'آپشن',

    home_Reward: 'انعام',
    home_Reward1: 'انعام',
    home_signUpNow: 'ابھی رجسٹر کریں',
    home_Casino: 'کیسینو',
    home_Sports: 'کھیلوں',
    home_PROMOTION: 'تشہیر',
    home_Checkin: 'چیک ان',
    home_Bouns: 'بونس',
    home_CheckinBouns: 'چیک ان بونس',
    home_SPIN: 'اسپن',
    home_Relief: 'راحت',
    home_Share: 'شیئر کریں',
    home_Deposit: 'جمع کرائیں',
    home_Fund: 'فنڈ',
    home_VIPClub: 'وی آئی پی کلب',
    home_FastAndEasy: 'رفتار سے اور آسانی سے کرپٹو کرنسی خریدیں اور USDT جمع کرائیں بونس حاصل کریں',
    home_Game: 'کھیل',
    home_User: 'صارف',
    home_Time: 'وقت',
    home_BetAmount: 'شرط کی رقم',
    home_Multiplier: 'ضرب کار',
    home_Payout: 'منافع',
    home_JoinOur: 'ہمارے کھیلوں کے شوقین، کرپٹو دوست اور جوا کھیلوں کی کمیونٹی میں شامل ہوں',
    home_JOIN: 'شامل ہوں',
    home_LICENSES: 'لائسنسز',
    home_OurPlatforms: 'ہماری منصوبے',
    home_Marketplac: 'مارکیٹ',
    home_Howtoearn: 'کیسے کمائی کریں',
    home_Games: 'کھیل',
    home_WhitePaper: 'سفید کاغذ',
    home_InvestorDeck: 'سرمایہ کار کا دسترخوان',
    home_News: 'خبریں',
    home_Aboutsus: 'ہمارے بارے میں',
    home_Industry: 'صنعت',
    home_Roadmap: 'روڈمیپ',
    home_Partner: 'شریک',
    home_FAQs: 'عمومی سوالات',
    home_Affiliate: 'وابستہ',
    home_Stayintouch: 'رابطے میں رہیں',
    home_ChatRoom: 'چیٹ روم',
    home_Selectacontact: 'بات چیت شروع کرنے کے لئے ایک رابطہ منتخب کریں۔',
    home_BetSlip: 'شرط بل',
    home_Typeyourmessage: 'اپنا پیغام ٹائپ کریں',
    home_AcceptAnyOdds: 'کسی بھی اوڈز کو قبول کریں',
    home_ClearAll: 'سب کو صاف کریں',
    home_Live: 'لائیو',
    home_Winner: 'جیتنے والا',
    home_EstPayout: 'تخمینہ کی گئی ادائیگی',
    home_TotalOdds: 'کل اوڈز',
    home_RegistertoBet: 'شرط لگانے کے لئے رجسٹر کریں',
    home_Single: 'سنگل شرط',
    home_Multi: 'ملٹیپل شرط',
    home_Checkineveryweek: 'ہر ہفتے کی چیک ان کے لئے ایک مخصوص انعام حاصل کریں۔',
    home_Day: 'دن',
    home_Sunday: 'پہلا دن',
    home_Monday: 'دوسرا دن',
    home_Tuesday: 'تیسرا دن',
    home_Wednesday: 'چوتھا دن',
    home_Thursday: 'پانچواں دن',
    home_Friday: 'چھٹا دن',
    home_Saturday: 'ساتواں دن',
    home_FreeSpin: 'مفت اسپن',
    home_Turntheturntable: 'روزانہ اسپن کریں اور USDT انعام حاصل کریں۔',
    home_DepositFift: 'جمع کرائیں انعام',
    home_ANYAMOUNT: 'آپ کمی کی کسی بھی مقدار کو جمع کرا سکتے ہیں',
    home_DEPOSIT: 'جمع کرائیں',
    home_First: 'پہلی بار',
    home_ONLY: 'صرف',
    home_1ST: '1ST',
    home_2ND: '2ND',
    home_3TH: '3TH',
    home_ANYAMOUNT: 'کوئی بھی رقم!',
    home_EXTRA: 'اضافی',
    home_Relief: 'راحت',
    home_Getrelief: 'راحت حاصل کریں اور دوبارہ شروع ہونے کا موقع حاصل کریں۔',
    home_YOUCANCLAIM: 'جب بیلنس',
    home_YOUCANReceive: 'کم ہوتا ہے تو آپ راحت حاصل کرسکتے ہیں اور بیلنس کو بھرنے کے لئے',
    home_threeTimes: ' 3 مرتبہ',
    home_everydayTimes: '/ہر روز!',
    home_Times: 'مرتبہ:',
    home_TotalAssets: 'کل دارائی',
    home_receive: 'حاصل کریں',
    home_TODAY: 'آج',
    home_Share: 'شیئر کریں',
    home_PLATFORMSHARING: 'دوسرے پلیٹ فارمز پر شیئر کریں',
    home_Sharewith: 'روزانہ فنی بیٹ کو شیئر کریں اور',
    home_SHARINGGIFT: 'شیئر کرنے کا تحفہ',
    home_CODE: 'شیئر کوڈ',
    home_Selectplatform: 'شیئر کرنے کا پلیٹ فارم منتخب کریں:',
    home_Fund: 'ترقی فنڈ',
    home_FundBonus: 'ترقی فنڈ انعام',
    home_Improveyour: 'اپنی VIP رتبہ بڑھائیں اور روزانہ مزید فنڈ انعام حاصل کریں۔',
    home_BuyFund: 'فنڈ خریدیں',
    home_Receivingconditions: 'وصول کی شرائط',
    home_Receivingstatus: 'وصول کی حالت',
    home_Received: 'وصول شدہ',
    home_noReceived: 'نہیں پورا ہوا',
    home_ExclusiveBenefits: 'خصوصی فوائد',
    home_Asawayof: 'VIP کھلاڑیوں کی حوصلہ افزائی اور قدر دینے کے لئے، فنی بیٹ آپ کو ہمارے VIP کلب میں شامل ہونے کی دعوت دیتا ہے، وہاں پر تحفے، تحفے، زیادہ کیش واپسی اور خصوصی خصوصیات ہیں۔ تمام دلچسپ اور حیران کن مزید خوشیاں نہیں کرنے کا موقع نہیں گزارنا! ',
    home_Yourcurrent: 'آپ کی موجودہ کل جمعی',
    home_WhyUpgrade: 'VIP کی قسط بڑھانے کی کیا وجوہات ہیں؟',
    home_VIPhave: 'VIP کو خصوصی منصوبہ کار کے طور پر منصوبہ بنایا گیا ہے 24 * 7 کی خدمت دیتا ہے۔ بنا رہے سوائے رقبت اور اضافہ کے، VIP کے پاس بہترین سطح کی موڑیں، ہفتہ وار / ماہانہ انعامات، اور تیز ترین جمع اور نکل کرنے کی راہیں ہوتی ہیں۔ VIP کو سب سے بہتر انعامات کے لئے کھلاڑیوں کی ایک اعلی سطح کے اجلاس میں شرکت کا موقع ملتا ہے، اور وہ FuniBet کی ٹیم کو نئے خصوصیات اور خصوصیات پیش کرنے کا موقع دیتا ہے۔',
    home_UpgradeYour: 'اپنے VIP سطح کو بڑھائیں',
    home_ASaFunibet: 'فنی بیٹ کے VIP کلب کے رکن کے طور پر آپ کو دینے والے انعامات اور شخصی تحفے کمال ہیں، مگر یہ سب کچھ نہیں ہے۔ آپ کھیلتے جائیں، اور جب آپ VIP سطحوں کے رکن بننے کے لئے اقدام کریں، تو آپ کو بہترین ممتاز VIP رکن بننے کے بعد فوائد ملیں گے، FuniBet آپ کو اپنے VIP خصوصیات کی تخصیص شدہ کھیلوں کے ساتھ دلچسپی کی پوری تخصیص کرے گا۔',
    home_YOUCANREACH: 'آپ منصوبہ بنانے کے لئے پہنچ سکتے ہیں',
    home_LEVELS: 'سطحیں',
    home_eachthehigh: 'ہر کوئی کو 25٪ کی کیش بیک منسلک کی جا سکتی ہے، اور تمام فوائد کو حاصل کریں۔ FuniBet میں رہیں، بہترین کھیل کے تجربے اور اعلی معیار کی خدمات کا لطف اٹھائیں!',
    home_Cashback: 'کیش بیک',
    home_Nowater: '1. کھیل کے دوران اب کوئی واٹر کوٹ نہیں ہے',
    home_Moregrowth: '2. زیادہ ترقی کا فنڈ',
    home_Increasein: '3. فوائد میں اضافہ',
    home_Doublethenumber: '4. ترنے کی موجودگی کو دگنا کریں',
    home_Doublethesign: '5. چیک ان کی موجودگی کو دگنا کریں',
    home_Doublesharingrewards: '6. تشہیر کے انعامات کو دگنا کریں',
    home_DoubletheAffiliate: '7. ایفلیئیٹ انعامات کو دگنا کریں',
    home_Rechargerewardamount: '8. ایفلیئیٹ انعامات کو دگنا کریں',
    home_ABOUTVIPCLUB: 'VIP کلب کے بارے میں',
    home_HightopRank: 'اعلی درجے کے حاصل کرنے والوں کو 25٪ کی کیش بیک انعام اور بہترین فوائد ملتے ہیں۔ BeDao میں رہنا، بہترین کھیل کے تجربے اور اعلی معیار کی خدمات کا لطف اٹھائیں!',
    home_Beingamember: 'VIP کلب کا رکن بننا یہ مطلب ہے کہ آپ کو دسترخوان کے قابل اعتماد منصب کی رسائی حاصل ہوتی ہے:',
    home_PersonalVIPmanager: 'شخصی VIP منیجر',
    home_Insights: 'مواقعت',
    home_Highercahbackrewards: 'زیادہ کیش بیک انعامات',
    home_Otheradditionalperks: 'دوسرے اضافی فوائد',
    home_WeeklyandMonthlyBonuses: 'ہفتہ وار اور ماہانہ انعامات',
    home_Showhighgaming: 'بلند کھیلوں کی سرگرمیاں دکھائیں، اور ہمارے VIP کلب کے اپرایشن میں شامل ہوں۔',
    home_BindMobile: 'موبائل بائنڈ کریں',
    home_Pleasecheckthe: 'براہ کرم جائیں اور اپنے گیم سے کمائی گئی USDT کو اپنے والٹ میں چیک کریں۔',
    home_BINDMOBILENUMBER: 'موبائل نمبر بائنڈ کریں',
    home_Get1USDT: '1USDT حاصل کریں',
    home_Bind: 'بائنڈ کریں',
    home_GiftCode: 'تحفہ کوڈ',
    home_EnteryourGiftCode: 'برائے کرم اپنا تحفہ کوڈ درج کریں',
    home_EnterCode: 'کوڈ درج کریں',
    home_Paste: 'پیسٹ کریں',
    home_confirms: 'تصدیق کریں',
    home_BuyCrypto: 'کرپٹو خریدیں',
    home_SignedIn: 'سائن ان کیا گیا',
    home_FirstDepositBonus: 'پہلی جمع کرائی کا انعام',
    home_Hi: 'ہیلو',
    home_welcomeAboard: 'خوش آمدید',



    home_CommissionRules: 'کمیشن کے قواعد',
    home_AffiliateTerms: 'وکیل کی شرائط',
    home_AffiliateDashboard: 'وکیل ڈیش بورڈ',
    home_AFFILIATE: 'وکیل',
    home_COMMISSONREADYEVERYDAY: 'ہر روز کمیشن ہر وقت تیار ہے $1,000 صرف ایک موصول کیلئے',
    home_MYREFERRALCODE: 'میرا اشتہار کا کوڈ',
    home_MYREFERRALLink: 'میرا اشتہار کا لنک',
    home_AffiliateReward: 'وکیل انعام',
    home_EXTRA: 'اضافی',
    home_USDREWARDS: 'ڈالر کی انعام',
    home_USDRewardsRules: 'ڈالر کی انعام کی قواعد',
    home_JUSAREFERRALAWAY: 'صرف ایک موصول کیلئے',
    home_NewlyAvailableRewards: 'حال ہی موصولہ',
    home_ReceivedMao: 'وصول کر لیا:',
    home_Locked: 'مقفل:',
    home_Yourcurrentfriends: 'آپ کے موجودہ دوستوں کی مدد سے آپ کو مزید انعام حاصل ہوں گے',
    home_Withdraw: 'واپس',
    home_MY: 'میرا',
    home_COMMISSIONREWARDS: 'کمیشن انعامات',
    home_CommissionRewardRules: 'کمیشن انعام کی قواعد',
    home_NewlyAvailableCommissionRewards: 'حال ہی موصولہ کمیشن انعامات',
    home_CommissionYouhaveReceivedinTotal: 'آپ کو موصول کیا گیا کمیشن:',
    home_REWARDSSENTTODATE: 'اب تک بھیجے گئے انعامات',
    home_USDrewardssenttodate: 'اب تک بھیجے گئے ڈالر کی انعامات',
    home_Commissionsenttodatet: 'کمیشن بھیجنے کی تاریخ',
    home_Areyouablogger: 'کیا آپ ایک بلاگر ہیں، جس کے پاس بہت سے پڑھنے والے ہوتے ہیں، بہت سے فالورز ہوتے ہیں؟ ہم آپ کے لئے خصوصی تخفیف پیش کرتے ہیں ایک منفرد الائنس پروگرام، جس میں بڑے انعامات دیے جاتے ہیں۔ ہم ایک علیحدہ منصوبہ تخصیص کرتے ہیں جس میں خصوصی شرائط شامل ہوں گی۔ شرائط کی تبادلہ کرنے کے لئے ہمارے منصوبہ کار سے رابطہ کریں',
    home_onlythoseusers: 'اہم خبر: اس منصوبے میں شرکت کرنے کے لئے صرف ان صارفین کو شامل کیا جائے گا جو منتظم کی درخواست پر اور منتظم کی منظوری کے بعد شامل ہوں گے۔',
    home_notice: 'نوٹس',
    home_bindPhone: 'موبائل بائنڈ کریں',
    home_SignIn: 'سائن ان کریں',
    home_SignUp: 'رجسٹر کریں',
    home_SignOut: 'لاگ آؤٹ کریں',
    home_CookiesPolicy: 'کوکیز پالیسی',
    home_isUsingCookies: 'Funibet.com ہر صارف کو بہتر خدمات فراہم کرنے کے لئے کوکیز کا استعمال کرتا ہے۔ ہماری ویب سائٹ استعمال کرنے کا مطلب ہے کہ آپ کوکی کا استعمال کرنے کو رضا مند ہیں۔ خصوصیت پالیسی اور خدمات کی شرائط دیکھیں۔',
    home_SETTING: 'ترتیبات',
    home_LiveSupport: 'لائیو سپورٹ',
    home_Accept: 'مان لیں',
    home_FrequentlyAskedQuestions: 'اکثر پوچھے گئے سوالات',
    home_SiteUnnderConstruction: 'سائٹ تعمیر کی جا رہی ہے',
    home_ComingSoon: 'جلد آ رہا ہے',
    home_Wallet: 'والٹ',
    home_UserId: 'صارف کی شناخت:',
    home_UserName: 'صارف کا نام:',
    home_turnTimes: 'چکر کی تعداد:',
    home_Bonusincreased: "انعام اضافی+",
    home_bonus: 'انعام',
    home_SPINBONUSTOTAL: 'مکمل انعامات کا اجمالی میزان',
    home_WIN: 'جیت:',
    home_In: 'میں',
    home_Outoftime: 'آج کی تعداد ختم ہو گئی ہے',


    game_search: 'تلاش کریں',
    game_SortBy: 'ترتیب دینا',
    game_Created: 'تخلیق کی تاریخ',
    game_Lobby: 'لابی',
    game_LiveCasino: 'لائیو کیسینو',
    game_FeaturedSlots: 'نمایاں اسلاتس',
    game_GameShows: 'گیم شوز',
    game_FuniBetOriginals: 'فنی بیٹ کی خود مختار کارکردگیاں',
    game_BigWins: 'بڑی جیتیں',
    game_LcukyWins: 'خوش قسمت جیتیں',
    game_Challenges: 'چیلنجز',
    game_Description: 'تفصیل',
    game_Slots: 'اسلاتس',
    game_Rank: 'رینک',
    game_User: 'صارف',
    game_Date: 'تاریخ',
    game_BetAmount: 'رقم شرط',
    game_lucky: 'زیادہ سے زیادہ ضربی',
    game_Multiplier: 'ضربی',
    game_Payout: 'اخراجات',
    game_Recommend: 'تجویز کریں',
    game_Providers: 'فراہم کنندگان',
    game_startGame: 'شروع کریں',
    game_balance: 'بقیہ رقم',
    game_provider: 'فراہم کنندہ',
    game_Selectyourdisplay: 'اپنا بقیہ دکھائیں منتخب کریں',
    game_Balancein: 'بقیہ',
    game_FunPlay: 'فن کھیلنے کا انداز',
    game_RealPlay: 'حقیقی کھیلنے کا انداز',
    game_DispLaying: "نمایاں",
    game_LoadMore: "مزید لوڈ کریں",
    game_NoData: 'اس قسم کے کوئی کھیل موقت طور پر نہیں ہیں',


    message_SigningIn: "JWT سائن ان کیا جا رہا ہے",
    message_CourseSaved: "کورس محفوظ کر دیا گیا ہے",
    message_PleaseBind: "کیا آپ کو مصری کرنے کیلئے فون بائنڈ کرنا چاہئے؟ کیا آپ بائنڈ کرنا چاہتے ہیں؟",
    message_UserData: "صارف کے ڈیٹا API کے ذریعے محفوظ کر دیئے گئے ہیں",
    message_Success: "کامیابی",
    message_VIPlevel: "VIP درجہ V3 سے کم ہے",
    message_ListOrder: "فہرست کا ترتیب محفوظ کر دیا گیا ہے",
    message_CardOrder: "کارڈ کا ترتیب محفوظ کر دیا گیا ہے",
    message_HiHow: "ہیلو، آپ کیسے ہیں؟",
    
    Funibet_web: 'فنی بیٹ-ویب 3.0، کرپٹو کرنسی، شرط، کھیل کود، ایلکٹرانکس ایتھلیٹکس، اسلاتس، لائیو کیسینو، پوکر',
    
    home_ThereAre: "فی الحال یہاں کوئی اطلاعات نہیں ہیں۔",
    
    play_no_balance: "فی الحال کرنسی بیقراری کافی نہیں ہے، براہ کرم کرنسی تبدیل کریں یا اپنی رقم شارج کریں",
    
    home_News_1: 'خبریں',
    home_News_2: 'تفریحات',
    home_News_3: 'کرپٹو کرنسی',
    home_News_4: 'کھیل',
    
    home_SuggRep_1: 'تجاویز اور رپورٹ',
    home_SuggRep_2: 'تجاویز اور رپورٹ',
    home_SuggRep_3: 'بہتری کے لئے تبادلہ خیال کریں!',
    home_SuggRep_4: 'سیل گیم میں شامل اشیاء کی ویب سائٹ کی رپورٹ دیں۔',
    home_SuggRep_5: 'تجاویز درج کریں یا رپورٹ کریں',
    home_SuggRep_6: 'مثالی تصویریں',
    home_SuggRep_7: 'مسترد کی گئی تجاویز اور رپورٹوں کو انعام دیا جائے گا!',
    home_SuggRep_8: 'جمع کرائیں',
    
    home_subscription_1: 'کیا آپ پشتوں کی اطلاعات کی فہرست میں شامل ہونا چاہتے ہیں؟',
    home_subscription_2: 'آپ ہمیشہ کے لئے فہرست سے خارج ہو سکتے ہیں۔',
    home_subscription_3: 'ونڈرپش ویب پشتوں',
    home_subscription_4: 'بعد میں',
    home_subscription_5: 'فہرست میں شامل کریں',
    
    home_Affiliate_1: 'ہم سے رابطہ کریں',
    home_Affiliate_2: 'ہر دوست کو دعوت دیں، آپ 10U حاصل کریں گے، جتنی زیادہ دعوتیں دیں گے، آپ کو اتنا زیادہ ملے گا!',
    home_Affiliate_3: 'ٹیلیگرام',
    home_Affiliate_4: 'ای میل',
    
    home_banner_1: 'کرپٹو گیمز',
    home_banner_2: '100+ کرپٹو کرنسیوں اور 50+ NFT کی حمایت',
    home_banner_3: 'مرکز سے دور کی شدت کا بازار',
    home_banner_4: 'سمارٹ کنٹریکٹ بیس اشیاء کی شرط لگانے کے لئے مرکز سے دور کی والٹ سے',
    home_banner_5: 'شرط لگائیں اور پیسے کمائیں',
    home_banner_6: 'شرط لگائیں، مائن کریں اور سرمایہ کاری کریں، مستقل منافع حاصل کریں',
    home_banner_7: 'ویب3.0 + کیسینو',
    home_banner_8: 'کھلاڑی بھی بینکر اور مالک بھی',

    home_EventDetails: 'تفصیلات',
    
    home_vip_1: '1. حاصل کردہ منافع کا اشارہ کریں +',
    home_vip_2: '2. منافع حاصل کرنے کے لئے پیچھے چکر لگائیں +',
    home_vip_3: '3. منافع حاصل کرنے کے لئے اشتراک کریں +',
    home_vip_4: '4. منافع حاصل کرنے کے لئے بلند کریں +',
    home_vip_5: '5. چیٹ کی اجازت، ہر دن بھیجنے کے لئے',
    home_vip_6: 'پیغام',
    
    ERROR_628: 'یہ پشتوں کوڈ پہلے ہی حاصل کر لیا گیا ہے',
    ERROR_629: 'یہ پشتوں کوڈ ختم ہو گیا ہے',
    ERROR_630: 'یہ پشتوں کوڈ قابل استعمال نہیں ہے',
    ERROR_631: 'یہ پشتوں کوڈ ختم ہو گیا ہے',
    ERROR_633: 'دوسرے ایجنٹ کے پشتوں کوڈ کا استعمال ممکن نہیں ہے',

    home_Maintaining_1: "فراہمی",
    home_Maintaining_2: "یہ خصوصیت فراہمی کی حالت میں ہے",
    home_Maintaining_3: "ختم ہونے والا ہے",
    home_Maintaining_4: "شروع",
    
    home_Slots: 'سلاتس',
    home_LiveCasino: 'لائیو کیسینو',
    home_CountryFiat: 'ملک اور فیات کرنسی',
    home_CryptNFTs: 'کرپٹو کرنسی اور این ایف ٹیز',
    
    home_GoTo: 'جائیں',
    
    home_gameout_1: "آپ فی الحال دوسرے کھیل میں ہیں، کیا آپ باہر نکلنا چاہتے ہیں؟",
    home_gameout_2: "منسوخ کریں",
    
    home_BuyFund1: "کیا آپ 50 USDT کھرچ کر کے مرکز سے دور کی شدت کی خرید کرنا چاہتے ہیں؟",
    
    home_BetFor: "کے لئے شرط لگائیں",

    tongzhi_1: "سفارش",
    tongzhi_2: "سفارش اور وکیل کا جواب دیں۔",
    tongzhi_3: "بہترین کام! یہ واہیات ہے۔",
    tongzhi_4: "معائنہ کا منتظر ہوں۔",
    tongzhi_5: "معائنہ منظور ہوگیا۔",
    tongzhi_6: "ادائیگی کی پروسیسنگ ہو رہی ہے۔",
    tongzhi_7: "ادائیگی کامیابی سے مکمل ہوگئی۔",
    tongzhi_8: "معائنہ منظور نہیں ہوا۔",
    tongzhi_9: "ادائیگی ناکام ہوگئی۔",
    tongzhi_10: "انتقال ناکام ہوگیا۔",
    tongzhi_11: "ادائیگی ہوگئی۔",
    tongzhi_12: "مکمل ہوگئی۔",
    tongzhi_13: "پروسیسنگ ناکام ہوگئی۔",
    tongzhi_14: "خریداری کریں",
    tongzhi_15: "تک",
    tongzhi_16: "نکالیں",
    tongzhi_17: "کرپٹو کرنسی",
    tongzhi_18: "فیٹ کرنسی",
    tongzhi_19: "ڈاؤن لوڈ کریں",
    tongzhi_20: "لاگ ان کی تشویش کی جاسکتی ہے",
    tongzhi_21: "ڈاؤن لوڈ کریں",
    tongzhi_22: "فی الحال iOS ورژن دستیاب نہیں ہے",
    tongzhi_23: "بھیجیں",
    tongzhi_24: "نصائح بھیجیں",

    home_banner_9: "گھوڑا دوڑ",
    home_banner_10: "مرغوں کی لڑائی",
    home_banner_11: "دلچسپ اور تشویشناک، بڑے انعام جیتیں",
    home_banner_12: "جذباتی اور جذباتی",
    
    home_wanfa_1:"گیم پلے مسئلہ",
    home_wanfa_2:"منتقلی معاملے کی مشکل",
    home_wanfa_3:"دوسرا مسئلہ",
    play_not_balance: "کھیلنے کے لئے eUSDT استعمال کرنا ممکن نہیں ہے۔ کیا آپ کو کرنسی تبدیل کرنی چاہیے؟ ",
    qieHuan_balance: "موجودہ منتخب کردہ کرنسی میں بیلنس کھیلنے کے لئے کافی نہیں ہے۔ کیا آپ کسی دوسری کرنسی پر سوئچ کرنا چاہتے ہیں؟",
    Provided_by: "سے",

    error_0: "انعام حاصل کرنے میں ناکامی",
    error_1: "ڈیٹا حاصل کرنے میں ناکامی",
    error_2: "چیک ان ناکام ہوگیا",
    error_3: "گھومنے کا چکر ناکام ہوگیا",
    error_4: "مالی مدد حاصل کرنے میں ناکامی",
    error_5: "شیئر کرنے میں ناکامی",
    error_6: "فنڈ خریداری میں ناکامی",
    error_7: "کھیل کی معلومات حاصل نہیں کی جا سکتی ہے",
    error_8: "اس کھیل کو کھیلنے سے قاصر ہے",
    error_10: "حد کا منتقلی ناکام ہوگیا",
    error_11: "آپریشن ناکام ہوگیا",
    error_12: "تصدیق کوڈ بھیجنے میں ناکامی",
    error_13: "بھیجنے کی حد تک پہنچ گیا ہے",
    error_15: "",
    error_16: "",
    error_17: "",
    error_18: "",
    error_19: "",
    error_20: "",
    error_21: "",
    error_22: "",
    error_23: "",
    error_24: "",
    error_25: "",
    error_26: "",
    error_27: "",
    error_28: "",
    error_29: "",
    error_30: "",
    error_31: "",
    error_32: "",
    error_33: "",
    error_34: "",
};

export default locale;
