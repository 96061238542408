import "../../../styles/game.css";
import * as React from "react";
import Common_foot from "../common/Common_foot";
import Common_gameReport from "../common/Common_gameReport";
import { motion } from "framer-motion";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import clsx from "clsx";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  startGame,
  getRankList,
  getTagCasinoGames,
  demoGame,
} from "../../store/casino/casinoThunk";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectUserData, updateUserFiats, updateUserSymbols } from "../../store/user";
import { selectCasinoData } from "../../store/casino";
import { getCasinoGames } from "../../store/casino/casinoThunk";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import {
  doSaveToWallet,
  getUserSetting,
  openWallet,
  setCasinoGameTagList,
  setGameFavor,
} from "../../store/user/userThunk";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { onLogin } from "../../store/user/userThunk";
import { gotoFullscreen } from "../../util/tools/function";
import { showMessage } from "app/store/fuse/messageSlice";
import { selectUserSetting } from "app/store/user/userSetting";
import { isMobile } from "../../util/tools/function";
import { selectUserAssets } from "app/store/user/userAssets";
import { selectUserGameScore } from "app/store/user/userGameScore";
import { selectFiats } from "app/store/config/fiats";
import { selectSymbols } from "app/store/config/symbols";
import { selectTopGroup } from "app/store/casino/topGroup";
import { selectTags } from "app/store/casino/tags";
import { LazyLoadImage } from "react-lazy-load-image-component";
import countryLang from "../../json/country";
import { selectChannels } from "app/store/config/channels";
import { borderRadius } from "@mui/system";


function formatTime(value) {
  if (value) {
    // let date = new Date(value * 1000)	// 时间戳为秒：10位数
    let date = new Date(value); // 时间戳为毫秒：13位数
    let year = date.getFullYear();
    let month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    let hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    let minute =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    let second =
      date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  } else {
    return "";
  }
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function not(a, b) {
  var tmpB = [];
  if (b[0]?.tagID) {
    tmpB = b.map((v) => {
      return v.tagID;
    });
  } else {
    tmpB = b;
  }

  return a.filter((value) => tmpB.indexOf(value) === -1);
}

function intersection(a, b) {
  var tmpB = [];
  if (b[0]?.tagID) {
    tmpB = b.map((v) => {
      return v.tagID;
    });
  } else {
    tmpB = b;
  }

  return a.filter((value) => tmpB.indexOf(value) !== -1);
}

function Play(props) {
  const { t } = useTranslation("mainPage");
  const dispatch = useDispatch();
  const params = useParams();
  const channel = params.channel;
  const gameId = params.gameId;
  const casinoID = Number(params.casinoID);
  const user = useSelector(selectUserData);

  const [userAmount, setUserAmount] = useState({
    symbol: "",
    img: "",
    balance: 0.0,
  });
  const [mask, setMask] = useState(true);
  const casinoData = useSelector(selectCasinoData);
  const tags = useSelector(selectTags);
  const topGroup = useSelector(selectTopGroup);
  const userData = useSelector(selectUserData);
  const allFiats = useSelector(selectFiats);
  const allSymbols = useSelector(selectSymbols);
  const userAssets = useSelector(selectUserAssets);
  const userSetting = useSelector(selectUserSetting);
  const userGameScore = useSelector(selectUserGameScore);
  const favoriteTagID = casinoData.favoriteTag; // 收藏ID

  const [isMask, setIsMask] = useState(false);
  const [favoriteList, setFavoriteList] = useState([]);

  // 圆点点左右选
  const [hideCompleted, setHideCompleted] = useState(false);
  const [gameUrl, setGameUrl] = useState("");
  const [recommendData, setRecommendData] = useState([]);

  // 多列切页滑动
  const [selectedTab, setSelectedTab] = useState(0);
  function handleTabChange(event, value) {
    setSelectedTab(value);
  }

  //出现回弹效果
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  // channel page
  const allChannel = useSelector(selectChannels).channelList;
  const [channelPage, setChannelPage] = useState(1);

  const nextChannelPage = () => {
    let pageSize = 7;
    if (isMobile()) {
      pageSize = 3;
    }

    if (channelPage < Math.ceil(allChannel.length / pageSize)) {
      setChannelPage(channelPage + 1);
    }
  }

  const previousChannelPage = () => {
    console.log('previous')
    if (channelPage > 1) {
      setChannelPage(channelPage - 1);
    }
  }



  function NumToFixed(num) {
    num = parseFloat(num);
    let tmpNum = num;
    var aa = parseInt(num).toString().length;
    if (aa == 1) {
      tmpNum = num.toFixed(6);
      // eslint-disable-next-line eqeqeq
    } else if (aa == 2) {
      tmpNum = num.toFixed(5);
      // eslint-disable-next-line eqeqeq
    } else if (aa == 3) {
      tmpNum = num.toFixed(4);
      // eslint-disable-next-line eqeqeq
    } else if (aa == 4) {
      tmpNum = num.toFixed(3);
    } else if (aa == 5) {
      tmpNum = num.toFixed(2);
    } else if (aa == 6) {
      num.toFixed(1);
    } else if (aa > 6) {
      tmpNum = num.toFixed(0);
    }
    return tmpNum;
  }

  function NumToFixed2(tempAmount) {
    var symbolArr = ["USDT", "USDC", "DAI", "BUSD", "PAX", "TUSD", "USD", "UST", "USDD", "eUSDT", "IDR", "MMK"]
    var tmpNum = 0;
    if (symbolArr.indexOf(tempAmount.symbol) != -1) {
      tmpNum = parseFloat(tempAmount.balance).toFixed(2);
    } else {
      tmpNum = NumToFixed(tempAmount.balance)
    }
    return tmpNum;
  }

  //自定义的循环表格假数据
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0E1421",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#161C2A",
    },
  }));
  const [ranks, setRanks] = useState([]);

  const [gameData, setGameData] = useState(null);
  const [allCasinoTags, setAllCasinoTags] = useState([]);
  const [openSetting, setOpenSetting] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [addTagID, setAddTagID] = useState([]);
  const [mainTagID, setMainTagID] = useState([]);
  const [removeTagID, setRemoveTagID] = useState([]);
  const [checked, setChecked] = useState([]);

  const handleChangeSelect = (event) => {
    setMainTagID(event.target.value);
  };
  const handleOpenSetting = () => {
    dispatch(
      getCasinoGames({
        casinoID: casinoID,
      })
    ).then((res) => {
      const data = res.payload;
      if (data) {
        let casinoGameTags = data[0].extendTags;
        let allTmpTags = [];
        for (let casinoKey in casinoGameTags) {
          if (casinoKey == 2 || casinoKey == 3 || casinoKey == 4) {
            allTmpTags = [...allTmpTags, ...casinoGameTags[casinoKey]];
          }
        }

        let allTags = Object.keys(tags.tagList); // 所有的tagID
        let allSelectedTags = allTmpTags.map((v) => {
          return v.tagID;
        }); // 已选择的tagID
        let allTagSome = allTags.filter(
          (v) => !allSelectedTags.some((item) => item === v)
        ); // 未选中的tagID

        console.log(allTmpTags, 'allTmpTags......')
        setAllCasinoTags(allTagSome);
        setSelectedTags(allSelectedTags);
        setGameData(data[0]);
        setMainTagID(data[0].casinoTagID);
        setOpenSetting(true);
      }
    });
  };

  const leftChecked = intersection(checked, allCasinoTags);
  const rightChecked = intersection(checked, selectedTags);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setSelectedTags(selectedTags.concat(allCasinoTags));
    setAllCasinoTags([]);

    setAddTagID(allCasinoTags);
    setRemoveTagID([]);
  };

  const handleCheckedRight = () => {
    setSelectedTags(selectedTags.concat(leftChecked));
    setAllCasinoTags(not(allCasinoTags, leftChecked));
    setChecked(not(checked, leftChecked));

    setAddTagID([...addTagID, ...leftChecked]);
    setRemoveTagID(not(removeTagID, leftChecked));
  };

  const handleCheckedLeft = () => {
    setAllCasinoTags(allCasinoTags.concat(rightChecked));
    setSelectedTags(not(selectedTags, rightChecked));
    setChecked(not(checked, rightChecked));

    setRemoveTagID([...removeTagID, ...rightChecked]);
    setAddTagID(not(addTagID, rightChecked));
  };

  const handleAllLeft = () => {
    setAllCasinoTags(allCasinoTags.concat(selectedTags));
    setSelectedTags([]);

    setRemoveTagID(selectedTags);
    setAddTagID([]);
  };


  const countryLangChange = (lang) => {
    const tempLang = [];
    Object.keys(countryLang).map((item) => {
      if (countryLang[item].langCode == lang) {
        tempLang.push(item)
      }
    })
    if (tempLang.length > 0) {
      return tempLang[0];
    } else {
      return Object.keys(countryLang)[0];
    }
  };

  const handleSetGameFavor = (bRemove) => {
    dispatch(
      setGameFavor({
        casinoID,
        bRemove,
      })
    ).then((res) => {
      let result = res.payload;
      if (result) {
        let tmpFavoriteList = [...favoriteList];
        if (bRemove) {
          Array.prototype.remove = function (val) {
            var index = this.indexOf(val);
            if (index > -1) {
              this.splice(index, 1);
            }
          };
          tmpFavoriteList.remove(casinoID);
        } else {
          tmpFavoriteList.push(casinoID);
        }
        setFavoriteList(tmpFavoriteList);
      }
    });
  };

  const handleDemoGame = () => {
    if (!userData.token) {
      dispatch(onLogin({
        type: "login",
      }))
      return
    }
    dispatch(
      demoGame({
        channel: channel,
        gameId: gameId,
        language: countryLangChange(window.localStorage.getItem('lang')),
      })
    ).then((res) => {
      let data = res.payload;
      if (data) {
        if (isMobile()) {
          window.open(data)
        }
        setGameUrl(data);
      }
    });
  };

  const handleStartGame = (isNewWindow = false) => {
    if (
      userSetting.setting &&
      JSON.stringify(allFiats.allFiats) !== "{}" &&
      JSON.stringify(allSymbols.allSymbols) !== "{}" &&
      JSON.stringify(userGameScore.score) !== "{}"
    ) {
      let amount = "";
      let balance = 0;
      if (userSetting.setting.currencyType === 1) {
        balance =
          userAssets.userSymbols[userSetting.setting.symbol]?.balance ||
          0;
        setUserAmount({
          symbol: userSetting.setting.symbol,
          img: allSymbols.allSymbols[userSetting.setting.symbol]?.avatar,
          balance:
            userAssets.userSymbols[
              userSetting.setting.symbol
            ]?.balance.toFixed(6),
        });
      } else {
        balance =
          userAssets.userFiats[userSetting.setting.fiatCode]?.balance ||
          0;
        setUserAmount({
          symbol: userSetting.setting.fiatCode,
          img: allFiats.allFiats[userSetting.setting.fiatCode]?.avatar,
          balance:
            userAssets.userFiats[
              userSetting.setting.fiatCode
            ]?.balance.toFixed(2),
        });
      }

      // userGameScore.score.cashCoupon    //EUSGT(代金券)
      // userGameScore.score.score         //USGT
      // userGameScore.score.currencyScore //当前币种余额
      if (
        userGameScore?.score?.cashCoupon +
        userGameScore?.score?.score +
        userGameScore?.score?.currencyScore <=
        0
      ) {
        if (balance <= 0) {
          dispatch(showMessage({ message: t("play_no_balance"), code: 3 }));
          setTimeout(() => {
            dispatch(
              openWallet({
                type: "account",
              })
            );
          }, 1000);
          return;
        }
        amount = balance;
      }

      if (amount) {
        amount = amount.toFixed(4)
      }

      dispatch(
        startGame({
          channel: channel,
          gameId: gameId,
          language: countryLangChange(window.localStorage.getItem('lang')),
          amount: amount,
        })
      ).then((res) => {
        let data = res.payload;
        if (data) {
          setGameUrl(data);
          setMask(false);
          if (isNewWindow) {
            window.open(data)
          }
        }
      });
    }
  }

  useEffect(() => {
    dispatch(
      getTagCasinoGames({
        tagID: casinoData.recommondTag,
      })
    ).then((res) => {
      let result = res.payload;
      if (result) {
        if (isMobile()) {
          result = result.slice(0, 3);
        } else {
          result = result.slice(0, 7);
        }
        setRecommendData(result);
      }
    });

    dispatch(
      getCasinoGames({
        casinoID: casinoID,
      })
    ).then((res) => {
      let result = res.payload;
      if (result) {
        setGameData(result[0]);
      }
    });

    dispatch(
      getTagCasinoGames({
        tagID: favoriteTagID,
      })
    ).then((res) => {
      let data = res.payload;
      let tmpFavoriteID = data.map((v) => {
        return v.casinoID;
      });
      setFavoriteList(tmpFavoriteID);
    });
  }, [dispatch]);

  useEffect(() => {
    // console.log(addTagID, "addTagID");
    // console.log(removeTagID, "removeTagID");
  }, [addTagID, removeTagID]);

  useEffect(() => {
    if (
      userSetting.setting &&
      JSON.stringify(allFiats.allFiats) !== "{}" &&
      JSON.stringify(allSymbols.allSymbols) !== "{}" &&
      JSON.stringify(userGameScore.score) !== "{}"
    ) {
      let amount = "";
      let balance = 0;
      if (userSetting.setting.currencyType === 1) {
        balance =
          userAssets.userSymbols[userSetting.setting.symbol]?.balance ||
          0;
        setUserAmount({
          symbol: userSetting.setting.symbol,
          img: allSymbols.allSymbols[userSetting.setting.symbol]?.avatar,
          balance:
            userAssets.userSymbols[
              userSetting.setting.symbol
            ]?.balance.toFixed(6),
        });
      } else {
        balance =
          userAssets.userFiats[userSetting.setting.fiatCode]?.balance ||
          0;
        setUserAmount({
          symbol: userSetting.setting.fiatCode,
          img: allFiats.allFiats[userSetting.setting.fiatCode]?.avatar,
          balance:
            userAssets.userFiats[
              userSetting.setting.fiatCode
            ]?.balance.toFixed(2),
        });
      }
      // userGameScore.score.cashCoupon    //EUSGT(代金券)
      // userGameScore.score.score         //USGT
      // userGameScore.score.currencyScore //当前币种余额
      if (
        userGameScore?.score?.cashCoupon +
        userGameScore?.score?.score +
        userGameScore?.score?.currencyScore <=
        0
      ) {
        if (balance <= 0) {
          dispatch(showMessage({ message: t("play_no_balance"), code: 3 }));
          setTimeout(() => {
            dispatch(
              openWallet({
                type: "account",
              })
            );
          }, 1000);
          return;
        }
        amount = balance;
      }
    }
  }, [
    allFiats.allFiats,
    allSymbols.allSymbols,
    userAssets.userFiats,
    userAssets.userSymbols,
    userSetting,
    userGameScore
  ]);

  useEffect(() => {
    return () => {
      setTimeout(() => {
        dispatch(doSaveToWallet({}))
      }, 500)
    }
  }, []);

  const customList = (items) => (
    <Paper sx={{ width: 200, height: 230, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${tags.tagList[value].tagName}`}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  useEffect(() => {
    dispatch(
      getRankList({
        sortType: selectedTab + 1,
        groupType: 2,
      })
    ).then((res) => {
      const result = res.payload;
      let tmpRank = [];
      if (result) {
        result.map((item) => {
          var multiplier = 0;
          if (item.sumWinUSGT > item.sumBetUSGT) {
            multiplier = (item.sumWinUSGT / item.sumBetUSGT).toFixed(2) + "x";
          } else {
            if (item.sumWinUSGT === 0) {
              multiplier = "0.00x";
            } else {
              multiplier =
                (
                  1 +
                  (item.sumWinUSGT - item.sumBetUSGT) / item.sumBetUSGT
                ).toFixed(2) + "x";
            }
          }
          tmpRank.push({
            recordID: item.recordID,
            url: item.recordID > 3 ? '' : `assets/images/icon/rank-${item.recordID}.png`,
            user: item.nickName,
            time: formatTime(item.dayGroup),
            bet: item.sumBetUSGT,
            multiplier: multiplier,
            luckyRate: item.maxOneWinRate.toFixed(2) + "x",
            payout: item.sumWinUSGT,
          });
        });

        setRanks(tmpRank);
      }
    });
  }, [selectedTab]);

  return (
    <div className="flex w-full container">
      <div className="flex flex-col sm:flex-row flex-auto funbetTxt betDiColor sm:items-center min-w-0 p-10 md:p-32 pb-0 md:pb-0 gameHuoDongDing">
        <div className="flex flex-col flex-auto align-item">
          <motion.div className="flex grow shrink-0 flex-col items-center container ">
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              id="play-window"
              className={clsx(
                "activitybanner play-window",
                isMask && "play-window-active"
              )}
            >
              <motion.div
                variants={item}
                className={clsx(
                  "height-740 overflowHidden borderRadius-10 backGround-index displayPhoneSwitch1 min-w-full play-window",
                  isMask && "play-window-active"
                )}
              >
                {/*<LazyLoadImage src="assets/images/index/gameImg.png"></LazyLoadImage>*/}
                <iframe
                  className={clsx(
                    "play-iframe",
                    isMask && "play-iframe-active"
                  )}
                  src={gameUrl}
                  frameBorder="0"
                  id="play-iframe"
                ></iframe>
                {mask && (
                  <div
                    className={clsx(
                      "positionAb flex justify-center items-center play-mask",
                      isMask && "play-mask-active"
                    )}
                  >
                    <div className="width-260">
                      <div className="flex justify-center items-center marginJuZhong">
                        <span className="text-18">{t("game_balance")}</span>
                        <span className="text-18 ml-6">
                          {userAmount.symbol}
                        </span>
                        <span className="text-18 ml-6">
                          {userAmount.balance}
                        </span>
                        {userAmount.img && (
                          <LazyLoadImage
                            style={{
                              borderRadius: "50%",
                            }}
                            className="height-24 ml-4"
                            src={userAmount.LazyLoadImage}
                          />
                        )}
                        <LazyLoadImage
                          className="height-24 mt-3 btnPointer"
                          src="assets/images/index/jianTou.png"
                          onClick={() => {
                            dispatch(
                              openWallet({
                                type: "wallet",
                              })
                            );
                          }}
                        />
                      </div>
                      <div className="flex justify-center my-16">
                        {gameData?.canDemo && (
                          <div
                            className="btnPointer text-16 text-align borderRadius mr-20 txtBrightness"
                            style={window.localStorage.getItem("lang") == "tmr" ? {
                              width: "140px",
                              height: "40px",
                              color: "#ffffff",
                              background: "#334155",
                              fontSize: "1rem"
                            } : {
                              width: "124px",
                              height: "40px",
                              lineHeight: "40px",
                              color: "#ffffff",
                              background: "#334155",
                            }}
                            onClick={() => {
                              handleDemoGame();
                              setMask(false);
                            }}
                          >
                            {t("game_FunPlay")}
                          </div>
                        )}
                        <Button
                          className="whitespace-nowrap borderRadius text-align displayBlock text-16 funbetTxt"
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            if (userData.token) {
                              handleStartGame()
                            } else {
                              dispatch(onLogin({
                                type: 'login'
                              }));
                            }
                          }}
                          style={{ width: "124px" }}
                        >
                          <span style={window.localStorage.getItem("lang") == "tmr" ? { fontSize: "12px" } : {}}>{t("game_startGame")}</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="promotion-list1">
                  <div className="height-24  width-120 promotion-list1 mt-20 ml-12">
                    <LazyLoadImage
                      onClick={() => {
                        gotoFullscreen(document.getElementById("play-iframe"));
                      }}
                      className="btnPointer txtBrightness"
                      src="assets/images/icon/10001.png"
                    />
                    <LazyLoadImage
                      onClick={() => {
                        if (favoriteList.indexOf(Number(casinoID)) === -1) {
                          handleSetGameFavor(false);
                        } else {
                          handleSetGameFavor(true);
                        }
                      }}
                      className="btnPointer txtBrightness w-24 h-24"
                      src={
                        favoriteList.indexOf(Number(casinoID)) === -1
                          ? "assets/images/icon/Favorite.png"
                          : "assets/images/icon/Favorite2.png"
                      }
                    />
                    <LazyLoadImage
                      className="btnPointer txtBrightness"
                      src="assets/images/icon/10002.png"
                      onClick={() => {
                        setIsMask(!isMask);
                      }}
                    />

                    {userSetting?.user?.canSetGame && (
                      <FuseSvgIcon
                        sx={{ color: "#eeeeee" }}
                        className="text-24 btnPointer txtBrightness"
                        size={24}
                        color="action"
                        onClick={() => {
                          handleOpenSetting();
                        }}
                      >
                        material-outline:brightness_7
                      </FuseSvgIcon>
                    )}
                    {/*<LazyLoadImage className="btnPointer txtBrightness" src="assets/images/icon/10003.png" ></LazyLoadImage>*/}
                  </div>
                  {/*<div className="mt-10 width-180  promotion-list1 mr-16">*/}
                  {/*    <div className="mt-10">Fun Play</div>*/}
                  {/*    <div>*/}
                  {/*        <Switch*/}
                  {/*            onChange={(ev) => {*/}
                  {/*                setHideCompleted(ev.target.checked);*/}
                  {/*            }}*/}
                  {/*            checked={hideCompleted}*/}
                  {/*            name="hideCompleted"*/}
                  {/*        /></div>*/}
                  {/*    <div className="mt-10">Real Play</div>*/}
                  {/*</div>*/}
                </div>
              </motion.div>
            </motion.div>

            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="activitybanner"
            >
              <motion.div variants={item} className="min-w-full">
                <div className="backGround-inde overflowHidden  displayPhoneSwitch ">
                  <div
                    className="mt-10"
                    style={{ width: "100%", display: "flex" }}
                  >
                    <div className="imgGameIconWh" >
                      <LazyLoadImage
                        className="borderRadius-10"
                        src={gameData?.gameIcon}
                      />
                    </div>

                    <div className=" " style={{ marginLeft: "20px", width: "55%", maxWidth: "55%" }}>
                      <div className="text-20 font-weight500" style={{ overflowWrap: "break-word" }}>
                        {gameData?.gameName}
                      </div>

                      {/* <div className="text-14 font-weight500 mt-10">
                        {t("game_Selectyourdisplay")}
                      </div> */}

                      {/* <div className="flex">
                        <div
                          className="text-14 giftStyle mt-8 height-20"
                          style={{ lineHeight: "20px" }}
                        >
                          {t("game_Balancein")}
                        </div>
                      </div> */}

                      <div className="flex mt-10">
                        {/* <div
                          className="text-14 giftStyle height-20"
                          style={{ lineHeight: "20px", paddingTop: "2px",marginRight:"5px" }}
                        >
                          {t("game_balance")}
                        </div> */}

                        {user.token && (
                          <div className="mr-4" style={{ width: "20px", height: "20px", }}>
                            <LazyLoadImage
                              style={{ borderRadius: "50%" }}
                              src={userAmount.LazyLoadImage}
                            />
                          </div>
                        )}

                        {!user.token && (
                          <LazyLoadImage
                            style={{
                              borderRadius: "50%", width: "20px", height: "20px"
                            }}
                            className="mr-4"
                            src="assets/images/symbol/USDT.png"
                          />
                        )}

                        <div
                          className="text-14 font-weight500 height-20"
                          style={{ lineHeight: "20px" }}
                        >
                          {user.token ? userAmount.symbol : "USDT"}
                        </div>

                        {user.token && (
                          <div
                            className="text-14 ml-4 font-weight500 height-20"
                            style={{ lineHeight: "20px" }}
                          >
                            {NumToFixed2(userAmount)}
                          </div>
                        )}

                        {!user.token && (
                          <div
                            className="text-14 ml-4 font-weight500 height-20"
                            style={{ lineHeight: "20px" }}
                          >
                            0.000000
                          </div>
                        )}

                      </div>
                      <div className="text-14 giftStyle mt-10">
                        {t("Provided_by")} <span style={{ color: "#ffffff" }}>{gameData?.gameChannel}</span>
                      </div>

                      <div className="mt-10 flex">
                        <LazyLoadImage
                          className="btnPointer txtBrightness"
                          onClick={() => {
                            if (favoriteList.indexOf(Number(casinoID)) === -1) {
                              handleSetGameFavor(false);
                            } else {
                              handleSetGameFavor(true);
                            }
                          }}
                          src={
                            favoriteList.indexOf(Number(casinoID)) === -1
                              ? "assets/images/icon/Favorite.png"
                              : "assets/images/icon/Favorite2.png"
                          }
                          style={{ width: "22px", height: "22px" }}
                        ></LazyLoadImage>
                        <LazyLoadImage
                          className="btnPointer txtBrightness ml-20"
                          src="assets/images/icon/Recommend.png"
                          style={{ width: "22px", height: "22px", opacity: "0.3" }}
                        ></LazyLoadImage>
                        <LazyLoadImage
                          className="btnPointer txtBrightness ml-20"
                          src="assets/images/icon/telegram.png"
                          style={{ width: "22px", height: "22px", opacity: "0.3" }}
                        ></LazyLoadImage>
                      </div>

                    </div>
                  </div>

                  <div
                    className="promotion-list1 "
                    style={{ marginTop: "20px", width: "100%" }}
                  >
                    <Button
                      className="whitespace-nowrap  borderRadius text-16 py-22 funbetTxt"
                      variant="contained"
                      color="secondary"
                      style={{ width: "47%", background: "#334155", border: "2px solid #14C2A3" }}
                      onClick={() => {
                        handleDemoGame()
                      }}
                    >
                      <LazyLoadImage className="w-24 h-24" src="assets/images/icon/play.png"></LazyLoadImage>
                      <span className="ml-8">{t("game_FunPlay")}</span>
                    </Button>

                    <Button
                      className="whitespace-nowrap  borderRadius text-16 py-22 funbetTxt"
                      variant="contained"
                      color="secondary"
                      style={{ width: "47%" }}
                      onClick={() => {
                        if (userData.token) {
                          handleStartGame(true)
                        } else {
                          dispatch(onLogin({
                            type: 'login'
                          }));
                        }
                      }}
                    >
                      <LazyLoadImage className="w-24 h-24" src="assets/images/icon/play.png"></LazyLoadImage>
                      <span className="ml-8">{t("game_RealPlay")}</span>
                    </Button>
                  </div>
                </div>

                <div className="mt-20  borderRadius-10 backGround-index displayPhoneSwitch1">
                  <div className="pt-16 ml-16 text-18">{gameData?.gameName}</div>
                  <div className="mx-12 mt-20 ">
                    <Tabs
                      value={selectedTab}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="inherit"
                      variant="scrollable"
                      scrollButtons={false}
                      className="min-h-40  backGround-foot borderRadius"
                      classes={{
                        indicator:
                          "flex justify-center bg-transparent w-full h-full",
                      }}
                      TabIndicatorProps={{
                        children: (
                          <Box
                            sx={{ bgcolor: "text.disabled" }}
                            className="w-full h-full rounded-full gamesListBtnBackground-Color opacity-100"
                          />
                        ),
                      }}
                    >
                      <Tab
                        className="text-14 font-semibold min-h-40  Zindex funbetTxt"
                        disableRipple
                        label={t("game_LcukyWins")}
                      />
                      <Tab
                        className="text-14 font-semibold min-h-40  Zindex  flex funbetTxt"
                        disableRipple
                        label={t("game_BigWins")}
                      />
                      <Tab
                        className="text-14 font-semibold min-h-40  Zindex  flex funbetTxt"
                        disableRipple
                        label={t("game_Challenges")}
                      />
                      {/*<Tab*/}
                      {/*    className="text-14 font-semibold min-h-40 width-120 Zindex  flex"*/}
                      {/*    disableRipple*/}
                      {/*    label={t("game_Description")}*/}
                      {/*/>*/}
                    </Tabs>
                  </div>

                  <Paper sx={{ width: "97%", margin: "10px auto" }}>
                    <TableContainer>
                      <Table
                        stickyHeader
                        aria-label="customized table"
                        className="backGroundColorNode"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              className="funbetTxt"
                              sx={{
                                border: "none",
                                color: "#B8BABF",
                                minWidth: "120px",
                              }}
                            >
                              {t("game_Rank")}
                            </StyledTableCell>
                            <StyledTableCell
                              className="funbetTxt"
                              align="center"
                              sx={{
                                border: "none",
                                color: "#B8BABF",
                                minWidth: "120px",
                              }}
                            >
                              {t("game_User")}
                            </StyledTableCell>
                            <StyledTableCell
                              className="funbetTxt"
                              align="center"
                              sx={{
                                border: "none",
                                color: "#B8BABF",
                                minWidth: "180px",
                              }}
                            >
                              {t("game_Date")}
                            </StyledTableCell>
                            <StyledTableCell
                              className="funbetTxt"
                              align="right"
                              sx={{
                                border: "none",
                                color: "#B8BABF",
                                minWidth: "120px",
                              }}
                            >
                              {t("game_BetAmount")}
                            </StyledTableCell>
                            {selectedTab === 0 ? (
                              <StyledTableCell
                                align="center"
                                className="funbetTxt"
                                sx={{
                                  border: "none",
                                  color: "#B8BABF",
                                  minWidth: "140px",
                                }}
                              >
                                {t("game_lucky")}
                              </StyledTableCell>
                            ) : (
                              <StyledTableCell
                                align="center"
                                className="funbetTxt"
                                sx={{ border: "none", color: "#B8BABF" }}
                              >
                                {t("game_Multiplier")}
                              </StyledTableCell>
                            )}

                            <StyledTableCell
                              align="right"
                              className="funbetTxt"
                              sx={{
                                border: "none",
                                color: "#B8BABF",
                                minWidth: "120px",
                              }}
                            >
                              {t("game_Payout")}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ranks.map((row, index) => (
                            <StyledTableRow key={row.recordID}>
                              <StyledTableCell
                                component="th"
                                className="funbetTxt"
                                scope="row"
                                sx={{
                                  borderBottom: "0px",
                                  display: "flex",
                                  borderRadius: "5px",
                                }}
                              >
                                {row.url ? (
                                  <LazyLoadImage src={row.url}></LazyLoadImage>
                                ) : (
                                  <div style={{ width: '24px', textAlign: 'center' }}>{row.recordID}</div>
                                )}

                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                className="funbetTxt"
                                sx={{ borderBottom: "0px" }}
                              >
                                {row.user}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                className="funbetTxt"
                                sx={{ borderBottom: "0px" }}
                              >
                                {row.time}
                              </StyledTableCell>
                              <StyledTableCell
                                align="right"
                                className="funbetTxt"
                                scope="row"
                                sx={{
                                  borderBottom: "0px",
                                  borderRadius: "5px",
                                  display: "flex",
                                }}
                              >
                                {" "}
                                <LazyLoadImage src="assets/images/icon/501.png"></LazyLoadImage>
                                {row.bet}
                              </StyledTableCell>
                              {selectedTab === 0 ? (
                                <StyledTableCell
                                  align="center"
                                  className="funbetTxt"
                                  sx={{ borderBottom: "0px" }}
                                >
                                  {row.luckyRate}
                                </StyledTableCell>
                              ) : (
                                <StyledTableCell
                                  align="center"
                                  className="funbetTxt"
                                  sx={{ borderBottom: "0px" }}
                                >
                                  {row.multiplier}
                                </StyledTableCell>
                              )}

                              <StyledTableCell
                                align="right"
                                className="funbetTxt"
                                scope="row"
                                sx={{ borderBottom: "0px", display: "flex" }}
                              >
                                <LazyLoadImage src="assets/images/icon/501.png"></LazyLoadImage>
                                {row.payout}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  <div className="height-24 "></div>
                </div>

                <div className="mt-20  borderRadius-10  displayPhoneSwitch">
                  <div className="text-18">{gameData?.gameName}</div>
                  <div className=" mt-10 " style={{ backgroundColor: "#0E1421", borderRadius: "20px" }}>
                    <div className="mt-10">
                      <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="inherit"
                        variant="scrollable"
                        scrollButtons={false}
                        className="min-h-40  backGround-foot borderRadius"
                        classes={{
                          indicator:
                            "flex justify-center bg-transparent w-full h-full",
                        }}
                        TabIndicatorProps={{
                          children: (
                            <Box
                              sx={{ bgcolor: "text.disabled" }}
                              className="w-full h-full rounded-full gamesListBtnBackground-Color opacity-100"
                            />
                          ),
                        }}
                      >
                        <Tab
                          className="text-14 font-semibold min-h-40  Zindex funbetTxt"
                          disableRipple
                          label={t("game_LcukyWins")}
                          style={{ width: "33.3%", overflow: "hidden", whiteSpace: "nowrap" }}
                        />
                        <Tab
                          className="text-14 font-semibold min-h-40  Zindex  flex funbetTxt"
                          disableRipple
                          label={t("game_BigWins")}
                          style={{ width: "33.3%", overflow: "hidden", whiteSpace: "nowrap" }}
                        />
                        <Tab
                          className="text-14 font-semibold min-h-40  Zindex  flex funbetTxt"
                          disableRipple
                          label={t("game_Challenges")}
                          style={{ width: "33.4%", overflow: "hidden", whiteSpace: "nowrap" }}
                        />
                      </Tabs>
                    </div>


                    <Paper sx={{ width: "100%", margin: "10px auto" }}>
                      <TableContainer>
                        <Table
                          stickyHeader
                          aria-label="customized table"
                          className="backGroundColorNode"
                        >
                          <TableHead style={{ width: "97%", margin: "10px auto", height: "40px !important", backgroundColor: "#ffc600" }}>
                            <TableRow className="testRed" style={{ borderTopLeftRadius: "15px" }}>
                              <StyledTableCell
                                className="funbetTxt"
                                sx={{
                                  border: "none",
                                  color: "#B8BABF",
                                  minWidth: "70px",
                                  maxHeight: "40px"
                                }}
                              >
                                {t("game_Rank")}
                              </StyledTableCell>

                              <StyledTableCell
                                className="funbetTxt"
                                align="center"
                                sx={{
                                  border: "none",
                                  color: "#B8BABF",
                                  minWidth: "130px",
                                  maxHeight: "40px"
                                }}
                              >
                                {t("game_User")}
                              </StyledTableCell>


                              {selectedTab === 0 &&
                                <StyledTableCell
                                  align="right"
                                  className="funbetTxt"
                                  sx={{
                                    border: "none",
                                    color: "#B8BABF",
                                    minWidth: "110px",
                                    maxHeight: "40px"
                                  }}
                                >
                                  {t("game_lucky")}
                                </StyledTableCell>
                              }

                              {selectedTab === 1 &&
                                <StyledTableCell
                                  align="right"
                                  className="funbetTxt"
                                  sx={{
                                    border: "none",
                                    color: "#B8BABF",
                                    minWidth: "110px",
                                    maxHeight: "40px"
                                  }}
                                >
                                  {t("game_Payout")}
                                </StyledTableCell>
                              }

                              {selectedTab === 2 &&
                                <StyledTableCell
                                  className="funbetTxt"
                                  align="right"
                                  sx={{
                                    border: "none",
                                    color: "#B8BABF",
                                    minWidth: "110px",
                                    maxHeight: "40px"
                                  }}
                                >
                                  {t("game_BetAmount")}
                                </StyledTableCell>}

                              {/* <StyledTableCell
                                className="funbetTxt"
                                align="center"
                                sx={{
                                  border: "none",
                                  color: "#B8BABF",
                                  minWidth: "180px",
                                  maxHeight: "40px"
                                }}
                              >
                                {t("game_Date")}
                              </StyledTableCell> */}

                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {ranks.map((row, index) => (
                              <StyledTableRow key={row.recordID}>
                                <StyledTableCell
                                  component="th"
                                  className="funbetTxt"
                                  scope="row"
                                  sx={{
                                    borderBottom: "0px",
                                    display: "flex",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {row.url ? (
                                    <LazyLoadImage src={row.url}></LazyLoadImage>
                                  ) : (
                                    <div style={{ width: '24px', textAlign: 'center' }}>{row.recordID}</div>
                                  )}
                                </StyledTableCell>

                                <StyledTableCell
                                  align="center"
                                  className="funbetTxt"
                                  sx={{ borderBottom: "0px" }}
                                >
                                  {row.user}
                                </StyledTableCell>


                                {selectedTab === 0 &&
                                  <StyledTableCell
                                    align="right"
                                    className="funbetTxt"
                                    sx={{ borderBottom: "0px" }}
                                  >
                                    {row.luckyRate}
                                  </StyledTableCell>
                                }

                                {selectedTab === 1 &&
                                  <StyledTableCell
                                    align="right"
                                    className="funbetTxt"
                                    scope="row"
                                    sx={{ borderBottom: "0px", display: "flex" }}
                                  >
                                    <LazyLoadImage src="assets/images/icon/501.png"></LazyLoadImage>
                                    {row.payout}
                                  </StyledTableCell>}

                                {selectedTab === 2 &&
                                  <StyledTableCell
                                    align="right"
                                    className="funbetTxt"
                                    scope="row"
                                    sx={{
                                      borderBottom: "0px",
                                      borderRadius: "5px",
                                      display: "flex",
                                    }}
                                  >
                                    {" "}
                                    <LazyLoadImage src="assets/images/icon/501.png"></LazyLoadImage>
                                    {row.bet}
                                  </StyledTableCell>}

                                {/* <StyledTableCell
                                  align="center"
                                  className="funbetTxt"
                                  sx={{ borderBottom: "0px" }}
                                >
                                  {row.time}
                                </StyledTableCell> */}
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>


                {recommendData.length > 0 && (
                  <>
                    <div className="zuQiu mt-40 ">
                      <div className="promotion-list activitybanner marginJuZhong">
                        <div>
                          <div className="flex">
                            <LazyLoadImage src="assets/images/icon/10007.png"></LazyLoadImage>
                            <div className="font-14 mt-2 ml-4 font-weight500 fontColor">
                              {t("game_Recommend")}
                            </div>
                          </div>
                        </div>
                        {/* <div className="flex">
                          <LazyLoadImage
                            className="btnPointer btnOpacity"
                            src="assets/images/game/fanYeBtn1.png"
                          ></LazyLoadImage>
                          <LazyLoadImage
                            className="btnPointer btnOpacity"
                            src="assets/images/game/fanYeBtn2.png"
                          ></LazyLoadImage>
                        </div> */}
                      </div>
                    </div>
                    <div className="zuQiu mt-24">
                      <div
                        className="promotion-list activitybanner marginJuZhong hidden wrap"
                        style={{ width: "100%" }}
                      >
                        {recommendData.length > 0 &&
                          recommendData.map((item) => {
                            return (
                              <div
                                className="btnPointer   imgHidden maxWidth borderRadius-10"
                                key={item.gameID}
                              >
                                <LazyLoadImage
                                  className="imgMohu"
                                  src={
                                    item.gameIcon ||
                                    "assets/images/game/default.png"
                                  }
                                  onClick={() => {
                                    history.push(
                                      `/play/${item.gameChannel}/${item.gameID}`
                                    );
                                  }}
                                ></LazyLoadImage>
                                <a href="">
                                  {" "}
                                  <LazyLoadImage
                                    className="displayImg"
                                    src="assets/images/index/gameZheZhaoSmall.png"
                                  ></LazyLoadImage>
                                </a>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}

                <div className="zuQiu mt-24 ">
                  <div className="promotion-list activitybanner marginJuZhong">
                    <div>
                      <div className="flex">
                        <LazyLoadImage
                          className="flex w-24 h-24"
                          src="assets/images/icon/Providers.png"
                        ></LazyLoadImage>
                        <div className="font-14 mt-4 ml-4 font-weight500 fontColor">
                          {t("game_Providers")}
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <LazyLoadImage
                        className="btnPointer btnOpacity"
                        src="assets/images/game/fanYeBtn1.png"
                        onClick={() => {
                          previousChannelPage()
                        }}
                      ></LazyLoadImage>
                      <LazyLoadImage
                        className="btnPointer btnOpacity"
                        src="assets/images/game/fanYeBtn2.png"
                        onClick={() => {
                          nextChannelPage()
                        }}
                      ></LazyLoadImage>
                    </div>
                  </div>
                </div>

                <div className="zuQiu mt-32 displayPhoneSwitch1" style={{
                  width: '1193px',
                  overflow: 'hidden'
                }}>
                  <div
                    className="flex"
                    style={{
                      width: "auto",
                      transition: "all 1s",
                      marginLeft: `-${(channelPage - 1) * 1196}px`
                    }}
                  >
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/1.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/2.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/3.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/4.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/5.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/6.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/7.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/8.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/9.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/10.png"
                    ></LazyLoadImage>
                  </div>
                </div>

                <div className="zuQiu mt-20 displayPhoneSwitch"
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: "0px"
                  }}
                >
                  <div
                    className="promotion-listPhone activitybanner marginJuZhong hidden wrap"
                    style={{
                      width: "auto",
                      transition: "all 1s",
                      marginLeft: `-${(channelPage - 1) * 100}%`
                    }}
                  >
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/1.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/2.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/3.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/1.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/2.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/3.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/1.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/2.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/3.png"
                    ></LazyLoadImage>
                    <LazyLoadImage
                      className="btnPointer  txtBrightness workSty"
                      src="assets/images/work/1.png"
                    ></LazyLoadImage>
                  </div>

                </div>
              </motion.div>

              {/*setting*/}
              <BootstrapDialog
                onClose={() => {
                  setOpenSetting(false);
                }}
                aria-labelledby="customized-dialog-title"
                open={openSetting}
                className="game-setting"
              >
                <DialogContent sx={{ border: "none" }} dividers>
                  <div>
                    <h3>顶级分类</h3>
                    <div className="my-16">
                      <Box>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            topGroup
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={mainTagID}
                            label="topGroup"
                            onChange={handleChangeSelect}
                          >
                            {topGroup.topGroup.length > 0 &&
                              topGroup.topGroup.map((item) => {
                                return (
                                  <MenuItem value={item.tagID} key={item.tagID}>
                                    {item.tagName}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <h3>标签编辑</h3>
                    <div className="flex justify-between items-center my-16">
                      <Grid item>
                        <div>所有标签</div>
                        {customList(allCasinoTags)}
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" alignItems="center">
                          <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllRight}
                            disabled={allCasinoTags.length === 0}
                            aria-label="move all right"
                          >
                            ≫
                          </Button>
                          <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                          >
                            &gt;
                          </Button>
                          <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                          >
                            &lt;
                          </Button>
                          <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllLeft}
                            disabled={selectedTags.length === 0}
                            aria-label="move all left"
                          >
                            ≪
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <div>已选中标签</div>
                        {customList(selectedTags)}
                      </Grid>
                    </div>
                  </div>
                  <div className="flex justify-center my-16">
                    <Button
                      className="whitespace-nowrap borderRadius text-20"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        dispatch(
                          setCasinoGameTagList({
                            channel: gameData.gameChannel,
                            gameID: gameData.gameID,
                            mainTagID: mainTagID,
                            addTagID: addTagID.toString(),
                            removeTagID: removeTagID.toString(),
                          })
                        );
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </DialogContent>
              </BootstrapDialog>
            </motion.div>
          </motion.div>
          <Common_gameReport />
          <Common_foot />
          <div className="mt-32  promotion-list colorGameListTitle mb-20 displayPhoneSwitch1">
            <div>FuniBet 2022- ALL Rights Reserved</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Play;
