import "../../../styles/index.css";
import { useState, useEffect, default as React } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import history from '@history';
import AnimateModal from '../../components/FuniModal';
import { selectUserData } from "../../store/user";
import { useDispatch, useSelector } from "react-redux";
import AwardPop from "../../components/AwardPop";
import { motion } from 'framer-motion';
import { LazyLoadImage } from "react-lazy-load-image-component";


const Common_foot = (props) => {
  const { t } = useTranslation('mainPage');


  const [openChangeStatus, setOpenChangeStatus] = useState({
    relief: false,
  });

  const [openChangeStatus2, setOpenChangeStatus2] = useState({
    paiZhao: false,
  });

  const onChangeStatus = (key, value) => {
    setOpenChangeStatus({ ...openChangeStatus, [key]: value });
  };

  const onChangeStatus2 = (key, value) => {
    setOpenChangeStatus2({ ...openChangeStatus2, [key]: value });
  };


  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };


  return (
    <>
      <div className="zuQiu mt-24 backGround-foot backGround-footImg  mb-20">
        <div className="mt-32  displayPhoneSwitch1">
          <div className="promotion-list1 activitybanner marginJuZhong">
            <div className="foot1">
              <LazyLoadImage  src={`${React.$imgUrl}/images/index/footLogo.png`}></LazyLoadImage>
              <div className="text-20 mt-12 funbetTxt">{t("home_JoinOur")}</div>
              <Button className="whitespace-nowrap width-180  borderRadius mt-24 text-18 py-28 funbetTxt" variant="contained" color="secondary" onClick={() => { window.open('https://discord.gg/4xjurJnQ', '_blank') }} style={window.localStorage.getItem("lang") == "de"||"br"?{fontSize:"1.6rem"}:window.localStorage.getItem("lang") == "ru"?{fontSize:"1rem"}:{}}>
                {t("home_JOIN")} DISCORD
              </Button>
              <div className="text-18 mt-20 funbetTxt">{t("home_LICENSES")}</div>
              <div className="flex">
                <LazyLoadImage className="btnPointer txtBrightness" src={`${React.$imgUrl}/images/index/foot18_01.png`} onClick={() => { onChangeStatus('relief', true) }}></LazyLoadImage>
                <LazyLoadImage className="btnPointer txtBrightness" src={`${React.$imgUrl}/images/index/foot18_02.png`} onClick={() => { onChangeStatus2('paiZhao', true) }}></LazyLoadImage>
                <LazyLoadImage src={`${React.$imgUrl}/images/index/foot18_03.png`}></LazyLoadImage>
              </div>
            </div>
            <div className="foot2 phonefootTop">
              <div className="text-18  btnPointer txtFootColor " onClick={() => { history.push('comingSoon/OurPlatforms') }}>{t("home_OurPlatforms")}</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { history.push('home') }}>FuniBet</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { window.open('https://beingfi.io/', '_blank') }}>BeingFi</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { window.open('https://BeDAO.io/', '_blank') }}>BeDAO</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { history.push('comingSoon/Marketplac') }}>NFT {t("home_Marketplac")}</div>
            </div>
            <div className="foot2 phonefootTop">
              <div className="text-18  btnPointer txtFootColor " onClick={() => { history.push('home') }}>FuniBet</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { history.push('comingSoon/Howtoearn') }}>{t("home_Howtoearn")}</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { history.push('comingSoon/Nfts') }}>NFTs</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { history.push('comingSoon/home_Games') }} >{t("home_Games")}</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { window.open('https://bedao.gitbook.io/whitepaper/', '_blank') }}>{t("home_WhitePaper")}</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { history.push('comingSoon/InvestorDeck') }}>{t("home_InvestorDeck")}</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { window.open('https://medium.com/@tanse', '_blank') }}>{t("home_News")}</div>
            </div>
            <div className="foot2 phonefootTop">
              <div className="text-18  btnPointer txtFootColor" onClick={() => { history.push('comingSoon/Aboutsus') }}>{t("home_Aboutsus")}</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { history.push('comingSoon/Aboutsus') }}>{t("home_Aboutsus")}</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { history.push('comingSoon/Aboutsus') }}>{t("home_Industry")}</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { history.push('comingSoon/Aboutsus') }}>{t("home_Roadmap")}</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { history.push('comingSoon/Aboutsus') }}>{t("home_Partner")}</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { history.push('apps/help-center/faqs') }}>{t("home_FAQs")}</div>
              <div className="text-18 mt-20 colorGameListTitle btnPointer txtBrightness funbetTxt" onClick={() => { history.push('Affiliate') }}>{t("home_Affiliate")}</div>
            </div>
            <div className="foot2 mb-28 phonefootTop">
              <div className="text-18  btnPointer txtFootColor" onClick={() => { history.push('comingSoon/Stay') }}>{t("home_Stayintouch")}</div>
              <div className="text-18 mt-16 btnPointer flex txtBrightness">
                <LazyLoadImage className="w-32 h-32" src={`${React.$imgUrl}/images/index/footIcon1.png`}></LazyLoadImage>
                <div className="text-18  colorGameListTitle mt-4 ml-8 funbetTxt" onClick={() => { window.open('https://twitter.com/BoxFuni', '_blank') }}>Twitter</div>
              </div>
              <div className="text-18 mt-20 btnPointer flex txtBrightness">
                <LazyLoadImage className="w-32 h-32" src={`${React.$imgUrl}/images/index/footIcon2.png`}></LazyLoadImage>
                <div className="text-18  colorGameListTitle mt-4 ml-8 funbetTxt" onClick={() => { window.open('https://medium.com/@tanse', '_blank') }}>Medium</div>
              </div>
              <div className="text-18 mt-20 btnPointer flex txtBrightness">
                <LazyLoadImage className="w-32 h-32" src={`${React.$imgUrl}/images/index/footIcon3.png`}></LazyLoadImage>
                <div className="text-18  colorGameListTitle mt-4 ml-8 funbetTxt" onClick={() => { window.open('https://discord.gg/4xjurJnQ', '_blank') }}>Discord</div>
              </div>
              <div className="text-18 mt-20 btnPointer flex txtBrightness">
                <LazyLoadImage className="w-32 h-32" src={`${React.$imgUrl}/images/index/footIcon4.png`}></LazyLoadImage>
                <div className="text-18  colorGameListTitle mt-4 ml-8 funbetTxt" onClick={() => { window.open('https://t.me/BeingFi_Official', '_blank') }}>Telegram</div>
              </div>
              <div className="text-18 mt-20 btnPointer flex txtBrightness">
                <LazyLoadImage className="w-32 h-32" src={`${React.$imgUrl}/images/index/footIcon5.png`}></LazyLoadImage>
                <div className="text-18  colorGameListTitle mt-4 ml-8 funbetTxt" onClick={() => { history.push('comingSoon/GitHub') }}>GitHub</div>
              </div>
              <div className="text-18 mt-20 btnPointer flex txtBrightness">
                <LazyLoadImage className="w-32 h-32" src={`${React.$imgUrl}/images/index/footIcon6.png`}></LazyLoadImage>
                <div className="text-18  colorGameListTitle mt-4 ml-8 funbetTxt" onClick={() => { window.open('https://bedao.gitbook.io/whitepaper/', '_blank') }}>{t("home_WhitePaper")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="displayPhoneSwitch px-16 betDiColor">
          <div className="promotion-list1">
            <LazyLoadImage className="w-32 h-32" src={`${React.$imgUrl}/images/index/footIcon1.png`} onClick={() => { window.open('https://twitter.com/BoxFuni', '_blank') }}></LazyLoadImage>
            <LazyLoadImage className="w-32 h-32" src={`${React.$imgUrl}/images/index/footIcon2.png`} onClick={() => { window.open('https://medium.com/@tanse', '_blank') }}></LazyLoadImage>
            <LazyLoadImage className="w-32 h-32" src={`${React.$imgUrl}/images/index/footIcon3.png`} onClick={() => { window.open('https://discord.gg/4xjurJnQ', '_blank') }}></LazyLoadImage>
            <LazyLoadImage className="w-32 h-32" src={`${React.$imgUrl}/images/index/footIcon4.png`} onClick={() => { window.open('https://t.me/FuniBet6', '_blank') }}></LazyLoadImage>
            <LazyLoadImage className="w-32 h-32" src={`${React.$imgUrl}/images/index/footIcon6.png`} onClick={() => { window.open('https://bedao.gitbook.io/whitepaper/', '_blank') }}></LazyLoadImage>
          </div>
        </div>
      </div>

      <AnimateModal className="paiZhaoGC" closeClass="closeBtnPaiZhao" open={openChangeStatus.relief} onClose={() => onChangeStatus('relief', false)}>
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="imgHidden funbetTxt" style={{ height: "100%", width: "100%" }}>
        </motion.div>
      </AnimateModal>

      <AnimateModal className="paiZhaoPG" closeClass="closeBtnPaiZhao1" open={openChangeStatus2.paiZhao} onClose={() => onChangeStatus2('paiZhao', false)}>
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="imgHidden funbetTxt" style={{ height: "100%", width: "100%" }}>
        </motion.div>
      </AnimateModal>

    </>

  );
};
export default Common_foot;
