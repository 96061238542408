import "../../../styles/game.css";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { navbarClose, navbarOpen } from 'app/store/fuse/navbarSlice';
import { useParams } from 'react-router-dom';
import { doSaveToWallet, getSportToken, getUserSetting, onLogin, openWallet } from "app/store/user/userThunk";
import FuseLoading from "@fuse/core/FuseLoading";
import { margin } from "@mui/system";
import { isMobile, judgeLangFontSize } from "../../util/tools/function";
import history from "@history";
import { selectUserData } from "app/store/user";
import { selectUserAssets } from "app/store/user/userAssets";
import { selectFiats } from "app/store/config/fiats";
import { showMessage } from "app/store/fuse/messageSlice";
import { startGame } from "app/store/casino/casinoThunk";
import GameTips from "../cookies/GameTips";
import SportTips from "../cookies/SportTips";
import { selectUserSetting } from "app/store/user/userSetting";
import { selectUserGameScore } from "app/store/user/userGameScore";
import { updateUserFiats, updateUserSymbols } from "app/store/user";
import { selectSymbols } from "app/store/config/symbols";
import AnimateModal from "../../components/FuniModal";
import { motion } from "framer-motion";
import LoadingButton from "@mui/lab/LoadingButton";
import { closeMaintainPage } from "app/store/config/maintainConfig";
import Maintenance from '../maintenance/Maintenance';
import { appendScript } from "../../util/tools/utils";
import clsx from "clsx";


const container = {
    show: {
        transition: {
            staggerChildren: 0.1,
        },
    },
};
const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
};


function Sport(props) {
    const { t } = useTranslation("mainPage");

    const dispatch = useDispatch();
    const params = useParams();
    const bookView = params.bookView;
    const user = useSelector(selectUserData);
    const userAssets = useSelector(selectUserAssets);
    const allFiats = useSelector(selectFiats);
    const allSymbols = useSelector(selectSymbols);
    const userData = user;
    const userSetting = useSelector(selectUserSetting);
    const userGameScore = useSelector(selectUserGameScore);
    const [sportToken, setSportToken] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showGameTips, setShowGameTips] = useState(false);
    const [isTips, setIsTips] = useState(false);
    const [isMaintain, setIsMaintain] = useState(false);

    const [isChangeSymbol, setIsChangeSymbol] = useState(false);

    const getAmountInSportToken = () => {
        if (
            userSetting.setting &&
            JSON.stringify(allFiats.allFiats) !== "{}" &&
            JSON.stringify(allSymbols.allSymbols) !== "{}" &&
            JSON.stringify(userGameScore.score) !== "{}" &&
            JSON.stringify(userAssets.userFiats) !== "{}" &&
            JSON.stringify(userAssets.userSymbols) !== "{}"
        ) {
            let amount = "";
            let balance = 0;
            if (userSetting.setting.currencyType === 1) {
                balance =
                    userAssets.userSymbols[userSetting.setting.symbol]?.balance ||
                    0;
            } else {
                balance =
                    userAssets.userFiats[userSetting.setting.fiatCode]?.balance ||
                    0;
            }

            // userGameScore.score.cashCoupon    //EUSGT(代金券)
            // userGameScore.score.score         //USGT
            // userGameScore.score.currencyScore //当前币种余额
            if (
                Number(userGameScore?.score?.cashCoupon) +
                Number(userGameScore?.score?.score) +
                Number(userGameScore?.score?.currencyScore) <=
                0
            ) {
                if (balance <= 0) {
                    dispatch(showMessage({ message: t("play_no_balance"), code: 3 }));
                    setTimeout(() => {
                        dispatch(
                            openWallet({
                                type: "account",
                            })
                        );
                    }, 1000);
                    return;
                }
                amount = balance;
            }

            if (amount) {
                amount = amount.toFixed(4)
            } else {
                amount = undefined
            }

            getUserSportToken(amount)

        }
    }

    // 获取用户Sport token
    const getUserSportToken = (amount) => {
        setSportToken('')
        dispatch(getSportToken({
            amount
        })).then((res) => {
            let result = res.payload;
            console.log(result, 'sport token......')
            if (result.code === 0) {
                setSportToken(result.data)
            } else {
                if (result.data === 'unlogin') {
                    handleOnLogin()
                }
            }
        })
    }

    // 加载sport页面(iframe)
    const initIframePage = () => {
        if (isMobile()) {
            //正式服
            // var params = {
            //     server: "https://sport.funibet.com/",
            //     containerId: "sport_div_iframe",
            //     token: sportToken,
            //     defaultLanguage: "en",
            //     sportPartner: "dc9ab0f9-2506-4e1f-b6a7-0bae7d6802c3",
            //     parent: ["funibet.com"],
            // }

            //测试服
            var params = {
                server: "https://sport.test-game-api.funihash.com/",
                containerId: "sport_div_iframe",
                token: sportToken,
                defaultLanguage: "en",
                sportPartner: "3d6a4c11-ea08-43ef-9732-8013a37a71b8",
                parent: ["test-game.funibet.com"],
            }

            // Bootstrapper.boot(params, { name: "LivePage" });
            Bootstrapper.bootIframe(params, { name: "mobile" }, { height: "900px" }).then(() => {
                setIsLoading(false);
            })
        } else {
            if (bookView === 'europeanView') {
                //正式服
                // var sp = [
                //     ["server", "https://sport.funibet.com/"],
                //     ["token", sportToken],
                //     ["currentPage", "Home"],
                //     ["language", "en"],
                //     ["sportsBookView", bookView],
                //     // ["fixedHeight", true],
                //     ["sportPartner", "dc9ab0f9-2506-4e1f-b6a7-0bae7d6802c3"]
                // ];

                //测试服
                var sp = [
                    ["server", "https://sport.test-game-api.funihash.com/"],
                    ["token", sportToken],
                    ["currentPage", "Home"],
                    ["language", "en"],
                    ["sportsBookView", bookView],
                    ["fixedHeight", true],
                    ["sportPartner", "3d6a4c11-ea08-43ef-9732-8013a37a71b8"]
                ];
                SportFrame.frame(sp);
                setIsLoading(false);
            } else {
                //正式服
                // var params = {
                //     server: "https://sport.funibet.com/",
                //     containerId: "sport_div_iframe",
                //     token: sportToken,
                //     defaultLanguage: "en",
                //     // fixedHeight: true,
                //     sportPartner: "dc9ab0f9-2506-4e1f-b6a7-0bae7d6802c3",
                //     parent: ["funibet.com"],
                // }

                //测试服
                var params = {
                    server: "https://sport.test-game-api.funihash.com/",
                    containerId: "sport_div_iframe",
                    token: sportToken,
                    defaultLanguage: "en",
                    fixedHeight: true,
                    sportPartner: "3d6a4c11-ea08-43ef-9732-8013a37a71b8",
                    parent: ["test-game.funibet.com"],
                }

                // Bootstrapper.boot(params, { name: "LivePage" });
                Bootstrapper.bootIframe(params, { name: bookView }, { height: "900px" }).then(() => {
                    setIsLoading(false);
                })
            }
        }
        // Bootstrapper.bootIframe(params, { name: "AsianView" }, { height: "900px" });
    };

    // 关闭左侧菜单
    const leftNavClose = () => {
        dispatch(navbarClose());
    };

    // 删除拉丁的BetSlip
    const delBetSlip = () => {
        var bodyDom = document.getElementsByTagName("body")[0];
        if (document.getElementById("betslip-container-element")) {
            var betslipDom = document.getElementById("betslip-container-element");
            bodyDom.removeChild(betslipDom);
        }
    }


    // 替换欧洲的图片
    const changeEuropeanImg = () => {
        var iframeDom = document.getElementById("sport_div_iframe").getElementsByTagName("iframe")[0];
        console.log(iframeDom, "iframeDom......");
        iframeDom.onload = function () {
            // var timerDom = setInterval(() => {

            // }, 200)
            console.log($('#sport_div_iframe').children('iframe')[0].contents(), 123123123)
            $('#sport_div_iframe').children('iframe')[0].contents().find('#info_panel_block').innerHTML = '12345'
        }


        // if (document.getElementById("info_panel_block")) {
        //     var eurChindDom = document.getElementById("betslip-container-element");
        //     eurDom.removeChild(eurChindDom);
        // }
    }

    // 判断是否是sport专用域名
    const handleOpenSport = () => {
        // let loadSdkUrl1 = '//sport.funibet.com/js/partner/bootstrapper.min.js'
        // let loadSdkUrl2 = '//sport.funibet.com/js/Partner/IntegrationLoader.min.js'
        let loadSdkUrl1 = '//sport.test-game-api.funihash.com/js/partner/bootstrapper.min.js'
        let loadSdkUrl2 = '//sport.test-game-api.funihash.com/js/Partner/IntegrationLoader.min.js'
        appendScript(loadSdkUrl1)
        appendScript(loadSdkUrl2)

        return true
    }

    // const handleOpenSport = () => {
    //     let currentUrl = document.URL
    //     // let sportDomain = 'sports.funibet.com'
    //     let sportDomain = 'localhost:3000'
    //     if (currentUrl.indexOf(sportDomain) === -1) {
    //         let openUrl = currentUrl.replace(window.location.host, sportDomain)
    //         $.ajax({
    //             type: "GET",
    //             cache: false,
    //             url: openUrl,
    //             data: "",
    //             success: function () {
    //                 // openUrl = 'http://192.168.31.154:3000/sport/asianView'
    //                 if (openUrl.indexOf('?') === -1) {
    //                     openUrl += `?token=${window.localStorage.getItem('Authorization')}`
    //                 } else {
    //                     openUrl += `&token=${window.localStorage.getItem('Authorization')}`
    //                 }
    //
    //                 window.open(openUrl)
    //                 history.back()
    //             },
    //             error: function () {
    //                 console.log('维护......')
    //                 setIsLoading(false)
    //                 setIsMaintain(true)
    //             }
    //         })
    //         return false
    //     } else {
    //         return true
    //     }
    // }

    const handleOnLogin = () => {
        history.push("/home");
        dispatch(onLogin({ type: "login" }));
    };

    useEffect(() => {
        if (sportToken) {
            if (userSetting?.setting?.casinoGame && !isTips) {
                setShowGameTips(true)
                setIsTips(true)
            } else {
                document.getElementById("sport_div_iframe").innerHTML = ""
                delBetSlip()
                try {
                    initIframePage()
                } catch (e) {
                    setIsLoading(false)
                    setIsMaintain(true)
                    console.log(e, 'e...')
                }

            }
        }
    }, [sportToken, bookView])

    useEffect(() => {
        if (
            !window.localStorage.getItem('Authorization') &&
            window.localStorage.getItem(
                `Authorization-${window.sessionStorage.getItem('openAppId') || 0
                }-${window.sessionStorage.getItem('openIndex') || 0
                }`
            )
        ) {
            handleOnLogin();
        }
        leftNavClose();
        return () => {
            dispatch(doSaveToWallet({}))
            dispatch(navbarOpen());
            delBetSlip()
        }
    }, []);

    useEffect(() => {
        if (
            JSON.stringify(allFiats.allFiats) !== '{}' &&
            JSON.stringify(allSymbols.allSymbols) !== '{}' &&
            JSON.stringify(userAssets.userFiats) !== '{}' &&
            JSON.stringify(userAssets.userSymbols) !== '{}' &&
            JSON.stringify(userSetting.setting) !== '{}' &&
            JSON.stringify(userGameScore.score) !== '{}'
        ) {
            console.log(userSetting.setting, 'userSetting.setting')
            if (userSetting.setting.currencyType === 1 && userSetting.setting.symbol === 'eUSDT') {
                setIsChangeSymbol(true);
            } else {
                if (handleOpenSport()) {
                    getAmountInSportToken()
                }
            }
        }
    }, [
        allFiats.allFiats,
        allSymbols.allSymbols,
        userAssets.userFiats,
        userAssets.userSymbols,
        userSetting,
        userGameScore
    ]);


    return (
        <>
            {isMaintain && <Maintenance open={true} />}
            {isLoading && <div className="m-auto  flex align-item justifyContent" style={{ height: "100vh" }}>
                <div>
                    <div className='logo m-auto'>
                        <img style={{ width: "120px", maxWidth: "120px", marginBottom: "20px" }} width='128' src={`${React.$imgUrl}/images/logo/logo-prefix.png`} alt='logo' />
                    </div>
                    <FuseLoading />
                </div>
            </div>
            }
            <div id="sport_div_iframe" className={clsx("", isMobile() ? 'mt-24' : 'mt-0')} >
            </div>
            <GameTips
                open={showGameTips}
                successFun={() => {
                    setShowGameTips(false)
                    getAmountInSportToken()
                }}
                errorFun={() => {
                    setShowGameTips(false)
                }}
            />
            <SportTips
                open={isChangeSymbol}
                successFun={() => {
                    setIsChangeSymbol(false)
                    dispatch(
                        openWallet({
                            type: "account",
                        })
                    )
                }}
                errorFun={() => {
                    setIsChangeSymbol(false)
                    history.back()
                }}
            />
        </>
    );
}
export default Sport;
