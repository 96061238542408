import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import Logo from '../../../../shared-components/Logo';
import NavbarToggleButton from '../../../../shared-components/NavbarToggleButton';
import UserNavbarHeader from '../../../../shared-components/UserNavbarHeader';
import Navigation from '../../../../shared-components/Navigation';
import LanguageSwitcher from '../../../../shared-components/LanguageSwitcher';
import FuseSvgIcon from '../../../../../../@fuse/core/FuseSvgIcon/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { memo, useState, useEffect, forwardRef, default as React, useRef, useMemo, useCallback } from "react";
import Slide from '@mui/material/Slide';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";
import { getRedeemCode, getSignInConfig } from "../../../../../store/activity/activityThunk";
import { getInsert, getFeedBack, getUpload } from "../../../../../store/user/userThunk"
import { useDebounce } from "@fuse/hooks";

import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { changeLanguage, selectCurrentLanguage, selectLanguages } from '../../../../../store/i18nSlice';
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { showMessage } from "../../../../../store/fuse/messageSlice";
import NotificationPanelToggleButton from '../../../../shared-components/notificationPanel/NotificationPanelToggleButton';
import AdjustFontSize from '../../../../shared-components/AdjustFontSize';

import AnimateModal from '../../../../../components/FuniModal';
import AwardPop from "../../../../../components/AwardPop";
import TextField from '@mui/material/TextField';
import { ImageAspectRatio } from '@mui/icons-material';
import LoadingButton from "@mui/lab/LoadingButton";
import { selectUserData } from "../../../../../store/user";
import { onLogin } from "../../../../../store/user/userThunk";
import { selectChatPanelState } from "app/theme-layouts/shared-components/chatPanel/store/stateSlice";

import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { navbarToggle, navbarToggleMobile } from 'app/store/fuse/navbarSlice';
import { Crisp } from "crisp-sdk-web";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  '& ::-webkit-scrollbar-thumb': {
    boxShadow: `inset 0 0 0 20px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
      }`,
  },
  '& ::-webkit-scrollbar-thumb:active': {
    boxShadow: `inset 0 0 0 20px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
      }`,
  },
}));

const StyledContent = styled(FuseScrollbars)(({ theme }) => ({
  overscrollBehavior: 'contain',
  overflowX: 'hidden',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 40px, 100% 10px',
  backgroundAttachment: 'local, scroll',
}));

function NavbarStyle1Content(props) {
  const { t } = useTranslation('mainPage');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openChangeStatus, setOpenChangeStatus] = useState({
    relief: false,
  });

  const [ liveSupportNum, setLiveSupportNum ] = useState(0);
  const chatState = useSelector(selectChatPanelState);

  const ref = useRef(0);

  const [inputVal, setInputVal] = useState({
    address: '',
  });

  const user = useSelector(selectUserData);

  const onChangeStatus = (key, value) => {
    setOpenChangeStatus({ ...openChangeStatus, [key]: value });
  };

  const handleOnLogin = () => {
    dispatch(onLogin({ type: "login" }));
  };

  const [openChangeStatus1, setOpenChangeStatus1] = useState({
    suggestions: false,
  });

  const onChangeStatus1 = (key, value) => {
    setOpenChangeStatus1({ ...openChangeStatus1, [key]: value });
  };

  const [popAward, setPopAward] = useState(false);
  const [spinRewardBalance, setSpinRewardBalance] = useState({
    balance: 0,
    symbol: 'eUSDT'
  });


  const currentLanguage = useSelector(selectCurrentLanguage);
  const languages = useSelector(selectLanguages);
  const [menu, setMenu] = useState(null);
  const dispatch = useDispatch();
  const [getloading, setGetloading] = useState(false)
  const [ isOpenChat, setIsOpenChat ] = useState(false);
  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const langMenuClose = () => {
    setMenu(null);
  };

  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  function handleLanguageChange(lng) {
    dispatch(changeLanguage(lng.id));
    langMenuClose();
  }

  const [feedID, setFeedID] = useState();
  const [redeemCode, setRedeemCode] = useState('');
  const [suggestText, setSuggestText] = useState('');

  const handleGetRedeemCode = () => {
    dispatch(getRedeemCode({
      redeemCode: redeemCode
    })).then((res) => {
      let result = res.payload;
      if ((result.RedeemGiveMoney / 100) > 0) {
        setSpinRewardBalance({
          balance: result.RedeemGiveMoney / 100,
          symbol: 'eUSDT'
        });
        setPopAward(true);
      } else {
        dispatch(showMessage({ message: t('error_1'), code: 2 }));
      }
    });
  };
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState(null)
  const [url1, setUrl1] = useState(null)

  const submit = (e) => {
    setGetloading(true)
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', image);

    fetch('//test-api.funihash.com/fingernft/storage/upload', {
      method: "POST",
      body: formData,
      headers: {
        'Finger-Nft-Token': window.localStorage.getItem('Authorization'),
        'Wallet-OpenApp-Id': window.sessionStorage.getItem(
            'openAppId'
        ) || '6436951541b60d250c692481',
        'Wallet-OpenApp-Index': window.sessionStorage.getItem(
            'openAppIndex'
        ) || '0'
      }
    }).then(response => response.json()).then(data => {
      let resUrl = data.data.url
      setGetloading(false)
      dispatch(getInsert({
        title: "Suggest",
        content: suggestText,
        imgurl: "http://test-api.funihash.com/" + resUrl
      })).then((res) => {
        if (res.payload) {
          setFeedID(res.payload)
        }
      })
    }).catch(err => {
      console.log(err);
      setGetloading(false)
    })
  }
  //  useEffect(() => {
  //   if(feedID!=''){
  //     dispatch(getFeedBack({
  //       IsProcessed:1
  //     })).then((res)=>{
  //       console.log(res,"后台回复");
  //       let notice=res.payload
  //     })
  //     }else{
  //       dispatch(getFeedBack({
  //         IsProcessed:1
  //       })).then((res)=>{
  //         console.log(res,"后台回复");
  //       })
  //     }
  //  }, []);
  const handleImageChange = (e) => {
    const file = e.target.files[0]
    const localUrl = URL.createObjectURL(e.target.files[0])
    setUrl(localUrl)
    setImage(file)
  };

  const initChat = () => {
    Crisp.configure('c29c560c-5875-458b-bd83-c4ccf0a76a3c');
    Crisp.setColorTheme("blue_grey");
    Crisp.chat.hide();
    Crisp.chat.close();

    Crisp.chat.onChatOpened(() => {
      Crisp.chat.show();
      console.log('onChatOpened?')
      setIsOpenChat(true)
    })
    Crisp.chat.onChatClosed(() => {
      Crisp.chat.hide();
      console.log('close?')
      setIsOpenChat(false)
    })
  }

  useEffect(() => {
    if (JSON.stringify(currentLanguage)=="{}") {
      dispatch(changeLanguage('en'));
    }

    initChat();
  }, []);
  // useEffect(() => {
  //   let allNotice = concat(notice, transRecordList, feedBackList)
  //   setLiveSupportNum(allNotice.length);
  // }, [notice, transRecordList, feedBackList])
  
  return (
    <Root className={clsx('flex flex-auto flex-col overflow-hidden h-full', props.className)}>

      <AnimateModal className="giftDi" closeClass="closeBtnGift" open={openChangeStatus.relief} onClose={() => onChangeStatus('relief', false)}>
        {/* <BootstrapDialog
        onClose={() => { onChangeStatus('relief', false) }}
        aria-labelledby="customized-dialog-title"
        // TransitionComponent={Transition}
        open={openChangeStatus.relief}
        className="noBackGroundCheckIn giftDiWH"
      >
        <DialogContent sx={{ border: "none" }} className='giftDi' dividers> */}
        <div>
          <Typography id="customized-dialog-title" className='textAlignRight'>
            <span style={{ display: "block", color: "#FFD569" }} className=" text-align  giftTitleMt">{t("home_GiftCode")}</span>
          </Typography>
          <Box className="">
            <div className="text-align giftTxtmt-32 ">
              {t("home_EnteryourGiftCode")}
            </div>
            <div className='txtColorHui text-20 flex justify-center giftTxtmtInput'>
              <FormControl className="wallet-search" sx={{ width: '78%', borderColor: '#151D2B', border: "none", maxWidth: "1196px" }} variant="outlined">
                <OutlinedInput
                  sx={{ width: '100%', borderColor: '#151D2B', border: "none", backgroundColor: "#151D2B" }}
                  id="outlined-adornment-address outlined-adornment-address-wallet outlined-adornment-address-wallet-input"
                  // value={redeemCode}
                  onChange={(event) => {
                    if (ref.redeemCodeTimer) {
                      clearTimeout(ref.redeemCodeTimer)
                    }

                    ref.redeemCodeTimer = setTimeout(() => {
                      setRedeemCode(event.target.value);
                    }, 100)
                  }}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'redeemCode',
                  }}
                  placeholder={t("home_EnteryourGiftCode")}
                />
              </FormControl>
            </div>
            <div className='promotion-list1 px-20 giftBtnmt-48'>
              <div className='giftDiBtn height-42 borderRadius text-align btnPointer backGroundGreenBtn  marginJuZhong' onClick={() => {
                const clipPromise = navigator.clipboard.readText();
                console.log('clipPromise',clipPromise);
                clipPromise.then(clipText => {
                  setRedeemCode(clipText)
                })
              }}>{t("home_Paste")}</div>
              <div
                onClick={() => {
                  handleGetRedeemCode()
                }}
                className='giftDiBtn height-42 borderRadius text-align btnPointer  backGroundGreenBtn  marginJuZhong'
              >
                {t("home_confirms")}
              </div>
            </div>
          </Box>
        </div>
        {/* </DialogContent>
      </BootstrapDialog> */}
      </AnimateModal>

      <AnimateModal className="suggDi" closeClass="closeBtnSuff" open={openChangeStatus1.suggestions} onClose={() => onChangeStatus1('suggestions', false)}>
        <div>
          <Typography id="customized-dialog-title" className='textAlignRight'>
            <span style={{ display: "block", color: "#312100", textAlign: "left", marginLeft: "10px", marginTop: "4px" }} className="suffTitleMt titleTxt">{t("home_SuggRep_2")}</span>
          </Typography>
          <Box className="suffBox">
            <div className='flex mt-20 ml-10'>
              <FuseSvgIcon size={20} className="mr-6" color="action">heroicons-outline:clipboard</FuseSvgIcon>
              <div className='suffTitle1'>{t("home_SuggRep_3")}</div>
            </div>

            <div className='flex mt-10 ml-10'>
              <FuseSvgIcon size={20} className="mr-6" color="action">heroicons-outline:pencil-alt</FuseSvgIcon>
              <div className='suffTitle7'>{t("home_SuggRep_4")}</div>
            </div>

            <TextField
              id="standard-multiline-static"
              label=""
              multiline
              rows={10}
              placeholder={t('home_SuggRep_5')}
              variant="filled"
              className='suffBoxTextField'
              onChange={(e) => {
                if (ref.suggestTextTimer) {
                  clearTimeout(ref.suggestTextTimer)
                }

                ref.suggestTextTimer = setTimeout(() => {
                  setSuggestText(e.target.value)
                }, 100)
              }}
            />
            <div className='suffTitle2' style={{ color: "#00aab1" }}>{t('home_SuggRep_6')}</div>
            <div className='' style={{ width: "340px", height: "100px", marginLeft: "10px", marginTop: "10px", backgroundColor: "#053035", borderRadius: "5px" }}>
              <IconButton color="primary" aria-label="upload picture" component="label" style={{ padding: '0' }}>
                <input hidden accept="image/*" type="file" onChange={(e) => handleImageChange(e)} />
                {url == null ?
                  <img className='suffTitle3 btnPointer txtBrightness' src='assets/images/index/jiaHaoTu.png'></img>
                  : <img style={{ borderRadius: '10px', maxHeight: '100px', width: '220px', position: 'relative', left: '25%', padding: '2px' }} src={url}></img>
                }
              </IconButton>
            </div>
            <div className='suffTitle8'> <span className='sigin-txt-yellow'>500U</span> {t('home_SuggRep_7')}</div>

            <LoadingButton onClick={(e) => submit(e)} loading={getloading} className="whitespace-nowrap loadingBtnSty width-160  signBtnStyle  borderRadius text-20 funbetTxt  ml-96" variant="contained" color="secondary" >
              {t('home_SuggRep_8')}
            </LoadingButton>

          </Box>
        </div>
      </AnimateModal>


      <div className="flex flex-row items-center shrink-0 h-48 md:h-72 px-20">
        <div className="flex flex-1 mx-4 mt-4">
          <Logo />
        </div>

        <NavbarToggleButton className="w-40 h-40 p-0  mt-4" />
      </div>

      <StyledContent
        className="flex flex-1 flex-col min-h-0"
        option={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <div className='displayPhoneSwitch1'>
          <UserNavbarHeader />
        </div>
        <Navigation layout="vertical" />


        <div className='pl-16 mt-28 '>
          <div className='ml-10 mb-12' style={{ color: "#15A58B", fontWeight: "600", fontSize: "12px" }}>{t("home_SETTING")}</div>
          <AdjustFontSize />

          <div style={{ marginTop: "10px" }}>
            <NotificationPanelToggleButton />
          </div>

          <div className='flex borderRadius-5 btnPointer mt-10 giftStyle font-weight500 pl-10 width-250 height-42 align-item ' onClick={() => {
            if (user.token) {
              onChangeStatus('relief', true)
            } else {
              handleOnLogin();
            }

            if (isMobile) {
              dispatch(navbarToggleMobile());
            }

          }}>
            <FuseSvgIcon size={24} color="action">heroicons-outline:Gift-Code</FuseSvgIcon>
            <div className='ml-16 funbetTxt' >{t("home_GiftCode")}</div>
          </div>

          <div
              className='flex borderRadius-5 btnPointer mt-10 giftStyle font-weight500 pl-10 width-250 height-42 align-item '
              onClick={() => {
                if (!isOpenChat) {
                  // Crisp.chat.show();
                  Crisp.chat.open();
                } else {
                  // Crisp.chat.hide();
                  Crisp.chat.close();
                }
                // setIsOpenChat(!isOpenChat)

                // window.location.href = 'https://go.crisp.chat/chat/embed/?website_id=e30a94cf-704d-4331-ae34-093533e37fd6'
                setLiveSupportNum( liveSupportNum % 2 === 0 || liveSupportNum / 2 - 1);
              }}>
            <FuseSvgIcon size={24} color="action">heroicons-outline:Live-Support</FuseSvgIcon>
            <div className='ml-16 funbetTxt  flex items-center justify-between' style={{width: '100%'}}>
              {t("home_LiveSupport")}
              {liveSupportNum > 0 ? <div className="item-badge text-white rounded-full notice-num" style={{marginRight:'10px'}}>{liveSupportNum}</div> : null}
            </div>
          </div>

          <div className='flex borderRadius-5 btnPointer mt-10 giftStyle font-weight500 pl-10 width-250 height-42 align-item ' onClick={() => {
            if (user.token) {
              onChangeStatus1('suggestions', true)
            } else {
              handleOnLogin();
            }

            if (isMobile) {
              dispatch(navbarToggleMobile());
            }

          }}>
            <FuseSvgIcon size={24} style={{ color: "#B9BBC0" }}>heroicons-outline:clipboard-list</FuseSvgIcon>
            <div className='ml-16 funbetTxt'>{t("home_SuggRep_1")}</div>
          </div>

          <div style={{ marginLeft: "4px", marginTop: "10px" }}>
            <Button className="h-40 funbetTxt" onClick={langMenuClick}>
              <img
                className="min-w-20"
                src={`assets/images/flags/${currentLanguage?.flag}.jpg`}
                alt={currentLanguage?.title}
              />
              <Typography className="pl-16 font-semibold uppercase " color="text.secondary">
                {currentLanguage?.id}
              </Typography>
            </Button>
            <Popover
              open={Boolean(menu)}
              anchorEl={menu}
              onClose={langMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              classes={{
                paper: '',
              }}
              style={{ top: "4px", height: "500px" }}
            >
              {languages.map((lng) => (
                <MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
                  <ListItemIcon className="min-w-40">
                    <img
                      className="min-w-20"
                      src={`assets/images/flags/${lng?.flag}.jpg`}
                      alt={lng?.title}
                    />
                  </ListItemIcon>
                  <ListItemText primary={lng?.title} />
                </MenuItem>
              ))}

              {/*<MenuItem*/}
              {/*  component={Link}*/}
              {/*  to="/documentation/configuration/multi-language"*/}
              {/*  onClick={langMenuClose}*/}
              {/*  role="button"*/}
              {/*>*/}
              {/*</MenuItem>*/}
            </Popover>
          </div>
        </div>

        <AwardPop
          open={popAward}
          onClose={() => {
            setPopAward(false);
          }}
          symbol={spinRewardBalance?.symbol}
          symbolImg={`assets/images/symbol/${spinRewardBalance?.symbol}.png`}
          balance={spinRewardBalance?.balance}
        />

        <div className="flex flex-0 items-center justify-center py-48 opacity-10">
          {/* <img className="w-full max-w-64" src="assets/images/logo/logo.svg" alt="footer logo" /> */}
        </div>
      </StyledContent>
    </Root>
  );
}

export default memo(NavbarStyle1Content);
