import "../../../styles/game.css";
import * as React from 'react';
import utils from '../../util/tools/utils'
import Common_foot from '../common/Common_foot';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { getGrowthFundConfig, buyGrowthFund, getGrowthFundReward } from "../../store/activity/activityThunk";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../../store/user";
import { getUserSetting, onLogin } from "../../store/user/userThunk";
import { showMessage } from "app/store/fuse/messageSlice";
import AwardPop from "../../components/AwardPop";
import { selectUserSetting } from "app/store/user/userSetting";
import { selectUserInfo } from "app/store/user/userInfo";
import { selectUserProperties } from "app/store/user/userProperties";
import clsx from "clsx";
import { isMobile } from "../../util/tools/function";

function Fund(props) {
    const { t } = useTranslation('mainPage');
    const dispatch = useDispatch();
    const userData = useSelector(selectUserData);
    const userInfo = useSelector(selectUserInfo);
    const userSetting = useSelector(selectUserSetting);
    const userProperties = useSelector(selectUserProperties);
    const [open, setOpen] = useState(false);
    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };
    const item2 = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    const [fundConfig, setFundConfig] = useState([]);
    const [fundPrice, setFundPrice] = useState(0);
    const [popAward, setPopAward] = useState(false);
    const [spinRewardBalance, setSpinRewardBalance] = useState({
        balance: 0,
        symbol: "USDT",
    });

    const scrollToTop = () => {
        document.documentElement.scrollTo({ top: 0 });
    };

    useEffect(() => {
        scrollToTop()
    }, [userData.token]);


    // 购买成长基金
    const handleBuyFund = () => {
        if (!userData.token) {
            dispatch(onLogin({
                type: 'login'
            }));
            return
        }

        // 是否绑定手机
        if (userInfo.userInfo.nation === "0") {
            dispatch(showMessage({ message: t('home_bindPhone'), code: 3 }));
            return
        }

        // 判断是否有足够的余额


        // 购买成长基金
        dispatch(buyGrowthFund()).then((res) => {
            let result = res.payload;
            if (result) {
                dispatch(getUserSetting({
                    callback: () => { setOpen(false) }
                }));
            }
        })
    };

    // 判断是否可领取
    const isCanGetReward = (vipLevel) => {
        // 判断是否购买过成长基金
        if (!userProperties?.properties?.HasBuyVIPGrowthFund) {
            return 0
        }

        // 当前等级 <= 购买等级 不可领取
        if (vipLevel <= userProperties?.properties?.BuyVIPGrowthFundLevel) {
            return 0
        }

        // 用户等级 < 当前等级 不可领取
        if (userProperties?.properties?.MemberOrder < vipLevel) {
            return 0
        }

        // 判断当前等级奖励是否已领取
        if ((userProperties?.properties?.VIPGrowthFundStatus & (2 ** (vipLevel - 1))) != 0) {
            return 2
        }

        return 1
    }

    // 领取成长基金奖励
    const handleGetReward = (vipLevel) => {
        dispatch(getGrowthFundReward({
            vipLevel: vipLevel
        })).then((res) => {
            let result = res.payload;
            if (parseInt(result?.RewardCashCoupon) > 0) {
                setSpinRewardBalance({
                    balance: result?.RewardCashCoupon / 100,
                    symbol: "USDT",
                });
                setPopAward(true);

                setTimeout(() => {
                    dispatch(getUserSetting({
                        callback: () => { }
                    }));
                }, [1000]);
            }
        })
    }

    useEffect(() => {
        dispatch(getGrowthFundConfig()).then((res) => {
            let result = res.payload;
            if (result) {
                setFundConfig(result.config);
                setFundPrice(result.price / 100);
            }
        });
    }, []);

    return (
        <div className="flex w-full container">
            <div className="flex flex-col sm:flex-row betDiColor funbetTxt flex-auto sm:items-center min-w-0 p-10 md:p-32 pb-0 md:pb-0 huoDongDing">
                <div className="flex flex-col flex-auto align-item">
                    <div className="flex grow shrink-0 flex-col items-center container ">
                        <motion.div
                            variants={container}
                            initial="hidden"
                            animate="show"
                            className="activitybanner mt-10"
                        >
                            <motion.div variants={item} className="min-w-full">
                                <div className="zuQiu fundBgDi ">
                                    <div className=" text-48 displayPhoneSwitch1 titleTxt"  >{t("home_FundBonus")}</div>
                                    <div className="FundWidth-480 FundText-28 ">{t("home_Improveyour")}</div>
                                    {!userProperties?.properties?.HasBuyVIPGrowthFund && <div onClick={() => { setOpen(true) }} className='borderRadius text-align btnPointer  txtColorHe  fundBuyBtn btnYellowColor txtBrightness' >{t("tongzhi_14")}</div>}
                                </div>
                            </motion.div>

                            <div className={clsx("zuQiu  fundTitle0  backGround-foot", !isMobile() ? 'pb-24' : 'pb-5')}>
                                <motion.div variants={item} className="min-w-full">
                                    <div className="promotion-list1 text-16 colorGameListTitle  fundTitle1  borderRadius-10 ">
                                        <div className="font-weight500 fundTitle2" >{t("home_FundBonus")}</div>
                                        <div className="font-weight500  fundTitle3" >{t("home_Receivingconditions")}</div>
                                        <div className="font-weight500  fundTitle4 " >{t("home_Receivingstatus")}</div>
                                    </div>
                                </motion.div>

                                <motion.div
                                    variants={container}
                                    initial="hidden"
                                    animate="show"
                                >
                                    {fundConfig.length > 0 && fundConfig.map((item) => {
                                        return (
                                            <motion.div variants={item2} className="flex betDiColor font-weight500 borderRadius-5 fundLineBtn">
                                                <div className="flex Fundwidth-100Icon">
                                                    <div className="Fundwidth-100Icon">
                                                        <img className="Fundwh42 marginJuZhong" src="assets/images/index/jinBiSmall.png" ></img>
                                                        <div className="fontBold fundTxtIcon marginJuZhong">{item.cashCouponNum / 100} EUSDT</div>
                                                    </div>
                                                </div>
                                                <div className="fontBold Fundwidth-120Icon">VIP{item.vipLevel}</div>
                                                {isCanGetReward(item.vipLevel) === 1 && <div onClick={() => { handleGetReward(item.vipLevel) }} className=' borderRadius text-align  btnPointer  backGroundGreenBtn fundBtnRev1'>{t("home_receive")}</div>}
                                                {isCanGetReward(item.vipLevel) === 0 && <div className='borderRadius text-align  btnPointer txtColorHui fundBtnRev'>{t("home_noReceived")}</div>}
                                                {isCanGetReward(item.vipLevel) === 2 && <div className='borderRadius text-align  btnPointer txtColorHui fundBtnRev'>{t("home_Received")}</div>}
                                            </motion.div>
                                        )
                                    })}
                                </motion.div>
                            </div>
                        </motion.div>
                    </div>
                    <Common_foot />
                    <div className="mt-32  promotion-list colorGameListTitle mb-20 displayPhoneSwitch1">
                        <div>FuniBet 2022- ALL Rights Reserved</div>
                    </div>
                </div>
            </div>

            <AwardPop
                open={popAward}
                onClose={() => {
                    setPopAward(false);
                    var oTurntable = document.getElementById("spin");
                    oTurntable.style.transition = "all 0s";
                    oTurntable.style.transform = "rotate(0deg)";
                }}
                symbol={spinRewardBalance.symbol}
                symbolImg={`assets/images/symbol/${spinRewardBalance.symbol}.png`}
                balance={spinRewardBalance.balance}
            />



            {open && (
                <div style={{ position: "fixed", bottom: "40%", margin: "0px auto", left: "0%", right: "0%", zIndex: "999" }} >
                    <motion.div
                        variants={container}
                        initial="hidden"
                        animate="show"
                    >
                        <motion.div variants={item} className="gameTipsSty px-20 py-20 mx-auto" >
                            <div className="cookiesTxt1">
                                <div className="" style={{ fontSize: "20px", marginBottom: "4px", width: "330px", wordWrap: 'break-word' }}>{t("home_BuyFund1")}</div>
                            </div>
                            <div className="flex justifyContent mt-16">
                                <Button className="whitespace-nowrap borderRadius px-24 gameTipsBtn  funbetTxt " variant="contained" color="secondary" style={{ fontSize: "16px", minWidth: "100px" }}
                                    onClick={() => {
                                        handleBuyFund()
                                    }}
                                >
                                    {t("home_confirms")}
                                </Button>

                                <Button className="whitespace-nowrap borderRadius px-24 gameTipsBtn  funbetTxt ml-24" variant="contained" color="secondary" style={{ fontSize: "16px", minWidth: "100px" }}
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    {t("home_gameout_2")}
                                </Button>

                            </div>
                        </motion.div>
                    </motion.div>
                </div>
            )}


        </div>
    );
}
export default Fund;
