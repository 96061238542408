import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import reducer from './store';
import { selectNotifications } from './store/dataSlice';
import { toggleNotificationPanel } from './store/stateSlice';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import {getNewsList} from "../../../store/config/configThunk";
import { useState, useEffect } from 'react';
import { selectConfigData } from "app/store/config";
import {getFeedBack, getTransRecord} from "app/store/user/userThunk";
import { concat } from 'lodash';
import { changeLanguage } from "../../../util/tools/function";
import countryLang from "../../../json/country";


function NotificationPanelToggleButton(props) {
  const { t } = useTranslation('mainPage');
  const notifications = useSelector(selectNotifications);
  const config = useSelector(selectConfigData);
  const [feedBackList, setFeedBackList] = useState([]);
  const [transRecordList, setTransRecordList] = useState([]);
  const [notice, setNotice] = useState([]);
  const [ noticeNum, setNoticeNum ] = useState(0);
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeedBack({
      IsProcessed: 1
    })).then((resed) => {
      if (resed.payload) {
        setFeedBackList(resed.payload);
      }
    })

    dispatch(getTransRecord()).then((res) => {
      if (res.payload) {
        let sendTipsRecord = res.payload.sendTips;
        let cryptoDepositRecord = res.payload.deposite.crypto;
        let fiatDepositRecord = res.payload.deposite.fiat;
        let cryptoWithdrewRecord = res.payload.withdrew.crypto;
        let fiatWithdrewRecord = res.payload.withdrew.fiat;

        setTransRecordList([
          ...sendTipsRecord,
          ...cryptoDepositRecord,
          ...fiatDepositRecord,
          ...cryptoWithdrewRecord,
          ...fiatWithdrewRecord
        ]);
      }
    })

    dispatch(getNewsList({
      classID: 2,
      lang: changeLanguage(countryLang, window.localStorage.getItem('lang')),
      brush: true
    })).then((res) => {
      let result = res.payload
      if (result) {
        setNotice(result);
      }
    })
  }, [dispatch]);

  useEffect(() => {
    let allNotice = concat(notice, transRecordList, feedBackList)
    setNoticeNum(allNotice.length);
  }, [notice, transRecordList, feedBackList])

  const clicknoticeNum = (ev) => {
    if (noticeNum > 0) {
      dispatch(toggleNotificationPanel());
      setNoticeNum( noticeNum % 2 === 0 || noticeNum / 2 - 1);
    }
  }
  return (
    <Button
      className="width-250 h-40 giftStyle borderRadius-5 flex"
      onClick={clicknoticeNum}
      size="large"
    >
      <Badge color="secondary" variant="dot" invisible={notifications.length === 0}>
        {props.children}
      </Badge>
      {
        <div className='tongZhiBtn2 ml-12 flex items-center justify-between funbetTxt' style={{width: '100%'}}>
          {t("home_notice")}
          {noticeNum > 0 ? <div className="item-badge text-white rounded-full notice-num">{noticeNum}</div> : null}
      </div>
      }
      
    </Button>
  );
}

NotificationPanelToggleButton.defaultProps = {
  children: <FuseSvgIcon style={{}}>heroicons-outline:bell</FuseSvgIcon>,
};

export default withReducer('notificationPanel', reducer)(NotificationPanelToggleButton);
