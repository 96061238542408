import "../../../styles/game.css";
import * as React from 'react';
import utils from '../../util/tools/utils'
import Common_foot from '../common/Common_foot';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import { getVIPConfig } from "../../store/activity/activityThunk";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { selectUserData } from "../../store/user";
import {
    openWallet,
    userProfile,
    onLogin,
    countUserAllAssets,
} from "../../store/user/userThunk";
import { userInfo } from "os-browserify";
import { isMobile } from "../../util/tools/function";
import clsx from "clsx";
import { selectUserSetting } from "app/store/user/userSetting";
import { selectUserAvatars } from "app/store/user/userAvatars";
import { selectUserProperties } from "app/store/user/userProperties";
import { LazyLoadImage } from "react-lazy-load-image-component";


function Vip(props) {
    const { t } = useTranslation('mainPage');
    const dispatch = useDispatch();

    const user = useSelector(selectUserData);
    const userAvatars = useSelector(selectUserAvatars);
    const userSetting = useSelector(selectUserSetting);
    const userProperties = useSelector(selectUserProperties);

    const stepSize = isMobile() ? 1 : 3;
    const [currClickNum, setcurrClickNum] = useState(0);

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };

    const [chargeTotal, setChargeTotal] = useState(0);
    const [userFaceID, setUserFaceID] = useState(userSetting?.user?.faceID || 0);
    const [betTotal, setBetTotal] = useState(0);
    const [vipConfig, setVipConfig] = useState({});
    const [vipLevel, setVipLevel] = useState(0);
    const [vipExperience, setVipExperience] = useState(0.0);
    const [vipActive, setVipActive] = useState(0);

    const [useOnLine, setUseOnLine] = useState(false);

    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    const scrollToTop = () => {
        document.documentElement.scrollTo({ top: 0 });
    };

    const handleOnLogin = () => {
        dispatch(onLogin({ type: "login" }));
    };

    const onWallet = (type = "wallet") => {
        dispatch(
            openWallet({
                type,
            })
        );
    };

    useEffect(() => {
        if (user.token) {
            setUseOnLine(true);
        } else {
            setUseOnLine(false);
        }
        scrollToTop()
    }, [user.token])


    const backVipCommission = () => {
        let level = 0;
        let plus = 0;
        if (vipActive === 0) {
            level = vipLevel;
        } else if (vipActive === 1) {
            level = vipLevel + 1;
        } else if (vipActive === 2) {
            level = vipLevel + 2;
        }

        if (level > 0) {
            plus = vipConfig[level]?.TurntablePlus || 0;
        }
        return {
            level: level,
            plus: plus,
        };
    };


    const handleChangeExperience = () => {
        if (chargeTotal > 0) {
            let experience = 0;
            if (betTotal == 0) {
                setVipExperience(experience);
                return;
            }
            if (parseInt(chargeTotal) > parseInt(betTotal)) {
                experience = betTotal;
            } else if (parseInt(chargeTotal) <= parseInt(betTotal)) {
                experience = chargeTotal;
            }
            setVipExperience(experience);
        }
    };


    useEffect(() => {
        // vip等级
        if (userProperties?.properties?.MemberOrder) {
            setVipLevel(userProperties.properties.MemberOrder);
        }

        // 充值金额
        if (userProperties?.properties?.ChargeTotal) {
            setChargeTotal(userProperties.properties.ChargeTotal);
        }

        // 下注金额
        if (userProperties?.properties?.BetTotal) {
            setBetTotal(userProperties.properties.BetTotal);
        }

    }, [userProperties]);

    useEffect(() => {
        handleChangeExperience();
    }, [chargeTotal, betTotal]);

    useEffect(() => {
        dispatch(getVIPConfig()).then((res) => {
            let result = res.payload;
            if (result) {
                setVipConfig(result)
                console.log('res', result)
            }
        });
    }, [dispatch]);

    return (
        <div className="flex w-full container ">
            <div className="betDiColor funbetTxt flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 p-10 md:p-32 pb-0 md:pb-0 huoDongDing">
                <div className="flex flex-col flex-auto align-item">
                    <div className="flex grow shrink-0 flex-col items-center container mt-10">
                        <motion.div
                            variants={container}
                            initial="hidden"
                            animate="show"
                            className="activitybanner"
                        >
                            <motion.div variants={item} className="min-w-full ">
                                <div className="zuQiu vipBgDi ">
                                    <div className="titleTxt  sigin-txt-yellow vipZi">VIP</div>
                                    <div className="titleTxt vipZi2">{t("home_ExclusiveBenefits")}</div>
                                    <div className="text-16 ml-400 font-weight500 width-580 displayPhoneSwitch1">{t("home_Asawayof")} </div>
                                </div>
                            </motion.div>

                            <motion.div variants={item} className="min-w-full ">
                                <div className="zuQiu promotion-list1 " style={{ marginTop: "-8px" }}>
                                    <div className="borderRadius-5 width-350 backGround-foot height-130 py-20 px-20 flex displayPhoneSwitch2">
                                        <div>
                                            {
                                                useOnLine ? <LazyLoadImage className="" style={{ borderRadius: "999px", width: "88px", height: "88px" }} src={userAvatars.allAvatars[userFaceID].url}></LazyLoadImage> : <LazyLoadImage className="" style={{ borderRadius: "999px", width: "88px", height: "88px" }} src='assets/images/head/47.png'></LazyLoadImage>
                                            }
                                        </div>
                                        <div className="mt-12 ml-20">
                                            <div className="text-20">
                                                {useOnLine ? userSetting?.user?.nickName : 'user'}
                                            </div>
                                            <div className="text-20 sigin-txt-yellow mt-12">
                                                {
                                                    useOnLine ? `VIP${vipLevel}` : 'VIP0'
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="borderRadius-5 backGround-foot vipZi3 promotion-list1">
                                        <div className="">
                                            {
                                                useOnLine ? <LazyLoadImage className="vipwidth-80" src={`${React.$imgUrl}/images/index/vip${vipLevel}.png`}></LazyLoadImage> : <LazyLoadImage className="vipwidth-80" src={`${React.$imgUrl}/images/index/vip${0}.png`}></LazyLoadImage>
                                            }
                                            <div className="viptext-20 vipwidth-80 text-align">
                                                {
                                                    useOnLine ? `VIP${vipLevel}` : 'VIP0'
                                                }
                                            </div>

                                        </div>

                                        <div className="vipJdWidth" style={{ position: "relative" }}>
                                            <div className="positionAb vipJdTiao" style={{ background: "#111827", borderRadius: "16px", width: "100%" }}></div>
                                            <div
                                                className="positionAb vipJdTiao1"
                                                style={{
                                                    background: "#FFC600",
                                                    borderRadius: "16px",
                                                    width: vipConfig[backVipCommission().level]?.Condition
                                                        ? `${(
                                                            (vipExperience /
                                                                vipConfig[backVipCommission().level]
                                                                    ?.Condition) *
                                                            100
                                                        ).toFixed(2)}%`
                                                        : 0,
                                                    height: "20px",
                                                }}
                                            >
                                            </div>
                                            <div className="text-align vipJdWidth  text-16 positionAb vipJdTiao2 " style={{ color: "#ffffff" }}>
                                                {useOnLine ? vipExperience / 100 : 0}/{useOnLine ? vipConfig[backVipCommission().level]?.Condition / 100 : 1}
                                            </div>
                                            <div className="vipZi5  text-align colorGameListTitle">{t("home_Yourcurrent")}
                                                <span className="txtColor ">
                                                    {
                                                        useOnLine ? `${vipExperience / 100}` : '$0'
                                                    }
                                                </span> </div>
                                        </div>

                                        <div className="">
                                            {
                                                useOnLine ? <LazyLoadImage className="vipwidth-80" src={`${React.$imgUrl}/images/index/vip${vipLevel + 1}.png`}></LazyLoadImage> : <LazyLoadImage className="vipwidth-80" src={`${React.$imgUrl}/images/index/vip${1}.png`}></LazyLoadImage>
                                            }
                                            <div className="viptext-20 vipwidth-80 text-align">
                                                {
                                                    useOnLine ? `VIP${vipLevel + 1}` : 'VIP1'
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>

                            <motion.div variants={item} className="min-w-full ">
                                <div className="zuQiu mt-40 backGround-foot px-24 py-24 displayPhoneSwitch1 ">
                                    <div className="text-24">{t("home_WhyUpgrade")}</div>
                                    <div className="colorGameListTitle text-16 mt-16">{t("home_VIPhave")}
                                    </div>
                                    <div className="text-24 mt-20">{t("home_UpgradeYour")}</div>
                                    <div className="colorGameListTitle text-16 mt-16">{t("home_ASaFunibet")}
                                    </div>
                                </div>
                            </motion.div>

                            <motion.div variants={item} className="min-w-full ">
                                <div className="vipZi6 text-align fontBold">{t("home_YOUCANREACH")} <span className="sigin-txt-yellow">10 {t("home_LEVELS")}</span></div>
                                <div className="vipZi7  font-weight500 colorGameListTitle">{t("home_eachthehigh")}</div>
                            </motion.div>

                            <div className="zuQiu mt-36" style={{ height: "446px", maxWidth: "1196px", overflow: "hidden" }}>

                                <div
                                    className="promotion-list3 "
                                    style={{
                                        marginLeft: isMobile() ? (-100 * currClickNum) + '%' : (-409 * currClickNum) + 'px',
                                        transition: 'all .4s',
                                    }}>
                                    {
                                        vipConfig.length > 0 &&
                                        vipConfig.map((item) => {
                                            return (
                                                <motion.div variants={item} className="phone100Bai " >
                                                    <div className="vipLvDiImg  text-align">
                                                        <LazyLoadImage className="ml-124 " style={{ margin: "0 auto", position: "relative", top: "-10px" }} src={`${React.$imgUrl}/images/index/vip${item.VIPLevel}.png`}></LazyLoadImage>
                                                        <div className="text-24 mt-20">VIP{item.VIPLevel}</div>
                                                        {/* <div className="text-20 mt-4 txtGreenColor">{t("home_Cashback")}</div>
                                                        <div className="text-16 sigin-txt-yellow">1%</div> */}
                                                        <div className="text-18 text-alignLeft mt-12">
                                                            <div >{t("home_vip_1")} {item.SignPlus}%</div>
                                                            <div >{t("home_vip_2")} {item.TurntablePlus}%</div>
                                                            <div >{t("home_vip_3")} {item.SharePlus}%</div>
                                                            <div >{t("home_vip_4")} {item.FinancialPlus}%</div>
                                                            <div >{t("home_vip_5")} {item.ChatEntry} {t("home_vip_6")}</div>
                                                        </div>
                                                    </div>
                                                </motion.div>
                                            )
                                        })
                                    }
                                </div>

                            </div>

                            <motion.div variants={item} className="min-w-full ">
                                <div className="mt-20">
                                    <div className="zuQiu promotion-list1 marginJuZhong width-80 ">
                                        <LazyLoadImage
                                            className={
                                                clsx("btnPointer txtBrightness ", (currClickNum === 0) && 'imgHui')
                                            }
                                            src="assets/images/index/vipFanYeBtn1.png" onClick={() => {
                                                let tmpClickNum = currClickNum - 1;
                                                if (tmpClickNum >= 0) {
                                                    setcurrClickNum(tmpClickNum)
                                                }
                                            }}></LazyLoadImage>
                                        <LazyLoadImage className={
                                            clsx("btnPointer txtBrightness", ((vipConfig.length - stepSize) === currClickNum) && 'imgHui')
                                        } src="assets/images/index/vipFanYeBtn2.png" onClick={() => {
                                            let tmpClickNum = currClickNum + 1;

                                            if (tmpClickNum <= (vipConfig.length - stepSize)) {
                                                setcurrClickNum(tmpClickNum)
                                            }
                                        }}></LazyLoadImage>
                                    </div>
                                </div>
                            </motion.div>

                            <motion.div variants={item} className="min-w-full ">
                                <div className="mt-20">
                                    <div className="vipLvDiImg2 text-align">
                                        <div className="fontBold  vipZi8">{t("home_ABOUTVIPCLUB")}</div>
                                        <div className="vipZi9  text-alignLeft  txtColorHui">{t("home_HightopRank")}</div>

                                        <div className="vipZi10 text-alignLeft" >{t("home_Beingamember")}</div>

                                        <div className="vipZi11 text-alignLeft flex">
                                            <LazyLoadImage className="height-24" src="assets/images/index/vipDi3.png"></LazyLoadImage>
                                            <div className="vipZi12">{t("home_PersonalVIPmanager")}</div>
                                        </div>

                                        <div className="vipZi11 text-alignLeft flex">
                                            <LazyLoadImage className="height-24" src="assets/images/index/vipDi3.png"></LazyLoadImage>
                                            <div className="vipZi12">{t("home_Highercahbackrewards")}</div>
                                        </div>

                                        <div className="vipZi11 text-alignLeft flex">
                                            <LazyLoadImage className="height-24" src="assets/images/index/vipDi3.png"></LazyLoadImage>
                                            <div className="vipZi12">{t("home_WeeklyandMonthlyBonuses")}</div>
                                        </div>

                                        <div className="vipZi11 text-alignLeft flex">
                                            <LazyLoadImage className="height-24" src="assets/images/index/vipDi3.png"></LazyLoadImage>
                                            <div className="vipZi12">{t("home_Otheradditionalperks")}</div>
                                        </div>

                                        <div className="vipZi11 text-alignLeft flex displayPhoneSwitch2">
                                            <LazyLoadImage className="height-24" src="assets/images/index/vipDi3.png"></LazyLoadImage>
                                            <div className="vipZi12">{t("home_Insights")}</div>
                                        </div>

                                        <div className="text-alignLeft text-24 mt-8 sigin-txt-yellow displayPhoneSwitch1" style={{ width: "74%", marginLeft: "240px" }}>{t("home_Showhighgaming")}</div>
                                        <div className='vipZi13  borderRadius text-align btnPointer  backGroundGreenBtn' onClick={() => {
                                            if (user.token) {
                                                onWallet("buy");
                                            } else {
                                                handleOnLogin();
                                            }
                                        }}>{t("home_JOIN")}</div>
                                    </div>
                                </div>
                            </motion.div>

                        </motion.div>
                    </div>
                    <Common_foot />
                    <div className="mt-32  promotion-list colorGameListTitle mb-20 displayPhoneSwitch1">
                        <div>FuniBet 2022- ALL Rights Reserved</div>
                    </div>
                </div>
            </div>
        </div >
    );
}
export default Vip;
