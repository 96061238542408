// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import "./i18n";
import "./styles/app-base.css";
import "./styles/app-components.css";
import "./styles/app-utilities.css";
import { createRoot } from "react-dom/client";
import App from "./app/App";
import Loading from "./app/loading/Loading";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import eventListener from "./eventListener";
import React,{useState} from "react";
import api from "./app/api";
import utils from "./app/util/tools/utils";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Loading />);

const timer = setInterval(() => {
  console.log(window.localStorage.getItem('renderApp'))
  if (window.localStorage.getItem('renderApp') == 1) {
    clearInterval(timer);

    // 加载字体css
    utils.loadCss("https://scource-static.funibet.com/funibet/css/font.css");

    root.render(<App />);
  }
}, 300);

React.$api = api;

React.$imgUrl = "https://scource-static.funibet.com/funibet";

/* 测试*/
FuniBox.Tools.default.setOpenAppId('6436951541b60d250c692481');
FuniBox.Tools.default.setIndex('0');

/* 正式*/
// FuniBox.Tools.default.setOpenAppId('64915ebf60b24e97a4584544');
// FuniBox.Tools.default.setIndex('0');

reportWebVitals();
// eventListener();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
