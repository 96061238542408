import "../../../styles/index.css";
import "../../../styles/xianStyle.css";
import utils from '../../util/tools/utils'
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import { useState, useEffect, useRef, forwardRef, default as React } from 'react';
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import history from '@history';
import { useDispatch, useSelector } from 'react-redux';
import {
    queryBaseEnsureConfig,
    takeBaseEnsure
} from "../../store/activity/activityThunk";
import { selectUserData } from "../../store/user";
import AwardPop from '../../components/AwardPop';
import AnimateModal from '../../components/FuniModal';
import { selectUserSetting } from "app/store/user/userSetting";
import { selectUserProperties } from "app/store/user/userProperties";
import {selectUserInfo, updateUser} from "app/store/user/userInfo";
import { getAwardOfBindPhone } from "../../store/activity/activityThunk";
import { getUserSetting, openWallet, onLogin } from "../../store/user/userThunk";

function PhoneBind(props) {
    const open = props.open;
    const onClose = props.onClose;

    const { t } = useTranslation('mainPage');
    const dispatch = useDispatch();
    const [baseEnsureConfig, setBaseEnsureConfig] = useState({});
    const userData = useSelector(selectUserData);
    const userSetting = useSelector(selectUserSetting);
    const userProperties = useSelector(selectUserProperties);
    const [canTakeTimes, setCanTakeTimes] = useState(0);
    const [userAssets, setUserAssets] = useState(0);
    const [receiveStatus, setReceiveStatus] = useState(0);
    const [countDown, setCountDown] = useState(0);
    const ref = useRef(0);
    const [bindPhoneScore, setBindPhoneScore] = useState(0);
    const userInfo = useSelector(selectUserInfo).userInfo;
    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };


    useEffect(() => {
        if (userProperties?.properties?.BindGrantScore) {
          setBindPhoneScore(userProperties.properties.BindGrantScore / 100);
        }
      }, [userProperties]);

    const handleGetAwardOfBindPhone = () => {
        dispatch(getAwardOfBindPhone()).then((res) => {
          let result = res.payload;
          if (result) {
            onClose()
            setPopAward(true);
            setSpinRewardBalance({
              balance: result.cashCoupon / 100,
              symbol: "eUSDT",
            });
            dispatch(getUserSetting({
                callback: () => {}
            }));
          }
          //   dispatch(showMessage({ message: result.cashCoupon / 100 }));
        });
      };


    return (
        <>
            <AnimateModal className="scMoneyDi2" closeClass="closeBtnRelief" open={open} onClose={onClose}>
                <motion.div
                    variants={container}
                    initial="hidden"
                    animate="show"
                    className="imgHidden funbetTxt"
                    style={{ height: "100%", width: "100%", maxWidth: "360px" }}
                >
                    <div
                        className="positionAb faGuang turnAni2"
                        style={{ top: "0px", bottom: "0px", left: "-16px", right: "0px" }}
                    ></div>
                    <motion.div
                        variants={item}
                        className="positionAb titleCzDeposit titleCzDepositBg2 titleTxt"
                        style={{
                            top: "0px",
                            bottom: "0px",
                            left: "0px",
                            right: "0px",
                            margin: "0 auto",
                        }}
                    >
                        {t("home_BindMobile")}
                    </motion.div>
                    <div className="positionAb bigXcBindImg activityMoveLeftBind"
                        style={{ top: "51px", right: "0px", bottom: "0px" }}
                    ></div>
                    <motion.div
                        variants={item}
                        className="positionAb bigXcDepositTitle2 titleTxt2"
                        style={{
                            width: "90%",
                            overflow: "hidden",
                            textAlign: "center",
                            margin: "0 auto",
                            top: "292px",
                            left: "0px",
                            right: "0px",
                        }}
                    >
                        {t("home_BINDMOBILENUMBER")}
                    </motion.div>
                    <motion.div
                        variants={item}
                        className="positionAb bigXcDepositTitle2 titleTxt2"
                        style={{
                            width: "90%",
                            overflow: "hidden",
                            textAlign: "center",
                            margin: "0 auto",
                            top: "322px",
                            left: "0px",
                            right: "0px",
                        }}
                    >
                        {t("home_GET")}
                        <span className="bigXcDepositTitle3"> ${bindPhoneScore} </span>
                        <span className="bigXcDepositTitle2">eUSDT</span>
                    </motion.div>
                    <motion.div
                        variants={item}
                        className="positionAb "
                        style={{
                            top: "360px",
                            bottom: "0px",
                            left: "0px",
                            right: "0px",
                            margin: "0 auto",
                        }}
                    >
                        <div className="containerSpinBtn align-item flex justifyContent">
                            <div className="btn titleTxt">
                                {userInfo.nation === "0" ? (
                                    <a
                                        onClick={() => {
                                            onClose()
                                            dispatch(
                                                openWallet({
                                                    type: "kyc",
                                                    data: {},
                                                    callback: () => {
                                                        FuniBox.Tools.default.getUserInfo().then((res) => {
                                                            if (res) {
                                                                dispatch(updateUser(res))
                                                            }
                                                        })
                                                    }
                                                })
                                            )
                                        }}
                                        style={{ fontSize: "20px", color: "#ffffff" }}
                                    >
                                        {t("home_Bind")}
                                    </a>
                                ) : (
                                    <a
                                        onClick={handleGetAwardOfBindPhone}
                                        style={{ fontSize: "20px", color: "#ffffff" }}
                                    >
                                        {t("home_receive")}
                                    </a>
                                )}
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            </AnimateModal>
        </>
    );
}
export default PhoneBind;


