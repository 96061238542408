import "../../../styles/index.css";
import "../../../styles/xianStyle.css";
import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import { useState, useEffect, forwardRef, default as React } from 'react';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import {
    queryDailyShareInfo,
    updateDailyShareInfo,
    getDailyShareReward
} from "../../store/activity/activityThunk";
import AwardPop from '../../components/AwardPop';
import { motion } from 'framer-motion';
import { isMobile, judgeLangFontSize } from "../../util/tools/function";

function Share(props) {
    const { t } = useTranslation('mainPage');
    const dispatch = useDispatch();
    const [shareData, setShareData] = useState({});
    const [popSignAward, setPopSignAward] = useState(false);
    const [isHideBody, setIsHideBody] = useState(true);
    const [signInRewardBalance, setSignInRewardBalance] = useState({
        balance: 0,
        symbol: 'eUSDT'
    });



    // 获取分享情况
    const handleQueryDailyShareInfo = () => {
        dispatch(queryDailyShareInfo()).then((res) => {
            let result = res.payload;
            setShareData(result);
        })
    };

    const handleShareClick = (type) => {
        let status = 0;
        let shareType = 0;
        if (type === 'facebook') {
            shareType = 2;
            status = shareData?.ShareFaceBookTdy;
        } else if (type === 'line') {
            shareType = 4;
            status = shareData?.ShareLineTdy;
        } else if (type === 'whatsapp') {
            shareType = 1;
            status = shareData?.ShareWhatsAppTdy;
        } else if (type === 'twitter') {
            shareType = 3;
            status = shareData?.ShareTwiterTdy;
        } else {
            return
        }

        if (status === 1) {
            handleGetShareReward(shareType)
        } else {
            handleShare(shareType)
        }
    };

    // 分享
    const handleShare = (shareType) => {
        let shareUrl = 'https://scource-static.funibet.com/share';
        let shareText = 'FuniBet';
        if (shareType === 2) {
            window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(shareUrl) + '&t=' + encodeURIComponent(shareText));
        } else if (shareType === 4) {
            window.open(`https://lineit.line.me/share/ui?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`);
        } else if (shareType === 1) {
            window.open(`https://wa.me?text=${encodeURIComponent(shareText) + encodeURIComponent("\n\n" + shareUrl)}`);
        } else if (shareType === 3) {
            window.open(`https://twitter.com/share?text=${shareText}&url=${encodeURIComponent(shareUrl)}`);
        } else {
            return
        }

        dispatch(updateDailyShareInfo({
            cbMode: shareType
        })).then((res) => {
            let result = res.payload;
            if (result) {
                handleQueryDailyShareInfo();
            }
        })
    };

    // 领取分享奖励
    const handleGetShareReward = (type) => {
        dispatch(getDailyShareReward({
            cbMode: type
        })).then((res) => {
            let result = res.payload;
            if (result) {
                setSignInRewardBalance({
                    balance: result.cashCoupon / 100,
                    symbol: 'eUSDT'
                });
                setPopSignAward(true);
                handleQueryDailyShareInfo();
            }
        })
    };



    const hideBodyHuaDong = (setIsHideBody) => {
        if (setIsHideBody) {
            document.getElementsByTagName("body")[0].classList.add("bodyLock");
        } else {
            document.getElementsByTagName("body")[0].classList.remove("bodyLock");
        }

    }


    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    useEffect(() => {
        handleQueryDailyShareInfo()
    }, [window.localStorage.getItem("lang")]);

    return (
        <>
            <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                className=" funbetTxt" style={{ height: "100%", width: "100%" }}>

                <motion.div variants={item} className={clsx("positionAb shareBannerTitle  activityMoveLeftShare titleTxt", judgeLangFontSize(['tmr', 'br', 'tr', 'id']) && "font-18")}
                    style={{ top: "10px", right: "0px", bottom: "0px", color: "#ffffff" }}>
                    {t("home_PLATFORMSHARING")}
                </motion.div>

                <motion.div variants={item} className="positionAb btnShare1">
                    <div className="btnShareTitle titleTxt2">
                        {shareData?.ShareWhatsAppTdy === 2 ? (
                            <img
                                src="assets/images/activity/received.png"
                                alt=""
                                className="positionAb receivedSty"
                                style={{ zIndex: "1" }}
                            />
                        ) : (
                            <>
                                ${shareData?.RewardCoupon && (shareData?.RewardCoupon / 100)}
                            </>
                        )}
                    </div>
                    <img
                        className={clsx(
                            "btnShare1Img btnPointer txtBrightness",
                            shareData?.ShareWhatsAppTdy === 0 && 'spinZhuanBtnImg'
                        )}
                        src={`${React.$imgUrl}/images/index/q1.png`}
                        onClick={() => {
                            handleShareClick('whatsapp')
                        }}
                        style={{ position: "absolute", top: "37px", left: "4px" }}
                    />
                    {shareData?.ShareWhatsAppTdy === 1 && <img className="positionAb redPointSty" src="assets/images/activity/redPoint.png" alt="" />}
                </motion.div>

                <motion.div variants={item} className="positionAb btnShare2 ">
                    <div className="btnShareTitle titleTxt2">
                        {shareData?.ShareLineTdy === 2 ? (
                            <img src="assets/images/activity/received.png" className="positionAb receivedSty" alt="" style={{ zIndex: "1" }} />
                        ) : (
                            <>
                                ${shareData?.RewardCoupon && (shareData?.RewardCoupon / 100)}
                            </>
                        )}
                    </div>
                    <img className={clsx(
                        "btnShare1Img btnPointer txtBrightness",
                        shareData?.ShareLineTdy === 0 && 'spinZhuanBtnImg2'
                    )}
                        src={`${React.$imgUrl}/images/index/li.png`}
                        onClick={() => {
                            handleShareClick('line')
                        }}
                        style={{ position: "absolute", top: "37px", left: "2px" }}
                    />
                    {shareData?.ShareLineTdy === 1 && <img className="positionAb redPointSty" src="assets/images/activity/redPoint.png" alt="" />}
                </motion.div>

                <motion.div variants={item} className="positionAb btnShare3 ">
                    <div className="btnShareTitle titleTxt2">
                        {shareData?.ShareTwiterTdy === 2 ? (
                            <img src="assets/images/activity/received.png" className="positionAb receivedSty" alt="" style={{ zIndex: "1" }} />
                        ) : (
                            <>
                                ${shareData?.RewardCoupon && (shareData?.RewardCoupon / 100)}
                            </>
                        )}
                    </div>
                    <img
                        className={clsx(
                            "btnShare1Img btnPointer txtBrightness",
                            shareData?.ShareTwiterTdy === 0 && 'spinZhuanBtnImg'
                        )}
                        src={`${React.$imgUrl}/images/index/tw.png`}
                        onClick={() => {
                            handleShareClick('twitter')
                        }}
                        style={{ position: "absolute", top: "37px", left: "2px" }}
                    />
                    {shareData?.ShareTwiterTdy === 1 && <img className="positionAb redPointSty" src="assets/images/activity/redPoint.png" alt="" />}
                </motion.div>

                <motion.div variants={item} className="positionAb btnShare4 ">
                    <div className="btnShareTitle titleTxt2">
                        {shareData?.ShareFaceBookTdy === 2 ? (
                            <img src="assets/images/activity/received.png" alt="" className="positionAb receivedSty" style={{ zIndex: "1" }} />
                        ) : (
                            <>
                                ${shareData?.RewardCoupon && (shareData?.RewardCoupon / 100)}
                            </>
                        )}
                    </div>
                    <img
                        className={clsx(
                            "btnShare1Img btnPointer txtBrightness",
                            shareData?.ShareFaceBookTdy === 0 && 'spinZhuanBtnImg2'
                        )}
                        src={`${React.$imgUrl}/images/index/fb.png`}
                        onClick={() => {
                            handleShareClick('facebook')
                        }}
                        style={{ position: "absolute", top: "37px", left: "2px" }}
                    />
                    {shareData?.ShareFaceBookTdy === 1 && <img className="positionAb redPointSty" src="assets/images/activity/redPoint.png" alt="" />}
                </motion.div>

                <motion.div variants={item} className={clsx("positionAb btnShare5", judgeLangFontSize(['de', 'br', 'fr', 'bd', 'es', 'vn', 'pl', 'jp', 'ph', 'id']) && "font-14")}>
                    {t("home_Sharewith")}
                    <span className='sigin-txt-yellow fontBold'>${shareData?.RewardCoupon && (shareData?.RewardCoupon / 100) * 4}</span><span>{t("home_bonus")}!</span>
                </motion.div>
            </motion.div>

            <AwardPop
                open={popSignAward}
                onClose={() => {
                    setPopSignAward(false);
                }}
                symbol={signInRewardBalance.symbol}
                symbolImg={`assets/images/symbol/${signInRewardBalance.symbol}.png`}
                balance={signInRewardBalance.balance}
            />
        </>
    );
}
export default Share;


