const locale = {
    home_StakeNFT: 'Stake NFT untuk lebih banyak keuntungan ',
    home_everyday: ' Setiap hari',
    home_Beton: 'Bertaruh ',
    home_mining: 'Penambangan ',
    home_shareholder: 'Menjadi pemegang saham',
    home_Stake: 'Stake ',
    home_dividend: ' Mendapatkan pembagian keuntungan',
    home_Banker: 'Menjadi salah satu mitra',
    home_SIGNUPAND: 'Daftar',
    home_GET: 'Dapatkan',
    home_UPTO: 'Total',
    home_FuniHash: 'Permainan blockchain',
    home_Option: 'Opsi',
    
    home_Reward: ' Ganjaran',
    home_Reward1: 'Ganjaran',
    home_signUpNow: 'Daftar',
    home_Casino: 'Kasino',
    home_Sports: 'Sukan',
    home_PROMOTION: 'Promosi',
    home_Checkin: 'Mendaftar',
    home_Bouns: 'Bonus',
    home_CheckinBouns: 'Bonus Mendaftar',
    home_SPIN: 'Pusingan',
    home_Relief: 'Bantuan',
    home_Share: 'Kongsi',
    home_Deposit: 'Deposit',
    home_Fund: 'Dana',
    home_VIPClub: 'Kelab VIP',
    home_FastAndEasy: 'Beli kripto dengan mudah dan dapatkan bonus deposit hingga USDT.',
    home_Game: 'Permainan',
    home_User: 'Pengguna',
    home_Time: 'Masa',
    home_BetAmount: 'Jumlah Pertaruhan',
    home_Multiplier: 'Pemalar',
    home_Payout: 'Bayaran',
    home_JoinOur: 'Sertai komuniti peminat sukan, penggemar kripto, dan penjudi kami',
    home_JOIN: 'Sertai',
    home_LICENSES: 'Lesen',
    home_OurPlatforms: 'Platform kami',
    home_Marketplac: 'Pasaran',
    home_Howtoearn: 'Cara untuk Mencari Pendapatan',
    home_Games: 'Permainan',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'Papan Pelabur',
    home_News: 'Berita',
    home_Aboutsus: 'Tentang Kami',
    home_Industry: 'Industri',
    home_Roadmap: 'Peta Jalan',
    home_Partner: 'Rakan Kongsi',
    home_FAQs: 'Soalan Lazim',
    home_Affiliate: 'Affiliate',
    home_Stayintouch: 'Berkomunikasi dengan Kami',
    home_ChatRoom: 'Bilik Cakap',
    home_Selectacontact: 'Pilih kenalan untuk memulakan perbualan. ',
    home_BetSlip: 'Slip Pertaruhan',
    home_Typeyourmessage: 'Taip mesej anda',
    home_AcceptAnyOdds: 'Terima apa-apa peluang',
    home_ClearAll: 'Padam Semua',
    home_Live: 'Live',
    home_Winner: 'Pemenang',
    home_EstPayout: 'Anggaran pembayaran',
    home_TotalOdds: 'Jumlah pertaruhan',
    home_RegistertoBet: 'Daftar untuk bertaruh',
    home_Single: 'Pertaruhan tunggal',
    home_Multi: 'Pertaruhan berganda',
    home_Checkineveryweek: 'Daftar masuk setiap minggu untuk menerima hadiah yang sesuai.',
    home_Day: 'Hari',
    home_Sunday: 'Hari ke-1',
    home_Monday: 'Hari ke-2',
    home_Tuesday: 'Hari ke-3',
    home_Wednesday: 'Hari ke-4',
    home_Thursday: 'Hari ke-5',
    home_Friday: 'Hari ke-6',
    home_Saturday: 'Hari ke-7',
    home_FreeSpin: 'Putar roda',
    home_Turntheturntable: 'Putar roda setiap hari untuk memenangkan USGT.',
    home_DepositFift: 'Deposit',
    home_ANYAMOUNT: 'Anda dapat menerima bonus untuk deposit pertama dengan berapa pun jumlahnya',
    home_DEPOSIT: 'Deposit',
    home_First: 'Pertama',
    home_ONLY: 'Hanya',
    home_1ST: '1',
    home_2ND: '2',
    home_3TH: '3',
    home_ANYAMOUNT: 'BERAPAPUN JUMLAHNYA!',
    home_EXTRA: 'Extra',
    home_Relief: 'Relief Fund',
    home_Getrelief: 'Get relief fund for a chance to turn things around.',
    home_YOUCANCLAIM: 'When balance is less than',
    home_YOUCANReceive: ', you can claim relief fund to top up balance to',
    home_threeTimes: ' 3 times',
    home_everydayTimes: '/every day!',
    home_Times: 'times:',
    home_TotalAssets: 'Total assets',
    home_receive: 'Claim',
    home_TODAY: 'Today',
    home_Share: 'Share',
    home_PLATFORMSHARING: 'Share to other platforms',
    home_Sharewith: 'Share FuniBet every day to get',
    home_SHARINGGIFT: 'Sharing Gift Pack',
    home_CODE: 'Sharing Code',
    home_Selectplatform: 'Select sharing platform:',
    home_Fund: 'Fund',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
    home_BuyFund: 'Buy Fund',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'Received',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'Exclusive Benefits',
    home_Asawayof: 'Sebagai tanda penghargaan dan apresiasi kami terhadap pemain VIP, FuniBet mengundang Anda untuk bergabung dengan klub VIP kami, di mana ada hadiah, hadiah, pengembalian uang tunai yang lebih tinggi, dan fitur unik yang melimpah. Jangan lewatkan semua kesenangan yang menggembirakan dan menakjubkan! ',
    home_Yourcurrent: 'Total deposit Anda saat ini',
    home_WhyUpgrade: 'Mengapa harus naik ke VIP?',
    home_VIPhave: 'VIP menikmati layanan manajer eksklusif 24x7. Selain pengembalian uang dan pengisian dasar, VIP juga menikmati putaran tertinggi, bonus mingguan/bulanan, serta jalur penarikan/deposit tercepat. VIP berkesempatan menghadiri pertemuan pemain elit dan membangun kasino dengan memberikan fitur dan fungsi baru kepada tim FuniBet.',
    home_UpgradeYour: 'Tingkatkan tingkat VIP Anda',
    home_ASaFunibet: 'Sebagai anggota klub VIP FUNIBet, Anda akan mendapatkan hadiah dan hadiah yang melimpah serta hadiah yang dipersonalisasi, tetapi itu belum semuanya. Semakin banyak Anda bermain, ketika Anda naik ke anggota VIP elit, Anda akan menerima manfaat mewah yang lebih baik, FUNIBET akan memberikan hak istimewa VIP yang disesuaikan dengan kebutuhan Anda.',
    home_YOUCANREACH: 'Anda bisa naik ke',
    home_LEVELS: 'tingkat',
    home_eachthehigh: 'Semua orang bisa mendapatkan bonus pengembalian 25% dan menikmati semua keuntungan platform. Bergabunglah dengan FUNIBET, nikmati pengalaman bermain game terbaik dan layanan berkualitas! ',
    home_Cashback: 'Cashback',
    home_Nowater: '1. Tiada caj pemotongan dalam permainan',
    home_Moregrowth: '2. Dana tumbesaran yang lebih banyak',
    home_Increasein: '3. Jumlah faedah bertambah',
    home_Doublethenumber: '4. Kuantiti putaran berganda',
    home_Doublethesign: '5. Jumlah tanda-tangan berganda',
    home_Doublesharingrewards: '6. Ganjaran berkongsi berganda',
    home_DoubletheAffiliate: '7. Ganjaran rakan kongsi berganda',
    home_Rechargerewardamount: '8. Jumlah ganjaran top-up meningkat 1.5 kali.',
    home_ABOUTVIPCLUB: 'ABOUT VIP CLUB',
    home_HightopRank: 'High top Rank has 25% cashback bonus and the best benefits. Stay with BeDao to receive the best gaming experience and high level of service!',
    home_Beingamember: 'Being a member of the VIP club means that you have access to:',
    home_PersonalVIPmanager: 'Personal VIP manager',
    home_Highercahbackrewards: 'Higher cahback rewards',
    home_Otheradditionalperks: 'Other additional perks',
    home_Insights: 'Intipati',
    home_WeeklyandMonthlyBonuses: 'Bonus mingguan dan bulanan',
    home_Showhighgaming: 'Tunjukkan aktiviti permainan yang tinggi dan dapatkan jemputan ke Kelab VIP kami. ',
    home_BindMobile: 'Mengikat',
    home_Pleasecheckthe: 'Sila semak USGT yang anda dapat dari dalam dompet permainan anda. ',
    home_BINDMOBILENUMBER: 'Mengikat nombor',
    home_Get1USDT: 'Dapatkan 1USDT',
    home_Bind: 'Ikatan',
    home_GiftCode: 'Kod hadiah',
    home_EnteryourGiftCode: 'Masukkan kod hadiah anda',
    home_EnterCode: 'Masukkan kod',
    home_Paste: 'Tampal',
    home_confirms: 'Sahkan',
    home_BuyCrypto: 'Beli kripto',
    home_SignedIn: 'Telah log masuk',
    home_CommissionRules: 'Peraturan komisen',
    home_AffiliateTerms: 'Syarat-syarat ahli',
    home_AffiliateDashboard: 'Papan pemuka ahli',
    home_AFFILIATE: 'Ahli',
    

    
    home_COMMISSONREADYEVERYDAY: 'Peraturan komisen.',
    home_MYREFERRALCODE: 'Kod rujukan saya',
    home_MYREFERRALLink: 'Pautan rujukan saya',
    home_AffiliateReward: 'Ganjaran ahli',
    home_EXTRA: 'Tambahan',
    home_USDREWARDS: 'Ganjaran dalam USD',
    home_USDRewardsRules: 'Peraturan ganjaran dalam USD',
    home_JUSAREFERRALAWAY: 'Hanya dengan satu rujukan',
    home_NewlyAvailableRewards: 'Ganjaran terbaru',
    home_ReceivedMao: 'Telah diterima:',
    home_Locked: 'Dikunci:',
    home_Yourcurrentfriends: 'Rakan anda kini perlu kekal aktif untuk membuka lebih banyak ganjaran untuk anda',
    home_Withdraw: 'Tarik',
    home_MY: 'Saya',
    home_COMMISSIONREWARDS: 'Ganjaran komisen',
    home_CommissionRewardRules: 'Peraturan ganjaran komisen',
    home_NewlyAvailableCommissionRewards: 'Ganjaran komisen terbaru',
    home_CommissionYouhaveReceivedinTotal: 'Komisen yang diterima:',
    home_REWARDSSENTTODATE: 'Jumlah Hadiah',
    home_USDrewardssenttodate: 'Ganjaran dalam USD yang telah dihantar sejauh ini',
    home_Commissionsenttodatet: 'Tarikh penghantaran komisen',
    home_Areyouablogger: 'Adakah anda seorang blogger dengan banyak pembaca dan pengikut? Kami menawarkan anda satu program gabungan unik dengan tawaran rujukan yang lebih besar. Kami akan menyusun program tersendiri dengan syarat-syarat istimewa. Sila hubungi pengurus kami untuk membincangkan terma',
    home_onlythoseusers: 'Amaran penting: Hanya pengguna yang diminta oleh pentadbir dan diluluskan oleh pentadbir sahaja yang layak untuk menyertai program ini.',
    home_notice: 'Notis',
    home_bindPhone: 'Ikat Telefon',
    home_SignIn: 'Log Masuk',
    home_SignUp: 'Daftar',
    home_SignOut: 'Log Keluar',
    home_CookiesPolicy: 'Dasar Kuki',
    home_isUsingCookies: 'Funibet.com gunakan kuki untuk perkhidmatan yang lebih baik. Bersetuju dengan penggunaan kuki bila guna laman web kami. Sila lihat dasar privasi dan terma perkhidmatan kami.',
    home_SETTING: 'Tetapan',
    home_LiveSupport: 'Sokongan Langsung',
    home_Accept: 'Terima',
    home_FrequentlyAskedQuestions: 'Soalan Lazim',
    home_SiteUnnderConstruction: 'Laman Web Dalam Pembinaan',
    home_ComingSoon: 'Akan Datang',
    home_Wallet: 'Wallet',
    home_UserId: 'ID:',
    home_UserName: 'Nama:',
    home_turnTimes: 'Bilangan Putaran:',
    home_Bonusincreased: "Bonus tambahan +",
    home_bonus: 'Bonus',
    home_SPINBONUSTOTAL: 'Jumlah Bonus Putaran',
    home_WIN: 'WIN:',
    home_In: 'Dari',
    home_FirstDepositBonus: 'Bonus Deposit Pertama',
    home_Hi: 'Hi',
    home_welcomeAboard: 'Selamat Datang Kembali',
    home_Outoftime: 'Bilangan putaran hari ini telah habis',
    
    game_search: 'Cari',
    game_SortBy: 'Disusun mengikut',
    game_Created: 'Tarikh Dicipta',
    game_Lobby: 'Lobi',
    game_LiveCasino: 'Kasino Langsung',
    game_FeaturedSlots: 'Slot Unggulan',
    game_GameShows: 'Acara Permainan',
    game_FuniBetOriginals: 'Karya Asli FuniBet',
    game_BigWins: 'Kemenangan Besar',
    game_LcukyWins: 'Kemenangan Bertuah',
    game_Challenges: 'Cabaran',
    game_Description: 'Deskripsi',
    game_Slots: 'Slot',
    game_Rank: 'Peringkat',
    game_User: 'Pengguna',
    game_Date: 'Tarikh',
    game_BetAmount: 'Jumlah Pertaruhan',
    game_lucky: 'Pendaratan Keberuntungan',
    game_Multiplier: 'Pengganda',
    game_Payout: 'Bayaran',
    game_Recommend: 'Disyorkan',
    game_Providers: 'Pembekal',
    game_startGame: 'mula',
    game_balance: 'Baki',
    game_provider: 'Tag',
    game_Selectyourdisplay: 'Pilih paparan baki anda',
    game_Balancein: 'Baki di',
    game_FunPlay: 'Mod Percubaan',
    game_RealPlay: 'wang sebenar',
    game_DispLaying: "Penampilan",
    game_LoadMore: "Muat Lebih Banyak",
    game_NoData: 'Tiada permainan jenis ini buat sementara waktu',
    
    message_SigningIn:"Log masuk dengan JWT",
    message_CourseSaved:"Kursus Disimpan",
    message_PleaseBind:"Untuk menyertai aktiviti, anda perlu mengikat telefon anda. Adakah anda mahu mengikatnya?",
    message_UserData:"Data pengguna disimpan dengan api",
    message_Success:"berjaya",
    message_VIPlevel:"Tahap VIP kurang daripada V3",
    message_ListOrder:"Pesanan Senarai Disimpan",
    message_CardOrder :"Pesanan Kad Disimpan",
    message_HiHow:"Hai, apa khabar?",

    Funibet_web: 'Funibet-Web3.0,Kripto,Pertaruhan,Sukan,E-sukan,Slot,Kasino langsung,Poker',

    home_ThereAre: "Tiada pemberitahuan buat masa ini.",

    play_no_balance: "Baki mata wang semasa tidak mencukupi, sila tukar mata wang atau tambah nilai",
    
    home_News_1: 'Berita',
    home_News_2: 'Acara',
    home_News_3: 'Crypto',
    home_News_4: 'Permainan',
    
    home_SuggRep_1: 'Cadangan & Laporan',
    home_SuggRep_2: 'Cadangan & Laporan',
    home_SuggRep_3: 'Kongsi maklum balas untuk meningkatkan prestasi!',
    home_SuggRep_4: 'Laporkan laman web yang menjual dalam permainan.',
    home_SuggRep_5: 'Masukkan cadangan atau hantar laporan',
    home_SuggRep_6: 'Tangkapan skrin contoh',
    home_SuggRep_7: 'Ganjaran untuk cadangan & laporan yang diambil!',
    home_SuggRep_8: 'Hantar',
    
    home_subscription_1: 'Adakah anda ingin melanggan pemberitahuan dorongan?',
    home_subscription_2: 'Boleh berhenti langganan bila-bila masa.',
    home_subscription_3: 'Penghantaran WonderPush',
    home_subscription_4: 'Kemudian',
    home_subscription_5: 'Melanggan',
    
    home_Affiliate_1: 'Hubungi Kami',
    home_Affiliate_2: 'Setiap kawan yang anda jemput, akan memberikan anda 10U, semakin banyak yang anda jemput, semakin banyak yang anda akan dapat!',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'Emel',

    home_banner_1: 'Permainan Crypto',
    home_banner_2: '100+ crypto & 50+ NFT disokong',
    home_banner_3:'Decentralized Betting',
    home_banner_4:'Smart contract betting from decentralized wallet',
    home_banner_5:'Bet 2 Earn',
    home_banner_6:'Bet, mine, and invest for steady profits',
    home_banner_7: 'Web3.0 + Kasino',
    home_banner_8: 'Pemain juga Pemilik & Banker',
    
    home_EventDetails: 'Butiran',

    home_vip_1: "1.Dapatkan pendapatan dengan mendaftar+",
    home_vip_2: "2.Dapatkan pendapatan dengan putaran+",
    home_vip_3: "3.Dapatkan pendapatan dengan berkongsi+",
    home_vip_4: "4.Dapatkan pendapatan dengan mengisi semula+",
    home_vip_5: "5.Chat dibuka, boleh menghantar mesej setiap hari",
    home_vip_6: "Mesej",
    
    ERROR_628: 'Kod hadiah sudah ditebus',
    ERROR_629: 'Kod hadiah sudah ditebus sepenuhnya',
    ERROR_630: 'Kod hadiah tidak aktif',
    ERROR_631: 'Kod hadiah tamat tempoh',
    ERROR_633: 'Tidak dapat menggunakan kod hadiah daripada ejen lain',

    home_Maintaining_1: "Penyelenggaraan",
    home_Maintaining_2: "Ciri ini sedang dalam penyelenggaraan",
    home_Maintaining_3: "AKHIR",
    home_Maintaining_4: "MULAKAN",

    home_Slots: 'Slot',
    home_LiveCasino: 'Kasino Langsung',
    home_CountryFiat: 'Negara & Fiat',
    home_CryptNFTs: 'Kripto & NFT',

    home_GoTo:'Pergi ke',

    home_gameout_1: "Adakah anda sedang berada dalam permainan lain? Adakah anda ingin keluar?",
    home_gameout_2: "Batal",
    
     home_BuyFund1:'Adakah anda ingin membeli dana pertumbuhan?',

     home_BetFor:'Bertaruh untuk',

     tongzhi_1: "Cadangan",
     tongzhi_2: "Balas kepada cadangan dan wakil.",
     tongzhi_3: "Baik sekali! Ini sangat hebat.",
     tongzhi_4: "Menunggu semakan.",
     tongzhi_5: "Semakan diluluskan.",
     tongzhi_6: "Pemprosesan pembayaran.",
     tongzhi_7: "Pembayaran berjaya diselesaikan.",
     tongzhi_8: "Semakan tidak diluluskan.",
     tongzhi_9: "Pembayaran gagal.",
     tongzhi_10: "Pengeluaran gagal.",
     tongzhi_11: "Dibayar.",
     tongzhi_12: "Selesai.",
     tongzhi_13: "Pemprosesan gagal.",
     tongzhi_14: "Beli",
     tongzhi_15: "Hingga",
     tongzhi_16: "Tarik",
     tongzhi_17: "Kriptokurensi",
     tongzhi_18: "Mata Wang Fidusiari",
     tongzhi_19: "Muat turun ",
     tongzhi_20: "Log masuk boleh dihargai",
     tongzhi_21: "Muat turun",
     tongzhi_22: "Tiada versi iOS yang tersedia pada masa ini",
     tongzhi_23: "Hantar",
     tongzhi_24: "Hantar Tip",

     home_banner_9: "Lumba kuda",
     home_banner_10: "Pertarungan ayam",
     home_banner_11: "Mengujakan dan Mengghairahkan, Menangi Hadiah Besar",
     home_banner_12: "Penuh semangat dan bersemangat",
     
     home_wanfa_1:"Masalah Gameplay",
     home_wanfa_2:"Masalah Pemindahan",
     home_wanfa_3:"Masalah Lain",
     play_not_balance: "Pertaruhan sukan dengan eUSDT tidak mungkin. Adakah anda ingin menukar mata wang?",
     qieHuan_balance: "Baki dalam mata wang yang dipilih pada masa ini tidak mencukupi untuk bermain. Adakah anda ingin bertukar kepada mata wang lain?",
     Provided_by: "Dari",

     error_0: "Gagal menerima ganjaran",
     error_1: "Gagal mendapatkan data",
     error_2: "Gagal mendaftar masuk",
     error_3: "Gagal memutarkan piring putar",
     error_4: "Gagal menerima bantuan kewangan",
     error_5: "Gagal berkongsi",
     error_6: "Pembelian dana gagal",
     error_7: "Tidak dapat mendapatkan maklumat permainan",
     error_8: "Tidak dapat mencuba permainan ini",
     error_10: "Had pengalihan gagal",
     error_11: "Operasi gagal",
     error_12: "Gagal menghantar kod pengesahan",
     error_13: "Had penghantaran telah dicapai",
     error_15: "",
     error_16: "",
     error_17: "",
     error_18: "",
     error_19: "",
     error_20: "",
     error_21: "",
     error_22: "",
     error_23: "",
     error_24: "",
     error_25: "",
     error_26: "",
     error_27: "",
     error_28: "",
     error_29: "",
     error_30: "",
     error_31: "",
     error_32: "",
     error_33: "",
     error_34: "",
    };
    
    export default locale;
    
    
