import "../../../styles/game.css";
import * as React from 'react';
import utils from '../../util/tools/utils'
import Common_foot from '../common/Common_foot';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { getGrowthFundConfig, buyGrowthFund, getGrowthFundReward } from "../../store/activity/activityThunk";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../../store/user";
import { getUserSetting, onLogin } from "../../store/user/userThunk";
import { isMobile, judgeIosOrAndroid } from '../../util/tools/function'

function Course(props) {
    const { t } = useTranslation('mainPage');

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    return (
        <div className="flex w-full container">
            <div className="flex flex-col sm:flex-row betDiColor funbetTxt flex-auto sm:items-center min-w-0 p-10 md:p-32 pb-0 md:pb-0">
                <div className="flex flex-col flex-auto align-item">
                    <div className="flex grow shrink-0 flex-col items-center container ">
                        <motion.div
                            variants={container}
                            initial="hidden"
                            animate="show">

                            {(isMobile() && judgeIosOrAndroid() === 'ios') && <>
                                <motion.div variants={item} className="zuQiu flex justifyContent">
                                    <img className="borderRadius-10" src="assets/images/nav/courseBGPhone1.jpg" style={{ width: "360px", height: "164px", marginTop: "40px" }}></img>
                                </motion.div>
                                <motion.div variants={item} className="flex mt-40 mb-16">
                                    <div className="courseTxtYuanDian borderRadius">
                                        1
                                    </div>
                                    <div className="courseTxt1">
                                        Click the share button
                                    </div>
                                </motion.div>
                                <motion.div variants={item} className="flex justifyContent">
                                    <img className="borderRadius-10" src="assets/images/nav/courseIos1.png" style={{ width: "360px", height: "301px" }}></img>
                                </motion.div>

                                <motion.div variants={item} className="flex mt-40 mb-16">
                                    <div className="courseTxtYuanDian borderRadius">
                                        2
                                    </div>
                                    <div className="courseTxt1">
                                        Click Add to Home Screen button
                                    </div>
                                </motion.div>
                                <motion.div variants={item} className="flex justifyContent">
                                    <img className="borderRadius-10" src="assets/images/nav/courseIos2.png" style={{ width: "360px", height: "280px" }}></img>
                                </motion.div>

                                <motion.div variants={item} className="flex mt-40 mb-16">
                                    <div className="courseTxtYuanDian borderRadius">
                                        3
                                    </div>
                                    <div className="courseTxt1">
                                        Click the Add button after entering the name
                                    </div>
                                </motion.div>
                                <motion.div variants={item} className="flex justifyContent">
                                    <img className="borderRadius-10 mb-40" src="assets/images/nav/courseIos3.png" style={{ width: "360px", height: "234px" }}></img>
                                </motion.div>
                            </>
                            }
                            {(isMobile() && judgeIosOrAndroid() === 'android') && <>
                                <motion.div variants={item} className="zuQiu flex justifyContent">
                                    <img className="borderRadius-10" src="assets/images/nav/courseBGPhone1.jpg" style={{ width: "360px", height: "164px", marginTop: "40px" }}></img>
                                </motion.div>
                                <motion.div variants={item} className="flex mt-40 mb-16">
                                    <div className="courseTxtYuanDian borderRadius">
                                        1
                                    </div>
                                    <div className="courseTxt1">
                                        Click the function button on the right side of the browser
                                    </div>
                                </motion.div>
                                <motion.div variants={item} className="flex justifyContent">
                                    <img className="borderRadius-10" src="assets/images/nav/courseAndroid1.jpg" style={{ width: "360px", height: "250px" }}></img>
                                </motion.div>

                                <motion.div variants={item} className="flex mt-40 mb-16">
                                    <div className="courseTxtYuanDian borderRadius">
                                        2
                                    </div>
                                    <div className="courseTxt1">
                                        Click Add to Home Screen button
                                    </div>
                                </motion.div>
                                <motion.div variants={item} className="flex justifyContent">
                                    <img className="borderRadius-10" src="assets/images/nav/courseAndroid2.jpg" style={{ width: "360px", height: "256px" }}></img>
                                </motion.div>

                                <motion.div variants={item} className="flex mt-40 mb-16">
                                    <div className="courseTxtYuanDian borderRadius">
                                        3
                                    </div>
                                    <div className="courseTxt1">
                                        Click the Add button after entering the name
                                    </div>
                                </motion.div>
                                <motion.div variants={item} className="flex justifyContent">
                                    <img className="borderRadius-10 mb-40" src="assets/images/nav/courseAndroid3.jpg" style={{ width: "360px", height: "457px" }}></img>
                                </motion.div>
                            </>}
                            {isMobile() === false && <div>
                                <motion.div variants={item} className="zuQiu courseBg flex align-item" style={{ justifyContent: "right" }}>
                                    <div className="sigin-txt-yellow  ">Operation steps</div>
                                </motion.div>
                                <motion.div variants={item} className="flex mt-40 mb-16">
                                    <div className="courseTxtYuanDian borderRadius">
                                        1
                                    </div>
                                    <div className="courseTxt1">
                                        Click the function button on the right side of the browser
                                    </div>
                                </motion.div>
                                <motion.div variants={item}>
                                    <img className="borderRadius-10" src="assets/images/nav/course1.jpg"></img>
                                </motion.div>

                                <motion.div variants={item} className="flex mt-40 mb-16">
                                    <div className="courseTxtYuanDian borderRadius">
                                        2
                                    </div>
                                    <div className="courseTxt1">
                                        Select more tools and select Create Shortcut in the expanded list
                                    </div>
                                </motion.div>
                                <motion.div variants={item}>
                                    <img className="borderRadius-10" src="assets/images/nav/course2.jpg"></img>
                                </motion.div>


                                <motion.div variants={item} className="flex mt-40 mb-16">
                                    <div className="courseTxtYuanDian borderRadius">
                                        3
                                    </div>
                                    <div className="courseTxt1">
                                        After entering the name, click the Create button
                                    </div>
                                </motion.div>
                                <motion.div variants={item}>
                                    <img className="borderRadius-10 mb-40" src="assets/images/nav/course3.png"></img>
                                </motion.div>
                            </div>}

                        </motion.div>
                    </div>
                    <Common_foot />
                    <div className="mt-32  promotion-list colorGameListTitle mb-20 displayPhoneSwitch1">
                        <div>FuniBet 2022- ALL Rights Reserved</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Course;
