import "@mock-api";
import BrowserRouter from "@fuse/core/BrowserRouter";
import FuseLayout from "@fuse/core/FuseLayout";
import FuseTheme from "@fuse/core/FuseTheme";
import { SnackbarProvider } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { selectCurrentLanguageDirection } from "app/store/i18nSlice";
import { selectUser } from "app/store/userSlice";
import themeLayouts from "app/theme-layouts/themeLayouts";
import { selectMainTheme } from "app/store/fuse/settingsSlice";
import FuseAuthorization from "@fuse/core/FuseAuthorization";
import settingsConfig from "app/configs/settingsConfig";
import withAppProviders from "./withAppProviders";
import { AuthProvider } from "./auth/AuthContext";
import PhoneLayout from "./main/phoneLayout/PhoneLayout";
import { selectUserData, updateIsUserInfoLoading, updateUserToken } from "./store/user";
import { userProfile, setUserSetting, setSpreaderInfo, doSaveToWallet, depositNotify } from "./store/user/userThunk";
import {getCasinoType, getChannelList} from "./store/casino/casinoThunk";
import { getUserSetting } from "./store/user/userThunk";
import { updateTagList } from "./store/casino/tags";
import axios from "axios";
import country from "./json/country";
import { changeLanguage } from "./store/i18nSlice";
import { updateUserFiats, updateUserSymbols } from "./store/user/userAssets";
import { updateCurrentCountryData } from './store/user/userLoginCountry'
import { updateAllFiats } from "./store/config/fiats";
import { updateAllSymbols } from "./store/config/symbols";
import Cookies from "./main/cookies/Cookies";
import DownPhoneApp from "./main/cookies/DownPhoneApp";
import Subscription from "./main/cookies/Subscription";
import ActivePopup from "./main/cookies/ActivePopup";
import { getIPExtendInfo } from "./store/config/configThunk";
import { showMessage } from "app/store/fuse/messageSlice";
import browerLang from './json/browserLang.json'
import { getUrlParam } from "./util/tools/function";


import { selectConfigData, updateSpreaderID, updateZoomRatio } from "app/store/config";
import { use } from "i18next";
import { selectUserSetting } from "app/store/user/userSetting";
import calendarApp from "./main/apps/calendar/CalendarApp";
import { selectUserInfo, updateUser } from "app/store/user/userInfo";


/**
 * Axios HTTP Request defaults
 */
// axios.defaults.baseURL = "";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const emotionCacheOptions = {
  rtl: {
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById("emotion-insertion-point"),
  },
  ltr: {
    key: "muiltr",
    stylisPlugins: [],
    insertionPoint: document.getElementById("emotion-insertion-point"),
  },
};

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserData);
  const userInfo = useSelector(selectUserInfo);
  const userSetting = useSelector(selectUserSetting);
  const configData = useSelector(selectConfigData);
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const mainTheme = useSelector(selectMainTheme);

  const [currentCountry, setCurrentCountry] = useState({});
  const [isSettingSuccess, setIsSettingSuccess] = useState(false);
  const [token, setToken] = useState("");

  const isMobile = () => {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleGetUserInfo = (token) => {
    if (token) {
      // FuniBox.Tools.default.setToken('64915ebf60b24e97a4584544', token);//正式
      FuniBox.Tools.default.setToken('6436951541b60d250c692481', token);
      localStorage.setItem('Authorization', token);

      setTimeout(() => {
        FuniBox.Tools.default.getUserInfo().then((res) => {
          if (JSON.stringify(res) !== "{}") {
            setToken(token);
            dispatch(updateUser(res));
            dispatch(updateUserToken(token));
            dispatch(updateIsUserInfoLoading(false));
            dispatch(doSaveToWallet()).then(() => {
              // 获取用户信息
              dispatch(getUserSetting({
                callback: () => { }
              }));
            })
          } else {
            FuniBox.Tools.default.destroyToken();
            dispatch(
              userProfile({
                token: "",
                userData: {},
              })
            );
            setToken("");
          }
        });
      }, 1500);
    }
  }

  const getIpCountryInfo = () => {
    // 获取IP信息
    dispatch(getIPExtendInfo()).then((res) => {
      let result = res.payload;
      if (result) {
        setCurrentCountry(result);
        dispatch(updateCurrentCountryData(result));
      }
    })
  }

  useEffect(() => {
    let token = FuniBox.Tools.default.getToken();
    if (token === 'undefined' || token === '') {
      token = localStorage.getItem('Authorization')
      if (!token) {
          token = window.localStorage.getItem(
              `Authorization-${
                  window.sessionStorage.getItem('openAppId') || 0
              }-${
                  window.sessionStorage.getItem('openIndex') || 0
              }`
          );
      } 
    }
    if (token) {
      setTimeout(() => {
        FuniBox.Tools.default.getUserInfo().then((res) => {

          if (JSON.stringify(res) !== "{}") {
            setToken(token);
            dispatch(updateUser(res));
            dispatch(updateUserToken(token));
            dispatch(updateIsUserInfoLoading(false));
            dispatch(doSaveToWallet()).then(() => {
              // 获取用户信息
              dispatch(getUserSetting({
                callback: () => { }
              }));
            })
            // 获取用户入金通知
            dispatch(depositNotify()).then((res) => {
              let data = res.payload

              // 法币
              if (data.fiat && data.fiat.length > 0) {
                data.fiat.map((item) => {
                  fbq('track', 'Purchase', { value: item.amount, currency: item.currency });
                })
              }

              // 虚拟币
              if (data.crypto && data.crypto.length > 0) {
                data.crypto.map((item) => {
                  fbq('track', 'Purchase', { value: item.amount, currency: item.symbol });
                })
              }

            })
          } else {
            FuniBox.Tools.default.destroyToken();
            dispatch(
              userProfile({
                token: "",
                userData: {},
              })
            );
            setToken("");
          }
        });
      }, 1500);
    } else {
      dispatch(
        userProfile({
          token: "",
          userData: {},
        })
      );
    }

    if (window.localStorage.getItem("lang")) {
      dispatch(changeLanguage(window.localStorage.getItem("lang")));
    }

    setTimeout(() => {
      // 获取Casino标签(所有标签)
      dispatch(
        getCasinoType({
          tagGroup: -2,
        })
      ).then((res) => {
        let result = res.payload;
        dispatch(
          updateTagList({
            tagList: result.list,
          })
        );
      });

      // 获取所有供应商
      dispatch(getChannelList());

      // 根据IP获取国家
      if (!window.localStorage.getItem("lang")) {
        // 先获取浏览器语言
        const currBrowserLang = navigator.language;
        if (browerLang[currBrowserLang]) {
          dispatch(changeLanguage(browerLang[currBrowserLang].langCode))
          getIpCountryInfo()
        } else {
          dispatch(getIPExtendInfo()).then((res) => {
            let result = res.payload;
            if (result) {
              setCurrentCountry(result);
              dispatch(updateCurrentCountryData(result));
              dispatch(changeLanguage(country[result.languageCode].langCode ?? "en"));
            }
          }).catch(() => {
            dispatch(changeLanguage('en'))
          });
        }
      } else {
        getIpCountryInfo()
      }
    }, 2000);


    //监听浏览器返回按钮事件
    window.addEventListener(
      "popstate",
      function (e) {
        if (document.getElementsByClassName("hiddenIt")) {
          document.getElementsByClassName("hiddenIt")[0].style.display =
            "block";
          document.getElementsByClassName("hiddenIt")[1].style.display =
            "block";
        }
      },
      false
    );

    const spreaderID = getUrlParam('spreaderID');
    if (spreaderID) {
      dispatch(updateSpreaderID(spreaderID))
    }

    const standardWith = 1600;
    const scrollWith = window.screen.width;
    let scale = 1;
    if (750 < scrollWith && scrollWith < 1600) {
      scale = (scrollWith / standardWith);
      if (0 < scale && scale <= 1) {
        dispatch(updateZoomRatio(scale));
        document.getElementsByTagName('body')[0].style.zoom = scale;
      }
    }
  }, []);


  useEffect(() => {
    const tokenParam = getUrlParam('token');
    handleGetUserInfo(tokenParam)
  }, [])

  const tidyUserData = () => {
    if (token) {
      // 获取所有法币
      FuniBox.Tools.default.getAllFiats().then((res) => {
        if (res) {
          dispatch(updateAllFiats(res.currency));
        }
      });

      // 获取所有虚拟币
      setTimeout(() => {
        FuniBox.Tools.default.getAllSymbols().then((res) => {
          if (res) {
            dispatch(updateAllSymbols(res));
          }
        });
      }, 100);

      // 获取用户法币余额
      setTimeout(() => {
        FuniBox.Tools.default.getUserFiat().then((res) => {
          if (res) {
            dispatch(updateUserFiats(res));
          }
        });
      }, 200);

      // 获取用户虚拟币余额
      setTimeout(() => {
        FuniBox.Tools.default.getCenterGetTokenBalanceList().then((res) => {
          if (res) {
            dispatch(updateUserSymbols(res));
          }
        });
      }, 300);
    }
  };

  useEffect(() => {
    tidyUserData();
  }, [token]);

  useEffect(() => {
    if (configData.spreaderID && user.token) {
      dispatch(setSpreaderInfo({
        spreaderID: configData.spreaderID
      }))
    }
  }, [configData.spreaderID, user]);

  // 第一次登录设置默认币种,如果没有,设置为USGT
  useEffect(() => {
    if (
      userSetting?.user?.webLogonTimes <= 2 &&
      Object.keys(currentCountry).length > 0 &&
      !isSettingSuccess &&
      userInfo.userInfo?.loginType
    ) {
      let data = {
        currencyType: 2,
        symbol: "",
        fiatCode: "",
      };
      if (userInfo.userInfo.loginType == 1) {
        data.currencyType = 1;
        data.symbol = "USDT";
      } else {
        data.currencyType = 2;
        let currency = currentCountry.fiatCode;
        if (!currency) {
          data.fiatCode = "USD";
        } else {
          data.fiatCode = currency;
        }
      }
      dispatch(setUserSetting(data)).then(() => {
        setIsSettingSuccess(true);
        dispatch(getUserSetting({
          callback: () => { }
        }));
      });
    }
  }, [userSetting, userInfo.userInfo, currentCountry]);

  // 设置用户信息
  function setUserCurrency(data) {
    dispatch(setUserSetting(data)).then(() => {
      setIsSettingSuccess(true);
      dispatch(getUserSetting({
        callback: () => { }
      }));
    });
  }
  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <FuseTheme theme={mainTheme} direction={langDirection}>
        <AuthProvider>
          <BrowserRouter classes="testRed">
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              classes={{
                containerRoot:
                  "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99 ",
              }}
            >
              {isMobile() ? (
                <>
                  <PhoneLayout></PhoneLayout>
                </>
              ) : (
                <>
                  <FuseLayout layouts={themeLayouts} />
                </>
              )}
              {!isMobile() && <Subscription />}
              <DownPhoneApp />
              <ActivePopup />
              <Cookies />
            </SnackbarProvider>
          </BrowserRouter>
        </AuthProvider>
      </FuseTheme>
    </CacheProvider>
  );
};
export default withAppProviders(App)();
