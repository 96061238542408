const locale = {
    home_StakeNFT: 'Apuesta en NFT para ganar más',
    home_everyday: 'Cada día',
    home_Beton: 'Apostar en ',
    home_mining: 'Minería ',
    home_shareholder: 'Conviértete en accionista',
    home_Stake: 'Estacar ',
    home_dividend: ' Obtener dividendos',
    home_Banker: 'Conviértete en uno de los socios',
    home_SIGNUPAND: 'SIGN UP & ',
    home_GET: 'GET',
    home_UPTO: 'UP TO ',
    home_FuniHash: 'Juegos de Blockchain',
    home_Option: 'Opciones',
    
    home_Reward: ' reward',
    home_Reward1: 'reward',
    home_signUpNow: 'Regístrate ahora',
    home_Casino: 'Casino',
    home_Sports: 'Deportes',
    home_PROMOTION: 'Promoción',
    home_Checkin: 'Registro',
    home_Bouns: 'Bono',
    home_CheckinBouns: 'Bono de registro',
    home_SPIN: 'Girar',
    home_Relief: 'Alivio',
    home_Share: 'Compartir',
    home_Deposit: 'Depósito',
    home_Fund: 'Fondo',
    home_VIPClub: 'Club VIP',
    home_FastAndEasy: 'Compra criptomonedas rápidamente y recibe un bono de depósito de hasta USDT',
    home_Game: 'Juego',
    home_User: 'Usuario',
    home_Time: 'Tiempo',
    home_BetAmount: 'Monto de apuesta',
    home_Multiplier: 'Multiplicador',
    home_Payout: 'Pago',
    home_JoinOur: 'Únete a nuestra comunidad de fanáticos de los deportes, amantes de la criptografía y jugadores',
    home_JOIN: 'UNIRSE',
    home_LICENSES: 'Licencias',
    home_OurPlatforms: 'Nuestras plataformas',
    home_Marketplac: 'Mercado',
    home_Howtoearn: 'Cómo ganar',
    home_Games: 'Juegos',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'Plataforma de inversores',
    home_News: 'Noticias',
    home_Aboutsus: 'Acerca de nosotros',
    home_Industry: 'Industria',
    home_Roadmap: 'Hoja de ruta',
    home_Partner: 'Socio',
    home_FAQs: 'Preguntas frecuentes',
    home_Affiliate: 'Afiliado',
    home_Stayintouch: 'Mantenerse en contacto',
    home_ChatRoom: 'Sala de chat',
    home_Selectacontact: 'Seleccione un contacto para comenzar a conversar.',
    home_BetSlip: 'Boleto de apuestas',
    home_Typeyourmessage: 'Escribe tu mensaje',
    home_AcceptAnyOdds: 'Aceptar cualquier probabilidad',
    home_ClearAll: 'Limpiar todo',
    home_Live: 'En vivo',
    home_Winner: 'Ganador',
    home_EstPayout: 'Pago estimado',
    home_TotalOdds: 'Apuesta total',
    home_RegistertoBet: 'Registrarse para apostar',
    home_Single: 'Apuesta simple',
    home_Multi: 'Apuesta múltiple',
    home_Checkineveryweek: 'Acumula registro cada semana para recibir una recompensa correspondiente.',
    home_Day: 'Día',
    home_Sunday: 'Día 1',
    home_Monday: 'Día 2',
    home_Tuesday: 'Día 3',
    home_Wednesday: 'Día 4',
    home_Thursday: 'Día 5',
    home_Friday: 'Día 6',
    home_Saturday: 'Día 7',
    home_FreeSpin: 'Girar la ruleta',
    home_Turntheturntable: 'Gira la ruleta cada día para obtener recompensas en USGT.',
    home_DepositFift: 'depósito',
    home_ANYAMOUNT: 'Recibe una recompensa por tu primer depósito de cualquier cantidad.',
    home_DEPOSIT: 'Depósito',
    home_First: 'Primer',
    home_ONLY: 'Solo',
    home_1ST: '1RO',
    home_2ND: '2DO',
    home_3TH: '3RO',
    home_ANYAMOUNT: '¡CUALQUIER CANTIDAD!',
    home_EXTRA: 'Extra',
    home_Relief: 'Ayuda',
    home_Getrelief: 'Obtén ayuda para tener una segunda oportunidad.',
    home_YOUCANCLAIM: 'Cuando tu saldo sea inferior a',
    home_YOUCANReceive: ', puedes recibir ayuda para aumentar tu saldo a',
    home_threeTimes: ' 3 veces',
    home_everydayTimes: '/cada día!',
    home_Times: 'Veces:',
    home_TotalAssets: 'Activos totales',
    home_receive: 'Recibir',
    home_TODAY: 'Hoy',
    home_Share: 'Compartir',
    home_PLATFORMSHARING: 'PLATFORM SHARING',
    home_Sharewith: 'Share FuniBet every day and get ',
    home_SHARINGGIFT: 'Regalo de compartición',
    home_CODE: 'Código de compartición',
    home_Selectplatform: 'Seleccionar plataforma de compartición:',
    home_Fund: 'Fondo',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'Mejora tu nivel VIP y recibe más recompensas diarias.',
    home_BuyFund: 'Comprar',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'Recibido',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'Beneficios exclusivos',
    home_Asawayof: 'Como muestra de agradecimiento y aprecio a nuestros jugadores VIP, FuniBet te invita a unirte a nuestro club VIP donde encontrarás una gran cantidad de regalos, obsequios, mayores reembolsos en efectivo y funciones exclusivas. ¡No te pierdas toda la emoción y diversión increíble!',
    home_Yourcurrent: 'Tu actual recarga total',
    home_WhyUpgrade: '¿Por qué actualizar a VIP? ',
    home_VIPhave: 'Los VIP tienen servicio excepcional 24/7 con un gestor personal dedicado, giros de alto nivel, bonos semanales/mensuales y canales de depósito/retirada más rápidos. También pueden asistir a eventos exclusivos y contribuir a mejorar el casino.',
    home_UpgradeYour: 'Actualice su nivel VIP',
    home_ASaFunibet: 'Como miembro del Club VIP de FuniBet, puede recibir recompensas generosas y regalos personalizados, pero eso no es todo. Cuanto más juegue, mejor será su experiencia, y cuando sea ascendido a miembro VIP Elite a través de los niveles VIP, obtendrá beneficios de lujo aún mejores, con privilegios VIP diseñados a medida para usted por FuniBet.',
    home_YOUCANREACH: 'Puede alcanzar el nivel de',
    home_LEVELS: 'Nivel',
    home_eachthehigh: 'Cada uno puede obtener un bono de reembolso del 25% y disfrutar de todas las ventajas de la plataforma. ¡Únase a FuniBet y disfrute de la mejor experiencia de juego y el mejor servicio!',
    home_Cashback: 'Reembolso de efectivo',
    home_Nowater: '1. Sin comisión en el juego',
    home_Moregrowth: '2. Mayor fondo de crecimiento',
    home_Increasein: '3. Aumento de la cantidad de beneficios',
    home_Doublethenumber: '4. Doble cantidad de giros',
    home_Doublethesign: '5. Doble cantidad de registro',
    home_Doublesharingrewards: '6. Doble cantidad de recompensas por compartir',
    home_DoubletheAffiliate: '7. Doble cantidad de recompensas para afiliados',
    home_Rechargerewardamount: '8. El monto de recompensa de recarga se convierte en 1.5 veces',
    home_ABOUTVIPCLUB: 'ABOUT VIP CLUB',
    home_HightopRank: 'High top Rank has 25% cashback bonus and the best benefits. Stay with BeDao to receive the best gaming experience and high level of service!',
    home_Beingamember: 'Being a member of the VIP club means that you have access to:',
    home_PersonalVIPmanager: 'Personal VIP manager',
    home_Highercahbackrewards: 'Higher cahback rewards',
    home_Otheradditionalperks: 'Other additional perks',
    home_Insights: 'Información',
    home_WeeklyandMonthlyBonuses: 'Bonos semanales y mensuales',
    home_Showhighgaming: 'Mostrar actividades de juegos de alto nivel y recibir invitaciones a nuestro club VIP.',
    home_BindMobile: 'Vincular',
    home_Pleasecheckthe: 'Por favor, revise su billetera para verificar los USDT que ha obtenido del juego.',
    home_BINDMOBILENUMBER: 'Vincular número ',
    home_Get1USDT: 'Obtener 1 USDT',
    home_Bind: 'Vincular',
    home_GiftCode: 'Código de regalo',
    home_EnteryourGiftCode: 'Ingrese su código de regalo',
    home_EnterCode: 'Ingresar código',
    home_Paste: 'Pegar',
    home_confirms: 'Confirmar',
    home_BuyCrypto: 'Comprar',
    home_SignedIn: 'Registrado',
    home_CommissionRules: 'Reglas de comisión',
    home_AffiliateTerms: 'Términos de afiliación',
    home_AffiliateDashboard: 'Tablero de afiliados',
    home_AFFILIATE: 'Afiliado',
    
    
    
    home_COMMISSONREADYEVERYDAY: 'Comisión lista en cualquier momento, $ 1,000 solo por recomendación',
    home_MYREFERRALCODE: 'Mi código de referencia',
    home_MYREFERRALLink: 'Mi enlace de referencia',
    home_AffiliateReward: 'Recompensa de afiliado',
    home_EXTRA: 'Extra',
    home_USDREWARDS: 'Recompensas en USD',
    home_USDRewardsRules: 'Reglas de recompensas en USD',
    home_JUSAREFERRALAWAY: 'Solo una recomendación',
    home_NewlyAvailableRewards: 'Nuevas recompensas disponibles',
    home_ReceivedMao: 'Recibido:',
    home_Locked: 'Bloqueado:',
    home_Yourcurrentfriends: 'Mantener a tus amigos activos desbloqueará más recompensas para ti',
    home_Withdraw: 'Retirar',
    home_MY: 'Mi',
    home_COMMISSIONREWARDS: 'Recompensas de comisión',
    home_CommissionRewardRules: 'Reglas de recompensas de comisión',
    home_NewlyAvailableCommissionRewards: 'Nuevas recompensas de comisión disponibles',
    home_CommissionYouhaveReceivedinTotal: 'Comisión recibida:',
    home_REWARDSSENTTODATE: 'Total de premios',
    home_USDrewardssenttodate: 'Recompensas en dólares enviadas hasta la fecha',
    home_Commissionsenttodatet: 'Fecha de envío de la comisión',
    home_Areyouablogger: '¿Eres un blogger con muchos lectores y seguidores? Ofrecemos un plan de afiliados especial con mayores recompensas de recomendación. Preparamos un programa separado con condiciones especiales. Póngase en contacto con nuestro gerente para discutir los términos.',
    home_onlythoseusers: 'Nota importante: solo los usuarios solicitados y aprobados por el administrador pueden participar en este programa.',
    home_notice: 'Aviso',
    home_bindPhone: 'Vincular teléfono',
    home_SignIn: 'Iniciar sesión',
    home_SignUp: 'Registrarse',
    home_SignOut: 'Cerrar sesión',
    home_CookiesPolicy: 'Política de cookies',
    home_isUsingCookies: 'Funibet.com utiliza cookies para brindar un mejor servicio a cada usuario. Al utilizar nuestro sitio web, usted acepta el uso de cookies. Consulte nuestra política de privacidad y nuestros términos de servicio.',
    home_SETTING: 'Configuración',
    home_LiveSupport: 'Soporte en vivo',
    home_Accept: 'Aceptar',
    home_FrequentlyAskedQuestions: 'Preguntas frecuentes',
    home_SiteUnnderConstruction: 'Sitio en construcción',
    home_ComingSoon: 'Próximamente',
    home_Wallet: 'Wallet',
    home_UserId: 'ID: ',
    home_UserName: 'Nombre: ',
    home_turnTimes: 'Número de vueltas:',
    home_Bonusincreased: "Bono extra +",
    home_bonus: 'Bono',
    home_SPINBONUSTOTAL: 'Bono total de giros',
    home_WIN: 'WIN:',
    home_In: 'en',
    home_FirstDepositBonus: 'First Deposit Bonus',
    home_Hi: 'Hola',
    home_welcomeAboard: 'Bienvenido de nuevo',
    home_Outoftime: 'Agotado el número de veces de hoy',
    
    
    game_search: 'Buscar',
    game_SortBy: 'Ordenar por',
    game_Created: 'Creado',
    game_Lobby: 'Lobby',
    game_LiveCasino: 'Casino en vivo',
    game_FeaturedSlots: 'Tragamonedas destacadas',
    game_GameShows: 'Programas de juegos',
    game_FuniBetOriginals: 'Juegos originales de FuniBet',
    game_BigWins: 'Grandes ganancias',
    game_LcukyWins: 'Ganancias de suerte',
    game_Challenges: 'Desafíos',
    game_Description: 'Descripción',
    game_Slots: 'Tragamonedas',
    game_Rank: 'Rango',
    game_User: 'Usuario',
    game_Date: 'Fecha',
    game_BetAmount: 'Monto de apuesta',
    game_lucky: 'Multiplicador máximo',
    game_Multiplier: 'Multiplicador',
    game_Payout: 'Pago',
    game_Recommend: 'Recomendado',
    game_Providers: 'Proveedores',
    game_startGame: 'comenzar',
    game_balance: 'Saldo',
    game_provider: 'Etiqueta',
    game_Selectyourdisplay: 'Selecciona tu visualización de saldo',
    game_Balancein: 'Saldo en',
    game_FunPlay: 'manifestación',
    game_RealPlay: 'Modo real',
    game_DispLaying: 'Mostrando',
    game_LoadMore: 'Cargar más',
    game_NoData: 'No hay juegos de este tipo temporalmente',
    
    message_SigningIn:"Iniciando sesión con JWT",
    message_CourseSaved:"Curso guardado",
    message_PleaseBind:"Para participar en la actividad, es necesario vincular el teléfono. ¿Desea vincularlo?",
    message_UserData:"Datos de usuario guardados con la API",
    message_Success:"éxito",
    message_VIPlevel:"Nivel VIP es menor que V3",
    message_ListOrder:"Orden de lista guardada",
    message_CardOrder :"Orden de tarjeta guardada",
    message_HiHow:"Hola, ¿cómo estás?",

    Funibet_web: 'Funibet-Web3.0,Criptomoneda,Apuesta,Deportes,E-sports,Tragamonedas,Casino en vivo,Póker',

    home_ThereAre: "No hay notificaciones por ahora.",

    play_no_balance: "El saldo de la moneda actual es insuficiente, por favor cambie de moneda o recargue",

    home_News_1: 'Noticias',
    home_News_2: 'Evento',
    home_News_3: 'Cripto',
    home_News_4: 'Juegos',
    
    home_SuggRep_1: 'Sug.&Rep.',
    home_SuggRep_2: 'Sugerencias e informes',
    home_SuggRep_3: '¡Comparta comentarios para mejorar!',
    home_SuggRep_4: 'Informe sobre sitio web que vende dentro del juego.',
    home_SuggRep_5: 'Ingrese sugerencias o informe',
    home_SuggRep_6: 'Captura de pantalla de ejemplo',
    home_SuggRep_7: '¡Recompensa por sugerencias e informes adoptados!',
    home_SuggRep_8: 'enviar',
    
    home_subscription_1: '¿Le gustaría suscribirse a las notificaciones push?',
    home_subscription_2: 'Siempre puede cancelar la suscripción en cualquier momento.',
    home_subscription_3: 'Notificaciones push web por WonderPush',
    home_subscription_4: 'Más tarde',
    home_subscription_5: 'Suscribir',
    
    home_Affiliate_1: 'Contáctenos',
    home_Affiliate_2: '¡Cada amigo que invite, le dará 10U, cuanto más invite, más obtendrá!',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'Email',

    home_banner_1:'Crypto Gaming',
    home_banner_2:'100+ crypto & 50+  NFTs supported',
    home_banner_3:'Decentralized Betting',
    home_banner_4:'Smart contract betting from decentralized wallet',
    home_banner_5: 'Apuesta para Ganar',
    home_banner_6: 'Apuesta, mina e invierte para ganancias constantes',
    home_banner_7: 'Web3.0 + Casino',
    home_banner_8: 'El jugador también es Banquero y Dueño',

    home_EventDetails: 'Details',

    home_vip_1: "1.Obtener ingresos al registrarse+",
    home_vip_2: "2.Obtener ingresos al girar+",
    home_vip_3: "3.Obtener ingresos al compartir+",
    home_vip_4: "4.Obtener ingresos al recargar+",
    home_vip_5: "5.Chat abierto, se pueden enviar mensajes diarios",
    home_vip_6: "Mensajes",

    ERROR_628: 'Código de regalo ya reclamado',
    ERROR_629: 'Código de regalo completamente reclamado',
    ERROR_630: 'Código de regalo no activo',
    ERROR_631: 'Código de regalo caducado',
    ERROR_633: 'No se puede usar el código de regalo de otro agente',

    home_Maintaining_1: "Mantenimiento",
    home_Maintaining_2: "Esta función está en mantenimiento",
    home_Maintaining_3: "END",
    home_Maintaining_4: "START",

    home_Slots: 'Tragamonedas',
    home_LiveCasino: 'Casino en Vivo',
    home_CountryFiat: 'País y Fiat',
    home_CryptNFTs: 'Cripto y NFTs',

    home_GoTo:'Ir a',

    home_gameout_1: "¿Estás en otro juego en este momento? ¿Te gustaría salir?",
    home_gameout_2: "Cancelar",

     home_BuyFund1:'¿Desea comprar un fondo de crecimiento?',

     home_BetFor:'Apostar por',

     tongzhi_1: "Sugerencia",
     tongzhi_2: "Responder a la sugerencia y al representante.",
     tongzhi_3: "¡Bien hecho! Esto es fantástico.",
     tongzhi_4: "Esperando revisión.",
     tongzhi_5: "Revisión aprobada.",
     tongzhi_6: "Procesando pago.",
     tongzhi_7: "Pago completado exitosamente.",
     tongzhi_8: "Revisión no aprobada.",
     tongzhi_9: "Pago fallido.",
     tongzhi_10: "Retiro fallido.",
     tongzhi_11: "Pagado.",
     tongzhi_12: "Completado.",
     tongzhi_13: "Procesamiento fallido.",
     tongzhi_14: "Comprar",
     tongzhi_16: "Retirar",
     tongzhi_17: "Criptomoneda",
     tongzhi_18: "Moneda Fiat",
     tongzhi_19: "Descargar ",
     tongzhi_20: "El inicio de sesión puede ser recompensado",
     tongzhi_21: "Descargar",
     tongzhi_22: "No hay versión para iOS disponible en este momento",
     tongzhi_23: "Enviar",
     tongzhi_24: "Enviar Consejos",

     home_banner_9: "Carreras de Caballos",
     home_banner_10: "Pelea de Gallos",
     home_banner_11: "Emocionante y emocionante, gane grandes premios",
     home_banner_12: "Sangre caliente y lleno de espíritu combativo",
     
     home_wanfa_1:"Problema de jugabilidad",
     home_wanfa_2:"Problema de transferencia",
     home_wanfa_3:"Otro problema",
     play_not_balance: "No es posible apostar en deportes con eUSDT. ¿Desea cambiar de moneda?",
     qieHuan_balance: "El saldo en la moneda seleccionada no es suficiente para jugar. ¿Deseas cambiar a otra moneda?",
     Provided_by: "De",

     error_0: "Error al reclamar la recompensa",
     error_1: "Error al obtener los datos",
     error_2: "Error al hacer el check-in",
     error_3: "Fallo al girar el plato giratorio",
     error_4: "Fracaso al recibir ayuda financiera",
     error_5: "Error al compartir",
     error_6: "Fallo en la compra del fondo",
     error_7: "No se pueden obtener las información del juego",
     error_8: "No es posible probar este juego",
     error_10: "Fallo en la transferencia de límite",
     error_11: "Operación fallida",
     error_12: "Falló el envío del Código de verificación",
     error_13: "Límite de envío alcanzado",
     error_15: "",
     error_16: "",
     error_17: "",
     error_18: "",
     error_19: "",
     error_20: "",
     error_21: "",
     error_22: "",
     error_23: "",
     error_24: "",
     error_25: "",
     error_26: "",
     error_27: "",
     error_28: "",
     error_29: "",
     error_30: "",
     error_31: "",
     error_32: "",
     error_33: "",
     error_34: "",



    
    };
    
    export default locale;
    
    
