import FuseScrollbars from "@fuse/core/FuseScrollbars";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputBase from "@mui/material/InputBase";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { selectSelectedContactId } from "./store/contactsSlice";
import { selectChat, sendMessage } from "./store/chatSlice";
import { selectUser } from "./store/userSlice";
import { height } from "@mui/system";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import * as RongIMLib from "@rongcloud/imlib-next";
import { selectChatData } from "../../../store/chat";
import { saveChatMsg } from "../../../store/chat/chatThunk";
import { selectUserData } from "app/store/user";
import { getChatToken } from "app/store/user/userThunk";
import { showMessage } from "app/store/fuse/messageSlice";
import { onLogin } from "app/store/user/userThunk";
import { selectUserSetting } from "app/store/user/userSetting";
import { scrollHeightDi } from "../../../util/tools/function";
import { getVIPConfig } from "app/store/activity/activityThunk";
import { selectUserInfo } from "app/store/user/userInfo";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledMessageRow = styled("div")(({ theme }) => ({
  "&.contact": {
    "& .bubble": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      "& .time": {
        marginLeft: 12,
      },
    },
    "&.first-of-group": {
      "& .bubble": {
        borderTopLeftRadius: 20,
      },
    },
    "&.last-of-group": {
      "& .bubble": {
        borderBottomLeftRadius: 20,
      },
    },
  },
  "&.me": {
    paddingLeft: 40,

    "& .bubble": {
      marginLeft: "auto",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      "& .time": {
        justifyContent: "flex-end",
        right: 0,
        marginRight: 12,
      },
    },
    "&.first-of-group": {
      "& .bubble": {
        borderTopRightRadius: 20,
      },
    },

    "&.last-of-group": {
      "& .bubble": {
        borderBottomRightRadius: 20,
      },
    },
  },
  "&.contact + .me, &.me + .contact": {
    paddingTop: 20,
    marginTop: 20,
  },
  "&.first-of-group": {
    "& .bubble": {
      borderTopLeftRadius: 20,
      paddingTop: 13,
    },
  },
  "&.last-of-group": {
    "& .bubble": {
      borderBottomLeftRadius: 20,
      paddingBottom: 13,
      "& .time": {
        display: "flex",
      },
    },
  },
}));

function Chat(props) {
  const { t } = useTranslation("mainPage");
  const dispatch = useDispatch();
  const selectedContactId = useSelector(selectSelectedContactId);
  const chat = useSelector(selectChat);
  const user = useSelector(selectUser);
  const userInfo = useSelector(selectUserInfo).userInfo;
  const chatData = useSelector(selectChatData);
  const userData = useSelector(selectUserData);
  const chatRoomID = `${document.domain}-${selectedContactId}`;
  const chatScroll = useRef(null);
  const [msgList, setMsgList] = useState([]);
  const [messageText, setMessageText] = useState("");
  const userSetting = useSelector(selectUserSetting);
  const [vipLevel, setVipLevel] = useState(0);
  const [head, setHead] = useState(0)
  const [usename, setUsename] = useState('user')
  const [cilckEmoji, setCilckEmoji] = useState(false);
  const [cilckSound, setCilckSound] = useState(false);
  const [showSound, setShowSound] = useState(false);
  const [useid, setUseid] = useState(null)
  const [userToken, setUserToken] = useState('');
  const [alreadySendNum, setAlreadySendNum] = useState(0);
  const [vipConfig, setVipConfig] = useState({});

  useEffect(() => {
    setUserToken(userData.token);
  }, [userData.token]);

  useEffect(() => {
    // vip等级
    if (userSetting?.user?.userID) {
      setUseid(userSetting.user.userID)
    }
    if (userSetting?.user?.faceID) {
      setHead(userSetting.user.faceID);
    }
    if (userSetting?.user?.nickName) {
      setUsename(userSetting.user.nickName);
    }
    if (userSetting?.properties?.MemberOrder) {
      setVipLevel(userSetting.properties.MemberOrder);
    }
    if (userSetting?.setting?.vIPNumLimit) {
      setAlreadySendNum(userSetting?.setting?.vIPNumLimit);
    }
  }, [userSetting]);

  useEffect(() => {
    if (chatData.chatMsg[chatRoomID]) {
      setMsgList(chatData.chatMsg[chatRoomID]);
      scrollToBottom();
    } else {
      setMsgList([]);
    }
  }, [chatData.chatMsg[chatRoomID]]);

  function scrollToBottom() {
    if (!chatScroll.current) {
      return;
    }
    chatScroll.current.scrollTo({
      top: chatScroll.current.scrollHeight,
      behavior: "smooth",
    });
  }

  const onInputChange = (ev) => {
    setMessageText(ev.target.value);
  };

  const [selectedTab, setSelectedTab] = useState(0);
  function handleTabChange(event, value) {
    console.log(value);
    setSelectedTab(value);
  }
  const styless = {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    background: "#b5b7bd"
  }
  // 加入聊天室
  const addChatRoom = () => {
    const count = 30;

    RongIMLib.joinChatRoom(chatRoomID, {
      count: count,
    })
      .then((res) => {
        // 加入聊天室成功
        if (res.code === RongIMLib.ErrorCode.SUCCESS) {
          console.log("加入聊天室成功", res.code, res);
        } else {
          console.log("加入聊天室失败", res.code, res.msg);
        }
      })
      .catch((error) => {
        console.log(error, "失败");
      });
  };

  // 获取聊天室内容
  // const getChatRoomMsg = () => {
  //     const timestamp = 0;
  //     const count = 30;
  //     const order = 1;
  //     RongIMLib.getChatroomHistoryMessages(chatRoomID, {
  //         timestamp: timestamp,
  //         count: count,
  //         order: order
  //     }).then(res => {
  //         // 获取聊天室历史信息成功
  //         if (res.code === 0){
  //             console.log(res.code, res.data, '聊天内容....')
  //         } else {
  //             console.log(res.code, res.msg)
  //         }
  //     }).catch(error => {
  //         console.log(error)
  //     })
  //
  // };

  // 发送消息
  const sendMsg = () => {
    // v3以上才可聊天
    if (vipLevel < 3) {
      dispatch(showMessage({ message: t("message_VIPlevel"), code: 3 }));
      return;
    }

    //如果发送数量超过了限制就不允许发送
    if (vipConfig[vipLevel].ChatEntry >= 0) {
      if (alreadySendNum >= vipConfig[vipLevel].ChatEntry) {
        dispatch(showMessage({ message: t('error_13'), code: 3 }));
        return;
      }
    }

    if (messageText.length === 0) {
      return;
    }

    // 定义消息投送目标会话, 这里定义一个群组类型会话
    const conversation = {
      conversationType: RongIMLib.ConversationType.CHATROOM,
      targetId: chatRoomID,
    };
    // 实例化待发送消息，RongIMLib.TextMessage 为内置文本型消息
    const message = new RongIMLib.TextMessage({
      content: messageText,
      extra: {
        vip: vipLevel,
        username: usename,
        count: head,
      }
    });
    // 配置附加消息
    message.messageExpansion = {
      vip: vipLevel,
      username: usename,
      count: head,
    }
    // 配置属性
    const options = {
      // 重发消息的 messageId, 可以从 onSendBefore 回调返回的 message 对象中 或 返回结果中获取
      messageId: 0,
    };

    RongIMLib.sendMessage(conversation, message, options).then((res) => {
      if (res.code === RongIMLib.ErrorCode.SUCCESS) {
        let saveMsg = {};
        saveMsg[chatRoomID] = [
          {
            userID: userInfo?.user?.id,
            content: messageText,
            sendTime: Date.now(),
            extra: {
              vip: vipLevel,
              username: usename,
              count: head,
            },
            // canIncludeExpansion:true
          },
        ];
        let currAlreadySendNum = alreadySendNum + 1;
        setAlreadySendNum(currAlreadySendNum);
        handleSaveMsg(saveMsg, currAlreadySendNum);
        setMessageText("");

        let chatListScroll = document.getElementById("chat-list-scroll");
        chatListScroll.scrollTop = chatListScroll.scrollHeight;

        // 消息发送成功，可以根据返回结果中的 messageId 字段将列表中的该消息状态改为发送成功。
        console.log("消息发送成功", res.data);
      } else {
        // 消息发送失败，可以根据返回结果中的 messageId 字段将列表中的该消息状态改为发送失败。
        console.log("消息发送失败", res.code, res.msg);
      }
    });
  };

  // 接受消息
  const acceptMsgListener = () => {
    console.log("监听接收消息......");
    const Events = RongIMLib.Events;
    const listener = (evt) => {
      let result = evt.messages;
      console.log(evt, 55555);
      if (result && result.length > 0) {
        let tmpMsg = {};
        console.log(result);
        result.map((item) => {
          if (tmpMsg[item.targetId]) {
            tmpMsg[item.targetId].push({
              userID: item.senderUserId,
              content: item.content.content,
              sendTime: item.sentTime,
              extra: {
                vip: item.content.extra?.vip,
                username: item.content.extra?.username,
                count: item.content.extra?.count
              },
              canIncludeExpansion: true
            });
          } else {
            tmpMsg[item.targetId] = [
              {
                userID: item.senderUserId,
                content: item.content.content,
                sendTime: item.sentTime,
                username: item.extra?.username,
                extra: {
                  vip: item.content.extra?.vip,
                  username: item.content.extra?.username,
                  count: item.content.extra?.count
                },
                canIncludeExpansion: true
              },
            ];
          }
        });
        handleSaveMsg(tmpMsg);
      }
    };
    RongIMLib.addEventListener(Events.MESSAGES, listener);
  };

  const removeListener = () => {
    const Events = RongIMLib.Events;
    RongIMLib.removeEventListeners(Events.MESSAGES);
  };

  // 存储信息
  const handleSaveMsg = (msg, vIPNumLimit = null) => {
    dispatch(saveChatMsg({
      msg,
      vIPNumLimit
    }));
  };
  const chatunlogin = "O9U3nppFaMm51cQdsWtN2/ASF2HloYCm@inly.sg.rongnav.com;inly.sg.rongcfg.com"
  useEffect(() => {
    // 初始话聊天室
    let config = {
      appkey: 'bmdehs6pb2grs',
      canIncludeExpansion: function (message) {
        // 判断是否需要携带扩展信息
        return true;
      }
    }
    RongIMLib.init(config);

    dispatch(getChatToken()).then((res) => {
      let result = res.payload;
      console.log(result);
      let chatToken = result || chatunlogin
      RongIMLib.connect(chatToken).then((res) => {
        if (res.code === 0) {
          console.log("链接聊天室成功!");
          // getChatRoomMsg();
          addChatRoom();
        }
      });
    });
  }, [chatRoomID]);

  useEffect(() => {
    acceptMsgListener();
    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    if (cilckSound) {
      let timer = null
      let startTime = ''
      let endTime = ''
      let tmpTime = 0;
      //长按函数
      const maiKeFeng = document.querySelector('.chatLabel')
      if (maiKeFeng) {
        maiKeFeng.addEventListener('touchstart', function () {
          startTime = +new Date()
          timer = setInterval(function () {
            tmpTime += 100
            if (showSound == false && tmpTime >= 700) {
              setShowSound(true)
            }
          }, 100)
        })

        maiKeFeng.addEventListener('touchend', function () {
          endTime = +new Date()
          clearInterval(timer)

          if ((endTime - startTime) < 700) {
            tmpTime = 0;
            setShowSound(false)
          } else {
            sendMsg();
            setShowSound(false)
          }
        })
      }
    }
  }, [cilckSound])

  useEffect(() => {
    dispatch(getVIPConfig()).then((res) => {
      let result = res.payload;
      if (result) {
        let tmpVipConfig = {}
        result.map((item) => {
          tmpVipConfig[item.VIPLevel] = item
        })
        setVipConfig(tmpVipConfig)
      }
    });
  }, [dispatch]);

  function isFirstMessageOfGroup(item, i) {
    return i === 0 || (chat[i - 1] && chat[i - 1].userID !== item.userID);
  }

  function isLastMessageOfGroup(item, i) {
    return (
      i === chat.length - 1 ||
      (chat[i + 1] && chat[i + 1].userID !== item.userID)
    );
  }

  return (
    <Paper
      className={clsx(
        "flex flex-col relative  shadow  width-360",
        props.className
      )}
      sx={{ background: (theme) => theme.palette.background.default }}
    >
      {selectedContactId === "beec5287-ed50-4504-858a-5dc3f8ce6935" ? (
        <div>
          {useMemo(() => {
            return (
              <div className=" " style={{ height: "100%" }}>
                <div className="flex flex-1 justify-start  mt-8 ml-8">
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons={false}
                    className="min-h-40  backGround-foot borderRadius"
                    classes={{
                      indicator:
                        "flex justify-center bg-transparent w-full h-full",
                    }}
                    TabIndicatorProps={{
                      children: (
                        <Box
                          sx={{ bgcolor: "text.disabled" }}
                          className="w-full h-full rounded-full gamesListBtnBackground-Color opacity-100"
                        />
                      ),
                    }}
                  >
                    <Tab
                      className="text-14 font-semibold min-h-40 min-w-120 Zindex px-12 flex"
                      disableRipple
                      icon={
                        <LazyLoadImage
                          className="min-w-24 min-h-24"
                          src="assets/images/icon/10012.png"
                        ></LazyLoadImage>
                      }
                      iconPosition="start"
                      label={t("home_Single")}
                    />
                    <Tab
                      className="text-14 font-semibold min-h-40 min-w-120 Zindex px-12"
                      disableRipple
                      icon={
                        <LazyLoadImage
                          className="min-w-24 min-h-24"
                          src="assets/images/icon/10013.png"
                        ></LazyLoadImage>
                      }
                      iconPosition="start"
                      label={t("home_Multi")}
                    />
                  </Tabs>
                </div>

                <div
                  style={{
                    borderBottom: "1px solid #334155",
                    marginTop: "10px",
                  }}
                ></div>
                <div className="promotion-list1 mt-8 px-10 pb-6">
                  <div className="flex  align-item">
                    <div className="txtFootColor font-weight800 btnPointer">
                      {t("home_AcceptAnyOdds")}
                    </div>
                    <LazyLoadImage src="assets/images/index/jianTou.png" />
                  </div>
                  <div className="txtFootColor font-weight800 btnPointer">
                    {t("home_ClearAll")}
                  </div>
                </div>

                <div className="" style={{ height: "100%" }}>
                  <div>
                    <div className="betDi marginJuZhong">
                      <div className="promotion-list1 pt-4">
                        <div className="flex lineHeght-24 ml-12 align-item">
                          <div
                            className="borderRadius-5 mr-8 text-align lineHeght-18"
                            style={{
                              width: "32px",
                              height: "18px",
                              backgroundColor: "#D52949",
                            }}
                          >
                            {t("home_Live")}
                          </div>
                          <div
                            className="width-180 txtColorHui overflowHidden"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Fucsovics,Marton-Wat...
                          </div>
                        </div>
                        <LazyLoadImage
                          className="btnPointer txtBrightness"
                          src="assets/images/index/closeBtn.png"
                        ></LazyLoadImage>
                      </div>
                      <div className="mx-10 mt-16">
                        <div className="txtColorHui font-weight500">
                          {t("home_Winner")}
                        </div>
                        <div className="promotion-list1">
                          <div className="fontBold">Watanuki,Yosuke</div>
                          <div className="fontBold txtGreenColor">4.90</div>
                        </div>
                        <div className="promotion-list1 mt-8">
                          <div className="promotion-list1 sportMainKuangColor width-140 height-40 myBetBtnColor betDiColor fontBold pt-12 px-8 borderRadius-5">
                            <div>0.00000000</div>
                            <LazyLoadImage
                              className="max-w-16 max-h-16"
                              src="assets/images/index/btbIcon.png"
                            ></LazyLoadImage>
                          </div>

                          <div>
                            <div className="textAlignRight text-12 txtColorHui">
                              {t("home_EstPayout")}
                            </div>
                            <div className="flex">
                              <div className="textAlignRight txtColorHui mr-4">
                                0.00000000
                              </div>
                              <LazyLoadImage
                                className="max-w-16 max-h-16"
                                src="assets/images/index/btbIcon.png"
                              ></LazyLoadImage>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div></div>

                  <div
                    className="px-10 positionAb"
                    style={{
                      width: "100%",
                      bottom: "0px",
                      backgroundColor: "#1E2633",
                    }}
                  >
                    <div className="promotion-list1 betDiColor mt-12 height-40 align-item px-10">
                      <div>0.00000000</div>
                      <LazyLoadImage
                        className="max-w-16 max-h-16"
                        src="assets/images/index/btbIcon.png"
                      ></LazyLoadImage>
                    </div>

                    <div className="promotion-list1 mt-8 px-8">
                      <div className="txtColorHui">{t("home_TotalOdds")}</div>
                      <div className="flex">
                        <div className="mr-8 fontBold">0.00000000</div>
                        <LazyLoadImage
                          className="max-w-16 max-h-16"
                          src="assets/images/index/btbIcon.png"
                        ></LazyLoadImage>
                      </div>
                    </div>

                    <div className="promotion-list1 mt-4 px-8">
                      <div className="txtColorHui">{t("home_EstPayout")}</div>
                      <div className="flex">
                        <div className="mr-8 fontBold">0.00000000</div>
                        <LazyLoadImage
                          className="max-w-16 max-h-16"
                          src="assets/images/index/btbIcon.png"
                        ></LazyLoadImage>
                      </div>
                    </div>

                    <Button
                      className="width-260 py-10 ml-8 mt-8 mb-12 borderRadius text-18"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        window.location.href = "home";
                      }}
                    >
                      {t("home_RegistertoBet")}
                    </Button>
                  </div>
                </div>
              </div>
            );
          }, [user?.id])}
        </div>
      ) : (
        <div className="chatMr-6" id="liaoTianKuang" style={{ height: "91%", overflowY: "scroll" }}>
          <FuseScrollbars
            ref={chatScroll}
            className="flex flex-1 flex-col overflow-y-auto overscroll-contain"
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <div className="flex flex-col pt-10" id="chat-list-scroll">
              {useMemo(() => { }, [user?.id])}
              {msgList?.length > 0
                ? msgList.map((item, i) => {
                  return (
                    <StyledMessageRow
                      key={i}
                      className={clsx(
                        "flex flex-col grow-0 shrink-0 items-start justify-end relative pl-16 pb-4 pr-4",
                        item.userID === userInfo?.user?.id ? "me" : "contact",
                        // { 'first-of-group': isFirstMessageOfGroup(item, i) },
                        // { 'last-of-group': isLastMessageOfGroup(item, i) },
                        i + 1 === msgList.length && "pb-72"
                      )}
                    >
                      <div
                        style={item.userID == userInfo?.user?.id ? { marginLeft: "auto", marginTop: "10px" } : { marginLeft: "-10px", marginTop: "10px" }}
                      >
                        <div style={{ display: "flex" }}>
                          {item.userID == userInfo?.user?.id ? '' : <div
                            style={{
                              position: "relative",
                              width: "48px",
                              height: "48px",
                              minWidth: "48px",
                              maxWidth: "48px",
                              marginLeft: "6px",
                            }}
                          >
                            <LazyLoadImage
                              style={{
                                width: "48px",
                                height: "48px",
                                position: "absolute",
                                borderRadius: "999px",
                              }}
                              src={
                                "assets/images/head/" +
                                item.extra.count +
                                ".png"
                              }
                            //   "assets/images/head/2.png"
                            ></LazyLoadImage>
                            <div
                              style={item.userID == userInfo?.user?.id ? {
                                width: "28px",
                                height: "18px",
                                position: "absolute",
                                borderRadius: "5px",
                                backgroundColor: "#14C2A3",
                                textAlign: "center",
                                top: "40px",
                                left: "20px",
                              } : {
                                width: "28px",
                                height: "18px",
                                position: "absolute",
                                borderRadius: "5px",
                                backgroundColor: "#14C2A3",
                                textAlign: "center",
                                top: "40px",
                                left: "2px",
                              }}
                            >
                              V{item.extra.vip}
                            </div>
                          </div>}

                          <div style={{ marginLeft: "5px" }}>
                            <div
                              style={item.userID == userInfo?.user?.id ? {
                                width: "100%",
                                overflow: "hidden",
                                textAlign: "right",
                              } : {
                                width: "100%",
                                overflow: "hidden",
                                textAlign: "left",
                              }}
                            >
                              {item.extra.username.length > 7
                                ? item.extra.username.slice(0, 7) + "..."
                                : item.extra.username}
                            </div>
                            <div className="bubble flex relative  p-8 max-w-full width-fit" style={item.userID == userInfo?.user?.id ? null : styless}>
                              <div className="leading-tight whitespace-pre-wrap chatWidth "
                              >
                                {item.content}
                                {/* <div className="promotion-list1 mx-16" style={{ height: "26px", lineHeight: "26px" }}>
                                  <div style={{ fontSize: "16px" }}>
                                    5
                                  </div>
                                  <div style={{ fontSize: "16px" }}>
                                    ''
                                  </div>
                                  <LazyLoadImage style={{ marginLeft: "10px", width: "19px", height: "26px" }}
                                    className=""
                                    src="assets/images/nav/yinLiang1.png"
                                  ></LazyLoadImage>
                                </div> */}


                                {/* <div className="promotion-list1">
                                      <LazyLoadImage
                                        className="w-36 h-36 mt-6"
                                        src="assets/images/nav/4.png"
                                      ></LazyLoadImage>
                                      <div className="ml-10">
                                        <div style={{ fontSize: "12px" }}>
                                          CHECK SPIN BONUS!
                                        </div>
                                        <div
                                          className="flex justifyContent mt-4 align-item"
                                          style={{
                                            background: "#334155",
                                            height: "24px",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <div style={{ fontSize: "14px" }}>
                                            +R$0.005
                                          </div>
                                          <LazyLoadImage
                                            className="w-16 h-16"
                                            src="assets/images/nav/money3.png"
                                          ></LazyLoadImage>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="">
                                    <div>the roll bonus has been awarded to the following players:</div>
                                    <div className="mt-4 py-6" style={{ backgroundColor: "#334155", borderRadius: "5px" }}>

                                      <div className="promotion-list1 px-7 pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@madrugadao</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@adnan78</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@unknown4nayz</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@GoodGrief</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@Knxx</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-7 pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@madrugadao</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@adnan78</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@unknown4nayz</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@GoodGrief</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>
                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@Knxx</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>
                                      <div className="px-6 btnPointer" style={{ width: "94%", margin: "4px auto", backgroundColor: "#1E2633", height: "34px", lineHeight: "34px", borderRadius: "5px" }}>Show more &gt; </div>
                                    </div>
                                  </div> */}

                                {/* <div className="">
                                    <div className="flex">
                                      <LazyLoadImage className="w-40 h-40" src="assets/images/nav/money1b.png"></LazyLoadImage>
                                      <div className="ml-4">
                                        <div className="fontBold">THE CRASH REACHES</div>
                                        <div className="fontBold text-20 sigin-txt-yellow">100X!!</div>
                                      </div>
                                    </div>
                                    <div className="mt-4 py-6" style={{ margin: "4px auto", backgroundColor: "#334155", borderRadius: "5px" }}>
                                      <LazyLoadImage style={{ width: "194px", height: "48px" }} src="assets/images/nav/cat.png"></LazyLoadImage>
                                      <div style={{ textAlign: "center" }}>Here comes the lucky rain</div>
                                    </div>
                                    <div className="mt-8 py-6" style={{ backgroundColor: "#334155", borderRadius: "5px" }}>
                                      <div className="promotion-list1 px-7 pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@madrugadao</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@adnan78</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@unknown4nayz</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@GoodGrief</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@Knxx</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-7 pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@madrugadao</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@adnan78</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@unknown4nayz</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>

                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@GoodGrief</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>
                                      <div className="promotion-list1 px-6  pt-4">
                                        <div className="txtGreenColor chatOverHidden" style={{ width: "110px" }}>@Knxx</div>
                                        <div className="flex">
                                          <div style={{ width: "70px", overflow: "hidden", textAlign: "right" }}>$0.001</div>
                                          <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money3.png"></LazyLoadImage>
                                        </div>
                                      </div>
                                      <div className="px-6 btnPointer" style={{ width: "94%", margin: "4px auto", backgroundColor: "#1E2633", height: "34px", lineHeight: "34px", borderRadius: "5px" }}>Show more &gt; </div>
                                    </div>
                                    <div className="mt-8 btnPointer" style={{ width: "100%", textAlign: "right" }}>Game ID:5809749 &gt;</div>
                                  </div> */}

                                {/*
                                  <div className="">
                                    <div>I'm in luck Today!</div>
                                    <div className="mt-6 p-6" style={{ backgroundColor: "#334155", borderRadius: "5px" }}>

                                      <div className="flex">
                                        <LazyLoadImage className="w-40 h-40" src="assets/images/nav/money1b.png"></LazyLoadImage>
                                        <div className="ml-4">
                                          <div className="">Winning tastes sweet!</div>
                                          <div className="text-12 fontColor">Aztec Magic Deluxe Wow Moment</div>
                                        </div>
                                      </div>

                                      <div className="mt-4 py-6" style={{ margin: "4px auto", backgroundColor: "#1E293B", borderRadius: "5px" }}>
                                        <div className="chatOverHidden" style={{ textAlign: "center" }}>Bet ID:#176145488084 &gt;</div>
                                      </div>

                                      <div className="mt-8 promotion-list1">

                                        <div className="pt-4">
                                          <LazyLoadImage src="assets/images/nav/bigwin.png"></LazyLoadImage>
                                          <div className="mt-6 ml-4" style={{ width: "96px", overflow: "hidden", height: "36px", lineHeight: "36px", textAlign: "center", backgroundColor: "#1E293B", borderRadius: "5px" }}>40.6027x</div>
                                        </div>

                                        <div className="pt-4">
                                          <div className="flex">
                                            <LazyLoadImage className="w-16 h-16" src="assets/images/nav/money1b.png"></LazyLoadImage>
                                            <div className="fontColor" style={{ height: "19px", lineHeight: "19px" }}>Profit</div>
                                          </div>
                                          <div className="mt-6 txtGreenColor" style={{ width: "96px", overflow: "hidden", height: "36px", lineHeight: "36px", textAlign: "center", backgroundColor: "#1E293B", borderRadius: "5px" }}>+921.672634</div>
                                        </div>

                                      </div>

                                    </div>
                                  </div> */}
                              </div>
                              <Typography
                                className="time absolute hidden w-full text-11 mt-8 -mb-24 ltr:left-0 rtl:right-0 bottom-0 whitespace-nowrap"
                                color="text.secondary"
                              >
                                {formatDistanceToNow(
                                  new Date(Math.ceil(item.sendTime / 1000)),
                                  { addSuffix: true }
                                )}
                              </Typography>
                            </div>
                          </div>
                          {item.userID == userInfo?.user?.id ? <div
                            style={{
                              position: "relative",
                              width: "48px",
                              height: "48px",
                              minWidth: "48px",
                              maxWidth: "48px",
                              marginLeft: "6px",
                            }}
                          >
                            <LazyLoadImage
                              style={{
                                width: "48px",
                                height: "48px",
                                position: "absolute",
                                borderRadius: "999px",
                              }}
                              src={
                                "assets/images/head/" +
                                item.extra.count +
                                ".png"
                              }
                            //   "assets/images/head/2.png"
                            ></LazyLoadImage>
                            <div
                              style={item.userID == userInfo?.user?.id ? {
                                width: "28px",
                                height: "18px",
                                position: "absolute",
                                borderRadius: "5px",
                                backgroundColor: "#14C2A3",
                                textAlign: "center",
                                top: "40px",
                                left: "20px",
                              } : {
                                width: "28px",
                                height: "18px",
                                position: "absolute",
                                borderRadius: "5px",
                                backgroundColor: "#14C2A3",
                                textAlign: "center",
                                top: "40px",
                                left: "2px",
                              }}
                            >
                              V{item.extra.vip}
                            </div>
                          </div> : ""}

                        </div>

                      </div>
                    </StyledMessageRow>
                  );
                })
                : null}
            </div>

            {msgList?.length === 0 && (
              // 聊天内容区域
              <div className="flex flex-col flex-1 ">
                <div className="flex flex-col flex-1 items-center justify-center ">
                  <FuseSvgIcon size={128} color="disabled">
                    heroicons-outline:chat
                  </FuseSvgIcon>
                  {/* <div className=' px-4 txtColorHui marginJuZhong' style={{ width: "100%" }}>
                    Start a conversation by typing your message below.
                  </div> */}
                </div>
              </div>
            )}
          </FuseScrollbars>
          {
            chat && (
              <div
                // onSubmit={sendMsg()}
                className="pb-12 px-8 absolute bottom-0 left-0 right-0"
              >
                {/* 输入框 */}
                <Paper className="rounded-24 flex items-center relative shadow phoneBorderChat">
                  {
                    showSound == false ? <InputBase
                      autoFocus={false}
                      id="message-input"
                      className="flex flex-1 grow shrink-0 mx-16 ltr:mr-48 rtl:ml-48 my-8"
                      placeholder={t("home_Typeyourmessage")}
                      onChange={onInputChange}
                      value={messageText}
                    /> :
                      <div className="flex">
                        <span className="text-16 ml-20 activityMoveSound_2" style={{ height: "56px", lineHeight: "56px", color: "#ff0000" }}>
                          ●
                        </span>
                        <span className=" ml-6" style={{ height: "56px", lineHeight: "56px", color: "#ffffff" }}>
                          0:05,1
                        </span>
                        <div className="flex flex-1 grow shrink-0 activityMoveSound_1" style={{ height: "56px", lineHeight: "56px" }}>
                          &lt; Slide to cancel
                        </div>
                      </div>
                  }

                  {!showSound && <LazyLoadImage className="w-24 h-24 absolute ltr:right-0 rtl:left-0 mr-48 btnPointer txtBrightness" src="assets/images/nav/xiaoLian.png" onClick={() => {
                    var config = {
                      size: 32, // 大小, 默认 24, 建议15 - 55
                      url: '//f2e.cn.ronghub.com/sdk/emoji-48.png', // 所有 emoji 的背景图片
                      lang: 'zh', // 选择的语言, 默认 zh
                      // 扩展表情
                      extension: {}
                    };
                    window.RongIMLib.RongIMEmoji.init(config);
                    setCilckEmoji(!cilckEmoji);
                  }}></LazyLoadImage>
                  }

                  {
                    !cilckSound && <IconButton
                      className="absolute ltr:right-0 rtl:left-0 mr-4 "
                      type="submit"
                      size="small"
                      onClick={() => {
                        if (userToken === '') {
                          dispatch(onLogin({
                            type: 'login'
                          }))
                          return
                        } else {
                          sendMsg();
                        }
                        !messageText.length > 0 && setCilckSound(!cilckSound);
                        scrollHeightDi();
                      }}
                    >
                      <FuseSvgIcon
                        className="rotate-90 "
                        style={{ marginLeft: "2px", marginRight: "-2px" }}
                        size={24}
                        color="action"
                      >
                        heroicons-outline:paper-airplane
                      </FuseSvgIcon>
                    </IconButton>
                  }

                  {
                    cilckSound && <IconButton
                      className="absolute ltr:right-0 rtl:left-0 mr-4 chatLabel"
                      type="submit"
                      size="small"
                      onClick={() => {
                        !messageText.length > 0 && setCilckSound(!cilckSound);
                      }}
                    >
                      <FuseSvgIcon
                        className=""
                        style={{ marginLeft: "0px", marginRight: "-2px" }}
                        size={24}
                        color="action"
                      >
                        heroicons-outline:microphone
                      </FuseSvgIcon>
                    </IconButton>
                  }


                  {cilckEmoji && <div className="absolute " style={{ left: "0px", right: "0px", bottom: "70px", margin: "0px auto", width: "260px", height: "180px", backgroundColor: "#1E293B", borderRadius: "5px", boxShadow: "3px 3px 3px rgba(0, 0, 0, .6)" }} >

                    <div className="promotion-list1 p-10 overflow-y-scroll " style={{ height: "180px", justifyContent: "left" }}>
                      {window.RongIMLib.RongIMEmoji.list.map((item) => {
                        return (
                          <div key={item.unicode} className="btnPointer" style={{ fontSize: "24px" }} onClick={() => {
                            setCilckEmoji(!cilckEmoji);
                            setMessageText(messageText + item.emoji)
                          }}>
                            {item.emoji}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  }
                </Paper>
              </div>
            )
          }
        </div >
      )
      }
    </Paper >
  );
}
export default Chat;
