import "../../../styles/index.css";
import "../../../styles/xianStyle.css";
import utils from '../../util/tools/utils'
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState, useEffect, forwardRef, default as React } from 'react';
import Slide from '@mui/material/Slide';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import history from '@history';
import { selectCasinoData } from "../../store/casino";
import { useDispatch, useSelector } from 'react-redux';
import { userProfile } from "../../store/user/userThunk";
import { selectUserData } from "../../store/user";
import AnimateModal from '../../components/FuniModal';
import {
    getSignInConfig,
    getTurnConfig,
    queryBaseEnsureConfig,
    querySignInInfo,
    signInDone,
    doTurn
} from "../../store/activity/activityThunk";


function Cookies(props) {
    const { t } = useTranslation('mainPage');
    const [isHaveCookies, setIsHaveCookies] = useState(window.localStorage.getItem('isHaveCookies') || false);

    const saveCookies = () => {
        setTimeout(() => {
            setIsHaveCookies(true)
            window.localStorage.setItem('isHaveCookies', true);
        }, 400)
    }

    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };
    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };

    const [isClickCookies, setIsClickCookies] = useState(false);

    return (
        <>
            {!isHaveCookies && <div id="cookiesPage" style={{ position: "fixed", bottom: "2%", left: "2%", zIndex: "999" }} className={clsx("", isClickCookies && "phoneMove3")} >
                <motion.div
                    variants={container}
                    initial="hidden"
                    animate="show"
                >
                    <motion.div variants={item} className="cookiesSty px-20 py-20">
                        <div className="cookies-list1">
                            <div className="cookiesTxt1">
                                <div className="cookiesPhoneTxt" style={{ fontSize: "20px", marginBottom: "4px"}}>{t("home_CookiesPolicy")}</div>
                                <div className="cookMaxWh" style={{ fontSize: "14px" }}>
                                    {t("home_isUsingCookies")}
                                </div>
                            </div>
                            <Button className="whitespace-nowrap borderRadius px-24 cookiesBtn  funbetTxt" variant="contained" color="secondary" style={window.localStorage.getItem("lang")=="ph"?{ fontSize: "12px",width:"140px" }:window.localStorage.getItem("lang")=="de"||"tmr"?{ fontSize: "14px" }:{ fontSize: "18px" }}
                                onClick={() => {
                                    saveCookies()
                                    setIsClickCookies(true)
                                }}
                                >
                                {t("home_Accept")}
                            </Button>
                        </div>
                    </motion.div>
                </motion.div>
            </div>}
        </>
    );
}
export default Cookies;


