import "../../../styles/index.css";
import "../../../styles/xianStyle.css";
import utils from "../../util/tools/utils";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { getNewsList } from "../../store/config/configThunk";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState, useEffect, forwardRef, default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAwardOfDownLoadApp, PopUpWindowConfigInfo } from "app/store/user/userThunk";
import { selectUserData } from "app/store/user";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

import { getUrlParam, isMobile, judgeIosOrAndroid } from "../../util/tools/function";
import { showMessage } from "app/store/fuse/messageSlice";

function DownPhoneApp(props) {
  const isApp = getUrlParam('isApp') ?? false;
  const { t } = useTranslation("mainPage");
  const userToken = useSelector(selectUserData).token;
  const dispatch = useDispatch();
  const [isClickDownPhoneApp, setIsClickDownPhoneApp] = useState(false);
  const [isTimeDely, setIsTimeDely] = useState(false);
  const [appStore, setAppStore] = useState(false);
  const [googlePlay, setGooglePlay] = useState(false);
  const [androidApp, setAndroidApp] = useState(false);
  const [isIos, setIsIos] = useState(false);//暂时关闭IOS
  const [isDownUrl, setIsDownUrl] = useState([]);




  // 获取当前日期的字符串表示（格式：YYYY-MM-DD）
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // 补0
    const day = String(today.getDate()).padStart(2, '0'); // 补0
    return `${year}-${month}-${day}`;
  }

  // 检查是否已经登录过今天
  function checkLoginStatus() {
    const currentDate = getCurrentDate();
    const lastLoginDate = sessionStorage.getItem('lastDownLoadAlertDate');

    if (lastLoginDate === currentDate) {
      // 已经登录过今天
      return true;
    } else {
      // 将今天的日期存储到sessionStorage
      sessionStorage.setItem('lastDownLoadAlertDate', currentDate);
      return false;
    }
  }

  const saveDownPhoneApp = () => {
    setTimeout(() => {
      setIsClickDownPhoneApp(true)
    }, 400)
  };

  useEffect(() => {
    if (isMobile()) {
      if (judgeIosOrAndroid() == "ios") {
        setIsIos(true)
      }
    }
  }, []);

  const downLoadApp = () => {
    if (isMobile()) {
      if (judgeIosOrAndroid() == "android") {
        if (googlePlay) {
          window.open(isDownUrl[1])
        } else if (androidApp) {
          window.open(isDownUrl[2])
        }
      } else if (judgeIosOrAndroid() == "ios") {
        dispatch(showMessage({ message: t('tongzhi_22'), code: 3 }))
      }
    }
  };


  const downLoadApkPC = () => {
    if (isDownUrl[2] != "") {
      window.open(isDownUrl[2])
      history.back();
    }
  };

  const downLoadGooglePC = () => {
    if (isDownUrl[1] != "") {
      window.open(isDownUrl[1])
      history.back();
    }
  };

  const downLoadIosPC = () => {
    if (isDownUrl[0] != "") {
      window.open(isDownUrl[0])
      history.back();
    } else {
      dispatch(showMessage({ message: t('tongzhi_22'), code: 3 }))
    }
  };

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };


  useEffect(() => {
    if (userToken) {
      dispatch(checkAwardOfDownLoadApp({
        isApp
      })).then((res) => {
        if (!res.payload) {
          setTimeout(() => {
            if (!checkLoginStatus()) {
              setIsTimeDely(true)
            }
          }, 3000)
        }
      })

      dispatch(PopUpWindowConfigInfo()).then((res) => {
        let result = res.payload
        let downUrl = []
        if (result) {
          if (result.Appstore.statusValue === 0) {
            setAppStore(true)
            downUrl.push(result.Appstore.statusString)
          } else {
            downUrl.push("")
          }
          if (result.Googleplay.statusValue === 0) {
            setGooglePlay(true)
            downUrl.push(result.Googleplay.statusString)
          } else {
            downUrl.push("")
          }
          if (result.AndroidAPP.statusValue === 0) {
            setAndroidApp(true)
            downUrl.push(result.AndroidAPP.statusString)
          } else {
            downUrl.push("")
          }
        }
        setIsDownUrl(downUrl)
      })
    }
  }, [userToken]);


  return (
    <>
      {(isMobile() && !isApp && isTimeDely && !isIos) && (
        <div className={clsx("downAppSty", "downAppSty2", isClickDownPhoneApp && "phoneMove6")} >
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className="subscriptionDiv"
          >
            <motion.div variants={item} className="">
              <div className="flex" style={{ marginTop: "16px" }}>
                <div className="flex" style={{ width: "80%" }}>
                  <img className="" style={{ width: "24px", height: "24px", margin: "22px 12px 0px 12px" }} src="assets/images/index/closeBtn1.png" onClick={() => {
                    saveDownPhoneApp();
                    setIsClickDownPhoneApp(true);
                  }}>
                  </img>
                  <div
                    style={{
                      width: "68px",
                      height: "68px",
                      marginRight: "10px",
                      borderRadius: "10px",
                      backgroundColor: "#334155",
                    }}
                  >
                    <img
                      style={{ width: "64px", height: "64px", marginTop: "2px", marginLeft: "2px" }}
                      src={`${React.$imgUrl}/images/logo/logo-prefix.png`}
                    ></img>
                  </div>
                  <div>
                    <div style={{ fontSize: "22px" }}>FuniBet</div>
                    <div className="flex">
                      <img style={{ width: "16px", height: "16px", marginRight: "3px" }} src="assets/images/index/star.png" />
                      <img style={{ width: "16px", height: "16px", marginRight: "3px" }} src="assets/images/index/star.png" />
                      <img style={{ width: "16px", height: "16px", marginRight: "3px" }} src="assets/images/index/star.png" />
                      <img style={{ width: "16px", height: "16px", marginRight: "3px" }} src="assets/images/index/star.png" />
                      <img style={{ width: "16px", height: "16px", }} src="assets/images/index/star.png" />
                    </div>

                    <div style={{ fontSize: "18px" }}>
                      {t("tongzhi_19")}
                      {(isMobile() && judgeIosOrAndroid() == "android") ? <span>APK</span> : <span>IOS</span>}</div>

                  </div>
                </div>
                <div className="" style={{ height: "68px", width: "20%" }}
                  onClick={() => {
                    downLoadApp();
                    saveDownPhoneApp();
                    setIsClickDownPhoneApp(true);
                  }}>
                  <img style={{ width: "40px", height: "40px", marginTop: "14px" }} src="assets/images/index/download.png">
                  </img>
                </div>
              </div>

            </motion.div>
          </motion.div>
        </div>
      )}

      {(!isMobile() && !isApp && isTimeDely) && (
        <div className={clsx("downAppSty", "downAppSty2", isClickDownPhoneApp && "phoneMove6")} >
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className="subscriptionDiv"
          >
            <motion.div variants={item} className="">
              <div className="flex" style={{ width: "100%", justifyContent: "right" }}>
                <img className="btnPointer" style={{ width: "24px", height: "24px", margin: "12px 12px 0px 0px" }} src="assets/images/index/closeBtn1.png" onClick={() => {
                  saveDownPhoneApp();
                  setIsClickDownPhoneApp(true);
                }} />
              </div>

              <div style={{ fontSize: "22px", width: "100%", textAlign: "center" }}>
                FuniBet
              </div>

              <div className="flex" style={{ width: "100%", marginTop: "12px", justifyContent: "center" }}>
                <div className="flex" >
                  <div
                    style={{
                      width: "90px",
                      height: "90px",
                      borderRadius: "10px",
                      backgroundColor: "#334155",
                    }}
                  >
                    <img
                      style={{ width: "80px", height: "80px", marginTop: "5px", marginLeft: "5px" }}
                      src={`${React.$imgUrl}/images/logo/logo-prefix.png`}
                    ></img>
                  </div>
                </div>
              </div>

              <div className="flex" style={{ width: "100%", justifyContent: "center", marginTop: "14px" }}>
                <div className="flex">
                  <img style={{ width: "16px", height: "16px", marginRight: "3px" }} src="assets/images/index/star.png" />
                  <img style={{ width: "16px", height: "16px", marginRight: "3px" }} src="assets/images/index/star.png" />
                  <img style={{ width: "16px", height: "16px", marginRight: "3px" }} src="assets/images/index/star.png" />
                  <img style={{ width: "16px", height: "16px", marginRight: "3px" }} src="assets/images/index/star.png" />
                  <img style={{ width: "16px", height: "16px", }} src="assets/images/index/star.png" />
                </div>
              </div>

              <div style={{ fontSize: "18px", width: "100%", textAlign: "center", marginTop: "10px" }}>
                {t("tongzhi_19")}<span>APP</span>
              </div>

              {androidApp && <div className="flex" style={{ width: "100%", justifyContent: "center" }}>
                <Button className="whitespace-nowrap borderRadius px-24 py-20  funbetTxt" variant="contained" color="secondary" style={{ width: "64%", margin: "14px 0px 0px 0px", fontSize: "15px" }}
                  onClick={() => {
                    downLoadApkPC();
                    saveDownPhoneApp();
                    setIsClickDownPhoneApp(true);
                  }}>
                  <img style={{ width: "16px", height: "16px", marginRight: "6px" }} src="assets/images/index/android.png"></img>
                  Android APK
                </Button>
              </div>}

              {googlePlay && <div className="flex" style={{ width: "100%", justifyContent: "center" }}>
                <Button className="whitespace-nowrap borderRadius px-24 py-20  funbetTxt" variant="contained" color="secondary" style={{ width: "64%", margin: "14px 0px 0px 0px", fontSize: "15px", backgroundColor: "#334155" }}
                  onClick={() => {
                    downLoadGooglePC();
                    saveDownPhoneApp();
                    setIsClickDownPhoneApp(true);
                  }}>
                  <img style={{ width: "16px", height: "16px", marginRight: "6px" }} src="assets/images/index/google.png"></img>
                  GooglePlay
                </Button>
              </div>}

              <div className="flex" style={{ width: "100%", justifyContent: "center" }}>
                <Button className="whitespace-nowrap borderRadius px-24 py-20  funbetTxt" variant="contained" color="secondary" style={{ width: "64%", margin: "14px 0px 0px 0px", fontSize: "15px", backgroundColor: "#334155" }}
                  onClick={() => {
                    downLoadIosPC();
                    saveDownPhoneApp();
                    setIsClickDownPhoneApp(true);
                  }}>
                  <img style={{ width: "16px", height: "16px", marginRight: "6px" }} src="assets/images/index/ios.png"></img>
                  Appstore
                </Button>
              </div>

            </motion.div>
          </motion.div>
        </div >
      )
      }



    </>
  );
}
export default DownPhoneApp;
