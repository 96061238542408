const locale = {
    home_StakeNFT: 'الرهن النقدي لربح المزيد من NFT',
    home_everyday: 'كل يوم',
      home_Beton: 'الرهان',
      home_mining: 'التعدين',
      home_shareholder: 'كن مساهمًا',
      home_Stake: 'الرهن النقدي',
      home_dividend: 'الحصول على أرباح التوزيع',
      home_Banker: 'كن أحد الشركاء',
      home_SIGNUPAND: 'التسجيل الآن',
      home_GET: 'احصل على',
      home_UPTO: 'يصل إلى',
      home_FuniHash: 'ألعاب بلوكتشين',
      home_Option: 'الخيارات',
      
      home_Reward: ' مكافأة',
      home_Reward1: 'مكافأة',
      home_signUpNow: 'التسجيل الآن',
      home_Casino: 'كازينو',
      home_Sports: 'رياضات',
      home_PROMOTION: 'الترقيات',
      home_Checkin: 'تسجيل الحضور',
      home_Bouns: 'مكافآت',
      home_CheckinBouns: 'مكافأة تسجيل الحضور',
      home_SPIN: 'الدوران',
      home_Relief: 'إغاثة',
      home_Share: 'شارك',
      home_Deposit: 'إيداع',
      home_Fund: 'صندوق',
      home_VIPClub: 'نادي VIP',
      home_FastAndEasy: 'شراء العملات المشفرة بسرعة وسهولة والحصول على مكافأة إيداع تصل إلى USDT',
      home_Game: 'لعبة',
      home_User: 'المستخدم',
      home_Time: 'الوقت',
      home_BetAmount: 'مبلغ الرهان',
      home_Multiplier: 'مضاعف',
      home_Payout: 'دفع',
      home_JoinOur: 'انضم إلى مجتمعنا من عشاق الرياضة وعشاق العملات المشفرة وعشاق المقامرة',
      home_JOIN: 'انضم',
      home_LICENSES: 'التراخيص',
      home_OurPlatforms: 'منصاتنا',
      home_Marketplac: 'السوق',
      home_Howtoearn: 'كيفية الربح',
      home_Games: 'ألعاب',
      home_WhitePaper: 'White Paper',
      home_InvestorDeck: 'منصة المستثمرين',
      home_News: 'أخبار',
      home_Aboutsus: 'عنا',
      home_Industry: 'صناعة',
      home_Roadmap: 'خارطة الطريق',
      home_Partner: 'الشريك',
      home_FAQs: 'أسئلة شائعة',
      home_Affiliate: 'عضو',
      home_Stayintouch: 'ابق على اتصال',
      home_ChatRoom: 'غرفة الدردشة',
      home_Selectacontact: 'اختر جهة اتصال لبدء المحادثة. ',
      home_BetSlip: 'تذكرة الرهان',
      home_Typeyourmessage: 'اكتب رسالتك',
      home_AcceptAnyOdds: 'قبول أي رهان',
      home_ClearAll: 'مسح الكل',
      home_Live: 'المباريات الحية',
      home_Winner: 'الفائز',
      home_EstPayout: 'المدفوعات المقدرة',
      home_TotalOdds: 'إجمالي الرهان',
      home_RegistertoBet: 'التسجيل للرهان',
      home_Single: 'رهان فردي',
      home_Multi: 'رهان مركب',
      home_Checkineveryweek: 'التحقق كل أسبوع يتيح الحصول على مكافأة مقابل ذلك',
      home_Day: 'يوم',
      home_Sunday: 'اليوم 1',
      home_Monday: 'اليوم 2',
      home_Tuesday: 'اليوم 3',
      home_Wednesday: 'اليوم 4',
      home_Thursday: 'اليوم 5',
      home_Friday: 'اليوم 6',
      home_Saturday: 'اليوم 7',
      home_FreeSpin: 'دوران مجاني',
      home_Turntheturntable: 'قم بدوران الدائرة يوميًا للحصول على مكافأة USGT',
      home_DepositFift: 'إيداع',
      home_ANYAMOUNT: 'يمكنك الحصول على مكافأة لأي مبلغ من الإيداع الأول',
      home_DEPOSIT: 'إيداع',
      home_First: 'الأولى',
      home_ONLY: 'فقط',
      home_1ST: '1ST',
      home_2ND: '2ND',
      home_3TH: '3TH',
      home_ANYAMOUNT: 'أي مبلغ!',
      home_EXTRA: 'إضافي',
      home_Relief: 'مساعدة مالية',
      home_Getrelief: 'احصل على مساعدة مالية واحصل على فرصة العودة في اللعبة. ',
      home_YOUCANCLAIM: 'عندما يكون الرصيد أقل من',
      home_YOUCANReceive: '، يمكنك الحصول على مساعدة مالية وتعويض الرصيد إلى',
      home_threeTimes: ' 3 مرات',
      home_everydayTimes: '/ كل يوم!',
      home_Times: 'مرات:',
      home_TotalAssets: 'إجمالي الأصول',
      home_receive: 'استلام',
      home_TODAY: 'اليوم',
      home_Share: 'مشاركة',
      home_PLATFORMSHARING: 'مشاركة على منصات أخرى',
      home_Sharewith: 'يمكنك الحصول على',
      home_SHARINGGIFT: 'هدية مشاركة',
      home_CODE: 'رمز المشاركة',
      home_Selectplatform: 'حدد منصة المشاركة:',
      home_Fund: 'صندوق النمو',
      home_FundBonus: 'مكافأة صندوق النمو',
      home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
      home_BuyFund: 'شراء',
      home_Receivingconditions: 'conditions',
      home_Receivingstatus: 'status',
      home_Received: 'تم الحصول',
      home_noReceived: 'unmet',
      home_ExclusiveBenefits: 'عروض حصرية',
      home_Asawayof: 'تدعو FuniBet جميع اللاعبين VIP للانضمام إلى نادي VIP الخاص بنا، حيث يتوفر هناك الكثير من الهدايا والجوائز والعوائد النقدية الأكبر والمزايا الفريدة. لا تفوت كل المرح والإثارة المذهلة!',
      home_Yourcurrent: 'إجمالي إيداعاتك الحالية',
      home_WhyUpgrade: 'لماذا الانتقال إلى عضوية VIP؟',
      home_VIPhave: 'تتمتع الأعضاء VIP بخدمة مخصصة لمدة 24 ساعة طوال أيام الأسبوع. بالإضافة إلى عائدات النقدية الأساسية والإيداعات، يتمتع أعضاء VIP بأعلى مستوى من الدوران والمكافآت الأسبوعية/الشهرية، بالإضافة إلى أسرع قنوات الإيداع والسحب. ويتاح لأعضاء VIP الفرصة لحضور اجتماعات للاعبين الممتازين والعمل مع فريق FuniBet لتقديم المزيد من المزايا والخصائص للكازينو.',
      home_UpgradeYour: 'ترقية عضويتك إلى VIP',
      home_ASaFunibet: 'باعتبارك عضوًا في نادي FuniBet VIP، يمكنك الحصول على جوائز سخية وهدايا شخصية، لكن هذا ليس كل شيء. كلما لعبت أكثر، ستحصل على مزيد من المزايا الفخمة عند ترقيتك إلى عضوية VIP النخبة، حيث سيتم توفير امتيازات VIP مصممة خصيصًا لك. ',
      home_YOUCANREACH: 'يمكنك الوصول إلى',
      home_LEVELS: 'المستويات',
      home_eachthehigh: 'يمكن للجميع الحصول على مكافأة نقدية بنسبة 25٪ والاستمتاع بجميع مزايا المنصة. انضم إلى FUNIBet واستمتع بتجربة لعب مثالية وخدمة عالية المستوى!',
      home_Cashback: 'العودة نقدًا',
      home_Nowater: '1.No water pumping in the game',
      home_Moregrowth: '2.More growth funds',
      home_Increasein: '3.Increase in the number of benefits',
      home_Doublethenumber: '4.Double the number of turntables',
      home_Doublethesign: '5.Double the sign in reward',
      home_Doublesharingrewards: '6.Double sharing rewards',
      home_DoubletheAffiliate: '7.Double the Affiliate reward',
      home_Rechargerewardamount: '8.Recharge reward amount X 1.5',
      home_ABOUTVIPCLUB: 'عن VIP النادي',
      home_HightopRank: 'يحصل أعلى الترتيبات على مكافأة نقدية بنسبة 25٪ وأفضل الحوافز. انضم إلى BeDao واستمتع بتجربة لعب مثالية وخدمة عالية المستوى!',
      home_Beingamember: 'يعني كونك عضوًا في نادي VIP أنك يمكنك الوصول إلى:',
      home_PersonalVIPmanager: 'مدير VIP الشخصي',
      home_Insights: 'القيادة',
      home_Highercahbackrewards: 'مكافآت عائد نقدي أعلى',
      home_Otheradditionalperks: 'امتيازات إضافية أخرى',
      home_WeeklyandMonthlyBonuses: 'مكافآت أسبوعية وشهرية',
      home_Showhighgaming: 'عرض أنشطة الألعاب العالية والحصول على دعوة لنادي VIP لدينا. ',
      home_BindMobile: 'ربط الهاتف المحمول',
      home_Pleasecheckthe: 'يرجى التحقق من محفظتك للتحقق من ال USGT الحصلت عليها من اللعبة. ',
      home_BINDMOBILENUMBER: 'ربط رقم الهاتف المحمول',
      home_Get1USDT: 'احصل على 1 USDT',
      home_Bind: 'ربط',
      home_GiftCode: 'كود الهدايا',
      home_EnteryourGiftCode: 'يرجى إدخال رمز الهدية الخاص بك',
      home_EnterCode: 'أدخل الرمز',
      home_Paste: 'لصق',
      home_confirms: 'تأكيد',
      home_BuyCrypto: 'شراء',
      home_SignedIn: 'تم تسجيل الدخول',
      home_Wallet: 'محفظة',
      home_turnTimes: 'الدورات: ',
      home_bonus: ' مكافأة',
      home_FirstDepositBonus: 'مكافأة الإيداع الأول',
      home_Hi: 'مرحبا ',
      home_welcomeAboard: 'مرحبا بك على متن السفينة',
      
      
      
      home_CommissionRules: 'قواعد العمولة',
      home_AffiliateTerms: 'شروط العضوية',
      home_AffiliateDashboard: 'لوحة العضوية',
      home_AFFILIATE: 'عضوية',
      home_COMMISSONREADYEVERYDAY: 'العمولة جاهزة في أي وقت $1,000 مجرد إحالة',
      home_MYREFERRALCODE: 'رمز الإحالة الخاص بي',
      home_MYREFERRALLink: 'رابط الإحالة الخاص بي',
      home_AffiliateReward: 'مكافأة العضوية',
      home_EXTRA: 'إضافي',
      home_USDREWARDS: 'مكافآت الدولار الأمريكي',
      home_USDRewardsRules: 'قواعد مكافآت الدولار الأمريكي',
      home_JUSAREFERRALAWAY: 'مجرد إحالة واحدة',
      home_NewlyAvailableRewards: 'مكافآت جديدة',
      home_ReceivedMao: 'تم الاستلام:',
      home_Locked: 'مقفل:',
      home_Yourcurrentfriends: 'سيسمح لك أصدقاؤك الحاليون النشطاء بفتح مزيد من المكافآت',
      home_Withdraw: 'سحب',
      home_MY: 'بلدي',
      home_COMMISSIONREWARDS: 'مكافآت العمولة',
      home_CommissionRewardRules: 'قواعد مكافآت العمولة',
      home_NewlyAvailableCommissionRewards: 'مكافآت العمولة الجديدة',
      home_CommissionYouhaveReceivedinTotal: 'تم الحصول على العمولة:',
      home_REWARDSSENTTODATE: 'مجموع الجوائز',
      home_USDrewardssenttodate: "إجمالي مكافأة بالدولار",
      home_Commissionsenttodatet: 'تاريخ إرسال العمولة',
      home_Areyouablogger: 'هل أنت مدوّن بمتابعين وقرّاء كبار؟ نقدّم لك برنامج تابع خاص يوفّر لك مكافآت إحالة أكبر. سنقوم بإعداد برنامج مستقل يتضمّن شروطًا خاصة. اتصل بمديرنا لمناقشة الأحكام.',
      home_onlythoseusers: 'تنويه هام: يمكن للمستخدمين الذين تم طلبهم من قبل المسؤولين والذين تمت موافقة المسؤولين على مشاركتهم في هذا البرنامج.',
      home_notice: 'إشعار',
      home_bindPhone: 'ربط الهاتف',
      home_SignIn: 'تسجيل الدخول',
      home_SignUp: 'التسجيل',
      home_SignOut: 'تسجيل الخروج',
      home_CookiesPolicy: 'سياسة الكوكيز',
      home_isUsingCookies: 'تستخدم Funibet.com ملفات تعريف الارتباط (الكوكيز) لتوفير خدمة أفضل لكل مستخدم. باستخدام موقعنا الإلكتروني، فإنك توافق على استخدام ملفات تعريف الارتباط. يرجى الاطلاع على سياسة الخصوصية وشروط الخدمة.',
      home_SETTING: 'الإعدادات',
      home_LiveSupport: 'الدعم الفني المباشر',
      home_Accept: 'موافق',
      home_FrequentlyAskedQuestions: 'الأسئلة الشائعة',
      home_SiteUnnderConstruction: 'الموقع تحت الإنشاء',
      home_ComingSoon: 'قريباً',
      home_UserId: 'المعرف: ',
      home_UserName: 'الاسم: ',
      home_Bonusincreased: "زيادة المكافأة+",
      home_SPINBONUSTOTAL: 'مجموع جوائز الدوران',
      home_WIN: 'WIN:',
      home_In: 'من',
      home_Outoftime: 'انتهت عدد مرات اللعب اليومية',
      
      game_search: 'البحث',
      game_SortBy: 'تصنيف حسب',
      game_Created: 'تاريخ الإنشاء',
      game_Lobby: 'الردهة',
      game_LiveCasino: 'كازينو مباشر',
      game_FeaturedSlots: 'فتحات مميزة',
      game_GameShows: 'برامج الألعاب',
      game_FuniBetOriginals: 'ألعاب FuniBet الأصلية',
      game_BigWins: 'الفوز الكبير',
      game_LcukyWins: 'الفوز الحظوظ',
      game_Challenges: 'التحديات',
      game_Description: 'الوصف',
      game_Slots: 'فتحات',
      game_Rank: 'الترتيب',
      game_User: 'المستخدم',
      game_Date: 'التاريخ',
      game_BetAmount: 'المبلغ المراهن',
      game_lucky: 'أعلى مضاعفة',
      game_Multiplier: 'مضاعف',
      game_Payout: 'المدفوعات',
      game_Recommend: 'توصي',
      game_Providers: 'الموردين',
      game_startGame: 'ابدأ اللعبة',
      game_balance: 'الرصيد',
      game_provider: 'العلامات التجارية',
      game_Selectyourdisplay: 'اختر عرض الرصيد الخاص بك',
      game_Balancein: 'الرصيد في',
      game_FunPlay: 'وضع تجريبي',
      game_RealPlay: 'وضع اللعب الحقيقي',
      game_DispLaying: "عرض",
      game_LoadMore: "تحميل المزيد",
      game_NoData: "ليس هناك ألعاب من هذا النوع مؤقتًا",

    message_SigningIn:"التسجيل بواسطة JWT",
    message_CourseSaved:"تم حفظ الدورة،",
    message_PleaseBind:"يجب ربط الهاتف للمشاركة في الفعالية. هل ترغب في الربط؟",
    message_UserData:"تم حفظ بيانات المستخدم بواسطة api",
    message_Success:"نجاح،",
    message_VIPlevel:"مستوى العضويةVIP أقل من V3",
    message_ListOrder:"م حفظ ترتيب القائمة،",
    message_CardOrder :"تم حفظ ترتيب البطاقة،",
    message_HiHow:"مرحبًا، كيف حالك؟",

    Funibet_web: 'Funibet-3.0العملا, الرقمية , الرهان ,الرياضة , الرياضات الإلكترونية , الفتحات , الكازينو المباشر ,البوكر,ويب',

    home_ThereAre:"لا توجد إشعارات حاليًا.",

    play_no_balance: "الرصيد الحالي للعملة غير كاف، يرجى التحويل إلى عملة أخرى أو إعادة الشحن.",
    
    home_News_1: 'أخبار',
    home_News_2: 'حدث',
    home_News_3: 'عملة مشفرة',
    home_News_4: 'ألعاب',
    
    home_SuggRep_1: 'اقتراحات وتقارير',
    home_SuggRep_2: 'الاقتراحات والتقارير',
    home_SuggRep_3: 'شاركنا بتعليقاتك لتحسين الخدمة!',
    home_SuggRep_4: 'تقرير عن موقع يبيع أدوات للعبة داخلية.',
    home_SuggRep_5: 'أدخل اقتراحاتك أو أرسل تقريرًا',
    home_SuggRep_6: 'لقطة شاشة مثالية',
    home_SuggRep_7: 'مكافأة للاقتراحات والتقارير المعتمدة!',
    home_SuggRep_8: 'إرسال',
    
    home_subscription_1: 'هل ترغب في الاشتراك في الإشعارات الفورية؟',
    home_subscription_2: 'يمكنك إلغاء الاشتراك في أي وقت.',
    home_subscription_3: 'إشعارات WonderPush',
    home_subscription_4: 'لاحقًا',
    home_subscription_5: 'الاشتراك',
    
    home_Affiliate_1: 'اتصل بنا',
    home_Affiliate_2: 'كل صديق تدعوه، سيجلب لك 10U، كلما دعوت أكثر، حصلت على المزيد!',
    home_Affiliate_3: 'تلغرام',
    home_Affiliate_4: 'البريد الإلكتروني',

    home_banner_1: 'ألعاب العملات الرقمية',
    home_banner_2: 'دعم 100+ عملة رقمية و 50+ NFT',
    home_banner_3: 'المراهنة اللامركزية',
    home_banner_4: 'المراهنة بعقد ذكي من محفظة لامركزية',
    home_banner_5: 'الرهان للربح',
    home_banner_6: 'الرهان والتعدين والاستثمار للحصول على أرباح مستقرة',
    home_banner_7: 'Web3.0 + كازينو',
    home_banner_8: 'اللاعب هو أيضًا المصرف والمالك',
    
    home_EventDetails: 'تفاصيل',

    home_vip_1: "1.احصل على أرباح بالتسجيل+",
    home_vip_2: "2.احصل على أرباح بالدوران+",
    home_vip_3: "3.احصل على أرباح بالمشاركة+",
    home_vip_4: "4.احصل على أرباح بالشحن+",
    home_vip_5: "5.فتح المحادثة، يمكن إرسال رسائل يوميًا",
    home_vip_6: "الرسائل",

    ERROR_628: 'تم استلام هذا الرمز الهدية بالفعل',
    ERROR_629: 'تم استلام جميع نسخ هذا الرمز الهدية',
    ERROR_630: 'رمز الهدية غير نشط',
    ERROR_631: 'انتهت صلاحية رمز الهدية',
    ERROR_633: 'لا يمكن استخدام رمز الهدية الخاص بوكيل آخر',

    home_Maintaining_1: 'الصيانة',
    home_Maintaining_2: 'هذه الميزة تحت الصيانة',
    home_Maintaining_3: 'النهاية',
    home_Maintaining_4:'يبدأ',

    home_Slots: 'الفتحات',
    home_LiveCasino: 'كازينو مباشر',
    home_CountryFiat: 'البلد والعملة الحالية',
    home_CryptNFTs: 'العملات الرقمية والNFTs',

    home_GoTo:'انتقل إلى',

    home_gameout_1: "هل أنت في لعبة أخرى؟ هل ترغب في الخروج؟",
    home_gameout_2: "إلغاء",

    home_BuyFund1:'هل ترغب في شراء صندوق النمو؟',

    home_BetFor:'الرهان على',

    tongzhi_1: "اقتراح",
    tongzhi_2: "الرد على الاقتراح والممثل.",
    tongzhi_3: "عمل رائع! هذا رائع جدًا.",
    tongzhi_4: "في انتظار المراجعة.",
    tongzhi_5: "تمت المراجعة بنجاح.",
    tongzhi_6: "جاري التحويل.",
    tongzhi_7: "تم التحويل بنجاح.",
    tongzhi_8: "رفض المراجعة.",
    tongzhi_9: "فشل التحويل.",
    tongzhi_10: "فشل السحب.",
    tongzhi_11: "تم الدفع.",
    tongzhi_12: "اكتمل.",
    tongzhi_13: "فشل المعالجة.",
    tongzhi_14: "شراء",
    tongzhi_15: "إلى",
    tongzhi_16: "سحب",
    tongzhi_17: "عملة افتراضية",
    tongzhi_18: "عملة وطنية",
    tongzhi_19: "تحميل ",
    tongzhi_20: "تسجيل الدخول قابل للمكافأة",
    tongzhi_21: "تنزيل",
    tongzhi_22: "لا توجد نسخة لنظام iOS متاحة حاليًا",
    tongzhi_23: "إرسال",
    tongzhi_24: "إرسال النصائح",

    home_banner_9: "سباق الخيول",
    home_banner_10: "مباراة الديك",
    home_banner_11: "مثيرة ومثيرة، اربح جوائز كبيرة",
    home_banner_12: "حماسي ومشحون بالحماس",

    home_wanfa_1:"مشكلة في اللعبة",
    home_wanfa_2:"مشكلة في التحويل",
    home_wanfa_3:"مشكلة أخرى",
    play_not_balance: "الرياضة لا يمكن استخدام eUSDT، هل تريد التبديل إلى عملة أخرى؟",
    qieHuan_balance: "رصيد العملة المحددة غير كافٍ للعب، هل ترغب في التبديل إلى عملة أخرى؟",
    Provided_by: "من",

    error_0: "فشل في جلب الجائزة",
    error_1: "فشل في الحصول على البيانات",
    error_2: "فشل في التسجيل",
    error_3: "فشلت دوران الدوار",
    error_4: "فشل في استلام المساعدات المالية",
    error_5: "فشل المشاركة",
    error_6: "فشل شراء الصندوق",
    error_7: "لا يمكن الحصول على معلومات اللعبة",
    error_8: "لا يمكن تجربة هذه اللعبة",
    error_10: "فشل في نقل الحد",
    error_11: "فشل العملية",
    error_12: "فشل إرسال رمز التحقق",
    error_13: "تم الوصول إلى الحد الأقصى للإرسال",
    error_15: "",
    error_16: "",
    error_17: "",
    error_18: "",
    error_19: "",
    error_20: "",
    error_21: "",
    error_22: "",
    error_23: "",
    error_24: "",
    error_25: "",
    error_26: "",
    error_27: "",
    error_28: "",
    error_29: "",
    error_30: "",
    error_31: "",
    error_32: "",
    error_33: "",
    error_34: "",
    
    };
export default locale;
    
    
