const locale = {
    home_StakeNFT: 'Stake NFT dapatkan gratis',
    home_everyday: 'Setiap hari',
    home_Beton: 'Berjudi',
    home_mining: 'Penambangan',
    home_shareholder: 'Menjadi pemegang saham',
    home_Stake: 'Stake',
    home_dividend: 'Dapatkan dividen keuntungan',
    home_Banker: 'Menjadi salah satu mitra',
    home_SIGNUPAND: 'Daftar',
    home_GET: 'Dapatkan',
    home_UPTO: 'Hingga',
    home_FuniHash: 'Permainan blockchain',
    home_Option: 'Opsi',
    
    home_Reward: ' Hadiah',
    home_Reward1: 'Hadiah',
    home_signUpNow: 'Daftar',
    home_Casino: 'Kasino',
    home_Sports: 'Olahraga',
    home_PROMOTION: 'Promosi',
    home_Checkin: 'Check-in',
    home_Bouns: 'Bonus',
    home_CheckinBouns: 'Bonus Check-in',
    home_SPIN: 'Putar',
    home_Relief: 'Bantuan',
    home_Share: 'Bagikan',
    home_Deposit: 'Deposit',
    home_Fund: 'Dana',
    home_VIPClub: 'Klub VIP',
    home_FastAndEasy: 'Mudah dan cepat untuk membeli kripto hingga bonus deposit USDT',
    home_Game: 'Permainan',
    home_User: 'Pengguna',
    home_Time: 'Waktu',
    home_BetAmount: 'Jumlah Taruhan',
    home_Multiplier: 'Pengganda',
    home_Payout: 'Pembayaran',
    home_JoinOur: 'Bergabunglah dengan komunitas penggemar olahraga, pecinta kripto, dan penjudi kami',
    home_JOIN: 'Gabung',
    home_LICENSES: 'Lisensi',
    home_OurPlatforms: 'Platform Kami',
    home_Marketplac: 'Marketplace',
    home_Howtoearn: 'Cara Mendapatkan',
    home_Games: 'Permainan',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'Investor Deck',
    home_News: 'Berita',
    home_Aboutsus: 'Tentang Kami',
    home_Industry: 'Industri',
    home_Roadmap: 'Roadmap',
    home_Partner: 'Mitra',
    home_FAQs: 'FAQ',
    home_Affiliate: 'Afiliasi',
    home_Stayintouch: 'Tetap Berhubungan',
    home_ChatRoom: 'Ruang Obrolan',
    home_Selectacontact: 'Pilih kontak untuk memulai obrolan.',
    home_BetSlip: 'Tiket Taruhan',
    home_Typeyourmessage: 'Ketik pesan Anda',
    home_AcceptAnyOdds: 'Terima peluang apa pun',
    home_ClearAll: 'Bersihkan semua',
    home_Live: 'Langsung',
    home_Winner: 'Pemenang',
    home_EstPayout: 'Perkiraan Pembayaran',
    home_TotalOdds: 'Total Taruhan',
    home_RegistertoBet: 'Daftar untuk Bertaruh',
    home_Single: 'Taruhannya Sendiri',
    home_Multi: 'Taruhannya Ganda',
    home_Checkineveryweek: 'Daftar setiap minggu untuk mendapatkan hadiah yang sesuai.',
    home_Day: 'Hari',
    home_Sunday: 'Hari ke-1',
    home_Monday: 'Hari ke-2',
    home_Tuesday: 'Hari ke-3',
    home_Wednesday: 'Hari ke-4',
    home_Thursday: 'Hari ke-5',
    home_Friday: 'Hari ke-6',
    home_Saturday: 'Hari ke-7',
    home_FreeSpin: 'Putaran Gratis',
    home_Turntheturntable: 'Putar roda setiap hari untuk memenangkan hadiah USGT.',
    home_DepositFift: 'Bonus Deposit',
    home_ANYAMOUNT: 'Anda bisa mendapatkan bonus deposit pertama dengan jumlah berapa pun',
    home_DEPOSIT: 'Deposit',
    home_First: 'Pertama',
    home_ONLY: 'Hanya',
    home_1ST: '1ST',
    home_2ND: '2ND',
    home_3TH: '3TH',
    home_ANYAMOUNT: 'APA PUN JUMLAHNYA!',
    home_EXTRA: 'Tambahan',
    home_Relief: 'Bantuan',
    home_Getrelief: 'Dapatkan bantuan dan kesempatan untuk memulihkan keseimbangan Anda',
    home_YOUCANCLAIM: 'Ketika saldo Anda kurang dari',
    home_YOUCANReceive: ', Anda bisa mendapatkan bantuan hingga saldo mencapai',
    home_threeTimes: ' 3 kali',
    home_everydayTimes: '/setiap hari!',
    home_Times: 'kali:',
    home_TotalAssets: 'Total Aset',
    home_receive: 'Dapatkan',
    home_TODAY: 'Hari Ini',
    home_Share: 'Bagikan',
    home_PLATFORMSHARING: 'Berbagi ke platform lain',
    home_Sharewith: 'Berbagi setiap hari dapatkan',
    home_SHARINGGIFT: 'Hadiah Berbagi',
    home_CODE: 'Kode berbagi',
    home_Selectplatform: 'Pilih platform berbagi:',
    home_Fund: 'Dana',
    home_FundBonus: 'Fund Bonus',
    home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
    home_BuyFund: 'Membeli',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'Diterima',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'Keuntungan Eksklusif',
    home_Asawayof: 'Sebagai cara untuk mengekspresikan terima kasih dan penghargaan kami kepada pemain VIP, FuniBet mengundang Anda untuk bergabung dengan Klub VIP kami, di mana terdapat hadiah, hadiah gratis, pengembalian tunai yang lebih tinggi, dan fitur unik. Jangan lewatkan semua kesenangan yang menyenangkan dan menakjubkan! ',
    home_Yourcurrent: 'Total deposit Anda saat ini',
    home_WhyUpgrade: 'Mengapa harus naik ke VIP? ',
    home_VIPhave: 'VIP menikmati layanan perawatan 24 * 7 eksklusif. Selain rebate dan deposit dasar, VIP juga menikmati putaran tertinggi, bonus mingguan / bulanan, dan jalur deposit / penarikan tercepat. VIP memiliki kesempatan untuk bergabung dengan acara komunikasi pemain teratas dan membangun kasino dengan mengusulkan fitur dan fungsionalitas baru untuk tim FuniBet. ',
    home_UpgradeYour: 'Tingkatkan level VIP Anda',
    home_ASaFunibet: 'Sebagai anggota klub VIP FuniBet, Anda dapat memperoleh hadiah dan hadiah yang luar biasa, tetapi itu belum semuanya. Semakin Anda bermain, semakin Anda naik ke level VIP elit, semakin banyak hadiah mewah yang Anda terima dan FuniBet akan memberikan hak istimewa VIP yang dipersonalisasi untuk Anda. ',
    home_YOUCANREACH: 'Anda dapat naik ke',
    home_LEVELS: 'Tingkat',
    home_eachthehigh: 'Setiap orang dapat menerima bonus cashback 25% dan menikmati semua manfaat platform. Bergabunglah dengan FUNIBET, nikmati pengalaman bermain game terbaik, dan layanan berkualitas tinggi!',
    home_Cashback: 'Cashback',
    home_Nowater: '1. Tidak ada lagi potongan dalam permainan',
    home_Moregrowth: '2. Lebih banyak dana tumbuh',
    home_Increasein: '3. Jumlah manfaat meningkat',
    home_Doublethenumber: '4. Jumlah putaran meningkat dua kali lipat',
    home_Doublethesign: '5. Jumlah tanda tangan meningkat dua kali lipat',
    home_Doublesharingrewards: '6. Hadiah berbagi meningkat dua kali lipat',
    home_DoubletheAffiliate: '7. Hadiah afiliasi meningkat dua kali lipat',
    home_Rechargerewardamount: '8. Jumlah hadiah pengisian ulang menjadi 1,5 kali lipat',
    home_ABOUTVIPCLUB: 'Tentang Klub VIP',
    home_HightopRank: 'Peringkat tinggi = Bonus cashback 25% + manfaat terbaik! Bergabunglah dengan BeDao sekarang!',
    home_Beingamember: 'Menjadi anggota Klub VIP berarti Anda memiliki akses ke:',
    home_PersonalVIPmanager: 'Manajer VIP Pribadi',
    home_Insights: 'Wawasan',
    home_Highercahbackrewards: 'Cashback yang lebih tinggi',
    home_Otheradditionalperks: 'Keuntungan tambahan lainnya',
    home_WeeklyandMonthlyBonuses: 'Bonus mingguan dan bulanan',
    home_Showhighgaming: 'Tampilkan acara game tinggi dan terima undangan untuk bergabung dengan Klub VIP kami. ',
    home_BindMobile: 'Mengikat',
    home_Pleasecheckthe: 'Silakan periksa USGT yang Anda dapatkan dari game di dompet Anda.',
    home_BINDMOBILENUMBER: 'Ikatan Nomor Ponsel',
    home_Get1USDT: 'Dapatkan 1 USDT',
    home_Bind: 'Mengikat',
    home_GiftCode: 'Kode hadiah',
    home_EnteryourGiftCode: 'Masukkan kode hadiah Anda',
    home_EnterCode: 'Masukkan Kode',
    home_Paste: 'Paste',
    home_confirms: 'confirms',
    home_BuyCrypto: 'membeli',
    home_SignedIn: 'Masuk',
    home_CommissionRules: 'Aturan Komisi',
    home_AffiliateTerms: 'Persyaratan Afiliasi',
    home_AffiliateDashboard: 'Dasbor Afiliasi',
    home_AFFILIATE: 'Afiliasi',
    

    
    home_COMMISSONREADYEVERYDAY: 'Siap Komisi Setiap Hari.',
    home_MYREFERRALCODE: 'Kode referral saya',
    home_MYREFERRALLink: 'Tautan referral saya',
    home_AffiliateReward: 'Hadiah Afiliasi',
    home_EXTRA: 'Ekstra',
    home_USDREWARDS: 'Hadiah USD',
    home_USDRewardsRules: 'Aturan Hadiah USD',
    home_JUSAREFERRALAWAY: 'Hanya dengan satu referensi',
    home_NewlyAvailableRewards: 'Hadiah Terbaru',
    home_ReceivedMao: 'Sudah diterima:',
    home_Locked: 'Terkunci:',
    home_Yourcurrentfriends: 'Teman Anda yang aktif saat ini akan membuka lebih banyak hadiah untuk Anda',
    home_Withdraw: 'Tarik',
    home_MY: 'Saya',
    home_COMMISSIONREWARDS: 'Hadiah Komisi',
    home_CommissionRewardRules: 'Aturan Hadiah Komisi',
    home_NewlyAvailableCommissionRewards: 'Hadiah Komisi Terbaru',
    home_CommissionYouhaveReceivedinTotal: 'Komisi yang diterima:',
    home_REWARDSSENTTODATE: 'Hadiah yang Dikirimkan hingga Saat Ini',
    home_USDrewardssenttodate: 'Hadiah USD yang Dikirimkan hingga Saat Ini',
    home_Commissionsenttodatet: 'Komisi Dikirimkan hingga Saat Ini',
    home_Areyouablogger: 'Apakah Anda seorang blogger dengan banyak pembaca dan pengikut? Kami menawarkan program afiliasi khusus dengan penghargaan referensi yang lebih besar untuk Anda. Kami akan menyusun program terpisah dengan persyaratan khusus. Silakan hubungi manajer kami untuk membahas persyaratan.',
    home_onlythoseusers: 'Catatan penting: Hanya pengguna yang diminta dan disetujui oleh administrator yang dapat bergabung dengan program ini.',
    home_notice: 'Pemberitahuan',
    home_bindPhone: 'Mengikat telepon',
    home_SignIn: 'Masuk',
    home_SignUp: 'Daftar',
    home_SignOut: 'Keluar',
    home_CookiesPolicy: 'Kebijakan Cookies',
    home_isUsingCookies: 'Funibet.com menggunakan cookies untuk memberikan layanan terbaik. Dengan menggunakan situs web kami, Anda menyetujui penggunaan cookie. Lihat kebijakan privasi dan syarat layanan kami.',
    home_SETTING: 'Pengaturan',
    home_LiveSupport: 'Dukungan Langsung',
    home_Accept: 'Setuju',
    home_FrequentlyAskedQuestions: 'Pertanyaan yang Sering Diajukan',
    home_SiteUnnderConstruction: 'Situs dalam Pembangunan',
    home_ComingSoon: 'Segera Hadir',
    home_Wallet: 'Wallet',
    home_UserId: 'ID:',
    home_UserName: 'Nama:',
    home_turnTimes: 'Putaran:',
    home_Bonusincreased: "Bonus tambahan +",
    home_bonus: 'Bonus',
    home_SPINBONUSTOTAL: 'Total Bonus Putaran',
    home_WIN: 'WIN:',
    home_In: 'Dalam',
    home_FirstDepositBonus: 'Bonus Deposit Pertama',
    home_Hi: 'Hai',
    home_welcomeAboard: 'Selamat Datang Kembali',
    home_Outoftime: 'Waktu Anda hari ini telah habis.',
    
    game_search: 'Cari',
    game_SortBy: 'Urutkan berdasarkan',
    game_Created: 'Dibuat pada',
    game_Lobby: 'Lobi',
    game_LiveCasino: 'Kasino Langsung',
    game_FeaturedSlots: 'Slot Unggulan',
    game_GameShows: 'Acara Permainan',
    game_FuniBetOriginals: 'FuniBet Original',
    game_BigWins: 'Besar',
    game_LcukyWins: 'Beruntung',
    game_Challenges: 'Tantangan',
    game_Description: 'Deskripsi',
    game_Slots: 'Slot',
    game_Rank: 'Peringkat',
    game_User: 'Pengguna',
    game_Date: 'Tanggal',
    game_BetAmount: 'Taruhan',
    game_lucky: 'Multiplier maksimum',
    game_Multiplier: 'Multiplier',
    game_Payout: 'Pembayaran',
    game_Recommend: 'Rekomendasikan',
    game_Providers: 'Penyedia',
    game_startGame: 'mulai',
    game_balance: 'Saldo',
    game_provider: 'Tag',
    game_Selectyourdisplay: 'Pilih tampilan saldo Anda',
    game_Balancein: 'Saldo dalam',
    game_FunPlay: 'Main Fun',
    game_RealPlay: 'Uang Asli',
    game_DispLaying: "Menampilkan",
    game_LoadMore: "Muat lebih banyak",
    game_NoData: 'Tidak ada permainan tipe ini sementara waktu',
    
    message_SigningIn:"Masuk dengan JWT",
    message_CourseSaved:"Kursus Tersimpan",
    message_PleaseBind:"Untuk berpartisipasi dalam kegiatan ini, Anda perlu mengikat ponsel Anda. Apakah Anda ingin mengikatnya?",
    message_UserData:"Data pengguna tersimpan dengan api",
    message_Success:"sukses",
    message_VIPlevel:"Level VIP kurang dari V3",
    message_ListOrder:"Pesanan Daftar Tersimpan",
    message_CardOrder :"Pesanan Kartu Tersimpan",
    message_HiHow:"Hai, apa kabar?",

    Funibet_web: 'Funibet-Web3.0,Mata uang kripto,Taruhan,Olahraga,E-sports,Mesin slot,Kasino langsung,Poker',

    home_ThereAre: "Tidak ada pemberitahuan untuk saat ini.",

    play_no_balance: "Saldo mata uang saat ini tidak mencukupi, silakan beralih mata uang atau isi ulang",
    
    home_News_1: 'Berita',
    home_News_2: 'Acara',
    home_News_3: 'Kripto',
    home_News_4: 'Gaming',
    
    home_SuggRep_1: 'Saran & Laporan',
    home_SuggRep_2: 'Saran & Laporan',
    home_SuggRep_3: 'Bagikan umpan balik untuk meningkatkan!',
    home_SuggRep_4: 'Laporkan situs web penjualan dalam game.',
    home_SuggRep_5: 'Masukkan saran atau kirim laporan',
    home_SuggRep_6: 'Tangkapan layar contoh',
    home_SuggRep_7: 'hadiah untuk saran & laporan yang diadopsi!',
    home_SuggRep_8: 'kirimkan',
    
    home_subscription_1: 'Apakah Anda ingin berlangganan pemberitahuan push?',
    home_subscription_2: 'Anda selalu dapat berhenti berlangganan kapan saja.',
    home_subscription_3: 'Web push oleh WonderPush',
    home_subscription_4: 'Nanti',
    home_subscription_5: 'konfirmasi',
    
    home_Affiliate_1: 'Hubungi kami',
    home_Affiliate_2: 'Setiap teman yang Anda undang, akan memberi Anda 10U, semakin banyak yang Anda undang, semakin banyak yang akan Anda dapatkan!',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'Email',

    home_banner_1: 'Crypto Gaming',
    home_banner_2: 'Didukung oleh 100+ kripto & 50+ NFT',
    home_banner_3: 'Betting Terdesentralisasi',
    home_banner_4: 'Betting kontrak cerdas dari dompet terdesentralisasi',
    home_banner_5: 'Bet 2 Earn',
    home_banner_6:'Bet, mine, and invest for steady profits',
    home_banner_7: 'Web3.0 + Casino',
    home_banner_8: 'Pemain juga Bankir & Pemilik',

    home_EventDetails: 'Detail',

    home_vip_1: "1.Dapatkan pendapatan dengan check-in+",
    home_vip_2: "2.Dapatkan pendapatan dengan memutar+",
    home_vip_3: "3.Dapatkan pendapatan dengan berbagi+",
    home_vip_4: "4.Dapatkan pendapatan dengan mengisi ulang+",
    home_vip_5: "5.Chat aktif, dapat mengirim pesan setiap hari",
    home_vip_6: "Pesan",

    ERROR_628: 'Kode hadiah sudah digunakan',
    ERROR_629: 'Kode hadiah telah digunakan sepenuhnya',
    ERROR_630: 'Kode hadiah tidak aktif',
    ERROR_631: 'Kode hadiah kedaluwarsa',
    ERROR_633: 'Tidak dapat menggunakan kode hadiah dari agen lain',

    home_Maintaining_1: "Pemeliharaan",
    home_Maintaining_2: "Fitur ini sedang dalam pemeliharaan",
    home_Maintaining_3: "AKHIR",
    home_Maintaining_4: "AWAL",
    
    home_Slots: 'Mesin Slot',
    home_LiveCasino: 'Kasino Langsung',
    home_CountryFiat: 'Negara & Fiat',
    home_CryptNFTs: 'Kripto & NFT',

    home_GoTo:'Pergi ke',

    home_gameout_1: "Apakah Anda saat ini sedang bermain game lain? Apakah Anda ingin keluar?",
    home_gameout_2: "Batal",
    
     home_BuyFund1:'Apakah Anda ingin membeli dana pertumbuhan?',

     home_BetFor:'Bertaruh untuk',

     tongzhi_1: "Saran",
     tongzhi_2: "Balas saran dan perwakilan.",
     tongzhi_3: "Bagus! Ini luar biasa.",
     tongzhi_4: "Menunggu tinjauan.",
     tongzhi_5: "Tinjauan disetujui.",
     tongzhi_6: "Pemrosesan pembayaran.",
     tongzhi_7: "Pembayaran berhasil.",
     tongzhi_8: "Tinjauan tidak disetujui.",
     tongzhi_9: "Pembayaran gagal.",
     tongzhi_10: "Penarikan gagal.",
     tongzhi_11: "Dibayar.",
     tongzhi_12: "Selesai.",
     tongzhi_13: "Pemrosesan gagal.",
     tongzhi_14: "Beli",
     tongzhi_15: "Ke",
     tongzhi_16: "Tarik",
     tongzhi_17: "Mata Uang Kripto",
     tongzhi_18: "Mata Uang Fiat",
     tongzhi_19: "Unduh ",
     tongzhi_20: "Login dapat dihadiahi",
     tongzhi_21: "Unduh",
     tongzhi_22: "Tidak ada versi iOS yang tersedia saat ini",
     tongzhi_23: "Kirim",
     tongzhi_24: "Kirim Tips",
    
     home_banner_9: "Balapan Kuda",
     home_banner_10: "Pertandingan Ayam",
     home_banner_11: "Seru dan Mendebarkan, Menangkan Hadiah Besar",
     home_banner_12: "Penuh Semangat dan Bergairah",
     
     home_wanfa_1:"Masalah Gameplay",
     home_wanfa_2:"Masalah Transfer",
     home_wanfa_3:"Masalah Lainnya",
     play_not_balance: "Taruhan olahraga dengan eUSDT tidak mungkin. Apakah Anda ingin beralih ke mata uang lain?",
     qieHuan_balance: "Saldo mata uang yang dipilih saat ini tidak mencukupi untuk bermain. Apakah Anda ingin beralih ke mata uang lain?",
     Provided_by: "Dari",

     error_0: "Gagal mengklaim hadiah",
     error_1: "Gagal mengambil data",
     error_2: "Gagal check-in",
     error_3: "Gagal memutar putar",
     error_4: "Gagal menerima bantuan keuangan",
     error_5: "Berbagi gagal",
     error_6: "Pembelian dana gagal",
     error_7: "Tidak dapat mengambil informasi permainan",
     error_8: "Tidak dapat mencoba permainan ini",
     error_10: "Gagal mentransfer batas",
     error_11: "Operasi gagal",
     error_12: "Gagal mengirim kode verifikasi",
     error_13: "Batas pengiriman tercapai",
     error_15: "",
     error_16: "",
     error_17: "",
     error_18: "",
     error_19: "",
     error_20: "",
     error_21: "",
     error_22: "",
     error_23: "",
     error_24: "",
     error_25: "",
     error_26: "",
     error_27: "",
     error_28: "",
     error_29: "",
     error_30: "",
     error_31: "",
     error_32: "",
     error_33: "",
     error_34: "",
    };
    
    export default locale;
    
    
