import Slider from "@mui/material/Slider";
import { alpha, styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import clsx from "clsx";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  useState,
  useEffect,
  forwardRef,
  default as React,
  useRef,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../../store/user";
import { getAwardOfBindPhone } from "../../store/activity/activityThunk";
import { showMessage } from "../../store/fuse/messageSlice";
import AnimateModal from "../../components/FuniModal";
import { getUserSetting, openWallet, onLogin } from "../../store/user/userThunk";
import AwardPop from "../../components/AwardPop";
import { motion } from "framer-motion";
import LoadingButton from "@mui/lab/LoadingButton";
import {selectConfigData} from "app/store/config";
import {closeMaintainPage, selectMaintainConfig} from "app/store/config/maintainConfig";
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { navbarToggle, navbarToggleMobile } from 'app/store/fuse/navbarSlice';
import {selectUserSetting} from "app/store/user/userSetting";
import {selectUserInfo, updateUser} from "app/store/user/userInfo";
import { selectUserProperties } from "app/store/user/userProperties";


const marks = [
  { value: 0.7, label: "70%" },
  { value: 0.8, label: "80%" },
  { value: 0.9, label: "90%" },
  { value: 1, label: "100%" },
  { value: 1.1, label: "110%" },
  { value: 1.2, label: "120%" },
  { value: 1.3, label: "130%" },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AdjustFontSize(props) {
  const ref = useRef(0);
  const { t } = useTranslation("mainPage");
  const [anchorEl, setAnchorEl] = useState(null);
  const [fontSize, setFontSize] = useState(1);
  const user = useSelector(selectUserData);
  const userData = useSelector(selectUserData);
  const userSetting = useSelector(selectUserSetting);
  const userProperties = useSelector(selectUserProperties);
  const userInfo = useSelector(selectUserInfo).userInfo;
  const dispatch = useDispatch();
  const [bindPhoneScore, setBindPhoneScore] = useState(0);
  const [popAward, setPopAward] = useState(false);
  const [spinRewardBalance, setSpinRewardBalance] = useState({
    balance: 0,
    symbol: "eUSDT",
  });
  const configData = useSelector(selectConfigData);
  const maintainConfig = useSelector(selectMaintainConfig);

  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  function changeHtmlFontSize() {
    const html = document.getElementsByTagName("html")[0];
    html.style.fontSize = `${fontSize * 62.5}%`;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openChangeStatus, setOpenChangeStatus] = useState({
    relief: false,
  });

  const onChangeStatus = (key, value) => {
    setOpenChangeStatus({ ...openChangeStatus, [key]: value });
  };

  const handleOnLogin = () => {
    dispatch(onLogin({ type: "login" }));
  };

  const handleGetAwardOfBindPhone = () => {
    dispatch(getAwardOfBindPhone()).then((res) => {
      onChangeStatus("relief", false);
      let result = res.payload;
      if (result) {
        setPopAward(true);
        setSpinRewardBalance({
          balance: result.cashCoupon / 100,
          symbol: "eUSDT",
        });
        dispatch(getUserSetting({
            callback: () => {}
        }));
      }
      //   dispatch(showMessage({ message: result.cashCoupon / 100 }));
    });
  };

  useEffect(() => {
    if (userInfo.user) {
      if (userInfo.nation === "0") {
        ref.current = setTimeout(() => {
          if (window.localStorage.getItem("mobileBindPhone") != 1) {
            onChangeStatus("relief", true);
            window.localStorage.setItem("mobileBindPhone", 1);
          }
        }, 1000);
      }
    }

    return () => {
      if (ref.current) {
        clearTimeout(ref.current);
      }
    };
  }, [userInfo.nation]);

  useEffect(() => {
    if (userProperties?.properties?.BindGrantScore) {
      setBindPhoneScore(userProperties.properties.BindGrantScore / 100);
    }
  }, [userProperties]);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <div>


      <AnimateModal
        className="scMoneyDi2"
        closeClass="closeBtnRelief"
        open={openChangeStatus.relief}
        onClose={() => onChangeStatus("relief", false)}
      >
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="imgHidden funbetTxt"
          style={{ height: "100%", width: "100%", maxWidth: "360px" }}
        >
          <div
            className="positionAb faGuang turnAni2"
            style={{ top: "0px", bottom: "0px", left: "-16px", right: "0px" }}
          ></div>
          <motion.div
            variants={item}
            className="positionAb titleCzDeposit titleCzDepositBg2 titleTxt"
            style={{
              top: "0px",
              bottom: "0px",
              left: "0px",
              right: "0px",
              margin: "0 auto",
            }}
          >
            {t("home_BindMobile")}
          </motion.div>
          <div className="positionAb bigXcBindImg activityMoveLeftBind"
            style={{ top: "51px", right: "0px", bottom: "0px" }}
          ></div>
          <motion.div
            variants={item}
            className="positionAb bigXcDepositTitle2 titleTxt2"
            style={{
              width: "90%",
              overflow: "hidden",
              textAlign: "center",
              margin: "0 auto",
              top: "292px",
              left: "0px",
              right: "0px",
            }}
          >
            {t("home_BINDMOBILENUMBER")}
          </motion.div>
          <motion.div
            variants={item}
            className="positionAb bigXcDepositTitle2 titleTxt2"
            style={{
              width: "90%",
              overflow: "hidden",
              textAlign: "center",
              margin: "0 auto",
              top: "322px",
              left: "0px",
              right: "0px",
            }}
          >
            {t("home_GET")}
            <span className="bigXcDepositTitle3"> ${bindPhoneScore} </span>
            <span className="bigXcDepositTitle2">eUSDT</span>
          </motion.div>
          <motion.div
            variants={item}
            className="positionAb "
            style={{
              top: "360px",
              bottom: "0px",
              left: "0px",
              right: "0px",
              margin: "0 auto",
            }}
          >
            <div className="containerSpinBtn align-item flex justifyContent">
              <div className="btn titleTxt">
                {userInfo.nation === "0" ? (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                  <a
                    onClick={() => {
                      onChangeStatus("relief", false);
                      dispatch(
                        openWallet({
                          type: "kyc",
                          data: {},
                          callback: () => {
                              FuniBox.Tools.default.getUserInfo().then((res) => {
                                  if (res) {
                                      dispatch(updateUser(res))
                                  }
                              })
                          }
                        })
                      )
                    }}
                    style={{ fontSize: "20px", color: "#ffffff" }}
                  >
                    {t("home_Bind")}
                  </a>
                ) : (
                  <a
                    onClick={handleGetAwardOfBindPhone}
                    style={{ fontSize: "20px", color: "#ffffff" }}
                  >
                    {t("home_receive")}
                  </a>
                )}
              </div>
            </div>
          </motion.div>
        </motion.div>
      </AnimateModal>

      {/* 维护界面 */}
      <AnimateModal
        className="scMoneyDi3"
        closeClass="closeBtnRelief"
        open={maintainConfig.maintainConfig.isShow}
        onClose={() => {
            dispatch(closeMaintainPage())
        }}
      >
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="imgHidden funbetTxt"
          style={{ height: "100%", width: "100%", maxWidth: "360px" }}
        >
          <motion.div
            variants={item}
            className="positionAb titleCzDeposit titleCzDepositBg3 titleTxt"
            style={{
              top: "0px",
              bottom: "0px",
              left: "0px",
              right: "0px",
              margin: "0 auto",
            }}
          >
            {t("home_Maintaining_1")}
          </motion.div>

          <div className="positionAb bigXcWeiHuImg activityMoveLeftBind"
            style={{ top: "51px", right: "0px", bottom: "0px" }}
          ></div>
          <motion.div
            variants={item}
            className="positionAb bigXcDepositTitle2 titleTxt2"
            style={{
              width: "90%",
              overflow: "hidden",
              textAlign: "center",
              margin: "0 auto",
              top: "250px",
              left: "0px",
              right: "0px",
            }}
          >
             {t("home_Maintaining_2")}
          </motion.div>


          <motion.div
            variants={item}
            className="positionAb bigXcDepositTitle2 "
            style={{
              width: "90%",
              overflow: "hidden",
              textAlign: "center",
              margin: "0 auto",
              top: "316px",
              left: "0px",
              right: "0px",
            }}
          >
            {t("home_Maintaining_3")}<span style={{ color: "#ffc600" }}> {maintainConfig.maintainConfig.startTime}</span> <br />
            {t("home_Maintaining_4")} <span style={{ color: "#ffc600" }}> {maintainConfig.maintainConfig.endTime}</span>
          </motion.div>


          <motion.div
            variants={item}
            className="positionAb flex justifyContent"
            style={{
              top: "380px",
              bottom: "0px",
              left: "0px",
              right: "0px",
              margin: "0 auto",
            }}
          >


            <LoadingButton className="whitespace-nowrap loadingBtnSty width-160  signBtnStyle  borderRadius text-20 funbetTxt " variant="contained" color="secondary"
              onClick={() => {
                  window.open(configData.customerServiceUrl)
                  dispatch(closeMaintainPage())
              }}
            >
              {t('home_LiveSupport')}
            </LoadingButton>

          </motion.div>
        </motion.div>
      </AnimateModal>





      <AwardPop
        open={popAward}
        onClose={() => {
          setPopAward(false);
        }}
        symbol={spinRewardBalance.symbol}
        symbolImg={`assets/images/symbol/${spinRewardBalance.symbol}.png`}
        balance={spinRewardBalance.balance}
      />

      {(!userSetting?.user?.hasGetAward ||
        userData.token.length === 0 ||
        userInfo.nation === "0") && (
          <Button
            className={clsx("w-240 h-40 flex giftStyle", props.className)}
            aria-controls="font-size-menu"
            aria-haspopup="true"
            onClick={() => {

              if (user.token) {
                onChangeStatus("relief", true);
              } else {
                handleOnLogin();
              }

              if (isMobile) {
                dispatch(navbarToggleMobile());
              }

            }}
            size="large"
          >
            <FuseSvgIcon>heroicons-outline:gift</FuseSvgIcon>
            <div className="tongZhiBtn2 ml-12 funbetTxt ">
              {t("home_bindPhone")}
            </div>
            {userData.token.length !== 0 &&
              (userInfo?.nation === "0" || !userSetting?.user?.hasGetAward) && (
                <img
                  className="positionAb"
                  style={{
                    right: 0,
                    top: 0,
                    width: "16px",
                    height: "16px",
                  }}
                  src="assets/images/activity/redPoint.png"
                  alt=""
                />
              )}
          </Button>
        )}
    </div>
  );
}

export default AdjustFontSize;
