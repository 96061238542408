const locale = {
    home_StakeNFT: 'আর্থিক লাভ হাসিল করতে NFT নিবাড় করুন',
    home_everyday: 'প্রতিদিন',
    home_Beton: 'বেট করুন',
    home_mining: 'মাইনিং',
    home_shareholder: 'শেয়ারহোল্ডার হন',
    home_Stake: 'লক্ষ নিবাড় করুন',
    home_dividend: 'লাভ বন্টন পান',
    home_Banker: 'একজন পার্টনার হন',
    home_SIGNUPAND: 'SIGN UP & ',
    home_GET: 'GET',
    home_UPTO: 'UP TO ',
    home_FuniHash: 'ব্লকচেইন গেম',
    home_Option: 'অপশন',

    home_Reward: ' Reward',
    home_Reward1: 'Reward',
    home_signUpNow: 'এখনি সাইন আপ করুন',
    home_Casino: 'ক্যাসিনো',
    home_Sports: 'খেলাধুলা',
    home_PROMOTION: 'প্রচার',
    home_Checkin: 'চেকইন',
    home_Bouns: 'বোনাস',
    home_CheckinBouns: 'চেকইন বোনাস',
    home_SPIN: 'ঘূর্ণি',
    home_Relief: 'সাহায্য',
    home_Share: 'ভাগ করুন',
    home_Deposit: 'আমানত',
    home_Fund: 'ফান্ড',
    home_VIPClub: 'ভিআইপি ক্লাব',
    home_FastAndEasy: 'একদম সহজে ক্রিপ্টোকারেন্সিতে আপনার জমা দিন এবং USDT বোনাস পান',
    home_Game: 'খেলা',
    home_User: 'ব্যবহারকারী',
    home_Time: 'সময়',
    home_BetAmount: 'বেট এর পরিমাণ',
    home_Multiplier: 'মাল্টিপ্লায়ার',
    home_Payout: 'পেআউট',
    home_JoinOur: 'আমাদের খেলাধুলা, ক্রিপ্টো এন্থুজিয়াস্ট এবং গেমব্লার সম্প্রদায়ে যোগ দিন',
    home_JOIN: 'যোগদিন',
    home_LICENSES: 'লাইসেন্স',
    home_OurPlatforms: 'আমাদের প্ল্যাটফর্ম',
    home_Marketplac: 'মার্কেটপ্লেস',
    home_Howtoearn: 'কিভাবে আয় করবেন',
    home_Games: 'খেলা',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'বিনিয়োগকারী ডেক',
    home_News: 'সংবাদ',
    home_Aboutsus: 'আমাদের সম্পর্কে',
    home_Industry: 'শিল্প',
    home_Roadmap: 'রোডম্যাপ',
    home_Partner: 'পার্টনার',
    home_FAQs: 'প্রশ্নগুলি',
    home_Affiliate: 'সহযোগিতা',
    home_Stayintouch: 'যোগাযোগ রক্ষা করুন',
    home_ChatRoom: 'চ্যাট রুম',
    home_Selectacontact: 'কোনও যোগাযোগকারী নির্বাচন করুন এবং কথা বলুন। ',
    home_BetSlip: 'বাজি স্লিপ',
    home_Typeyourmessage: 'আপনার বার্তা টাইপ করুন',
    home_AcceptAnyOdds: 'যে কোনও অক্ষর গ্রহণ করুন',
    home_ClearAll: 'সব মুছে ফেলুন',
    home_Live: 'লাইভ',
    home_Winner: 'বিজয়ী',
    home_EstPayout: 'আনুমানিক পেআউট',
    home_TotalOdds: 'মোট অক্ষর',
    home_RegistertoBet: 'বাজি দেওয়ার জন্য নিবন্ধন করুন',
    home_Single: 'একক বাজি',
    home_Multi: 'মাল্টি বাজি',
    home_Checkineveryweek: 'প্রতিসপ্তাহে চেকইন করে একটি সম্পূর্ণ পুরস্কার পান।',
    home_Day: 'দিন',
    home_Sunday: 'দিন 1',
    home_Monday: 'দিন 2',
    home_Tuesday: 'দিন 3',
    home_Wednesday: 'দিন 4',
    home_Thursday: 'দিন 5',
    home_Friday: 'দিন 6',
    home_Saturday: 'দিন 7',
    home_FreeSpin: 'ফ্রি স্পিন',
    home_Turntheturntable: 'প্রতিদিন ফ্রি স্পিন ঘুরিয়ে আপনি USGT পুরস্কার লাভ করতে পারেন।',
    home_DepositFift: 'আমানত',
    home_ANYAMOUNT: 'আপনি যে কোন পরিমাণের প্রথম আমানত লাভ করতে পারেন',
    home_DEPOSIT: 'আমানত',
    home_First: 'প্রথম',
    home_ONLY: 'কেবল',
    home_1ST: '1ম',
    home_2ND: '2য়',
    home_3TH: '3য়',
    home_ANYAMOUNT: 'যে কোনও পরিমাণ!',
    home_EXTRA: 'অতিরিক্ত',
    home_Relief: 'ভিক্ষা',
    home_Getrelief: 'সাহায্য তাকান পেতে পারেন এবং উল্টা দিকে পরিবর্তনের সুযোগ পাবেন।',
    home_YOUCANCLAIM: 'ব্যালেন্স ',
    home_YOUCANReceive: ' থেকে কম থাকলে, আপনি সাহায্য তাকান পেতে পারেন এবং ব্যালেন্সটি ',
    home_threeTimes: ' 3টি বার',
    home_everydayTimes: ' / প্রতিদিন!',
    home_Times: 'টাইমস:',
    home_TotalAssets: 'মোট সম্পদ',
    home_receive: 'পাবার জন্য ক্লিক করুন',
    home_TODAY: 'আজকে',
    home_Share: 'ভাগ করুন',
    home_PLATFORMSHARING: 'অন্যান্য প্ল্যাটফর্মে শেয়ার করুন',
    home_Sharewith: 'Share FuniBet every day and get ',
    home_SHARINGGIFT: 'ভাগ করা উপহারসমূহ',
    home_CODE: 'শেয়ার কোড',
    home_Selectplatform: 'শেয়ার করতে প্ল্যাটফর্ম নির্বাচন করুন:',
    home_Fund: 'গ্রোথ ফান্ড',
    home_FundBonus: 'গ্রোথ ফান্ড বোনাস',
    home_Improveyour: 'আপনার ভিআইপি স্তর উন্নয়ন করুন এবং প্রতিদিন আরো অর্থ পান।',
    home_BuyFund: 'ক্রয়',
    home_Receivingconditions: 'conditions',
    home_Receivingstatus: 'status',
    home_Received: 'গৃহীত',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'একক সুবিধাসমূহ',
    home_Asawayof: 'VIP খেলোয়াড়দের সম্মান এবং প্রশংসার প্রকাশের জন্য, FuniBet আমাদের VIP ক্লাবে যোগদান করার জন্য আমন্ত্রণ জানাচ্ছে, যেখানে সুবিধাময় উপহার, উপহার, আরও উচ্চ নগদ প্রতিফল এবং অনন্য বৈশিষ্ট্য রয়েছে। সমস্ত উত্সাহজনক এবং আশ্চর্যজনক মজার অনুভব উপেক্ষা করবেন না! ',
    home_Yourcurrent: 'আপনার বর্তমান মোট রেফিল',
    home_WhyUpgrade: 'কেন ভিআইপি আপগ্রেড করবেন? ',
    home_VIPhave: 'VIP সদস্যরা 24*7 বিশেষজ্ঞ হোস্টেসের সেবা পান। বেসিক ক্যাশব্যাক এবং রিচার্জ ছাড়াও, VIP সদস্যরা সর্বোচ্চ স্তরের স্পিন, প্রতি সপ্তাহ / মাসিক বোনাস এবং সর্বাধিক দ্রুত সম্পদ আদায়-প্রদানের সুবিধা উপভোগ করে। ভিআইপি সদস্যরা শীর্ষ গুণমানের খেলোয়ার বিনিময়ে উন্নত গুণমানের জন্য অভিজ্ঞ কর্মীরা সাথে সাক্ষাতে যোগ করা যায় এবং একটি পূর্ণাঙ্গ গেমিং ক্লাবে অংশগ্রহণ করতে পারেন। ',
    home_UpgradeYour: 'আপনার ভিআইপি স্তর উন্নয়ন করুন',
    home_ASaFunibet: 'FUNIBet VIP ক্লাবের একজন সদস্য হিসাবে, আপনি পুরোপুরি মূল্যবান পুরস্কার এবং ব্যক্তিগত উপহার পাবেন। কিন্তু এটা শুধু এটি নয়। আপনি যতবেশি খেলেন, যতবেশি আপনি VIP স্তর আপগ্রেড করবেন, আপনি লাভবান লুক্সারি সুবিধা পাবেন, FUNIBET আপনার জন্য ব্যক্তিগতভাবে তৈরি করা VIP সুবিধা সরবরাহ করবে।',
    home_YOUCANREACH: 'আপনি এক্সেস করতে পারেন',
    home_LEVELS: 'লেভেলগুলি',
    home_eachthehigh: 'প্রতিটি ব্যক্তি 25% ক্যাশব্যাক বোনাস পাবে এবং প্ল্যাটফর্মের সমস্ত সুবিধা উপভোগ করতে পারবেন। FUNIBET-এ অবস্থান নিন, সেরা গেম অভিজ্ঞতা এবং উন্নয়নশীল সেবা উপভোগ করুন!',
    home_Cashback: 'ক্যাশব্যাক',
    home_Nowater: '1. খেলার প্রকৃতি অনুযায়ী আর কোনো কমিশন নেওয়া হবে না',
    home_Moregrowth: '2. আরও বেশি গ্রোথ ফান্ড',
    home_Increasein: '3. সুবিধা সংখ্যা বাড়ানো হয়েছে',
    home_Doublethenumber: '4. পুরো প্রক্রিয়াটি দুইগুন হয়েছে',
    home_Doublethesign: '5. সাক্ষর সংখ্যা দুইগুন হয়েছে',
    home_Doublesharingrewards: '6. ভাগ করা পুরস্কার দুইগুন হয়েছে',
    home_DoubletheAffiliate: '7. এফিলিয়েট পুরস্কার দুইগুন হয়েছে',
    home_Rechargerewardamount: '8. রিচার্জ পরিমাণ ১.৫গুণ হবে',
    home_ABOUTVIPCLUB: 'VIP ক্লাব সম্পর্কে',
    home_HightopRank: 'উচ্চ রেঙ্কিং উপভোগ করুন ২৫% ক্যাশব্যাক বোনাস এবং সেরা সুবিধা। বেডাওতে বসে শ্রেষ্ঠ গেমিং অভিজ্ঞতা এবং উচ্চ স্তরের সেবা উপভোগ করুন! ',
    home_Beingamember: 'VIP ক্লাবের সদস্য হওয়া মানে আপনি অ্যাক্সেস করতে পারেন:',
    home_PersonalVIPmanager: 'ব্যক্তিগত VIP ম্যানেজার',
    home_Insights: 'গভীর জ্ঞান',
    home_Highercahbackrewards: 'আরও উচ্চ ক্যাশব্যাক পুরস্কার',
    home_Otheradditionalperks: 'অন্যান্য অতিরিক্ত ভাতা',
    home_WeeklyandMonthlyBonuses: 'সাপ্তাহিক এবং মাসিক বোনাস',
    home_Showhighgaming: 'উচ্চ গেমিং ইভেন্ট প্রদর্শন এবং আমাদের ভিআইপি ক্লাবের আমন্ত্রণ পান।',
    home_BindMobile: 'মোবাইল বাঁধান',
    home_Pleasecheckthe: 'খেলার পরিবর্তে আপনার ওয়ালেট চেক করুন।',
    home_BINDMOBILENUMBER: 'মোবাইল নম্বর বাঁধান',
    home_Get1USDT: '১ টি ইউএসডিটি পান',
    home_Bind: 'বাঁধন',
    home_GiftCode: 'উপহার কোড',
    home_EnteryourGiftCode: 'আপনার উপহার কোড লিখুন',
    home_EnterCode: 'কোড লিখুন',
    home_Paste: 'পেস্ট করুন',
    home_confirms: 'নিশ্চিত করুন',
    home_BuyCrypto: 'ক্রয়',
    home_SignedIn: 'সাইন ইন করা হয়েছে',
    home_CommissionRules: 'কমিশন নিয়মাবলী',
    home_AffiliateTerms: 'এফিলিয়েট শর্তাবলী',
    home_AffiliateDashboard: 'এফিলিয়েট ড্যাশবোর্ড',
    home_AFFILIATE: 'এফিলিয়েট',


    home_COMMISSONREADYEVERYDAY: 'প্রতিদিন সম্প্রচারযোগ্য কমিশন $1,000 শুধুমাত্র একটি পরামর্শকের মাধ্যমে',
    home_MYREFERRALCODE: 'আমার রেফারাল কোড',
    home_MYREFERRALLink: 'আমার রেফারাল লিঙ্ক',
    home_AffiliateReward: 'এফিলিয়েট পুরস্কার',
    home_EXTRA: 'অতিরিক্ত',
    home_USDREWARDS: 'মার্কিন ডলার পুরস্কার',
    home_USDRewardsRules: 'মার্কিন ডলার পুরস্কারের নিয়মাবলী',
    home_JUSAREFERRALAWAY: 'কেবলমাত্র একটি রেফারাল',
    home_NewlyAvailableRewards: 'নতুন পুরস্কার',
    home_ReceivedMao: 'পেয়েছি:',
    home_Locked: 'লক করা:',
    home_Yourcurrentfriends: 'আপনার বর্তমান বন্ধুরা আপনার জন্য আরো পুরস্কার আনলে আপনার জন্য আনলোক করবেন',
    home_Withdraw: 'উত্তোলন করুন',
    home_MY: 'আমার',
    home_COMMISSIONREWARDS: 'কমিশন পুরস্কার',
    home_CommissionRewardRules: 'কমিশন পুরস্কারের নিয়মাবলী',
    home_NewlyAvailableCommissionRewards: 'নতুন কমিশন পুরস্কার',
    home_CommissionYouhaveReceivedinTotal: 'মোট পাওয়া কমিশন:',
    home_REWARDSSENTTODATE: 'তারিখ পর্যন্ত প্রেরিত পুরস্কার',
    home_USDrewardssenttodate: 'মোট ডলার পুরষ্কার',
    home_Commissionsenttodatet: 'কমিশন প্রেরণের তারিখ',
    home_Areyouablogger: 'আপনি কি একজন ব্লগার, যার বহুল পাঠক এবং অনুগামী রয়েছে? আমরা আপনাকে একটি বিশেষ অ্যাফিলিয়েট প্রোগ্রাম প্রদান করে যা আপনাকে বেশি রেফারাল প্রতিপূর্তি দেবে। আমরা বিশেষ শর্তাদি সহ একটি স্বতন্ত্র প্রোগ্রাম তৈরি করব। শর্তাদি আলোচনার জন্য আমাদের ম্যানেজারকে যোগাযোগ করুন',
    home_onlythoseusers: 'গুরুত্বপূর্ণ বিজ্ঞপ্তি: এই পরিকল্পনাতে অংশগ্রহণ করতে চাইলে শুধুমাত্র প্রশাসকের অনুরোধে এবং প্রশাসকের অনুমোদনের মাধ্যমে ব্যবহারকারীদের অনুমতি দেওয়া হবে। ',
    home_notice: 'নোটিশ',
    home_bindPhone: 'ফোন বাঁধান',
    home_SignIn: 'লগইন',
    home_SignUp: 'রেজিস্টার',
    home_SignOut: 'সাইন আউট',
    home_CookiesPolicy: 'কুকিজ নীতি',
    home_isUsingCookies: 'Funibet.com প্রতিটি ব্যবহারকারীকে আরও ভাল সেবা প্রদান করতে কুকিজ ব্যবহার করে। আমাদের ওয়েবসাইট ব্যবহার করলে আপনি কুকি ব্যবহার করতে সম্মতি দিচ্ছেন। দয়া করে গোপনীয়তা নীতি এবং পরিষেবা শর্তাদি দেখুন। ',
    home_SETTING: 'সেটিংস',
    home_LiveSupport: 'লাইভ সাপোর্ট',
    home_Accept: 'স্বীকার করুন',
    home_FrequentlyAskedQuestions: 'প্রচলিত প্রশ্নাবলী',
    home_SiteUnnderConstruction: 'সাইট নির্মাণাধীন',
    home_ComingSoon: 'শীঘ্রই আসছে',
    home_Wallet: 'ওয়ালেট',
    home_UserId: 'আইডি: ',
    home_UserName: 'নাম: ',
    home_turnTimes: 'ঘূর্ণন সময়ঃ',
    home_Bonusincreased: "বোনাস অতিরিক্ত +",
    home_bonus: 'বোনাস',
    home_SPINBONUSTOTAL: 'স্পিন বোনাস মোট',
    home_WIN: 'জয়:',
    home_In: 'থেকে',
    home_FirstDepositBonus: 'প্রথম আমানত বোনাস',
    home_Hi: 'হাই',
    home_welcomeAboard: 'স্বাগতম',
    home_Outoftime: 'আজকের সময় শেষ হয়ে গেছে',


    ame_search: 'অনুসন্ধান',
    game_SortBy: 'সাজানো হচ্ছে এমনভাবে',
    game_Created: 'তৈরির তারিখ',
    game_Lobby: 'লবি',
    game_LiveCasino: 'লাইভ ক্যাসিনো',
    game_FeaturedSlots: 'বৈশিষ্ট্যযুক্ত স্লটস',
    game_GameShows: 'গেম শো',
    game_FuniBetOriginals: 'ফানিবেট অরিজিনাল',
    game_BigWins: 'বড় জেতারা',
    game_LcukyWins: 'ভাগ্যবান জেতারা',
    game_Challenges: 'চ্যালেঞ্জ',
    game_Description: 'বর্ণনা',
    game_Slots: 'স্লটস',
    game_Rank: 'শ্রেণী',
    game_User: 'ব্যবহারকারী',
    game_Date: 'তারিখ',
    game_BetAmount: 'সর্বনিম্ন বাজি',
    game_lucky: 'সর্বাধিক গুণনীয়তা',
    game_Multiplier: 'মাল্টিপ্লায়ার',
    game_Payout: 'পেআউট',
    game_Recommend: 'পরামর্শ',
    game_Providers: 'প্রদানকারী',
    game_startGame: 'শুরু ',
    game_balance: 'ব্যালেন্স',
    game_provider: 'ট্যাগ',
    game_Selectyourdisplay: 'আপনার প্রদর্শন নির্বাচন করুন',
    game_Balancein: 'ব্যালেন্স',
    game_FunPlay: 'ফান প্লে',
    game_RealPlay: 'রিয়েল প্লে',
    game_DispLaying: "প্রদর্শিত হচ্ছে",
    game_LoadMore: "আরো লোড করুন",
    game_NoData: 'কোনও ডাটা নেই',


    message_SigningIn: "JWT দিয়ে সাইন ইন হচ্ছে",
    message_CourseSaved: "কোর্স সংরক্ষিত হয়েছে",
    message_PleaseBind: "উপার্জন করতে ফোন বাঁধতে হবে, কি আপনি বাঁধতে চান?",
    message_UserData: "ব্যবহারকারীর তথ্য API দিয়ে সংরক্ষিত হয়েছে",
    message_Success: "সাফল্য",
    message_VIPlevel: "ভিআইপি লেভেল V3 এর চেয়ে কম",
    message_ListOrder: "তালিকা অর্ডার সংরক্ষিত হয়েছে",
    message_CardOrder: "কার্ড অর্ডার সংরক্ষিত হয়েছে",
    message_HiHow: "হাই, কেমন আছেন?",

    Funibet_web: 'Funibet-ওয়েব ৩.০,ক্রিপ্টো,বাজি,খেলা,ই-স্পোর্টস,স্লটস,লাইভ ক্যাসিনো,পোকার',

    home_ThereAre: "এখনো কোন বিজ্ঞপ্তি নেই।",

    play_no_balance: "বর্তমান মুদ্রা ব্যালেন্স অপর্যাপ্ত, দয়া করে মুদ্রা পরিবর্তন করুন বা রিচার্জ করুন।",

    home_News_1: 'সংবাদ',
    home_News_2: 'ইভেন্ট',
    home_News_3: 'ক্রিপ্টো',
    home_News_4: 'গেমিং',

    home_SuggRep_1: 'পরামর্শ এবং রিপোর্ট',
    home_SuggRep_2: 'পরামর্শ এবং রিপোর্ট',
    home_SuggRep_3: 'উন্নয়নের জন্য প্রতিক্রিয়া শেয়ার করুন!',
    home_SuggRep_4: 'গেমে বিক্রয়ের জন্য ওয়েবসাইট রিপোর্ট করুন।',
    home_SuggRep_5: 'পরামর্শ লিখুন বা রিপোর্ট জমা দিন',
    home_SuggRep_6: 'নমুনা স্ক্রীনশট',
    home_SuggRep_7: 'পরামর্শ এবং রিপোর্ট গৃহীত করার জন্য পুরস্কার!',
    home_SuggRep_8: 'জমা দিন',

    home_subscription_1: 'পুশ বিজ্ঞপ্তি সাবস্ক্রাইব করতে চান?',
    home_subscription_2: 'আপনি সর্বদা যে কোনও সময় সাদা হতে পারেন।',
    home_subscription_3: 'WonderPush দ্বারা ওয়েব পুশ',
    home_subscription_4: 'পরে',
    home_subscription_5: 'সাবস্ক্রাইব',

    home_Affiliate_1: 'যোগাযোগ করুন',
    home_Affiliate_2: 'আপনি প্রতিটি আমন্ত্রণ করা বন্ধুকে 10U পাবেন, আপনি যতবার আমন্ত্রণ করবেন ততবার আপনি বেশি পাবেন!',
    home_Affiliate_3: 'টেলিগ্রাম',
    home_Affiliate_4: 'ইমেল',

    home_banner_1: 'ক্রিপ্টো গেমিং',
    home_banner_2: '১০০+ ক্রিপ্টো এবং ৫০+ এনএফটি সমর্থিত',
    home_banner_3: 'Decentralized Betting',
    home_banner_4: 'Smart contract betting from decentralized wallet',
    home_banner_5: 'বেট 2 আর্ন',
    home_banner_6: 'স্থিতিশীল লাভের জন্য বেট, মাইন এবং বিনিয়োগ করুন',
    home_banner_7: 'Web3.0 + ক্যাসিনো',
    home_banner_8: 'খেলোয়াড়ও ব্যাঙ্কার এবং মালিক',

    home_EventDetails: 'বিস্তারিত',

    home_vip_1: "1.ইনকাম পেতে সাইন আপ+",
    home_vip_2: "2.ইনকাম পেতে রুলেট চালু+",
    home_vip_3: "3.ইনকাম পেতে শেয়ার+",
    home_vip_4: "4.ইনকাম পেতে রিচার্জ+",
    home_vip_5: "5.চ্যাট খোলা হয়েছে, প্রতিদিন পাঠানো যাবে",
    home_vip_6: "মেসেজ",

    ERROR_628: 'এই গিফট কোডটি ইতিমধ্যে গ্রহণ করা হয়েছে',
    ERROR_629: 'এই গিফট কোডটি সম্পূর্ণ গ্রহণ করা হয়েছে',
    ERROR_630: 'গিফট কোডটি কার্যকর হয়নি',
    ERROR_631: 'গিফট কোডটির মেয়াদ শেষ হয়েছে',
    ERROR_633: 'অন্য এজেন্টের গিফট কোড ব্যবহার করা যায় না',

    home_Maintaining_1: "পরিচালনা",
    home_Maintaining_2: "এই বৈশিষ্ট্যটি রক্ষণাবেক্ষণের অধীনে",
    home_Maintaining_3: "শেষ",
    home_Maintaining_4: 'শুরু',

    home_Slots: 'স্লোটস',
    home_LiveCasino: 'লাইভ ক্যাসিনো',
    home_CountryFiat: 'দেশ & ফিয়াট',
    home_CryptNFTs: 'ক্রিপ্ট & NFTs',

    home_GoTo: 'যাও',

    home_gameout_1: "আপনি এখন অন্য গেমে আছেন কি? আপনি কি বাহিরে যেতে চান?",
    home_gameout_2: "বাতিল",

    home_BuyFund1: '؟আপনি আপনার গ্রোথ ফান্ড কেনার ইচ্ছুক?',

    home_BetFor: 'বেট ফর',

    tongzhi_1: "পরামর্শ",
    tongzhi_2: "পরামর্শ এবং প্রতিনিধি প্রতিক্রিয়া দিন।",
    tongzhi_3: "সুন্দর কাজ! এটা অসাধারণ।",
    tongzhi_4: "পর্যালোচনা অপেক্ষারত।",
    tongzhi_5: "পর্যালোচনা সফল হয়েছে।",
    tongzhi_6: "টাকা প্রেরণ চলছে।",
    tongzhi_7: "টাকা সফলভাবে প্রেরণ করা হয়েছে।",
    tongzhi_8: "পর্যালোচনা অস্বীকার করেছে।",
    tongzhi_9: "টাকা প্রেরণ ব্যর্থ হয়েছে।",
    tongzhi_10: "উত্তোলন ব্যর্থ হয়েছে।",
    tongzhi_11: "পরিশোধ হয়েছে।",
    tongzhi_12: "সম্পন্ন হয়েছে।",
    tongzhi_13: "প্রক্রিয়া ব্যর্থ।",
    tongzhi_14: "ক্রয়",
    tongzhi_15: "থেকে",
    tongzhi_16: "উত্তোলন",
    tongzhi_17: "ভার্চুয়াল মুদ্রা",
    tongzhi_18: "মুদ্রা",
    tongzhi_19: "ডাউনলোড ",
    tongzhi_20: "লগইন করলে পুরস্কার",
    tongzhi_21: "ডাউনলোড",
    tongzhi_22: "বর্তমানে iOS সংস্করণ অনুপলব্ধ",
    tongzhi_23: "প্রেরণ",
    tongzhi_24: "প্রেরণ করুন টিপস",

    home_banner_9: "ঘোড়া দৌড়",
    home_banner_10: "মুরগি যুদ্ধ",
    home_banner_11: "উত্সাহজনক এবং উৎসাহবর্ধনকারী, বড় পুরষ্কার জিতুন",
    home_banner_12: "উত্সাহিত এবং উদ্ধত যোদ্ধা",
    home_wanfa_1: "খেলার সমস্যা",
    home_wanfa_2: "লেনদেন সমস্যা",
    home_wanfa_3: " অন্যান্য সমস্যা",
    play_not_balance: "খেলা eUSDT ব্যবহার করা সম্ভব না, আপনি অন্য মুদ্রায় পরিবর্তন করতে চান?",
    qieHuan_balance: "বর্তমান বাছাই করা মুদ্রার মুদ্রাস্তর খুব অপর্যাপ্ত, আপনি অন্য মুদ্রায় স্যুইচ করতে চান?",
    Provided_by: "থেকে",

    error_0: "পুরস্কার পাওয়া ব্যর্থ",
    error_1: " ডেটা পেতে ব্যর্থ",
    error_2: " সাইন-ইন ব্যর্থ",
    error_3: "চাকা ঘোরানো ব্যর্থ হয়েছে",
    error_4: "রাহাত অর্জন ব্যর্থ",
    error_5: "ভাগাভাগি ব্যর্থ",
    error_6: "ফান্ড কেনা ব্যর্থ হয়েছে",
    error_7: "খেলার তথ্য পেতে ব্যর্থ",
    error_8: "এই খেলা পরীক্ষা করা সম্ভব নয়",
    error_10: "স্থানান্তর সীমা ব্যর্থ",
    error_11: "অপারেশন ব্যর্থ হয়েছে",
    error_12: "যাচাইকরণ কোড পাঠানো ব্যর্থ হয়েছে",
    error_13: "প্রেরণের সর্বোচ্চ সীমা পৌঁছে গেছে",
    error_15: "",
    error_16: "",
    error_17: "",
    error_18: "",
    error_19: "",
    error_20: "",
    error_21: "",
    error_22: "",
    error_23: "",
    error_24: "",
    error_25: "",
    error_26: "",
    error_27: "",
    error_28: "",
    error_29: "",
    error_30: "",
    error_31: "",
    error_32: "",
    error_33: "",
    error_34: "",
};

export default locale;


