const locale = {
    home_StakeNFT: 'Aposte em NFTs e ganhe grátis. ',
    home_everyday: 'todos os dias',
    home_Beton: 'Aposte em ',
    home_mining: 'mineração',
    home_shareholder: ' Seja um acionista',
    home_Stake: 'Aposte',
    home_dividend: ' Receba dividendos de lucro',
    home_Banker: ' Um dos parceiros',
    home_SIGNUPAND: 'registro ',
    home_GET: 'GANHE',
    home_UPTO: 'ATÉ ',
    home_FuniHash: 'Jogo de blockchain',
    home_Option: 'Opções',
    
    home_Reward: ' recompensa',
    home_Reward1: 'recompensa',
    home_signUpNow: 'Cadastre-se agora',
    home_Casino: 'Cassino',
    home_Sports: 'Esportes',
    home_PROMOTION: 'PROMOÇÃO',
    home_Checkin: 'Check-in',
    home_Bouns: 'bônus',
    home_CheckinBouns: 'Bônus de Check-in',
    home_SPIN: 'GIRAR',
    home_Relief: 'Alívio',
    home_Deposit: 'Depósito',
    home_Fund: 'Fundos',
    home_VIPClub: 'Clube VIP',
    home_FastAndEasy: 'Compre criptomoedas facilmente e com bônus de depósito em USDT.',
    home_Game: 'Jogo',
    home_User: 'Usuário',
    home_Time: 'Tempo',
    home_BetAmount: 'Valor da Aposta',
    home_Multiplier: 'Multiplicador',
    home_Payout: 'Pagamento',
    home_JoinOur: 'Junte-se à nossa comunidade de fãs de esportes, criptoentusiastas e jogadores.',
    home_JOIN: 'JUNTAR-SE',
    home_LICENSES: 'LICENÇAS',
    home_OurPlatforms: 'Nossas Plataformas',
    home_Marketplac: 'Mercado',
    home_Howtoearn: 'Como ganhar',
    home_Games: 'Jogos',
    home_WhitePaper: 'White Paper',
    home_InvestorDeck: 'Investor Deck',
    home_News: 'Notícias',
    home_Aboutsus: 'Sobre nós',
    home_Industry: 'Indústria',
    home_Roadmap: 'Roadmap',
    home_Partner: 'Parceiro',
    home_FAQs: 'FAQs',
    home_Affiliate: 'Afiliado',
    home_Stayintouch: 'Mantenha contato',
    home_ChatRoom: 'Sala de bate-papo',
    home_Selectacontact: 'Selecione um contato para iniciar uma conversa.',
    home_BetSlip: 'Bilhete de apostas',
    home_Typeyourmessage: 'Digite sua mensagem',
    home_AcceptAnyOdds: 'Aceitar qualquer odds',
    home_ClearAll: 'Limpar tudo',
    home_Live: 'Ao vivo',
    home_Winner: 'Vencedor',
    home_EstPayout: 'Est. Pagamento',
    home_TotalOdds: 'Total de odds',
    home_RegistertoBet: 'Registre-se para apostar',
    home_Single: 'Aposta única',
    home_Multi: 'Múltipla',
    home_Checkineveryweek: 'Faça check-in toda semana acumulativamente e receba recompensas correspondentes.',
    home_Day: 'Dia',
    home_Sunday: 'Dia1',
    home_Monday: 'Dia2',
    home_Tuesday: 'Dia3',
    home_Wednesday: 'Dia4',
    home_Thursday: 'Dia5',
    home_Friday: 'Dia6',
    home_Saturday: 'Dia7',
    home_FreeSpin: 'Giro Grátis',
    home_Turntheturntable: 'Gire a roleta todos os dias para receber a recompensa USGT.',
    home_DepositFift: 'DEPÓSITO',
    home_ANYAMOUNT: 'QUALQUER VALOR DE PRIMEIRO DEPÓSITO QUE VOCÊ PODE RECEBER',
    home_DEPOSIT: 'DEPÓSITO',
    home_First: 'Primeiro',
    home_1ST: '1º',
    home_2ND: '2º',
    home_3TH: '3º',
    home_ANYAMOUNT: 'QUALQUER VALOR!',
    home_EXTRA: 'EXTRA',
    home_ONLY: 'APENAS',
    home_Relief: 'Alívio',
    home_PLATFORMSHARING: 'PLATFORM SHARING',
    home_Getrelief: 'Receba dinheiro de ajuda e tenha a chance de se levantar.',
    home_YOUCANCLAIM: 'Quando o saldo for menor que ',
    home_YOUCANReceive: ', você pode receber alívio e complementar o saldo para ',
    home_threeTimes: ' 3 vezes',
    home_Times: 'vezes:',
    home_everydayTimes: '/dia!',
    home_TotalAssets: 'Ativos totais',
    home_receive: 'receber',
    home_TODAY: 'HOJE',
    home_Share: 'Share',
    home_Sharewith: 'Compartilhe FuniBet para ganhar',
    home_SHARINGGIFT: 'PACOTE DE PRESENTE DE COMPARTILHAMENTO',
    home_CODE: 'CÓDIGO',
    home_Selectplatform: 'Selecione a plataforma para compartilhar:',
    home_Fund: 'Fundo',
    home_FundBonus: 'Bônus de Fundo',
    home_Improveyour: 'Improve your VIP level and get more fund rewards every day.',
    home_BuyFund: 'Comprar',
    home_Receivingconditions: 'condições',
    home_Receivingstatus: 'status',
    home_Received: 'Recebido',
    home_noReceived: 'unmet',
    home_ExclusiveBenefits: 'Benefícios Exclusivos',
    home_Asawayof: 'Como forma de mostrar nossa gratidão e apreciação aos nossos jogadores VIP, a FuniBet convida você a se juntar ao nosso Clube VIP, onde há uma abundância de presentes, brindes, maior cashback e recursos exclusivos. Não perca todas as emoções e diversão incríveis!',
    home_Yourcurrent: 'Sua aposta total atual',
    home_WhyUpgrade: 'Por que se tornar VIP?',
    home_VIPhave: 'O VIP tem atendimento 24/7 com anfitrião exclusivo, rakeback e recargas especiais, além de bônus e canais rápidos para depósito e retirada. Também pode participar de conferências de jogadores e apresentar novas funcionalidades à equipe FuniBet.',
    home_UpgradeYour: 'Melhore seu nível VIP',
    home_ASaFunibet: 'Como membro VIP do FUNIBet, você terá acesso a recompensas exclusivas e presentes personalizados. Quanto mais jogar, mais benefícios de luxo receberá à medida que avança nos níveis VIP, tornando-se um membro VIP Elite sem limites para os benefícios VIP personalizados.',
    home_YOUCANREACH: 'VOCÊ PODE ATINGIR',
    home_LEVELS: 'NÍVEIS',
    home_eachthehigh: 'Suba no ranking e ganhe um bônus de cashback de 25% e desbloqueie todos os benefícios. Fique com a FUNIBET para uma experiência de jogo incrível e ótimo serviço!',
    home_Cashback: 'Cashback',
    home_Nowater: '1. Sem bombeamento de água no jogo',
    home_Moregrowth: '2. Mais fundos de crescimento',
    home_Increasein: '3. Aumento no número de benefícios',
    home_Doublethenumber: '4. Dobre o número de giratórias',
    home_Doublethesign: '5. Dobre a recompensa de login',
    home_Doublesharingrewards: '6. Dobre as recompensas de compartilhamento',
    home_DoubletheAffiliate: '7. Dobre a recompensa de afiliado',
    home_Rechargerewardamount: '8. Quantidade da recompensa de recarga X 1,5',
    home_ABOUTVIPCLUB: 'ABOUT VIP CLUB',
    home_HightopRank: 'High top Rank has 25% cashback bonus and the best benefits. Stay with BeDao to receive the best gaming experience and high level of service!',
    home_Beingamember: 'Being a member of the VIP club means that you have access to:',
    home_PersonalVIPmanager: 'Personal VIP manager',
    home_Highercahbackrewards: 'Higher cahback rewards',
    home_Otheradditionalperks: 'Other additional perks',
    home_Insights: 'Percepções',
    home_WeeklyandMonthlyBonuses: 'Bônus semanais e mensais',
    home_Showhighgaming: 'Mostre alta atividade de jogo e obtenha seu convite para nosso Clube VIP.',
    home_BindMobile: 'Vincular',
    home_Pleasecheckthe: 'Por favor, verifique o USGT obtido no jogo na sua carteira.',
    home_BINDMOBILENUMBER: 'Vincular o número',
    home_Get1USDT: 'Obtenha 1 USDT',
    home_Bind: 'Vincular',
    home_GiftCode: 'Código de presente',
    home_EnteryourGiftCode: 'Digite o seu código de presente',
    home_EnterCode: 'Digite o código',
    home_Paste: 'Colar',
    home_confirms: 'confirma',
    home_BuyCrypto: 'Comprar',
    home_SignedIn: 'Conectado',
    home_Wallet: 'Wallet',
    home_turnTimes: 'Voltas: ',
    home_bonus: ' bônus',
    home_FirstDepositBonus: 'First Deposit Bonus',
    home_Hi: 'Oi ',
    home_welcomeAboard: ', seja bem-vindo(a)',
    
    
    
    home_CommissionRules: 'Regras de Comissão',
    home_AffiliateTerms: 'Termos de Afiliados',
    home_AffiliateDashboard: 'Painel de Afiliados',
    home_AFFILIATE: 'AFILIADO',
    home_COMMISSONREADYEVERYDAY: 'COMISSÃO PRONTA TODOS OS DIAS',
    home_MYREFERRALCODE: 'MEU CÓDIGO DE INDICAÇÃO',
    home_MYREFERRALLink: 'MEU LINK DE INDICAÇÃO',
    home_AffiliateReward: 'Recompensa de Afiliado',
    home_EXTRA: 'EXTRA',
    home_USDREWARDS: 'RECOMPENSAS USD',
    home_USDRewardsRules: 'Regras de Recompensas USD',
    home_JUSAREFERRALAWAY: 'APENAS UMA INDICAÇÃO DE DISTÂNCIA',
    home_NewlyAvailableRewards: 'Recompensas Recém-Disponíveis',
    home_ReceivedMao: 'Recebido: ',
    home_Locked: 'Bloqueado: ',
    home_Yourcurrentfriends: 'Seus amigos atuais que permanecem ativos desbloquearão mais recompensas para você',
    home_Withdraw: 'Retirar',
    home_MY: 'MEU',
    home_COMMISSIONREWARDS: 'RECOMPENSAS DE COMISSÃO',
    home_CommissionRewardRules: 'Regras de recompensa de comissão',
    home_NewlyAvailableCommissionRewards: 'Novas recompensas de comissão',
    home_CommissionYouhaveReceivedinTotal: 'Comissão total recebida:',
    home_REWARDSSENTTODATE: 'Total de Prêmios',
    home_USDrewardssenttodate: 'Recompensa total em dólares',
    home_Commissionsenttodatet: 'Comissão enviada até a data',
    home_Areyouablogger: 'Você é blogueiro, tem um grande público, muitos seguidores? Temos uma oferta especial para você - um programa de afiliados exclusivo com recompensas de indicação maiores. Compilaremos um programa individual com condições especiais.',
    home_onlythoseusers: 'Importante: somente os usuários que atenderem aos requisitos do gerente e somente após aprovação do gerente poderão participar do programa.',
    home_notice: 'Aviso',
    home_bindPhone: 'Vincular telefone',
    home_SignIn: 'Entrar',
    home_SignUp: 'Inscreva-se',
    home_SignOut: 'Sair',
    home_CookiesPolicy: 'Política de Cookies',
    home_isUsingCookies: 'A Funibet.com está usando cookies para fornecer um serviço melhor para cada usuário. Ao usar nosso site, você concorda com o uso de cookies. Por favor, verifique a Política de Privacidade e os Termos de Serviço.',
    home_SETTING: 'CONFIGURAÇÃO',
    home_LiveSupport: 'Suporte ao vivo',
    home_Accept: 'Aceitar',
    home_FrequentlyAskedQuestions: 'Perguntas Frequentes',
    home_SiteUnnderConstruction: 'Site em Construção',
    home_ComingSoon: 'Em Breve',
    home_UserId: 'ID: ',
    home_UserName: 'Nome: ',
    home_Bonusincreased: "Bônus aumentado +",
    home_SPINBONUSTOTAL: 'BÔNUS DE GIRO TOTAL',
    home_WIN: 'WIN:',
    home_In: 'em ',
    home_Outoftime: 'O número de jogadas de hoje esgotou-se',
    
    game_search: 'busca',
    game_SortBy: 'Ordenar Por',
    game_Created: 'Criado',
    game_Lobby: 'Área de Jogos',
    game_LiveCasino: 'Cassino Ao Vivo',
    game_FeaturedSlots: 'Caça-níqueis em Destaque',
    game_GameShows: 'Programas de jogos',
    game_FuniBetOriginals: 'FuniBet Originals',
    game_BigWins: 'Grandes Vitórias',
    game_LcukyWins: 'Vitórias da Sorte',
    game_Challenges: 'Desafios',
    game_Description: 'Descrição',
    game_Slots: 'Caça-níqueis',
    game_Rank: 'Classificação',
    game_User: 'Usuário',
    game_Date: 'Data',
    game_BetAmount: 'Valor da Aposta',
    game_lucky: 'Multiplicador Máximo',
    game_Multiplier: 'Multiplicador',
    game_Payout: 'Pagamento',
    game_Recommend: 'Recomendado',
    game_Providers: 'Fornecedores',
    game_startGame: 'começar',
    game_balance: 'Saldo',
    game_provider: 'Tag',
    game_Selectyourdisplay: 'Selecione o seu saldo de exibição',
    game_Balancein: 'Saldo em',
    game_FunPlay: 'demonstração',
    game_RealPlay: 'Jogo de verdade',
    game_DispLaying: "Exibindo",
    game_LoadMore: "Carregar mais",
    game_NoData: 'Não há jogos deste tipo temporariamente',

    message_SigningIn: "Iniciando sessão com JWT",
    message_CourseSaved: "Curso salvo",
    message_PleaseBind: "É necessário vincular o telefone para participar da atividade. Deseja vincular?",
    message_UserData: "Dados do usuário salvos com API",
    message_Success: "sucesso",
    message_VIPlevel: "O nível VIP é menor que V3",
    message_ListOrder: "Ordem da lista salva",
    message_CardOrder: "Ordem do cartão salva",
    message_HiHow: "Oi, como você está?",

    Funibet_web: 'Funibet-Web3.0,Criptomoeda,Aposta,Esportes,Esports,Caça-níqueis,Cassino ao vivo,Poker',

    home_ThereAre: "Não há notificações no momento.",

    play_no_balance: "O saldo atual da moeda é insuficiente, por favor troque a moeda ou recarregue",

    home_News_1: 'Notícias',
    home_News_2: 'Eventos',
    home_News_3: 'Criptomoeda',
    home_News_4: 'Jogos',
    
    home_SuggRep_1: 'Sugest.&Relat.',
    home_SuggRep_2: 'Sugestões e Relatórios',
    home_SuggRep_3: 'Compartilhe feedback para melhorar!',
    home_SuggRep_4: 'Relatar site que vende itens do jogo.',
    home_SuggRep_5: 'Insira sugestões ou envie relatórios',
    home_SuggRep_6: 'Captura de tela de exemplo',
    home_SuggRep_7: 'recompensa por sugestões e relatórios adotados!',
    home_SuggRep_8: 'enviar',
    
    home_subscription_1: 'Você gostaria de se inscrever para receber notificações push?',
    home_subscription_2: 'Você pode cancelar a inscrição a qualquer momento.',
    home_subscription_3: 'Web push por WonderPush',
    home_subscription_4: 'Mais tarde',
    home_subscription_5: 'Inscreva-se',
    
    home_Affiliate_1: 'Contate-Nos',
    home_Affiliate_2: 'Cada amigo que você convidar, ganhará 10U para você, quanto mais você convidar, mais ganhará!',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: 'E-mail',

    home_banner_1: 'Jogos de Criptomoeda',
    home_banner_2: 'Suportado por 100+ criptomoedas e 50+ NFTs',
    home_banner_3: 'Apostas Descentralizadas',
    home_banner_4: 'De uma carteira descentralizada para fazer apostas',
    home_banner_5: 'Aposte para Ganhar',
    home_banner_6: 'Aposte, mine e invista para lucros estáveis',
    home_banner_7: 'Web3.0 + Casino',
    home_banner_8: 'O jogador também é o Banqueiro e o Proprietário',
    
    home_EventDetails: 'detalhes',

    home_vip_1: "1.Ganhe receita ao fazer check-in+",
    home_vip_2: "2.Ganhe receita na roleta+",
    home_vip_3: "3.Ganhe receita ao compartilhar+",
    home_vip_4: "4.Ganhe receita ao recarregar+",
    home_vip_5: "5.Chat aberto, pode enviar mensagens diariamente",
    home_vip_6: "Mensagens",

    ERROR_628: 'Código do presente já resgatado',
    ERROR_629: 'Código do presente já foi completamente resgatado',
    ERROR_630: 'Código do presente não está ativo',
    ERROR_631: 'Código do presente expirado',
    ERROR_633: 'Não é possível usar um código do presente de outro agente',

    home_Maintaining_1: "Manutenção",
    home_Maintaining_2: "Este recurso está em manutenção",
    home_Maintaining_3: "END",
    home_Maintaining_4: "START",

    home_Slots:'Slots',
    home_LiveCasino:'Cassino ao vivo',
    home_CountryFiat:'País & Fiat',
    home_CryptNFTs:'Crypto & NFTs',

    home_GoTo:'Ir para',

    home_gameout_1: "Você está em outro jogo no momento? Deseja sair?",  
    home_gameout_2: "Cancelar",

     home_BuyFund1:'Deseja comprar um fundo de crescimento?',

     home_BetFor:'Aposta para',

     tongzhi_1: "Sugestão",
     tongzhi_2: "Responder à sugestão e ao representante.",
     tongzhi_3: "Bom trabalho! Isso é incrível.",
     tongzhi_4: "A aguardar revisão.",
     tongzhi_5: "Revisão aprovada.",
     tongzhi_6: "A processar pagamento.",
     tongzhi_7: "Pagamento concluído com sucesso.",
     tongzhi_8: "Revisão não aprovada.",
     tongzhi_9: "Pagamento falhou.",
     tongzhi_10: "Levantamento falhou.",
     tongzhi_11: "Pago.",
     tongzhi_12: "Concluído.",
     tongzhi_13: "Processamento falhou.",
     tongzhi_14: "Comprar",
     tongzhi_15: "Até",
     tongzhi_16: "Retirar",
     tongzhi_17: "Moeda Virtual",
     tongzhi_18: "Moeda Fiduciária",
     tongzhi_19: "Download ",
     tongzhi_20: "Login pode ser recompensado",
     tongzhi_21: "Download",
     tongzhi_22: "Nenhuma versão para iOS disponível no momento",
     tongzhi_23: "Enviar",
     tongzhi_24: "Enviar Dicas",

     home_banner_9: "Corrida de Cavalos",
     home_banner_10: "Luta de Galos",
     home_banner_11: "Emocionante e empolgante, ganhe grandes prêmios",
     home_banner_12: "Ardente e cheio de espírito de luta",
     home_wanfa_1:"Problema de transferênciaProblema de jogabilidade",
     home_wanfa_2:"Problema de transferência",
     home_wanfa_3:"Outro problema",
     play_not_balance: "O desporto não pode usar eUSDT, quer mudar para outra moeda?",
     qieHuan_balance: "O saldo da moeda selecionada não é suficiente para jogar. Deseja trocar por outra moeda?",
     Provided_by: "De",

     error_0: "Falha ao receber recompensa",
     error_1: "Falha ao obter dados",
     error_2: "Falha no check-in",
     error_3: "Falha ao girar o girador",
     error_4: "Falha ao receber ajuda financeira",
     error_5: "Falha ao compartilhar",
     error_6: "Falha na compra do fundo",
     error_7: "Não é possível obter informações do jogo",
     error_8: "Não é possível jogar este jogo",
     error_10: "Falha na transferência de limite",
     error_11: "Operação falhou",
     error_12: "Falha ao enviar o código de verificação",
     error_13: "Limite de envio atingido",
     error_15: "",
     error_16: "",
     error_17: "",
     error_18: "",
     error_19: "",
     error_20: "",
     error_21: "",
     error_22: "",
     error_23: "",
     error_24: "",
     error_25: "",
     error_26: "",
     error_27: "",
     error_28: "",
     error_29: "",
     error_30: "",
     error_31: "",
     error_32: "",
     error_33: "",
     error_34: "",
};

export default locale;
