import { createAsyncThunk } from '@reduxjs/toolkit';
import React from "react";
import history from '@history';
import { showMessage } from 'app/store/fuse/messageSlice';
import { getErrorCode } from "../../util/tools/function";

// 获取SignIn配置
export const getSignInConfig = createAsyncThunk(
    'activity/getSignInConfig',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.getSignInConfig");
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_1'), code: 2 }));
            return false
        }
    }
);

// 签到情况
export const querySignInInfo = createAsyncThunk(
    'activity/querySignInInfo',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.querySignInInfo");
        if (res.errno === 0) {
            return res.data
        } else {
            // dispatch(showMessage({ message: res.errmsg, code: 2 }));
            return false
        }
    }
);

// 签到
export const signInDone = createAsyncThunk(
    'activity/signInDone',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.signInDone");
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_2'), code: 2 }));
            return false
        }
    }
);

// 获取转盘配置
export const getTurnConfig = createAsyncThunk(
    'activity/getTurnConfig',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.getTurnConfig");
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_1'), code: 2 }));
            return false
        }
    }
);

// 转动转盘
export const doTurn = createAsyncThunk(
    'activity/doTurn',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.doTurn");
        if (res.errno === 0) {
            if (res.data.ErrorCode) {
                dispatch(showMessage({ message: t('error_3'), code: 2 }));
                return false
            }
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_3'), code: 2 }));
            return false
        }
    }
);

// 获取转盘历史记录与总额
export const getTurnRecord = createAsyncThunk(
    'activity/getTurnRecord',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.getTurnRecord");
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_1'), code: 2 }));
            return false
        }
    }
);

// 获取救济金配置
export const queryBaseEnsureConfig = createAsyncThunk(
    'activity/queryBaseEnsureConfig',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.queryBaseEnsureConfig");
        if (res.errno === 0) {
            return res
        } else {
            dispatch(showMessage({ message: t('error_1'), code: 2 }));
            return false
        }
    }
);

// 领取救济金
export const takeBaseEnsure = createAsyncThunk(
    'activity/takeBaseEnsure',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.takeBaseEnsure");
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_4'), code: 2 }));
            return false
        }
    }
);

// 获取分享情况
export const queryDailyShareInfo = createAsyncThunk(
    'activity/queryDailyShareInfo',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.queryDailyShareInfo");
        if (res.errno === 0) {
            return res.data
        } else {
            // dispatch(showMessage({ message: res.errmsg, code: 2 }));
            return false
        }
    }
);

// 设置为分享成功
export const updateDailyShareInfo = createAsyncThunk(
    'activity/updateDailyShareInfo',
    async (settings, { dispatch, getState }) => {
        const cbMode = settings.cbMode;

        const res = await React.$api("activity.updateDailyShareInfo", { cbMode });
        if (res.errno === 0) {
            return true
        } else {
            dispatch(showMessage({ message: t('error_5'), code: 2 }));
            return false
        }
    }
);

// 领取分享奖励
export const getDailyShareReward = createAsyncThunk(
    'activity/getDailyShareReward',
    async (settings, { dispatch, getState }) => {
        const cbMode = settings.cbMode;

        const res = await React.$api("activity.getDailyShareReward", { cbMode });
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_0'), code: 2 }));
            return false
        }
    }
);

// 获取充值奖励配置
export const getChargeGiftConfig = createAsyncThunk(
    'activity/getChargeGiftConfig',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.getChargeGiftConfig");
        if (res.errno === 0) {
            return res.data
        } else {
            // dispatch(showMessage({ message: res.errmsg, code: 2 }));
            return false
        }
    }
);

// 获取充值奖励领取状态
export const getChargeGiftStatus = createAsyncThunk(
    'activity/getChargeGiftStatus',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.getChargeGiftStatus");
        if (res.errno === 0) {
            return res.data
        } else {
            // dispatch(showMessage({ message: res.errmsg, code: 2 }));
            return false
        }
    }
);

// 领取今日首充礼包
export const getChargeGiftReward = createAsyncThunk(
    'activity/getChargeGiftReward',
    async (settings, { dispatch, getState }) => {
        let data = {
            giftType: settings.giftType || 3
        };

        const res = await React.$api("activity.getChargeGiftReward", data);
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_0'), code: 2 }));
            return false
        }
    }
);

// 获取成长基金配置
export const getGrowthFundConfig = createAsyncThunk(
    'activity/getGrowthFundConfig',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.getGrowthFundConfig");
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_1'), code: 2 }));
            return false
        }
    }
);

// 购买成长基金
export const buyGrowthFund = createAsyncThunk(
    'activity/buyGrowthFund',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.buyGrowthFund");
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_6'), code: 2 }));
            return false
        }
    }
);

// 领取成长基金奖励
export const getGrowthFundReward = createAsyncThunk(
    'activity/getGrowthFundReward',
    async (settings, { dispatch, getState }) => {
        let data = {
            vipLevel: settings.vipLevel
        };
        const res = await React.$api("activity.getGrowthFundReward", data);
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_0'), code: 2 }));
            return false
        }
    }
);

// 获取vip配置
export const getVIPConfig = createAsyncThunk(
    'activity/getVIPConfig',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("gamecenter.getVIPConfig");
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_1'), code: 2 }));
            return false
        }
    }
);


// 绑定手机后领取奖励
export const getAwardOfBindPhone = createAsyncThunk(
    'activity/getAwardOfBindPhone',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.getAwardOfBindPhone");
        if (res.errno === 0) {
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_0'), code: 2 }));
            return false
        }
    }
);

// 领取礼包码
export const getRedeemCode = createAsyncThunk(
    'activity/getRedeemCode',
    async (settings, { dispatch, getState }) => {
        let data = {
            redeemCode: settings.redeemCode ?? ''
        };
        const res = await React.$api("activity.getRedeemCode", data);
        if (res.errno === 0) {
            if (res.data.ErrorCode) {
                let errorCode = res.data.ErrorCode.replace("[=", "").replace("]", "");
                return { ErrorCode: `ERROR_${errorCode}` }
            }
            return res.data
        } else {
            dispatch(showMessage({ message: t('error_0'), code: 2 }));
            return false
        }
    }
);

// 获取游戏活动配置
export const getGameActivityNotice = createAsyncThunk(
    'activity/getGameActivityNotice',
    async (settings, { dispatch, getState }) => {
        const res = await React.$api("activity.gameActivityNotice", settings);
        if (res.errno === 0) {
            return res.data
        } else {
            // dispatch(showMessage({ message: res.errmsg, code: 2 }));
            return false
        }
    }
);
