import PhoneBind from './PhoneBind';


const PhoneBindConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'phoneBind',
            element: <PhoneBind />,
        },
    ],
};

export default PhoneBindConfig;