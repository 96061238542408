import { createSlice } from '@reduxjs/toolkit';
import history from '@history';

// state
const initialState = {
    token: FuniBox.Tools.default.getToken() || '',
    isUserInfoLoading: true,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUserToken: (state, action) => {
            let res = action.payload;
            state.token = res;
            localStorage.setItem('Authorization', res);
            localStorage.setItem(
                `Authorization-${
                    window.sessionStorage.getItem('openAppId') || 0
                }-${
                    window.sessionStorage.getItem('openIndex') || 0
                }`
            , res);
        },

        updateIsUserInfoLoading: (state, action) => {
            let res = action.payload;
            state.isUserInfoLoading = res;
        },
    },
    extraReducers: {
        // [doLogin.fulfilled]: (state, action) => action.payload,
    }
});

export const { updateUserToken, updateIsUserInfoLoading } = userSlice.actions;

export const selectUserData = ({ user }) => user;

export default userSlice.reducer;
