import "../../../styles/index.css";
import "../../../styles/xianStyle.css";
import "../../../styles/activity.css";
import utils from "../../util/tools/utils";
import Button from "@mui/material/Button";

import { motion } from "framer-motion";
import Common_foot from "../common/Common_foot";
import Common_gameReport from "../common/Common_gameReport";
import { useTranslation } from "react-i18next";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState, useEffect, forwardRef, default as React } from "react";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import history from "@history";
import { selectCasinoData } from "../../store/casino";
import { useDispatch, useSelector } from "react-redux";
import clsx from 'clsx';
import {
  openWallet,
  userProfile,
  onLogin,
  countUserAllAssets,
} from "../../store/user/userThunk";
import { selectUserData } from "../../store/user";
import AnimateModal from "../../components/FuniModal";
import {
  getSignInConfig,
  querySignInInfo,
  signInDone,
  queryDailyShareInfo,
  getChargeGiftStatus,
  queryBaseEnsureConfig,
} from "../../store/activity/activityThunk";
import Spin from "../spin/Spin";
import PhoneBind from "../phoneBind/PhoneBind";
import BingTips from "../cookies/BingTips";
import Relief from "../relief/Relief";
import Share from "../share/Share";
import Deposit from "../deposit/Deposit";
import AwardPop from "../../components/AwardPop";
import { showMessage } from "app/store/fuse/messageSlice";

import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import { isMobile, judgeLangFontSize } from "../../util/tools/function";
import { selectUserSetting } from "app/store/user/userSetting";
import { selectUserProperties } from "app/store/user/userProperties";
import { selectUserInfo } from "app/store/user/userInfo";
import { selectCurrentLanguageId } from 'app/store/i18nSlice';
import { height } from "@mui/system";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Root = styled("div")(({ theme }) => ({
  "& .username, & .email": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  "& .avatar": {
    background: theme.palette.background.default,
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    "& > img": {
      borderRadius: "50%",
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


function Home(props) {
  const { t } = useTranslation("mainPage");
  const [openChangeStatus, setOpenChangeStatus] = useState({
    checkIn: false,
    spin: false,
    relief: false,
    share: false,
    deposit: false,
    fund: false,
    vip: false,
    phone: false,
    spinReward: false,
  });

  const [signInConfig, setSignConfig] = useState([]);
  const [userSignData, setUserSignData] = useState({});
  const currentLang = useSelector(selectCurrentLanguageId);
  const [popSignAward, setPopSignAward] = useState(false);
  const [showBindTips, setShowBindTips] = useState(false);
  const [signInRewardBalance, setSignInRewardBalance] = useState({
    balance: 0,
    symbol: "eUSDT",
  });

  const [userTurnTimes, setUserTurnTime] = useState(0);
  const [canTakeTimes, setCanTakeTimes] = useState(0);
  const [shareData, setShareData] = useState({});
  const [chargeGiftStatus, setChargeGiftStatus] = useState({});

  function move(fangxiang, id) {
    var per = document.getElementById("cc");
    var j = per.style.top;
    var k = per.style.left;
    j = parseInt(j.substr(0, j.length - 2));
    k = parseInt(k.substr(0, k.length - 2));

    switch (fangxiang) {
      case 1:
        per.style.top = j - 1 + "px";
        break;
      case 2:
        per.style.top = j + 1 + "px";
        break;
      case 3:
        per.style.left = k - 1 + "px";
        break;
      case 4:
        per.style.left = k + 1 + "px";
        break;
    }
  }

  // 签到数据
  const handleGetSignInConfig = () => {
    // 签到config
    dispatch(getSignInConfig()).then((res) => {
      let result = res.payload;
      setSignConfig(result);
    });

    // 签到情况
    dispatch(querySignInInfo()).then((res) => {
      let result = res.payload;
      setUserSignData(result);
    });
  };

  // 签到
  const handleCheckIn = () => {
    if (!userSignData?.SignedToday) {
      dispatch(signInDone()).then((res) => {
        let result = res.payload;
        if (result) {
          setUserSignData({ ...userSignData, SignTimes: result.SignTimes, SignedToday: true });
          setSignInRewardBalance({
            balance: result.RewardCashCoupon / 100,
            symbol: "eUSDT",
          });
          setPopSignAward(true);
        }
      });
    } else {
      dispatch(showMessage({ message: t("home_SignedIn"), code: 1 }));
    }
  };

  const overChenckIn = () => {
    if (userSignData?.SignedToday) {
      dispatch(showMessage({ message: t("home_SignedIn"), code: 1 }));
    }
  };

  const dispatch = useDispatch();
  const user = useSelector(selectUserData);
  const userInfo = useSelector(selectUserInfo);
  const userSetting = useSelector(selectUserSetting);
  const userProperties = useSelector(selectUserProperties);

  const [userAssets, setUserAssets] = useState(0);
  const [baseEnsureConfig, setBaseEnsureConfig] = useState({});
  const [receiveStatus, setReceiveStatus] = useState(0);
  const [isFilipino, setIsFilipino] = useState(false);
  const [isPhoneBind, setIsPhoneBind] = useState(false);

  const handleOnLogin = () => {
    dispatch(onLogin({ type: "login" }));
  };

  const onWallet = (type = "wallet") => {
    dispatch(
      openWallet({
        type,
      })
    );
  };

  const casinoData = useSelector(selectCasinoData);
  const goRouter = (route) => {
    history.push(route);
  };


  const onChangeStatus = (key, value) => {
    if (value) {
      if (!user.token) {
        dispatch(onLogin({ type: "login" }));
        return;
      }
    }


    // 判断是否绑定过手机
    if (userInfo.userInfo?.nation === "0") {
      // dispatch(showMessage({ message: t("message_PleaseBind"), code: 3 }));
      setShowBindTips(true)
      return;
    }

    let tmpChangeStatus = { ...openChangeStatus };
    Object.keys(tmpChangeStatus).forEach((index) => {
      if (index === key) {
        tmpChangeStatus[index] = value;
      } else {
        tmpChangeStatus[index] = false;
      }
    });
    setOpenChangeStatus(tmpChangeStatus);

    if (!value) {
      return;
    }

    switch (key) {
      case "checkIn":
        if (signInConfig.length === 0) {
          handleGetSignInConfig();
        }
        break;
    }
  };

  const sportHandleOnLogin = () => {
    dispatch(onLogin({ type: "login" }));
  };

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  //当天开始时间
  const curStartTime = () => {
    let time = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
    return Math.ceil(time / 1000);
  };

  // 获取分享情况
  const handleQueryDailyShareInfo = () => {
    dispatch(queryDailyShareInfo()).then((res) => {
      let result = res.payload;
      setShareData(result);
    });
  };

  // 获取充值礼包领取状态
  const handleGetChargeGiftStatus = () => {
    dispatch(getChargeGiftStatus()).then((res) => {
      let result = res.payload;
      if (result) {
        setChargeGiftStatus(result);
      }
    });
  };

  // 获取用户资产
  // const getUserAssets = () => {
  //   dispatch(countUserAllAssets()).then((userRes) => {
  //     let asstes = userRes.payload || 0;
  //     setUserAssets(asstes);
  //   });
  // };

  // 获取救济金配置
  const handleQueryBaseEnsureConfig = () => {
    dispatch(queryBaseEnsureConfig()).then((res) => {
      let result = res.payload;
      if (result) {
        setBaseEnsureConfig(result.data);
        let walletAmount = result.wallet.Crypto + result.wallet.Fiat;
        let gameAmount = result.score.cashCoupon + result.score.insureScore + result.score.score;
        // 需要计算汇率额金额(如果是法币需要计算,虚拟币不用计算)
        let currencyScore = result.score.currencyScore;

        let userAmount = walletAmount + gameAmount / 100; // 缺少计算(currencyScore)
        setUserAssets(userAmount);
      }
    });
  };

  // 获取救济金领取状态
  const dealWithStatus = () => {
    // 开始时间（0点 + startTime） + 领取次数 （配置文件takeTimes - userProperties.properties.CanTakeTimes） * 间隔时间（秒） = 下一次领取的时间
    let startTime =
      0 +
      baseEnsureConfig.StartTime +
      (baseEnsureConfig.TakeTimes - canTakeTimes) *
      baseEnsureConfig.IntervalTime;
    startTime = curStartTime() + startTime;
    let curTime = Math.ceil(Date.now() / 1000);

    if (userAssets >= baseEnsureConfig.ScoreCondition / 100) {
      // 余额大于领取条件,不可领取
      setReceiveStatus(1);
    } else if (canTakeTimes <= 0) {
      //今日已全部领取,不可领取
      setReceiveStatus(2);
    } else if (curTime < startTime) {
      setReceiveStatus(3);
    } else {
      setReceiveStatus(0);
    }
  };

  const scrollToTop = () => {
    document.documentElement.scrollTo({ top: 0 });
  };

  useEffect(() => {
    scrollToTop()
  }, [user.token]);

  useEffect(() => {
    if (JSON.stringify(baseEnsureConfig) !== '{}' && JSON.stringify(userProperties.properties) !== '{}') {
      dealWithStatus();
    }
  }, [canTakeTimes, baseEnsureConfig, userAssets, userProperties.properties]);

  useEffect(() => {
    if (userSetting.user) {
      // 获取用户资产
      // getUserAssets();

      // 签到情况
      dispatch(querySignInInfo()).then((res) => {
        let result = res.payload;
        setUserSignData(result);
      });

      // 分享情况
      handleQueryDailyShareInfo();

      // 首充情况
      handleGetChargeGiftStatus();

      // 救济金情况
      handleQueryBaseEnsureConfig();
    }
  }, [userSetting]);

  useEffect(() => {
    // 转盘次数
    if (userProperties?.properties?.TurntableRemain) {
      setUserTurnTime(userProperties.properties.TurntableRemain);
    }

    // 救济金领取次数
    if (userProperties?.properties?.CanTakeTimes) {
      setCanTakeTimes(userProperties?.properties?.CanTakeTimes);
    }
  }, [userProperties]);

  // useEffect(() => {
  //   utils.appendScript("assets/js/script.js", false);

  //   return () => {
  //     utils.removeScript("assets/js/script.js");
  //   }
  // }, []);

  const [loaded, setLoaded] = useState(false);
  const style = {
    // backgroundImage: "url('https://scource-static.funibet.com/funibet/images/banner/1.png')",
    backgroundImage: "url('assets/images/banner/1.png')",
  };

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoaded(true);
    };
    // img.src = 'https://scource-static.funibet.com/funibet/images/banner/1.png';
    img.src = 'assets/images/banner/1.png';
  }, []);


  useEffect(() => {
    if (judgeLangFontSize(['ph', 'id']) == true) {
      setIsFilipino(true);
    } else {
      setIsFilipino(false);
    }
  }, [currentLang]);



  const bannerGo1 = () => {
    if (user.token) {
      history.push("/sport/europeanView");
    } else {
      handleOnLogin();
    }
  };

  const bannerGo2 = () => {
    if (user.token) {
      history.push("/gameOther/3/Provably Fair/1");
    } else {
      handleOnLogin();
    }
  };

  const bannerGo3 = () => {
    if (user.token) {
      history.push("/gameOther/5/Recommend/0");
    } else {
      handleOnLogin();
    }
  };

  const bannerGo4 = () => {
    if (user.token) {
      history.push("/gameOther/2/Live%20Casino/1");
    } else {
      handleOnLogin();
    }
  };

  const bannerGo5 = () => {
    if (user.token) {
      history.push("/gameOther/1/Slots/1");
    } else {
      handleOnLogin();
    }
  };

  const bannerGo6 = () => {
    if (user.token) {
      goRouter(`/game/${casinoData.recommondTag}`);
    } else {
      handleOnLogin();
    }
  };

  return (
    <div className="flex w-full container">
      <div className="flex flex-col sm:flex-row flex-auto sm:items-center  betDiColor min-w-0 px-10  md:p-32 pb-0 md:pb-0 huoDongDing">
        <div className="flex flex-col flex-auto ">
          <div className="zuQiu backGround-index mt-8 ">
            <div className="activitybanner marginJuZhong ">
              <div className="height-470-zuQiu imgHidden ">
                <canvas></canvas>

                {!isFilipino &&
                  <Carousel
                    className="positionAb activitybanner height-470-zuQiu"
                    showArrows={true}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    showThumbs={false}
                  >

                    <div className="btnPointer" onClick={() => { bannerGo1() }}>
                      <LazyLoadImage
                        className="lunBoTuWh"
                        src={
                          isMobile()
                            // ? "assets/images/index/saiMa_1.png" : "assets/images/index/saiMa.png"
                            ? `${React.$imgUrl}/images/index/zuQiu_1.png` : `${React.$imgUrl}/images/index/zuQiu.png`
                        }
                      />
                      <div className="legend">
                        {user.token ? (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className="text-20 titleTxt displayPhoneSwitch1">
                                <span className="txtColorHui">
                                  {t("home_Hi")}
                                </span>
                                {userSetting?.user?.nickName}!
                                <span className="txtColorHui">
                                  {t("home_welcomeAboard")}
                                </span>
                              </div>
                              <div className={clsx("sigin-txt titleTxt", judgeLangFontSize(['vn', 'ru', "in", "my", "id", "mm"]) && "font-28")}>
                                {t("home_FirstDepositBonus")}
                              </div>
                              <div className={clsx("sigin-txt1 sigin-txt-yellow titleTxt", judgeLangFontSize(['fr', 'pl', 'br']) && "font-30")}>
                                <span>+270%</span>
                                {t("home_Reward")}
                              </div>
                            </div>
                            {!isMobile() && (
                              <Button
                                className="whitespace-nowrap width-160 signBtnStyle  borderRadius text-20 funbetTxt"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onWallet("buy")
                                }}
                              >
                                {t("home_BuyCrypto")}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox ">
                              <div className="sigin-txt titleTxt">
                                {t("home_SIGNUPAND")}
                                <span className="sigin-txt-yellow">
                                  {t("home_GET")}
                                </span>
                                {t("home_Reward")}
                              </div>
                              <div className="sigin-txt1  titleTxt">
                                {t("home_UPTO")}
                                <span className="sigin-txt-yellow">
                                  $2,700,000
                                </span>
                              </div>
                            </div>
                            {!isMobile() && (
                              <Button
                                className="whitespace-nowrap width-160  signBtnStyle funbetTxt  borderRadius text-20"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (user.token) {
                                    onWallet("buy");
                                  } else {
                                    handleOnLogin();
                                  }
                                }}
                              >
                                {t("home_SignUp")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <div style={{ position: "relative" }} className="btnPointer bannerHeightBtn " onClick={() => { bannerGo2() }}>
                        <LazyLoadImage
                          className="lunBoTuWh zuQiu-img1 positionAb"
                          src="value"
                          style={{ left: 0 }}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner1_1"
                          src={`${React.$imgUrl}/images/index/tu_1.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner1_2"
                          src={`${React.$imgUrl}/images/index/tu_2.png`}
                          style={{}}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner1_3"
                          src={`${React.$imgUrl}/images/index/tu_3.png`}
                          style={{}}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner1_4"
                          src={`${React.$imgUrl}/images/index/tu_4.png`}
                          style={{}}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner1_5"
                          src={`${React.$imgUrl}/images/index/tu_5.png`}
                          style={{}}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner1_6"
                          src={`${React.$imgUrl}/images/index/tu_6.png`}
                          style={{}}
                        />
                      </div>

                      <div className="legend">
                        {user.token ? (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className="lunboTitleTxt2 sigin-txt-yellow  titleTxt ">
                                {t("home_banner_1")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_2")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle  borderRadius text-20 funbetTxt", judgeLangFontSize(['fr', 'tmr', 'br']) && "font-15")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onWallet("buy");
                                }}
                              >
                                {t("home_BuyCrypto")}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className="lunboTitleTxt2 sigin-txt-yellow  titleTxt ">
                                {t("home_banner_1")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_2")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160 signBtnStyle funbetTxt  borderRadius text-20", judgeLangFontSize(['ru', 'fr']) && "font-14")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (user.token) {
                                    onWallet("buy");
                                  } else {
                                    handleOnLogin();
                                  }
                                }}
                              >
                                {t("home_SignUp")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="btnPointer bannerHeightBtn" onClick={() => { bannerGo3() }}>
                      <LazyLoadImage className="lunBoTuWh zuQiu-img2" src="value" />
                      <div className="legend">
                        {user.token ? (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className="lunboTitleTxt2 sigin-txt-yellow titleTxt">
                                {t("home_banner_3")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_4")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className="whitespace-nowrap width-160  signBtnStyle  borderRadius text-20 funbetTxt"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onWallet("buy");
                                }}
                              >
                                {t("home_BuyCrypto")}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow  titleTxt">
                                {t("home_banner_3")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_4")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className="whitespace-nowrap width-160  signBtnStyle funbetTxt  borderRadius text-20"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (user.token) {
                                    onWallet("buy");
                                  } else {
                                    handleOnLogin();
                                  }
                                }}
                              >
                                {t("home_SignUp")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <div style={{ position: "relative" }} className="btnPointer bannerHeightBtn" onClick={() => { bannerGo4() }}>
                        <LazyLoadImage
                          className="lunBoTuWh zuQiu-img3"
                          src="value"
                          style={{ left: 0 }}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_1"
                          src={`${React.$imgUrl}/images/index/tu3_1.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_2"
                          src={`${React.$imgUrl}/images/index/tu3_2.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_3"
                          src={`${React.$imgUrl}/images/index/tu3_3.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_4"
                          src={`${React.$imgUrl}/images/index/tu3_4.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_5"
                          src={`${React.$imgUrl}/images/index/tu3_5.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_6"
                          src={`${React.$imgUrl}/images/index/tu3_6.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_7"
                          src={`${React.$imgUrl}/images/index/tu3_7.png`}
                        />
                      </div>

                      <div className="legend">
                        {user.token ? (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow titleTxt ">
                                {t("home_banner_5")}
                              </div>
                              <div className="lunboTitleTxt3 my-8  funbetTxt titleTxt">
                                {t("home_banner_6")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle  borderRadius text-20 funbetTxt", judgeLangFontSize(['br', 'fr', 'tmr']) && "font-15")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onWallet("buy");
                                }}
                              >
                                {t("home_BuyCrypto")}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow titleTxt ">
                                {t("home_banner_5")}
                              </div>
                              <div className="lunboTitleTxt3 my-8  funbetTxt titleTxt">
                                {t("home_banner_6")}
                              </div>
                            </div>
                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle funbetTxt  borderRadius text-20", judgeLangFontSize(['ru', 'fr']) && "font-15")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (user.token) {
                                    onWallet("buy");
                                  } else {
                                    handleOnLogin();
                                  }
                                }}
                              >
                                {t("home_SignUp")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="btnPointer bannerHeightBtn" onClick={() => { bannerGo5() }}>
                      <LazyLoadImage className="lunBoTuWh zuQiu-img4" src="value" />
                      <div className="legend">
                        {user.token ? (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow  titleTxt">
                                {t("home_banner_7")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_8")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle  borderRadius text-20 funbetTxt", judgeLangFontSize(['br', 'fr', 'tmr']) && "font-15")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onWallet("buy");
                                }}
                              >
                                {t("home_BuyCrypto")}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow  titleTxt">
                                {t("home_banner_7")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_8")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle funbetTxt  borderRadius text-20", judgeLangFontSize(['ru', 'fr']) && "font-14")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (user.token) {
                                    onWallet("buy");
                                  } else {
                                    handleOnLogin();
                                  }
                                }}
                                style={{ width: "200px" }}
                              >
                                {t("home_SignUp")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Carousel>}

                {isFilipino &&
                  <Carousel
                    className="positionAb activitybanner height-470-zuQiu"
                    showArrows={true}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    showThumbs={false}
                  >
                    <div className="btnPointer" onClick={() => { bannerGo1() }}>
                      <LazyLoadImage
                        className="lunBoTuWh"
                        src={
                          isMobile()
                            ? `${React.$imgUrl}/images/index/zuQiu_1.png` : `${React.$imgUrl}/images/index/zuQiu.png`
                        }
                      />
                      <div className="legend">
                        {user.token ? (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className="text-20 titleTxt displayPhoneSwitch1">
                                <span className="txtColorHui">
                                  {t("home_Hi")}
                                </span>
                                {userSetting?.user?.nickName}!
                                <span className="txtColorHui">
                                  {t("home_welcomeAboard")}
                                </span>
                              </div>
                              <div className={clsx("sigin-txt titleTxt", judgeLangFontSize(['vn', 'ru']) && "font-28")}>
                                {t("home_FirstDepositBonus")}
                              </div>
                              <div className={clsx("sigin-txt1 sigin-txt-yellow titleTxt", judgeLangFontSize(['fr', 'pl', 'br']) && "font-30")}>
                                <span>+270%</span>
                                {t("home_Reward")}
                              </div>
                            </div>
                            {!isMobile() && (
                              <Button
                                className="whitespace-nowrap width-160 signBtnStyle  borderRadius text-20 funbetTxt"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onWallet("buy");
                                }}
                              >
                                {t("home_BuyCrypto")}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className="sigin-txt titleTxt">
                                {t("home_SIGNUPAND")}
                                <span className="sigin-txt-yellow">
                                  {t("home_GET")}
                                </span>
                                {t("home_Reward")}
                              </div>
                              <div className="sigin-txt1  titleTxt">
                                {t("home_UPTO")}
                                <span className="sigin-txt-yellow">
                                  $2,700,000
                                </span>
                              </div>
                            </div>
                            {!isMobile() && (
                              <Button
                                className="whitespace-nowrap width-160  signBtnStyle funbetTxt  borderRadius text-20"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (user.token) {
                                    onWallet("buy");
                                  } else {
                                    handleOnLogin();
                                  }
                                }}
                              >
                                {t("home_SignUp")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <div style={{ position: "relative" }} className="btnPointer bannerHeightBtn " onClick={() => { bannerGo2() }}>
                        <LazyLoadImage
                          className="lunBoTuWh zuQiu-img1 positionAb"
                          src="value"
                          style={{ left: 0 }}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner1_1"
                          src={`${React.$imgUrl}/images/index/tu_1.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner1_2"
                          src={`${React.$imgUrl}/images/index/tu_2.png`}
                          style={{}}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner1_3"
                          src={`${React.$imgUrl}/images/index/tu_3.png`}
                          style={{}}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner1_4"
                          src={`${React.$imgUrl}/images/index/tu_4.png`}
                          style={{}}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner1_5"
                          src={`${React.$imgUrl}/images/index/tu_5.png`}
                          style={{}}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner1_6"
                          src={`${React.$imgUrl}/images/index/tu_6.png`}
                          style={{}}
                        />
                      </div>

                      <div className="legend">
                        {user.token ? (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className="lunboTitleTxt2 sigin-txt-yellow  titleTxt ">
                                {t("home_banner_1")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_2")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle  borderRadius text-20 funbetTxt", judgeLangFontSize(['fr', 'tmr', 'br']) && "font-15")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onWallet("buy");
                                }}
                              >
                                {t("home_BuyCrypto")}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className="lunboTitleTxt2 sigin-txt-yellow  titleTxt ">
                                {t("home_banner_1")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_2")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160 signBtnStyle funbetTxt  borderRadius text-20", judgeLangFontSize(['ru', 'fr']) && "font-14")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (user.token) {
                                    onWallet("buy");
                                  } else {
                                    handleOnLogin();
                                  }
                                }}
                              >
                                {t("home_SignUp")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="btnPointer bannerHeightBtn " onClick={() => { bannerGo3() }}>
                      <LazyLoadImage className="lunBoTuWh zuQiu-img2" src="value" />
                      <div className="legend">
                        {user.token ? (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className="lunboTitleTxt2 sigin-txt-yellow titleTxt">
                                {t("home_banner_3")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_4")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className="whitespace-nowrap width-160  signBtnStyle  borderRadius text-20 funbetTxt"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onWallet("buy");
                                }}
                              >
                                {t("home_BuyCrypto")}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow  titleTxt">
                                {t("home_banner_3")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_4")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className="whitespace-nowrap width-160  signBtnStyle funbetTxt  borderRadius text-20"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (user.token) {
                                    onWallet("buy");
                                  } else {
                                    handleOnLogin();
                                  }
                                }}
                              >
                                {t("home_SignUp")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <div style={{ position: "relative" }} className="btnPointer bannerHeightBtn " onClick={() => { bannerGo4() }}>
                        <LazyLoadImage
                          className="lunBoTuWh zuQiu-img3"
                          src="value"
                          style={{ left: 0 }}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_1"
                          src={`${React.$imgUrl}/images/index/tu3_1.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_2"
                          src={`${React.$imgUrl}/images/index/tu3_2.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_3"
                          src={`${React.$imgUrl}/images/index/tu3_3.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_4"
                          src={`${React.$imgUrl}/images/index/tu3_4.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_5"
                          src={`${React.$imgUrl}/images/index/tu3_5.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_6"
                          src={`${React.$imgUrl}/images/index/tu3_6.png`}
                        />
                        <LazyLoadImage
                          className="positionAb activityMoveBanner3_7"
                          src={`${React.$imgUrl}/images/index/tu3_7.png`}
                        />
                      </div>

                      <div className="legend">
                        {user.token ? (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow titleTxt ">
                                {t("home_banner_5")}
                              </div>
                              <div className="lunboTitleTxt3 my-10  funbetTxt titleTxt">
                                {t("home_banner_6")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle  borderRadius text-20 funbetTxt", judgeLangFontSize(['br', 'fr', 'tmr']) && "font-15")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onWallet("buy");
                                }}
                              >
                                {t("home_BuyCrypto")}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow titleTxt ">
                                {t("home_banner_5")}
                              </div>
                              <div className="lunboTitleTxt3 my-10  funbetTxt titleTxt">
                                {t("home_banner_6")}
                              </div>
                            </div>
                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle funbetTxt  borderRadius text-20", judgeLangFontSize(['ru', 'fr']) && "font-15")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (user.token) {
                                    onWallet("buy");
                                  } else {
                                    handleOnLogin();
                                  }
                                }}
                              >
                                {t("home_SignUp")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="btnPointer bannerHeightBtn " onClick={() => { bannerGo5() }}>
                      <LazyLoadImage className="lunBoTuWh zuQiu-img4" src="value" />
                      <div className="legend">
                        {user.token ? (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow  titleTxt">
                                {t("home_banner_7")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_8")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle  borderRadius text-20 funbetTxt", judgeLangFontSize(['br', 'fr', 'tmr']) && "font-15")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onWallet("buy");
                                }}
                              >
                                {t("home_BuyCrypto")}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow  titleTxt">
                                {t("home_banner_7")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_8")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle funbetTxt  borderRadius text-20", judgeLangFontSize(['ru', 'fr']) && "font-14")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (user.token) {
                                    onWallet("buy");
                                  } else {
                                    handleOnLogin();
                                  }
                                }}
                                style={{ width: "200px" }}
                              >
                                {t("home_SignUp")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="btnPointer bannerHeightBtn " onClick={() => { bannerGo6() }}>
                      <LazyLoadImage className="lunBoTuWh" src={
                        isMobile()
                          ? `${React.$imgUrl}/images/index/saiMa_1.png` : `${React.$imgUrl}/images/index/saiMa.png`
                      } />
                      <div className="legend">
                        {user.token ? (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow  titleTxt">
                                {t("home_banner_9")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_11")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle  borderRadius text-20 funbetTxt", judgeLangFontSize(['br', 'fr', 'tmr']) && "font-15")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onWallet("buy");
                                }}
                              >
                                {t("home_BuyCrypto")}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow  titleTxt">
                                {t("home_banner_9")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_11")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle funbetTxt  borderRadius text-20", judgeLangFontSize(['ru', 'fr']) && "font-14")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (user.token) {
                                    onWallet("buy");
                                  } else {
                                    handleOnLogin();
                                  }
                                }}
                                style={{ width: "200px" }}
                              >
                                {t("home_SignUp")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="btnPointer bannerHeightBtn " onClick={() => { bannerGo6() }}>
                      <LazyLoadImage className="lunBoTuWh" src={
                        isMobile()
                          ? `${React.$imgUrl}/images/index/douJi_1.png` : `${React.$imgUrl}/images/index/douJi.png`
                      } />
                      <div className="legend">
                        {user.token ? (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow  titleTxt">
                                {t("home_banner_10")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_12")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle  borderRadius text-20 funbetTxt", judgeLangFontSize(['br', 'fr', 'tmr']) && "font-15")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  onWallet("buy");
                                }}
                              >
                                {t("home_BuyCrypto")}
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div className="lunboTxtLeft">
                            <div className="lunboTxtBox">
                              <div className=" lunboTitleTxt2 sigin-txt-yellow  titleTxt">
                                {t("home_banner_10")}
                              </div>
                              <div className="lunboTitleTxt3 my-10 funbetTxt titleTxt">
                                {t("home_banner_12")}
                              </div>
                            </div>

                            {!isMobile() && (
                              <Button
                                className={clsx("whitespace-nowrap width-160  signBtnStyle funbetTxt  borderRadius text-20", judgeLangFontSize(['ru', 'fr']) && "font-14")}
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation()
                                  if (user.token) {
                                    onWallet("buy");
                                  } else {
                                    handleOnLogin();
                                  }
                                }}
                                style={{ width: "200px" }}
                              >
                                {t("home_SignUp")}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                  </Carousel>}

                <div className="flex mt-280 positionAb displayPhoneSwitch1">
                  <div className="activitybanner marginJuZhong">
                    <div className="flex grow shrink-0 flex-col items-center container ">
                      <motion.div
                        variants={container}
                        initial="hidden"
                        animate="show"
                        className="flex activitybanner promotion-list"
                      >
                        <motion.div
                          variants={item}
                          className="min-w-full sm:min-w-224"
                        >


                          {/* <div
                            className="bannerbutton font-weight500  btnPointer bannerImg1 funbetTxt"
                            style={loaded ? style : null}
                            onClick={() => {
                              window.location.href = "comingSoon/StakeNFT";
                            }}
                          >
                            <div className="banner-txt pt-10 pl-10 funbetTxt">
                              {t("home_StakeNFT")}
                              <span className="blueColor funbetTxt" >
                                USGT
                              </span>{" "}
                              {t("home_everyday")}
                            </div>
                          </div> */}



                          <div
                            className="bannerbutton font-weight500  btnPointer bannerImg1 funbetTxt"
                            style={loaded ? style : null}
                            onClick={() => {
                              window.location.href = "/gameOther/1/Slots/1";
                            }}
                          >
                            <div className="banner-txt pt-12 pl-12 funbetTxt text-20" style={{ textAlign: "left" }}>
                              3000+ <br />
                              <span className="blueColor funbetTxt" >
                                {t("home_Slots")}
                              </span>
                              <div className="backGroundGreenBtn" style={{ position: "fixed", bottom: "12px", width: "100px", overflow: "hidden", fontSize: "16px", borderRadius: "30px", height: "40px", textAlign: "center", lineHeight: "40px" }}>{t("home_GoTo")}</div>
                            </div>
                          </div>



                        </motion.div>
                        <motion.div
                          variants={item}
                          className="min-w-full sm:min-w-224"
                        >



                          {/* <div
                            className="bannerbutton font-weight500  btnPointer bannerImg2 funbetTxt"
                            onClick={() => {
                              window.location.href = "comingSoon/Bet on";
                            }}
                          >
                            <div className="banner-txt pt-10 pl-10 funbetTxt">
                              {t("home_Beton")}
                              <span className="ziColor funbetTxt">
                                {t("home_mining")}
                              </span>
                              {t("home_shareholder")}
                            </div>
                          </div> */}



                          <div
                            className="bannerbutton font-weight500  btnPointer bannerImg2 funbetTxt"
                            onClick={() => {
                              if (user.token) {
                                onWallet("deposite");
                              } else {
                                handleOnLogin();
                              }
                            }}
                          >
                            <div className="banner-txt pt-12 pl-12 funbetTxt text-20" style={{ textAlign: "left" }}>
                              {t("home_BetFor")}<br />
                              <span className="ziColor funbetTxt">
                                Web3.0
                              </span>
                              <div className="backGroundGreenBtn" style={{ position: "fixed", bottom: "12px", width: "100px", overflow: "hidden", fontSize: "16px", borderRadius: "30px", height: "40px", textAlign: "center", lineHeight: "40px" }}>{t("home_GoTo")}</div>
                            </div>
                          </div>

                        </motion.div>
                        <motion.div
                          variants={item}
                          className="min-w-full sm:min-w-224"
                        >



                          {/* <div
                            className="bannerbutton font-weight500  btnPointer bannerImg3 funbetTxt"
                            onClick={() => {
                              window.location.href = "comingSoon/Stake";
                            }}
                          >
                            <div className="banner-txt pt-10 pl-10 funbetTxt">
                              {t("home_Stake")}
                              <span className="yellowColor funbetTxt">BGT</span>
                              {t("home_dividend")}
                            </div>
                          </div> */}



                          <div
                            className="bannerbutton font-weight500  btnPointer bannerImg3 funbetTxt"
                            onClick={() => {
                              if (user.token) {
                                onWallet("deposite");
                              } else {
                                handleOnLogin();
                              }
                            }}
                          >
                            <div className="banner-txt pt-12 pl-12 funbetTxt text-20" style={{ textAlign: "left" }}>
                              150+<br />
                              <span className="greenColor funbetTxt"> {t("home_CountryFiat")} </span>
                              <div className="backGroundGreenBtn" style={{ position: "fixed", bottom: "12px", width: "100px", overflow: "hidden", fontSize: "16px", borderRadius: "30px", height: "40px", textAlign: "center", lineHeight: "40px" }}>{t("home_GoTo")}</div>
                            </div>
                          </div>




                        </motion.div>
                        <motion.div
                          variants={item}
                          className="min-w-full sm:min-w-224"
                        >



                          {/* <div
                            className="bannerbutton font-weight500  btnPointer bannerImg4 funbetTxt"
                            onClick={() => {
                              window.location.href = "comingSoon/Banker";
                            }}
                          >
                            <div className="banner-txt pt-10 pl-10 funbetTxt">
                              {t("home_Stake")}{" "}
                              <span className="greenColor funbetTxt">USGT</span>{" "}
                              {t("home_Banker")}
                            </div>
                          </div> */}



                          <div
                            className="bannerbutton font-weight500  btnPointer bannerImg4 funbetTxt "
                            onClick={() => {
                              if (user.token) {
                                onWallet("deposite");
                              } else {
                                handleOnLogin();
                              }
                            }}
                          >
                            <div className="banner-txt pt-12 pl-12 funbetTxt text-20" style={{ textAlign: "left" }}>
                              100+<br />
                              <span className="yellowColor funbetTxt"> {t("home_CryptNFTs")}</span>
                              <div className="backGroundGreenBtn" style={{ position: "fixed", bottom: "12px", width: "100px", overflow: "hidden", fontSize: "16px", borderRadius: "30px", height: "40px", textAlign: "center", lineHeight: "40px" }}>{t("home_GoTo")}</div>
                            </div>
                          </div>


                        </motion.div>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="displayPhoneSwitch ">
            <div className="promotion-list1 " style={{ width: "100%" }}>
              <div
                className="promotion-item p-8 mt-8 bg1Color funbetTxt"
                onClick={() => {
                  onChangeStatus("checkIn", true);
                }}
              >
                <div>
                  <div style={window.localStorage.getItem("lang") == "fi" ? { fontSize: "12px", color: "#E8E8EA" } : { fontSize: "14px", color: "#E8E8EA" }}>
                    {t("home_Checkin")}
                  </div>
                  <div
                    className="colorGameListTitle funbetTxt"
                    style={{ fontSize: "13px" }}
                  >
                    {t("home_Bouns")}
                  </div>
                </div>
                <div className="promotion-item-img ">
                  <LazyLoadImage src={`${React.$imgUrl}/images/nav/1.png`} alt="" />
                </div>
                {userSignData?.SignedToday === false && user.token && (
                  <LazyLoadImage
                    className="positionAb"
                    style={{
                      right: 0,
                      top: 0,
                      width: "16px",
                      height: "16px",
                    }}
                    src="assets/images/activity/redPoint.png"
                    alt=""
                  />
                )}
              </div>

              <div
                className="promotion-item p-8 promotion-item-bg2  mt-8"
                onClick={() => {
                  onChangeStatus("spin", true);
                }}
              >
                <div>
                  <div style={{ fontSize: "14px", color: "#E8E8EA" }}>
                    {t("home_SPIN")}
                  </div>
                  <div
                    className="colorGameListTitle funbetTxt"
                    style={{ fontSize: "13px" }}
                  >
                    {t("home_Bouns")}
                  </div>
                </div>
                <div className="promotion-item-img">
                  <LazyLoadImage
                    className="turnAni"
                    src={`${React.$imgUrl}/images/nav/2.png`}
                    alt=""
                  />
                </div>
                <div
                  className="posZhiZhenAbsolute"
                  style={{ width: "60px", height: "60px" }}
                >
                  <LazyLoadImage src={`${React.$imgUrl}/images/nav/2_1.png`} alt="" />
                </div>
                {userTurnTimes > 0 && user.token && (
                  <LazyLoadImage
                    className="positionAb"
                    style={{
                      right: 0,
                      top: 0,
                      width: "16px",
                      height: "16px",
                    }}
                    src="assets/images/activity/redPoint.png"
                    alt=""
                  />
                )}
              </div>

              <div
                className="promotion-item p-8 promotion-item-bg3  mt-8"
                onClick={() => {
                  onChangeStatus("relief", true);
                }}
              >
                <div>
                  <div style={{ fontSize: "14px", color: "#E8E8EA" }}>
                    {t("home_Relief")}
                  </div>
                  <div
                    className="colorGameListTitle funbetTxt"
                    style={{ fontSize: "13px" }}
                  >
                    {t("home_Bouns")}
                  </div>
                </div>
                <div className="promotion-item-img">
                  <LazyLoadImage src={`${React.$imgUrl}/images/nav/3.png`} alt="" />
                </div>
                {receiveStatus === 0 && user.token && (
                  <LazyLoadImage
                    className="positionAb"
                    style={{
                      right: 0,
                      top: 0,
                      width: "16px",
                      height: "16px",
                    }}
                    src="assets/images/activity/redPoint.png"
                    alt=""
                  />
                )}
              </div>

              <div
                className="promotion-item p-8 promotion-item-bg4  mt-8"
                onClick={() => {
                  onChangeStatus("share", true);
                }}
              >
                <div>
                  <div style={{ fontSize: "14px", color: "#E8E8EA" }}>
                    {t("home_Share")}
                  </div>
                  <div
                    className="colorGameListTitle funbetTxt"
                    style={{ fontSize: "13px" }}
                  >
                    {t("home_Bouns")}
                  </div>
                </div>
                <div className="promotion-item-img">
                  <LazyLoadImage src={`${React.$imgUrl}/images/nav/4.png`} alt="" />
                </div>
                {shareData?.CanGetRewardTdy === true && user.token && (
                  <LazyLoadImage
                    className="positionAb"
                    style={{
                      right: 0,
                      top: 0,
                      width: "16px",
                      height: "16px",
                    }}
                    src="assets/images/activity/redPoint.png"
                    alt=""
                  />
                )}
              </div>

              <div
                className="promotion-item p-8 promotion-item-bg5  mt-8"
                onClick={() => {
                  onChangeStatus("deposit", true);
                }}
              >
                <div>
                  <div style={{ fontSize: "14px", color: "#E8E8EA" }}>
                    {t("home_Deposit")}
                  </div>
                  <div
                    className="colorGameListTitle funbetTxt"
                    style={{ fontSize: "13px" }}
                  >
                    {t("home_Bouns")}
                  </div>
                </div>
                <div className="promotion-item-img">
                  <LazyLoadImage src={`${React.$imgUrl}/images/nav/5.png`} alt="" />
                </div>

                {(
                  chargeGiftStatus?.HasGotFirst === false ||
                  chargeGiftStatus?.RechargeCountAll === 0 ||
                  (chargeGiftStatus?.HasTodayReward === true && chargeGiftStatus?.RechargeCount <= 3 && chargeGiftStatus?.HasGotDailyThird == false)
                ) &&
                  user.token && (
                    <LazyLoadImage
                      className="positionAb"
                      style={{
                        right: 0,
                        top: 0,
                        width: "16px",
                        height: "16px",
                      }}
                      src="assets/images/activity/redPoint.png"
                      alt=""
                    />
                  )}
              </div>

              <div
                className="promotion-item p-8 promotion-item-bg6  mt-8"
                onClick={() => {
                  history.push("/fund");
                }}
              >
                <div>
                  <div style={{ fontSize: "14px", color: "#E8E8EA" }}>
                    {t("home_Fund")}
                  </div>
                  <div
                    className="colorGameListTitle funbetTxt"
                    style={{ fontSize: "13px" }}
                  >
                    {t("home_Bouns")}
                  </div>
                </div>
                <div className="promotion-item-img">
                  <LazyLoadImage src={`${React.$imgUrl}/images/nav/6.png`} alt="" />
                </div>
              </div>

              <div
                className="vipPhoneClue"
                onClick={() => {
                  history.push("/vip");
                }}
              >
                <div
                  className=" text-16 funbetTxt"
                  style={{ margin: "12px 0px 0px 10px", color: "#E8E8EA" }}
                >
                  {t("home_VIPClub")}
                </div>
              </div>
            </div>
          </div>

          <div className="flex grow shrink-0 flex-col items-center container ">
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className="activitybanner"
            >
              <div className="min-w-full sm:min-w-224">
                <div className="zuQiu">
                  <div className="promotion-list1 activitybanner marginJuZhong">
                    <motion.div variants={item} className="">
                      <div className="mt-20 ">
                        <div className="flex">
                          <LazyLoadImage
                            className="w-24 h-24"
                            src={`${React.$imgUrl}/images/icon/1.png`}
                          />
                          <div className="font-14 ml-8 mt-4 font-weight500 fontColor funbetTxt">
                            {t("home_Slots")}
                          </div>
                        </div>
                      </div>
                      <div
                        className="mt-20 imgHidden borderRadius-10 "
                        onClick={() => {
                          goRouter(`/game/${casinoData.recommondTag}`);
                        }}
                      >
                        <LazyLoadImage
                          className="imgMohu casino-img "
                          src={`${React.$imgUrl}/images/index/casino.png`}
                        />
                        <LazyLoadImage
                          className="displayImg index4bigImg casino-img btnPointer"
                          src={`${React.$imgUrl}/images/index/bigDiZheZhao.png`}
                        />
                      </div>
                    </motion.div>


                    <motion.div variants={item}>
                      <div className="mt-20">
                        <div className="flex">
                          <LazyLoadImage
                            className="w-24 h-24"
                            src={`${React.$imgUrl}/images/icon/4.png`}
                          />
                          <div className="font-14 ml-8 mt-4 font-weight500 fontColor funbetTxt">
                            {t("game_LiveCasino")}
                          </div>
                        </div>
                      </div>

                      <div className="mt-20 imgHidden borderRadius-10">
                        <LazyLoadImage
                          className="imgMohu"
                          src={`${React.$imgUrl}/images/index/option1.png`}
                        />
                        <LazyLoadImage
                          className="displayImg index4bigImg btnPointer"
                          src={`${React.$imgUrl}/images/index/bigDiZheZhao.png`}
                          onClick={() => {
                            history.push("/gameOther/2/Live%20Casino/1");
                          }}
                        />
                      </div>
                    </motion.div>

                  </div>
                </div>

                <div className="zuQiu">
                  <div className="promotion-list activitybanner marginJuZhong">
                    <motion.div variants={item}>
                      <div className="mt-20">
                        <div className="flex">
                          <LazyLoadImage
                            className="w-24 h-24"
                            src={`${React.$imgUrl}/images/icon/3.png`}
                          />
                          <div className="font-14 ml-8 mt-4 font-weight500 fontColor funbetTxt">
                            {t("home_FuniHash")}
                          </div>
                        </div>
                      </div>
                      <div className="mt-20 imgHidden borderRadius-10">
                        <LazyLoadImage
                          className="imgMohu"
                          src={`${React.$imgUrl}/images/index/hash.png`}
                        />
                        <LazyLoadImage
                          className="displayImg index4bigImg btnPointer"
                          src={`${React.$imgUrl}/images/index/bigDiZheZhao.png`}
                          onClick={() => {
                            history.push("/gameOther/3/Provably Fair/1");
                          }}
                        />
                      </div>
                    </motion.div>

                    <motion.div variants={item}>
                      <div className="mt-20 ">
                        <div className="flex">
                          <LazyLoadImage
                            className="w-24 h-24 "
                            src={`${React.$imgUrl}/images/icon/2.png`}
                          />
                          <div className="font-14 ml-8 mt-4 font-weight500 fontColor">
                            {t("home_Sports")}
                          </div>
                        </div>
                      </div>
                      <div className="mt-20  imgHidden borderRadius-10 ">
                        <LazyLoadImage
                          className="imgMohu"
                          src={`${React.$imgUrl}/images/index/sport.png`}
                        />
                        <LazyLoadImage
                          className="displayImg index4bigImg btnPointer"
                          src={`${React.$imgUrl}/images/index/bigDiZheZhao.png`}
                          onClick={() => {
                            if (user.token) {
                              history.push("/sport/europeanView");
                            } else {
                              sportHandleOnLogin();
                            }
                          }}
                        />
                      </div>
                    </motion.div>
                  </div>
                </div>

                <motion.div variants={item} className="zuQiu mt-40">
                  <div className="flex justifyContent activitybanner payDi borderRadius-10 buyCryP-20">
                    <LazyLoadImage className="payImg" src="value" />
                    <div>
                      <div className="crypto payPT">
                        <div className="crypto-txt font-weight500 pr-10 displayPhoneSwitch1 funbetTxt">
                          {t("home_FastAndEasy")}
                        </div>
                        <Button
                          className={clsx("whitespace-nowrap  borderRadius phoneTop funbetTxt", judgeLangFontSize(['ru', 'tmr', 'fi', 'id', 'es']) && "font-14")}
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            if (user.token) {
                              onWallet();
                            } else {
                              onLogin();
                            }
                          }}
                        >
                          {t("home_BuyCrypto")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          </div>
          <Common_gameReport />
          <Common_foot />
          <div className="mt-32 promotion-list colorGameListTitle mb-20 displayPhoneSwitch1  text-align">
            <div className="funbetTxt">FuniBet 2022- ALL Rights Reserved</div>
          </div>
        </div>

        <AnimateModal
          className="checkInDi"
          closeClass="closeBtnCheckIn"
          open={openChangeStatus.checkIn}
          onClose={() => onChangeStatus("checkIn", false)}
        >
          {/* <BootstrapDialog
                    onClose={() => { onChangeStatus('checkIn', false) }}
                    aria-labelledby="customized-dialog-title"
                    open={openChangeStatus.checkIn}
                    className="noBackGroundCheckIn checkInDiWH"
                >
                    <DialogContent sx={{ border: "none" }} className='checkInDi' dividers> */}
          <div>
            <Typography id="customized-dialog-title" className="textAlignRight">
              <span
                style={{ display: "block", color: "#FFD569" }}
                className=" checkInTitleMt  text-align titleTxt"
              >
                {t("home_CheckinBouns")}
              </span>{" "}
            </Typography>
            <motion.div variants={container} initial="hidden" animate="show">
              <motion.div
                variants={item}
                className="text-16  text-align checkInTxtmtMiaoShu funbetTxt"
              >
                {t("home_Checkineveryweek")}
              </motion.div>
              <motion.div
                variants={item}
                style={{
                  position: "relative",
                  paddingTop: "15%",
                  marginLeft: "5%",
                }}
              >
                <div
                  className="borderRadius "
                  style={{
                    width: "90%",
                    height: "12px",
                    backgroundColor: "#0F1520",
                    position: "absolute",
                  }}
                >
                  <div
                    className="borderRadius"
                    style={{
                      width: 14.3 * userSignData?.SignTimes + "%",
                      height: "13px",
                      backgroundColor: "#EA9B13",
                      position: "absolute",
                    }}
                  >
                    {" "}
                  </div>
                </div>
                <motion.div
                  variants={container}
                  initial="hidden"
                  animate="show"
                  className="flex"
                  style={{ position: "absolute", top: "14%", left: "22%" }}
                >
                  <motion.div
                    variants={item}
                    className="width-85 align-item text-align  "
                  >
                    <div className="text-14" style={{ color: "#FFD569" }}>
                      3{t("home_Day")}
                    </div>
                    <LazyLoadImage
                      src={`${React.$imgUrl}/images/index/jinBi2.png`}
                      style={{ width: "64px" }}
                    />
                    <div className="text-14" style={{ color: "#ffffff" }}>
                      {signInConfig[2]?.cashCoupon / 100 || 0}U
                    </div>
                  </motion.div>
                  <motion.div
                    variants={item}
                    className="width-85 align-item text-align"
                    style={{ marginLeft: "3%" }}
                  >
                    <div className="text-14" style={{ color: "#FFD569" }}>
                      5{t("home_Day")}
                    </div>
                    <LazyLoadImage
                      src={`${React.$imgUrl}/images/index/jinBi3.png`}
                      style={{ width: "64px" }}
                    />
                    <div className="text-14" style={{ color: "#ffffff" }}>
                      {signInConfig[4]?.cashCoupon / 100 || 0}U
                    </div>
                  </motion.div>
                  <motion.div
                    variants={item}
                    className="width-85 align-item text-align"
                    style={{ marginLeft: "3%" }}
                  >
                    <div className="text-14" style={{ color: "#FFD569" }}>
                      7{t("home_Day")}
                    </div>
                    <LazyLoadImage
                      src="assets/images/index/jinBi3.png"
                      style={{ width: "64px" }}
                    />
                    <div className="text-14" style={{ color: "#ffffff" }}>
                      {signInConfig[6]?.cashCoupon / 100 || 0}U
                    </div>
                  </motion.div>
                </motion.div>
              </motion.div>

              <div className="flex px-2" style={{ marginTop: "20%" }}>
                <motion.div
                  variants={item}
                  className=" align-item text-align  btnPointer txtBrightness mx-4"
                  style={{ position: "relative", width: "23%" }}
                  onClick={() => { overChenckIn(); }}
                >
                  <LazyLoadImage
                    className="positionAb"
                    style={{ top: "0px", left: "0px" }}
                    src={`${React.$imgUrl}/images/index/phoneQianDao1.png`}
                  />
                  <div
                    className="positionAb text-14 marginJuZhong"
                    style={{
                      paddingTop: "8%",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {t("home_Sunday")}
                  </div>
                  <LazyLoadImage
                    className="positionAb marginJuZhong"
                    style={{ top: "24px", left: "8px", width: "80%" }}
                    src={`${React.$imgUrl}/images/index/jinBi1.png`}
                  />
                  <div
                    className="positionAb text-14"
                    style={{
                      top: "92px",
                      left: "0px",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {signInConfig[0]?.cashCoupon / 100 || 0}U
                  </div>
                  {1 <= userSignData?.SignTimes && (
                    <LazyLoadImage
                      className="positionAb"
                      style={{ top: "0px", left: "0px" }}
                      src={`${React.$imgUrl}/images/index/checkOver1.png`}
                    />
                  )}
                  {!userSignData?.SignedToday &&
                    1 == userSignData?.SignTimes + 1 && (
                      <LazyLoadImage
                        className="positionAb"
                        style={{ top: "0px", left: "0px" }}
                        src={`${React.$imgUrl}/images/index/checkOver_1.png`}
                        onClick={() => {
                          handleCheckIn();
                        }}
                      />
                    )}
                </motion.div>

                <motion.div
                  variants={item}
                  className=" align-item text-align  btnPointer txtBrightness mx-4"
                  style={{ position: "relative", width: "23%" }}
                  onClick={() => { overChenckIn(); }}
                >
                  <LazyLoadImage
                    className="positionAb"
                    style={{ top: "0px", left: "0px" }}
                    src={`${React.$imgUrl}/images/index/phoneQianDao1.png`}
                  />
                  <div
                    className="positionAb text-14 marginJuZhong "
                    style={{
                      paddingTop: "8%",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {t("home_Monday")}
                  </div>
                  <LazyLoadImage
                    className="positionAb marginJuZhong"
                    style={{ top: "24px", left: "8px", width: "80%" }}
                    src={`${React.$imgUrl}/images/index/jinBi1.png`}
                  />
                  <div
                    className="positionAb text-14"
                    style={{
                      top: "92px",
                      left: "0px",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {signInConfig[1]?.cashCoupon / 100 || 0}U
                  </div>
                  {2 <= userSignData?.SignTimes && (
                    <LazyLoadImage
                      className="positionAb"
                      style={{ top: "0px", left: "0px" }}
                      src={`${React.$imgUrl}/images/index/checkOver1.png`}
                    />
                  )}
                  {!userSignData?.SignedToday &&
                    2 == userSignData?.SignTimes + 1 && (
                      <LazyLoadImage
                        className="positionAb"
                        style={{ top: "0px", left: "0px" }}
                        src={`${React.$imgUrl}/images/index/checkOver_1.png`}
                        onClick={() => {
                          handleCheckIn();
                        }}
                      />
                    )}
                </motion.div>

                <motion.div
                  variants={item}
                  className=" align-item text-align  btnPointer txtBrightness mx-4"
                  style={{ position: "relative", width: "23%" }}
                  onClick={() => { overChenckIn(); }}
                >
                  <LazyLoadImage
                    className="positionAb"
                    style={{ top: "0px", left: "0px" }}
                    src={`${React.$imgUrl}/images/index/phoneQianDao1.png`}
                  />
                  <div
                    className="positionAb text-14 marginJuZhong "
                    style={{
                      paddingTop: "8%",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {t("home_Tuesday")}
                  </div>
                  <LazyLoadImage
                    className="positionAb marginJuZhong"
                    style={{ top: "24px", left: "8px", width: "80%" }}
                    src="assets/images/index/jinBi2.png"
                  />
                  <div
                    className="positionAb text-14"
                    style={{
                      top: "92px",
                      left: "0px",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {signInConfig[2]?.cashCoupon / 100 || 0}U
                  </div>
                  {3 <= userSignData?.SignTimes && (
                    <LazyLoadImage
                      className="positionAb"
                      style={{ top: "0px", left: "0px" }}
                      src={`${React.$imgUrl}/images/index/checkOver1.png`}
                    />
                  )}
                  {!userSignData?.SignedToday &&
                    3 == userSignData?.SignTimes + 1 && (
                      <LazyLoadImage
                        className="positionAb"
                        style={{ top: "0px", left: "0px" }}
                        src={`${React.$imgUrl}/images/index/checkOver_1.png`}
                        onClick={() => {
                          handleCheckIn();
                        }}
                      />
                    )}
                </motion.div>

                <motion.div
                  variants={item}
                  className=" align-item text-align  btnPointer txtBrightness mx-4"
                  style={{ position: "relative", width: "23%" }}
                  onClick={() => { overChenckIn(); }}
                >
                  <LazyLoadImage
                    className="positionAb"
                    style={{ top: "0px", left: "0px" }}
                    src={`${React.$imgUrl}/images/index/phoneQianDao1.png`}
                  />
                  <div
                    className="positionAb text-14 marginJuZhong "
                    style={{
                      paddingTop: "8%",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {t("home_Wednesday")}
                  </div>
                  <LazyLoadImage
                    className="positionAb marginJuZhong"
                    style={{ top: "24px", left: "8px", width: "80%" }}
                    src={`${React.$imgUrl}/images/index/jinBi2.png`}
                  />
                  <div
                    className="positionAb text-14"
                    style={{
                      top: "92px",
                      left: "0px",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {signInConfig[3]?.cashCoupon / 100 || 0}U
                  </div>
                  {4 <= userSignData?.SignTimes && (
                    <LazyLoadImage
                      className="positionAb"
                      style={{ top: "0px", left: "0px" }}
                      src={`${React.$imgUrl}/images/index/checkOver1.png`}
                    />
                  )}
                  {!userSignData?.SignedToday &&
                    4 == userSignData?.SignTimes + 1 && (
                      <LazyLoadImage
                        className="positionAb"
                        style={{ top: "0px", left: "0px" }}
                        src={`${React.$imgUrl}/images/index/checkOver_1.png`}
                        onClick={() => {
                          handleCheckIn();
                        }}
                      />
                    )}
                </motion.div>
              </div>

              <div
                className="flex px-2 justifyContent"
                style={{ marginTop: "40%" }}
              >
                <motion.div
                  variants={item}
                  className=" align-item text-align  btnPointer txtBrightness mx-4"
                  style={{ position: "relative", width: "23%" }}
                  onClick={() => { overChenckIn(); }}
                >
                  <LazyLoadImage
                    className="positionAb"
                    style={{ top: "0px", left: "0px" }}
                    src={`${React.$imgUrl}/images/index/phoneQianDao1.png`}
                  />
                  <div
                    className="positionAb text-14 marginJuZhong"
                    style={{
                      paddingTop: "8%",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {t("home_Thursday")}
                  </div>
                  <LazyLoadImage
                    className="positionAb marginJuZhong"
                    style={{ top: "24px", left: "8px", width: "80%" }}
                    src={`${React.$imgUrl}/images/index/jinBi3.png`}
                  />
                  <div
                    className="positionAb text-14"
                    style={{
                      top: "92px",
                      left: "0px",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {signInConfig[4]?.cashCoupon / 100 || 0}U
                  </div>
                  {5 <= userSignData?.SignTimes && (
                    <LazyLoadImage
                      className="positionAb"
                      style={{ top: "0px", left: "0px" }}
                      src={`${React.$imgUrl}/images/index/checkOver1.png`}
                    />
                  )}
                  {!userSignData?.SignedToday &&
                    5 == userSignData?.SignTimes + 1 && (
                      <LazyLoadImage
                        className="positionAb"
                        style={{ top: "0px", left: "0px" }}
                        src={`${React.$imgUrl}/images/index/checkOver_1.png`}
                        onClick={() => {
                          handleCheckIn();
                        }}
                      />
                    )}
                </motion.div>

                <motion.div
                  variants={item}
                  className=" align-item text-align  btnPointer txtBrightness mx-4"
                  style={{ position: "relative", width: "23%" }}
                  onClick={() => { overChenckIn(); }}
                >
                  <LazyLoadImage
                    className="positionAb"
                    style={{ top: "0px", left: "0px" }}
                    src={`${React.$imgUrl}/images/index/phoneQianDao1.png`}
                  />
                  <div
                    className="positionAb text-14 marginJuZhong "
                    style={{
                      paddingTop: "8%",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {t("home_Friday")}
                  </div>
                  <LazyLoadImage
                    className="positionAb marginJuZhong"
                    style={{ top: "24px", left: "8px", width: "80%" }}
                    src={`${React.$imgUrl}/images/index/jinBi3.png`}
                  />
                  <div
                    className="positionAb text-14"
                    style={{
                      top: "92px",
                      left: "0px",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {signInConfig[5]?.cashCoupon / 100 || 0}U
                  </div>
                  {6 <= userSignData?.SignTimes && (
                    <LazyLoadImage
                      className="positionAb"
                      style={{ top: "0px", left: "0px" }}
                      src={`${React.$imgUrl}/images/index/checkOver1.png`}
                    />
                  )}
                  {!userSignData?.SignedToday &&
                    6 == userSignData?.SignTimes + 1 && (
                      <LazyLoadImage
                        className="positionAb"
                        style={{ top: "0px", left: "0px" }}
                        src={`${React.$imgUrl}/images/index/checkOver_1.png`}
                        onClick={() => {
                          handleCheckIn();
                        }}
                      />
                    )}
                </motion.div>

                <motion.div
                  variants={item}
                  className=" align-item text-align  btnPointer txtBrightness mx-4"
                  style={{ position: "relative", width: "23%" }}
                  onClick={() => { overChenckIn(); }}
                >
                  <LazyLoadImage
                    className="positionAb"
                    style={{ top: "0px", left: "0px" }}
                    src={`${React.$imgUrl}/images/index/phoneQianDao1.png`}
                  />
                  <div
                    className="positionAb text-14 marginJuZhong "
                    style={{
                      paddingTop: "8%",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {t("home_Saturday")}
                  </div>
                  <LazyLoadImage
                    className="positionAb marginJuZhong"
                    style={{ top: "24px", left: "8px", width: "80%" }}
                    src={`${React.$imgUrl}/images/index/jinBi4.png`}
                  />
                  <div
                    className="positionAb text-14"
                    style={{
                      top: "92px",
                      left: "0px",
                      width: "100%",
                      color: "#ffffff",
                    }}
                  >
                    {signInConfig[6]?.cashCoupon / 100 || 0}U
                  </div>
                  {7 <= userSignData?.SignTimes && (
                    <LazyLoadImage
                      className="positionAb"
                      style={{ top: "0px", left: "0px" }}
                      src={`${React.$imgUrl}/images/index/checkOver1.png`}
                    />
                  )}
                  {!userSignData?.SignedToday &&
                    7 == userSignData?.SignTimes + 1 && (
                      <LazyLoadImage
                        className="positionAb"
                        style={{ top: "0px", left: "0px" }}
                        src={`${React.$imgUrl}/images/index/checkOver_1.png`}
                        onClick={() => {
                          handleCheckIn();
                        }}
                      />
                    )}
                </motion.div>
              </div>
            </motion.div>
          </div>
          {/* </DialogContent>
                </BootstrapDialog> */}
        </AnimateModal>

        {/*救济金弹窗活动*/}
        <Relief
          open={openChangeStatus.relief}
          onClose={() => onChangeStatus("relief", false)}
        />

        <PhoneBind
          open={isPhoneBind}
          onClose={() => setIsPhoneBind(false)}
        />

        {/*分享弹窗活动*/}

        <AnimateModal
          className="shareDi"
          closeClass="closeBtnShare"
          open={openChangeStatus.share}
          onClose={() => onChangeStatus("share", false)}
        >
          <Share />
        </AnimateModal>

        {/*首充弹窗活动*/}
        <Deposit
          open={openChangeStatus.deposit}
          onClose={() => onChangeStatus("deposit", false)}
        />

        {/*转盘弹窗活动*/}
        <AnimateModal
          className="spinDi "
          closeClass="closeBtnspin"
          open={openChangeStatus.spin}
          onClose={() => onChangeStatus("spin", false)}
        >
          <Spin />
        </AnimateModal>
      </div>

      <AwardPop
        open={popSignAward}
        onClose={() => {
          setPopSignAward(false);
        }}
        symbol={signInRewardBalance.symbol}
        symbolImg={`assets/images/symbol/${signInRewardBalance.symbol}.png`}
        balance={signInRewardBalance.balance}
      />

      <BingTips open={showBindTips}
        successFun={() => {
          setIsPhoneBind(true)
          setShowBindTips(false)
        }}
        errorFun={() => {
          setShowBindTips(false)
        }}
      />
    </div>
  );
}
export default Home;
