const locale = {
    home_StakeNFT: '质押NFT赚取更多',
    home_everyday: '每一天',
    home_Beton: '投注',
    home_mining: '挖矿',
    home_shareholder: '成为股东',
    home_Stake: '质押',
    home_dividend: '获取利润分红',
    home_Banker: '成为合伙人之一',
    home_SIGNUPAND: '立即注册',
    home_GET: '获取',
    home_UPTO: '总计',
    home_FuniHash: '区块链游戏',
    home_Option: '期权',

    home_Reward: '奖励',
    home_Reward1: '奖励',
    home_signUpNow: '立即注册',
    home_Casino: '游戏',
    home_Sports: '体育',
    home_PROMOTION: '活动',
    home_Checkin: '签到',
    home_Bouns: '奖金',
    home_CheckinBouns: '签到奖金',
    home_SPIN: '转盘',
    home_Relief: '救济',
    home_Share: '分享',
    home_Deposit: '存款',
    home_Fund: '基金',
    home_VIPClub: '贵宾俱乐部',
    home_FastAndEasy: '快速简便地购买加密货币USDT存款奖金',
    home_Game: '游戏',
    home_User: '用户',
    home_Time: '时间',
    home_BetAmount: '投注金额',
    home_Multiplier: '乘数',
    home_Payout: '支出',
    home_JoinOur: '加入我们的体育狂热者、加密爱好者和游戏社区',
    home_JOIN: '加入',
    home_LICENSES: '许可证',
    home_OurPlatforms: '我们的平台',
    home_Marketplac: '市场',
    home_Howtoearn: '如何赚取',
    home_Games: '游戏',
    home_WhitePaper: '白皮书',
    home_InvestorDeck: '投资者平台',
    home_News: '新闻',
    home_Aboutsus: '关于我们',
    home_Industry: '工业',
    home_Roadmap: '路线图',
    home_Partner: '合作伙伴',
    home_FAQs: '常见问题',
    home_Affiliate: '代理',
    home_Stayintouch: '保持联系',
    home_ChatRoom: '聊天室',
    home_Selectacontact: '选择一个联系人开始对话。',
    home_BetSlip: '投注单',
    home_Typeyourmessage: '输入您的信息',
    home_AcceptAnyOdds: '接受任何赔率',
    home_ClearAll: '清除所有',
    home_Live: '滚球',
    home_Winner: '胜利',
    home_EstPayout: '预计支付额',
    home_TotalOdds: '总投注额',
    home_RegistertoBet: '注册投注',
    home_Single: '单项投注',
    home_Multi: '复式投注',
    home_Checkineveryweek: '每周累计签到，可获得相应奖励。',
    home_Day: '天',
    home_Sunday: '第1天',
    home_Monday: '第2天',
    home_Tuesday: '第3天',
    home_Wednesday: '第4天',
    home_Thursday: '第5天',
    home_Friday: '第6天',
    home_Saturday: '第7天',
    home_FreeSpin: '转动转盘',
    home_Turntheturntable: '每天转动转盘获得USGT奖励。',
    home_DepositFift: '入金奖励',
    home_ANYAMOUNT: '您可以获得的任何金额的第一笔存款',
    home_DEPOSIT: '入金',
    home_First: '首次',
    home_ONLY: '只需',
    home_1ST: '1ST',
    home_2ND: '2ND',
    home_3TH: '3TH',
    home_ANYAMOUNT: 'ANY AMOUNT!',
    home_EXTRA: '额外',
    home_Relief: '救济金',
    home_Getrelief: '领救济金获得翻身的机会。 ',
    home_YOUCANCLAIM: '当余额少于',
    home_YOUCANReceive: ', 你可以领取救济金将余额补充至',
    home_threeTimes: ' 3次',
    home_everydayTimes: '/每天!',
    home_Times: '次数:',
    home_TotalAssets: '总计资产',
    home_receive: '领取',
    home_TODAY: '今天',
    home_Share: '分享',
    home_PLATFORMSHARING: '分享到其他平台',
    home_Sharewith: '每天分享FuniBet可获得',
    home_SHARINGGIFT: '分享礼包',
    home_CODE: '分享码',
    home_Selectplatform: '选择分享平台:',
    home_Fund: '成长基金',
    home_FundBonus: '成长基金奖励',
    home_Improveyour: '提升您的VIP等级，每天获得更多资金奖励。',
    home_BuyFund: '购买基金',
    home_Receivingconditions: '领取条件',
    home_Receivingstatus: '领取状态',
    home_Received: '已领取',
    home_noReceived: '未达成',
    home_ExclusiveBenefits: '独家优惠',
    home_Asawayof: '为了表达我们对 VIP 玩家的感谢和赞赏，邀请您加入我们的VIP俱乐部，有丰富的礼物、赠品、更高的现金返还和独特的功能。不要错过所有令人兴奋和惊人的乐趣！ ',
    home_Yourcurrent: '您当前的总充值',
    home_WhyUpgrade: '为什么要升级为VIP？ ',
    home_VIPhave: 'VIP享受专属管家24*7周到服务。除了基本的返利和充值外，VIP还享有最高级别的旋转，每周/每月奖金，以及最快的存取款通道。 VIP有机会参加顶级玩家交流会，并通过向FuniBet团队提出新的特点和功能来建设平台。 ',
    home_UpgradeYour: '升级您的 VIP 等级',
    home_ASaFunibet: '作为FUNIBet VIP俱乐部会员，您可以获得丰厚的奖励和个性化礼物，但这还不是全部。您玩得越多，当您通过VIP等级晋升成为精英VIP会员时，您将获得更好的豪华福利，FUNIBET将为您提供量身定制的VIP特权。 ',
    home_YOUCANREACH: '你可以升级到',
    home_LEVELS: '等级',
    home_eachthehigh: '每个人都可以获得25%的返利奖金，并享受平台的所有好处。入住FUNIBET，享受最佳游戏体验和优质服务！ ',
    home_Cashback: '现金返还',
    home_Nowater: '1.游戏中不再抽水',
    home_Moregrowth: '2.更多成长基金',
    home_Increasein: '3.福利数量增加',
    home_Doublethenumber: '4.转盘数量增加一倍',
    home_Doublethesign: '5.签到数量增加一倍',
    home_Doublesharingrewards: '6.分享奖励增加一倍',
    home_DoubletheAffiliate: '7.代理奖励增加一倍',
    home_Rechargerewardamount: '8.充值金额变为1.5倍',
    home_ABOUTVIPCLUB: '关于VIP俱乐部',
    home_HightopRank: '高排名有25%的返现奖金和最佳福利。入住BeDao，享受最佳的游戏体验和高水平的服务！ ',
    home_Beingamember: '成为VIP俱乐部的成员意味着您可以访问：',
    home_PersonalVIPmanager: '职业经理人',
    home_Insights: '领导力',
    home_Highercahbackrewards: '更高的现金回报',
    home_Otheradditionalperks: '其他额外津贴',
    home_WeeklyandMonthlyBonuses: '每周和每月奖金',
    home_Showhighgaming: '显示高游戏活动，并获得我们VIP俱乐部的邀请。 ',
    home_BindMobile: '手机绑定',
    home_Pleasecheckthe: '请检查钱包中从游戏中获得的USGT。 ',
    home_BINDMOBILENUMBER: '绑定手机号码',
    home_Get1USDT: '获得1USDT',
    home_Bind: '绑定',
    home_GiftCode: '礼包码',
    home_EnteryourGiftCode: '请输入您的礼包码',
    home_EnterCode: '输入号码',
    home_Paste: '粘贴',
    home_confirms: '确定',
    home_BuyCrypto: '购买虚拟币',
    home_SignedIn: '已签到',
    home_FirstDepositBonus: '首次入金奖金',
    home_Hi: '您好',
    home_welcomeAboard: '欢迎回来',



    home_CommissionRules: '佣金规则',
    home_AffiliateTerms: '代理条款',
    home_AffiliateDashboard: '代理仪表板',
    home_AFFILIATE: '代理',
    home_COMMISSONREADYEVERYDAY: '佣金随时准备就绪$1,000只是推荐',
    home_MYREFERRALCODE: '我的推荐码',
    home_MYREFERRALLink: '我的推荐链接',
    home_AffiliateReward: '代理奖励',
    home_EXTRA: '额外的',
    home_USDREWARDS: '美元奖励',
    home_USDRewardsRules: '美元奖励规则',
    home_JUSAREFERRALAWAY: '只是一个推荐',
    home_NewlyAvailableRewards: '最新奖励',
    home_ReceivedMao: '已收到:',
    home_Locked: '锁定:',
    home_Yourcurrentfriends: '您当前的朋友保持活跃将为您解锁更多奖励',
    home_Withdraw: '提取',
    home_MY: '我的',
    home_COMMISSIONREWARDS: '佣金奖励',
    home_CommissionRewardRules: '佣金奖励规则',
    home_NewlyAvailableCommissionRewards: '新的佣金奖励',
    home_CommissionYouhaveReceivedinTotal: '收到佣金:',
    home_REWARDSSENTTODATE: '总计奖励',
    home_USDrewardssenttodate: '总计美元奖励',
    home_Commissionsenttodatet: '佣金发送日期',
    home_Areyouablogger: '你是一个博主，有大量的读者，很多追随者吗？我们为您提供特别优惠一个独特的联盟计划，拥有更大的推荐奖励。我们将编制具有特殊条件的单独程序。联系我们的经理讨论条款',
    home_onlythoseusers: '重要提示：只有通过管理员要求并经管理员批准的用户才能参与该计划。 ',
    home_notice: '通知',
    home_bindPhone: '绑定手机',
    home_SignIn: '登录',
    home_SignUp: '注册',
    home_SignOut: '登出',
    home_CookiesPolicy: 'Cookies政策',
    home_isUsingCookies: 'Funibet.com 使用cookies为每一位用户提供更好的服务。使用我们的网站即表示您同意使用cookie。请查看隐私政策和服务条款。 ',
    home_SETTING: '设置',
    home_LiveSupport: '在线客服',
    home_Accept: '同意',
    home_FrequentlyAskedQuestions: '经常问的问题',
    home_SiteUnnderConstruction: '网站建设中',
    home_ComingSoon: '即将推出',
    home_Wallet: '钱包',
    home_UserId: '用户编号:',
    home_UserName: '用户名:',
    home_turnTimes: '转动次数：',
    home_Bonusincreased: "奖励额外+",
    home_bonus: '奖金',
    home_SPINBONUSTOTAL: '转盘总奖金',
    home_WIN: '大奖：',
    home_In: '来自',
    home_Outoftime: '今日次数已用尽',

    game_search: '搜索',
    game_SortBy: '分类',
    game_Created: '创建时间',
    game_Lobby: '大厅',
    game_LiveCasino: '真人娱乐城',
    game_FeaturedSlots: '特色老虎机',
    game_GameShows: '游戏节目',
    game_FuniBetOriginals: 'FuniBet原创游戏',
    game_BigWins: '大赢家',
    game_LcukyWins: '幸运中奖',
    game_Challenges: '挑战',
    game_Description: '描述',
    game_Slots: '老虎机',
    game_Rank: '排名',
    game_User: '用户',
    game_Date: '时间',
    game_BetAmount: '金额',
    game_lucky: '最大乘数',
    game_Multiplier: '乘数',
    game_Payout: '结果',
    game_Recommend: '推荐',
    game_Providers: '供应商',
    game_startGame: '开始',
    game_balance: '余额',
    game_provider: '标签',
    game_Selectyourdisplay: '选择你的显示余额',
    game_Balancein: '余额',
    game_FunPlay: '试玩模式',
    game_RealPlay: '真钱模式',
    game_DispLaying: "展示",
    game_LoadMore: "加载更多",
    game_NoData: '暂时没有该类型游戏',


    message_SigningIn: "正在使用 JWT 签入",
    message_CourseSaved: "课程已保存",
    message_PleaseBind: "参加活动需要绑定手机，是否绑定？",
    message_UserData: "用户数据已通过 api 保存",
    message_Success: "成功",
    message_VIPlevel: "VIP 等级低于 V3",
    message_ListOrder: "列表顺序已保存",
    message_CardOrder: "卡片顺序已保存",
    message_HiHow: "你好，你怎么样？",

    Funibet_web: 'Funibet-Web3.0,加密货币,投注,体育,电子竞技,老虎机,真人互动,扑克',

    home_ThereAre: "当前这里没有通知。",

    play_no_balance: "当前货币余额不足，请切换货币或充值",

    home_News_1: '新闻',
    home_News_2: '活动',
    home_News_3: '加密货币',
    home_News_4: '游戏',

    home_SuggRep_1: '建议和报告',
    home_SuggRep_2: '建议和报告',
    home_SuggRep_3: '分享反馈以改进！',
    home_SuggRep_4: '报告出售游戏中物品的网站。',
    home_SuggRep_5: '输入建议或提交报告',
    home_SuggRep_6: '示例截图',
    home_SuggRep_7: '采纳的建议和报告将获得奖励！',
    home_SuggRep_8: '提交',

    home_subscription_1: '是否要订阅推送通知？',
    home_subscription_2: '您可以随时取消订阅。',
    home_subscription_3: 'WonderPush网页推送',
    home_subscription_4: '稍后',
    home_subscription_5: '订阅',

    home_Affiliate_1: '联系我们',
    home_Affiliate_2: '邀请每位好友，您将获得10U，邀请的越多，您将获得的越多！',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: '电子邮件',

    home_banner_1: '加密游戏',
    home_banner_2: '支持100+种加密货币和50+种NFT',
    home_banner_3: '去中心化的投注',
    home_banner_4: '从去中心化的钱包进行智能合约投注',
    home_banner_5: '投注赚钱',
    home_banner_6: '投注、挖矿和投资，实现稳定的利润',
    home_banner_7: 'Web3.0 +游戏平台',
    home_banner_8: '玩家也是银行家和所有者',

    home_EventDetails: '详情',

    home_vip_1: '1.签到获得收益+',
    home_vip_2: '2.转盘获得收益+',
    home_vip_3: '3.分享获得收益+',
    home_vip_4: '4.充值获得收益+',
    home_vip_5: '5.开放聊天功能，每天可以发送',
    home_vip_6: '条消息',

    ERROR_628: '已领取该礼包码',
    ERROR_629: '该礼包码已被领取完',
    ERROR_630: '礼包码未生效',
    ERROR_631: '礼包码已过期',
    ERROR_633: '无法使用其他代理的礼包码',

    home_Maintaining_1: "维护",
    home_Maintaining_2: "此功能正在维护中",
    home_Maintaining_3: "结束",
    home_Maintaining_4: "开始",

    home_Slots: '拉霸',
    home_LiveCasino: '真人娱乐城',
    home_CountryFiat: '国籍与货币',
    home_CryptNFTs: '加密货币和NFTs',

    home_GoTo: '前往',

    home_gameout_1: "您目前正在其他游戏中，是否退出？",
    home_gameout_2: "取消",

    home_BuyFund1: '是否购买成长基金?',

    home_BetFor: '投注给',

    tongzhi_1: "建议",
    tongzhi_2: "回复建议和代表。",
    tongzhi_3: "干得好！这太棒了。",
    tongzhi_4: "等待审核",
    tongzhi_5: "审核通过",
    tongzhi_6: "打款中",
    tongzhi_7: "打款完成",
    tongzhi_8: "审核不通过",
    tongzhi_9: "打款失败",
    tongzhi_10: "提现失败",
    tongzhi_11: "已付款",
    tongzhi_12: "已完成",
    tongzhi_13: "处理失败",
    tongzhi_14: "购买",
    tongzhi_15: "至",
    tongzhi_16: "提取",
    tongzhi_17: "虚拟币",
    tongzhi_18: "法币",
    tongzhi_19: "下载",
    tongzhi_20: "登录可奖励",
    tongzhi_21: "安装",
    tongzhi_22: "暂无IOS版本",
    tongzhi_23: "发送",
    tongzhi_24: "内部转账",

    home_banner_9: "赛马",
    home_banner_10: "斗鸡",
    home_banner_11: "激情刺激，赢大奖",
    home_banner_12: "热血澎湃，斗志昂扬",
    home_wanfa_1: "玩法问题",
    home_wanfa_2: "转账问题",
    home_wanfa_3: "其他问题",
    play_not_balance: "体育无法使用eUSDT，是否切换币种？",
    qieHuan_balance: "当前选择币种的余额不足以进行游戏，是否切换成其他币种？",
    Provided_by: "来自",

    error_0: "领取奖励失败",
    error_1: "获取数据失败",
    error_2: "签到失败",
    error_3: "转动转盘失败",
    error_4: "领取救济金失败",
    error_5: "分享失败",
    error_6: "购买基金失败",
    error_7: "无法获取游戏信息",
    error_8: "无法试玩该游戏",
    error_10: "转移额度失败",
    error_11: "操作失败",
    error_12: "发送验证码失败",
    error_13: "发送已达上限",
    error_15: "用戶不存在",
    error_16: "读取分数表失败",
    error_17: "不满足领取条件",
    error_18: "今日领取次数不足",
    error_19: "礼包的类型有误",
    error_20: "兑金券数量太小",
    error_21: "今日已经领取过该礼包奖励",
    error_22: "VIP等级不足",
    error_23: "未登录",
    error_24: "接收到错误的数据",
    error_25: "数据长度有误",
    error_26: "不支持该设置",
    error_27: "更新日期已过期",
    error_28: "更新数据失败",
    error_29: "未找到该币种",
    error_30: "玩家没有绑定手机,不能领取",
    error_31: "无法获取游戏列表",
    error_32: "无法获取游戏标签",
    error_33: "无法获取游戏链接",
    error_34: "无法获取游戏记录",
};

export default locale;
