const locale = {
    home_StakeNFT: '質押NFT賺取更多',
    home_everyday: '每一天',
    home_Beton: '投注',
    home_mining: '挖礦',
    home_shareholder: '成為股東',
    home_Stake: '質押',
    home_dividend: '獲取利潤分紅',
    home_Banker: '成為合夥人之一',
    home_SIGNUPAND: '立即註冊',
    home_GET: '獲取',
    home_UPTO: '總計',
    home_FuniHash: '區塊鏈遊戲',
    home_Option: '期權',

    home_Reward: '獎勵',
    home_Reward1: '獎勵',
    home_signUpNow: '立即註冊',
    home_Casino: '賭場',
    home_Sports: '體育',
    home_PROMOTION: '活動',
    home_Checkin: '簽到',
    home_Bouns: '獎金',
    home_CheckinBouns: '簽到獎金',
    home_SPIN: '轉盤',
    home_Relief: '救濟',
    home_Share: '分享',
    home_Deposit: '存款',
    home_Fund: '基金',
    home_VIPClub: '貴賓俱樂部',
    home_FastAndEasy: '快速簡便地購買加密貨幣高達USDT存款獎金',
    home_Game: '遊戲',
    home_User: '用戶',
    home_Time: '時間',
    home_BetAmount: '投注金額',
    home_Multiplier: '乘數',
    home_Payout: '支出',
    home_JoinOur: '加入我們的體育狂熱者、加密愛好者和游戏社區',
    home_JOIN: '加入',
    home_LICENSES: '許可證',
    home_OurPlatforms: '我們的平台',
    home_Marketplac: '市場',
    home_Howtoearn: '如何賺取',
    home_Games: '遊戲',
    home_WhitePaper: '白皮書',
    home_InvestorDeck: '投資者平台',
    home_News: '新聞',
    home_Aboutsus: '關於我們',
    home_Industry: '工業',
    home_Roadmap: '路線圖',
    home_Partner: '合作夥伴',
    home_FAQs: '常見問題',
    home_Affiliate: '代理',
    home_Stayintouch: '保持聯繫',
    home_ChatRoom: '聊天室',
    home_Selectacontact: '選擇一個聯繫人開始對話。',
    home_BetSlip: '投注單',
    home_Typeyourmessage: '輸入您的信息',
    home_AcceptAnyOdds: '接受任何賠率',
    home_ClearAll: '清除所有',
    home_Live: '滾球',
    home_Winner: '勝利',
    home_EstPayout: '预计支付额',
    home_TotalOdds: '總投注額',
    home_RegistertoBet: '註冊投注',
    home_Single: '單項投注',
    home_Multi: '復式投注',
    home_Checkineveryweek: '每週累計簽到，可獲得相應獎勵。',
    home_Day: '天',
    home_Sunday: '第1天',
    home_Monday: '第2天',
    home_Tuesday: '第3天',
    home_Wednesday: '第4天',
    home_Thursday: '第5天',
    home_Friday: '第6天',
    home_Saturday: '第7天',
    home_FreeSpin: '轉動轉盤',
    home_Turntheturntable: '每天轉動轉盤獲得USGT獎勵。',
    home_DepositFift: '入金獎勵',
    home_ANYAMOUNT: '您可以獲得的任何金額的第一筆存款',
    home_DEPOSIT: '入金',
    home_First: '首次',
    home_ONLY: '只需',
    home_1ST: '1ST',
    home_2ND: '2ND',
    home_3TH: '3TH',
    home_ANYAMOUNT: 'ANY AMOUNT!',
    home_EXTRA: '額外',
    home_Relief: '救濟金',
    home_Getrelief: '領救濟金获得翻身的機會。',
    home_YOUCANCLAIM: '當餘額少於',
    home_YOUCANReceive: ', 你可以領取救濟金將餘額補充至',
    home_threeTimes: ' 3次',
    home_everydayTimes: '/每天!',
    home_Times: '次數:',
    home_TotalAssets: '總計資產',
    home_receive: '領取',
    home_TODAY: '今天',
    home_Share: '分享',
    home_PLATFORMSHARING: '分享到其他平台',
    home_Sharewith: '每天分享FuniBet可獲得',
    home_SHARINGGIFT: '分享禮包',
    home_CODE: '分享碼',
    home_Selectplatform: '選擇分享平台:',
    home_Fund: '成長基金',
    home_FundBonus: '成長基金獎勵',
    home_Improveyour: '提升您的VIP等級，每天獲得更多資金獎勵。',
    home_BuyFund: '購買基金',
    home_Receivingconditions: '領取條件',
    home_Receivingstatus: '領取狀態',
    home_Received: '已領取',
    home_noReceived: '未達成',
    home_ExclusiveBenefits: '獨家優惠',
    home_Asawayof: '為了表達我們對 VIP 玩家的感謝和讚賞，FuniBet 邀請您加入我們的 VIP 俱樂部，那裡有豐富的禮物、贈品、更高的現金返還和獨特的功能。 不要錯過所有令人興奮和驚人的樂趣！',
    home_Yourcurrent: '您當前的總充值',
    home_WhyUpgrade: '為什麼要升級為VIP？',
    home_VIPhave: 'VIP享受專屬管家24*7周到服務。 除了基本的返水和充值外，VIP還享有最高級別的旋轉，每週/每月獎金，以及最快的存取款通道。 VIP有機會參加頂級玩家交流會，並通過向FuniBet團隊提出新的特點和功能來建設賭場。',
    home_UpgradeYour: '升級您的 VIP 等級',
    home_ASaFunibet: '作为FUNIBet VIP俱乐部会员，您可以获得丰厚的奖励和个性化礼物，但这还不是全部。您玩得越多，当您通过VIP等级晋升成为精英VIP会员时，您将获得更好的豪华福利，FUNIBET将为您提供量身定制的VIP特权。',
    home_YOUCANREACH: '你可以升級到',
    home_LEVELS: '等級',
    home_eachthehigh: '每个人都可以获得25%的返现奖金，并享受平台的所有好处。入住FUNIBET，享受最佳游戏体验和优质服务！',
    home_Cashback: '現金返還',
    home_Nowater: '1.遊戲中不再抽水',
    home_Moregrowth: '2.更多成長基金',
    home_Increasein: '3.福利數量增加',
    home_Doublethenumber: '4.轉盤數量增加一倍',
    home_Doublethesign: '5.簽到數量增加一倍',
    home_Doublesharingrewards: '6.分享獎勵增加一倍',
    home_DoubletheAffiliate: '7.代理獎勵增加一倍',
    home_Rechargerewardamount: '8.充值金額變為1.5倍',
    home_ABOUTVIPCLUB: '關於VIP俱樂部',
    home_HightopRank: '高排名有25%的返现奖金和最佳福利。入住BeDao，享受最佳的游戏体验和高水平的服务！',
    home_Beingamember: '成为VIP俱乐部的成员意味着您可以访问：',
    home_PersonalVIPmanager: '職業經理人',
    home_Insights: '領導力',
    home_Highercahbackrewards: '更高的現金回報',
    home_Otheradditionalperks: '其他額外津貼',
    home_WeeklyandMonthlyBonuses: '每週和每月獎金',
    home_Showhighgaming: '顯示高遊戲活動，並獲得我們VIP俱樂部的邀請。',
    home_BindMobile: '手機綁定',
    home_Pleasecheckthe: '請檢查錢包中從遊戲中獲得的USGT。',
    home_BINDMOBILENUMBER: '綁定手機號碼',
    home_Get1USDT: '獲得1USDT',
    home_Bind: '綁定',
    home_GiftCode: '禮包碼',
    home_EnteryourGiftCode: '請輸入您的禮包碼',
    home_EnterCode: '輸入號碼',
    home_Paste: '粘貼',
    home_confirms: '確定',
    home_BuyCrypto: '購買',
    home_SignedIn: '已簽到',
    home_CommissionRules: '佣金規則',
    home_AffiliateTerms: '代理條款',
    home_AffiliateDashboard: '代理儀表板',
    


    home_AFFILIATE: '代理',
    home_COMMISSONREADYEVERYDAY: '佣金隨時準備就緒$1,000只是推薦',
    home_MYREFERRALCODE: '我的推薦碼',
    home_MYREFERRALLink: '我的推薦鏈接',
    home_AffiliateReward: '代理獎勵',
    home_EXTRA: '額外的',
    home_USDREWARDS: '美元獎勵',
    home_USDRewardsRules: '美元獎勵規則',
    home_JUSAREFERRALAWAY: '只是一個推薦',
    home_NewlyAvailableRewards: '最新獎勵',
    home_ReceivedMao: '已收到:',
    home_Locked: '鎖定:',
    home_Yourcurrentfriends: '您當前的朋友保持活躍將為您解鎖更多獎勵',
    home_Withdraw: '提取',
    home_MY: '我的',
    home_COMMISSIONREWARDS: '佣金獎勵',
    home_CommissionRewardRules: '佣金獎勵規則',
    home_NewlyAvailableCommissionRewards: '新的佣金獎勵',
    home_CommissionYouhaveReceivedinTotal: '收到佣金:',
    home_REWARDSSENTTODATE: '迄今為止發送的獎勵',
    home_USDrewardssenttodate: '迄今為止發送的美元獎勵',
    home_Commissionsenttodatet: '佣金發送日期',
    home_Areyouablogger: '你是一個博主，有大量的讀者，很多追隨者嗎？我們為您提供特別優惠一個獨特的聯盟計劃，擁有更大的推薦獎勵。我們將編制具有特殊條件的單獨程序。聯繫我們的經理討論條款',
    home_onlythoseusers: '重要提示：只有通過管理員要求並經管理員批准的用戶才能參與該計劃。',
    home_notice: '通知',
    home_bindPhone: '绑定手机',
    home_SignIn: '登錄',
    home_SignUp: '註冊',
    home_SignOut: '登出',
    home_CookiesPolicy: 'Cookies政策',
    home_isUsingCookies: 'Funibet.com 使用cookies為每一位用戶提供更好的服務。使用我們的網站即表示您同意使用cookie。請查看隱私政策和服務條款。',
    home_SETTING: '設置',
    home_LiveSupport: '在線客服',
    home_Accept: '同意',
    home_FrequentlyAskedQuestions: '經常問的問題',
    home_SiteUnnderConstruction: '網站建設中',
    home_ComingSoon: '即將推出',
    home_Wallet: '錢包',
    home_UserId: '用戶編號:',
    home_UserName: '用戶名:',
    home_turnTimes: '轉動次數：',
    home_Bonusincreased: "獎勵額外+",
    home_bonus: '獎金',
    home_SPINBONUSTOTAL: '轉盤總獎金',
    home_WIN: '大獎：',
    home_In: '來自',
    home_FirstDepositBonus: '首次入金獎金',
    home_Hi: '您好',
    home_welcomeAboard: '歡迎回來',
    home_Outoftime: '今日次數已用盡',

    game_search: '搜索',
    game_SortBy: '分類',
    game_Created: '創建時間',
    game_Lobby: '大廳',
    game_LiveCasino: '真人娛樂城',
    game_FeaturedSlots: '特色老虎機',
    game_GameShows: '遊戲節目',
    game_FuniBetOriginals: 'FuniBet原創遊戲',
    game_BigWins: '大贏家',
    game_LcukyWins: '幸運中獎',
    game_Challenges: '挑戰',
    game_Description: '描述',
    game_Slots: '老虎機',
    game_Rank: '排名',
    game_User: '用戶',
    game_Date: '時間',
    game_BetAmount: '金額',
    game_lucky: '最大乘數',
    game_Multiplier: '乘數',
    game_Payout: '結果',
    game_Recommend: '推薦',
    game_Providers: '供應商',
    game_startGame: '开始',
    game_balance: '余额',
    game_provider: '标签',
    game_Selectyourdisplay: '選擇你的顯示餘額',
    game_Balancein: '餘額',
    game_FunPlay: '試玩模式',
    game_RealPlay: '真錢模式',
    game_DispLaying: "展示",
    game_LoadMore: "加載更多",
    game_NoData: '暫時沒有該類型遊戲',

    message_SigningIn:"以JWT方式登錄",
    message_CourseSaved:"課程已保存",
    message_PleaseBind:"參加活動需要綁定手機，是否綁定？",
    message_UserData:"用戶數據已通過API保存",
    message_Success:"成功",
    message_VIPlevel:"VIP等級低於V3",
    message_ListOrder:"清單訂單已保存",
    message_CardOrder :"卡訂單已保存",
    message_HiHow:"你好，你怎麼樣？",

    Funibet_web: 'Funibet-Web3.0,加密貨幣,押注,運動,電子競技,视訊老虎機,真人賭場,撲克',

    home_ThereAre:"目前沒有通知。" ,

    play_no_balance: "當前貨幣餘額不足，請切換貨幣或充值",

    home_News_1: '新聞',
    home_News_2: '活動',
    home_News_3: '加密',
    home_News_4: '遊戲',
    
    home_SuggRep_1: '建議與回報',
    home_SuggRep_2: '建議與回報',
    home_SuggRep_3: '分享您的反饋以改善！',
    home_SuggRep_4: '檢舉網站出售遊戲內物品。',
    home_SuggRep_5: '輸入建議或提交回報',
    home_SuggRep_6: '示例截圖',
    home_SuggRep_7: '採納的建議和回報將獲得獎勵！',
    home_SuggRep_8: '提交',
    
    home_subscription_1: '是否要訂閱推送通知？',
    home_subscription_2: '您可以隨時取消訂閱。',
    home_subscription_3: 'WonderPush 的 Web 推送',
    home_subscription_4: '稍後',
    home_subscription_5: '訂閱',
    
    home_Affiliate_1: '聯繫我們',
    home_Affiliate_2: '每邀請一位朋友，您將獲得 10U，邀請的人數越多，獲得的獎勵就越多！',
    home_Affiliate_3: 'Telegram',
    home_Affiliate_4: '電子郵件',

    home_banner_1:'加密遊戲',
    home_banner_2:'支援100多種加密貨幣和50多種NFT',
    home_banner_3:'去中心化投注',
    home_banner_4:'從去中心化錢包進行智能合約投注',
    home_banner_5:'投注可賺取收益',
    home_banner_6:'投注、挖礦和投資，獲得穩定的利潤',
    home_banner_7:'Web3.0 + 賭場',
    home_banner_8:'玩家也是銀行家和擁有者',

    home_EventDetails:'詳情',

    home_vip_1: "1.簽到獲得收益+",
    home_vip_2: "2.轉盤獲得收益+",
    home_vip_3: "3.分享獲得收益+",
    home_vip_4: "4.充值獲得收益+",
    home_vip_5: "5.開放聊天功能，每天可以發送",
    home_vip_6: "消息",

    ERROR_628: '已領取該禮包碼',
    ERROR_629: '該禮包碼已被領取完',
    ERROR_630: '禮包碼未生效',
    ERROR_631: '禮包碼已過期',
    ERROR_633: '無法使用其他代理的禮包碼',

    home_Maintaining_1: "維護",
    home_Maintaining_2: "此功能正在維護中",
    home_Maintaining_3: "結束",
    home_Maintaining_4: "開始",

    home_Slots: '拉霸',
    home_LiveCasino: '真人娛樂城',
    home_CountryFiat: '國家與法幣',
    home_CryptNFTs: '加密貨幣與NFTs',

    home_GoTo:'前往',

    home_gameout_1: "您目前正在其他遊戲中，是否退出？",
    home_gameout_2: "取消",
    
     home_BuyFund1:'是否購買成長基金?',

     home_BetFor:'投注給',

     tongzhi_1: "建議",
     tongzhi_2: "回覆建議和代表。",
     tongzhi_3: "幹得好！這太棒了。",
     tongzhi_4: "等待審核。",
     tongzhi_5: "審核通過。",
     tongzhi_6: "打款中。",
     tongzhi_7: "打款完成。",
     tongzhi_8: "審核不通過。",
     tongzhi_9: "打款失敗。",
     tongzhi_10: "提現失敗。",
     tongzhi_11: "已付款。",
     tongzhi_12: "已完成。",
     tongzhi_13: "處理失敗。",
     tongzhi_14: "購買",
     tongzhi_15: "至",
     tongzhi_16: "提取",
     tongzhi_17: "虛擬貨幣",
     tongzhi_18: "法定貨幣",
     tongzhi_19: "下載",
     tongzhi_20: "登錄可獎勵",
     tongzhi_21: "安裝",
     tongzhi_22: "暫無iOS版本",
     tongzhi_23: "發送",
     tongzhi_24: "內部轉帳",
     
     home_banner_9: "賽馬",
     home_banner_10: "鬥雞",
     home_banner_11: "激情刺激，贏大獎",
     home_banner_12: "熱血澎湃，斗志昂揚",
     
     home_wanfa_1:"玩法問題",
     home_wanfa_2:"轉帳問題",
     home_wanfa_3:"其他問題",
     play_not_balance: "体育無法使用eUSDT，是否切換幣種？",
     qieHuan_balance: "當前選擇的貨幣餘額不足以進行遊戲，是否切換到其他貨幣？",
     Provided_by: "來自",

     error_0: "領取獎勵失敗",
     error_1: "獲取數據失敗",
     error_2: "簽到失敗",
     error_3: "轉動轉盤失敗",
     error_4: "領取救濟金失敗",
     error_5: "分享失敗",
     error_6: "購買基金失敗",
     error_7: "無法獲取遊戲資訊",
     error_8: "無法試玩該遊戲",
     error_10: "轉移額度失敗",
     error_11: "操作失敗",
     error_12: "發送驗證碼失敗",
     error_13: "發送已達上限",
     error_15: "",
     error_16: "",
     error_17: "",
     error_18: "",
     error_19: "",
     error_20: "",
     error_21: "",
     error_22: "",
     error_23: "",
     error_24: "",
     error_25: "",
     error_26: "",
     error_27: "",
     error_28: "",
     error_29: "",
     error_30: "",
     error_31: "",
     error_32: "",
     error_33: "",
     error_34: "",

};

export default locale;
