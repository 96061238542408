import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import Toolbar from "@mui/material/Toolbar";
import clsx from "clsx";
import { default as React, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFuseCurrentLayoutConfig,
  selectToolbarTheme,
} from "app/store/fuse/settingsSlice";
import { selectFuseNavbar } from "app/store/fuse/navbarSlice";
import AdjustFontSize from "../../shared-components/AdjustFontSize";
import FullScreenToggle from "../../shared-components/FullScreenToggle";
import NotificationPanelToggleButton from "../../shared-components/notificationPanel/NotificationPanelToggleButton";
import NavigationShortcuts from "../../shared-components/NavigationShortcuts";
import NavigationSearch from "../../shared-components/NavigationSearch";
import NavbarToggleButton from "../../shared-components/NavbarToggleButton";
import UserMenu from "../../shared-components/UserMenu";
import QuickPanelToggleButton from "../../shared-components/quickPanel/QuickPanelToggleButton";
import ChatPanelToggleButton from "../../shared-components/chatPanel/ChatPanelToggleButton";
import Button from "@mui/material/Button";
import { useState } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import LanguageSwitcher from "../../shared-components/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { selectUserData } from "../../../store/user";
import { selectUserSetting } from "app/store/user/userSetting";
import {
  userProfile,
  openWallet,
  onLogin,
} from "../../../store/user/userThunk";
import history from "@history";
import { selectCasinoData } from "../../../store/casino";
import IconButton from "@mui/material/IconButton";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import FuseLoading from "@fuse/core/FuseLoading";
import { useLocation } from "react-router";
import { judgeLangFontSize, judgeIosOrAndroid } from "../../../util/tools/function";
import { selectUserAssets } from "app/store/user/userAssets";
import { selectFiats } from "app/store/config/fiats";
import { selectSymbols } from "app/store/config/symbols";
import { selectUserInfo } from "app/store/user/userInfo";
import { showMessage } from "app/store/fuse/messageSlice";

function ToolbarLayout1(props) {
  const { t } = useTranslation("mainPage");
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const user = useSelector(selectUserData);
  const allFiats = useSelector(selectFiats);
  const allSymbols = useSelector(selectSymbols);
  const userAssets = useSelector(selectUserAssets);
  const userSetting = useSelector(selectUserSetting);
  const userData = useSelector(selectUserInfo).userInfo;
  const location = useLocation();

  const [currentIsLoadAction, setCurrentIsLoadAction] = useState(window.localStorage.getItem('currentIsLoadAction') || false);


  const [isLoading, setIsLoading] = useState(user.isUserInfoLoading ?? false);
  const [isSettingLoading, setIsSettingLoading] = useState(userSetting.isUserSettingLoading ?? false);

  const routerName = () => {
    return location.pathname;
  };

  // const [ num, setNum ] = useState(9999999.999999);

  function NumToFixed(num) {
    num = parseFloat(num);
    let tmpNum = num;
    var aa = parseInt(num).toString().length;
    if (aa == 1) {
      tmpNum = num.toFixed(6);
      // eslint-disable-next-line eqeqeq
    } else if (aa == 2) {
      tmpNum = num.toFixed(5);
      // eslint-disable-next-line eqeqeq
    } else if (aa == 3) {
      tmpNum = num.toFixed(4);
      // eslint-disable-next-line eqeqeq
    } else if (aa == 4) {
      tmpNum = num.toFixed(3);
    } else if (aa == 5) {
      tmpNum = num.toFixed(2);
    } else if (aa == 6) {
      num.toFixed(1);
    } else if (aa > 6) {
      tmpNum = num.toFixed(0);
    }
    return tmpNum;
  }

  function NumToFixed2(tempAmount) {
    var symbolArr = ["USDT", "USDC", "DAI", "BUSD", "PAX", "TUSD", "USD", "UST", "USDD", "eUSDT", "IDR", "MMK"]
    var tmpNum = 0;
    if (symbolArr.indexOf(tempAmount.symbol) != -1) {
      tmpNum = parseFloat(tempAmount.balance).toFixed(2);
    } else {
      tmpNum = NumToFixed(tempAmount.balance)
    }
    return tmpNum;
  }


  const dispatch = useDispatch();
  const casinoData = useSelector(selectCasinoData);
  const goRouter = (route) => {
    history.push(route);
  };

  const [userAmount, setUserAmount] = useState({
    symbol: "",
    img: "",
    balance: 0.0,
  });
  const [selectedTab, setSelectedTab] = useState(0);
  function handleTabChange(event, value) {
    setSelectedTab(value);
  }
  const formatAddress = (str, len = 4) => {
    if (str) {
      return str.substr(0, len) + "..." + str.slice(-len);
    }
    return "";
  };
  const handleCopyText = (text) => {
    if (!text) {
      if (userData.loginType == 1) {
        text = userData.user.address;
      } else if (userData.loginType == 2) {
        text = "+" + userData.nation + userData.phone;
      } else if (userData.loginType == 3) {
        text = userData.user.email;
      }
    }

    var input = document.createElement("input");
    document.body.appendChild(input);
    input.setAttribute("value", text);
    input.select();
    document.execCommand("copy"); // 执行浏览器复制命令
    if (document.execCommand("copy")) {
      document.execCommand("copy");
    }
    document.body.removeChild(input);
  };


  const handleOnLogin = (type) => {
    setIsLoading(true);
    dispatch(
      onLogin({
        type,
        callback: () => {
          if (type == "signUp") {
            fbq('track', 'CompleteRegistration');
          }
          setIsLoading(false);
          setIsSettingLoading(false);
        }
      })
    );
    // FuniBox.Tools.default.openWallet(type, {}, function () {
    //     const token = FuniBox.Tools.default.getToken();
    //     if (token && token !== 'undefined') {
    //         FuniBox.Tools.default.getUserInfo().then((res) => {
    //             if (res) {
    //                 dispatch(userProfile({
    //                     token: token,
    //                     userData: res
    //                 }));
    //             }
    //         });
    //     }
    // })
  };

  const sportHandleOnLogin = () => {
    dispatch(onLogin({
      type: "login",
      callback: () => {
        setIsLoading(false);
        setIsSettingLoading(false);
      }
    }));
  };

  const isMobile = () => {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onAccount = () => {
    setIsSettingLoading(true);
    var type = "account";
    if (isMobile()) {
      type = "wallet";
    }
    dispatch(
      openWallet({
        type,
        callback: () => {
          setIsLoading(false);
          setIsSettingLoading(false);
        }
      })
    );
  };

  const onWallet = () => {
    setIsSettingLoading(true);
    dispatch(
      openWallet({
        type: "deposite",
        callback: () => {
          setIsLoading(false);
          setIsSettingLoading(false);
        }
      })
    );
  };

  const initUserMoney = () => {
    if (
      userSetting.setting &&
      JSON.stringify(allFiats.allFiats) !== "{}" &&
      JSON.stringify(allSymbols.allSymbols) !== "{}"
    ) {
      if (userSetting.setting.currencyType === 1) {
        setUserAmount({
          symbol: userSetting.setting.symbol,
          img: allSymbols.allSymbols[userSetting.setting.symbol]?.avatar,
          balance:
            userAssets.userSymbols[userSetting.setting.symbol]?.balance.toFixed(
              6
            ) || "0.000000",
        });
      } else {
        setUserAmount({
          symbol: userSetting.setting.fiatCode,
          img: allFiats.allFiats[userSetting.setting.fiatCode]?.avatar,
          balance:
            userAssets.userFiats[userSetting.setting.fiatCode]?.balance.toFixed(
              2
            ) || "0.00",
        });
      }
    }
  };

  useEffect(() => {
    initUserMoney();
  }, [
    allSymbols.allSymbols,
    allFiats.allFiats,
    userAssets.userFiats,
    userAssets.userSymbols,
    userSetting,
  ]);

  const downLoadApp = () => {
    if (isMobile()) {
      if (judgeIosOrAndroid() == "android") {
        window.open('https://static-scource.funibet.com/funibet/downLoad/funibet.apk')
      } else if (judgeIosOrAndroid() == "ios") {
        dispatch(showMessage({ message: t('tongzhi_22'), code: 3 }))
      }
    }
  };

  useEffect(() => {
    let pathName = routerName();
    if (pathName.indexOf("sport") !== -1) {
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
  });

  useEffect(() => {
    setIsLoading(user.isUserInfoLoading);
  }, [user.isUserInfoLoading]);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx("flex relative z-20 shadow-md  fixedPhone ", props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? toolbarTheme.palette.background.paper
              : toolbarTheme.palette.background.default,
        }}
        style={{ boxShadow: "4px 4px 3px rgba(0,0,0,.5)" }}
        position="static"
      >
        <Toolbar className="p-0 min-h-56 md:min-h-64 funbetTxt ">
          <div className="flex flex-1  height-40  toolBarHead">
            {config.navbar.display && config.navbar.position === "left" && (
              <>
                <Hidden lgDown>
                  {(config.navbar.style === "style-3" ||
                    config.navbar.style === "style-3-dense") && (
                      <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                    )}

                  {config.navbar.style === "style-1" && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>
                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}

            <Hidden lgDown>
              <div className="flex flex-col flex-0 lg:flex-row items-center ">
                <div className="flex flex-1 justify-end lg:my-0 ">
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons={false}
                    className="min-h-40  backGround-foot borderRadius"
                    classes={{
                      indicator:
                        "flex justify-center bg-transparent w-full h-full ",
                    }}
                    TabIndicatorProps={{
                      children: (
                        <Box
                          sx={{ bgcolor: "text.disabled" }}
                          className="w-full h-full rounded-full gamesListBtnBackground-Color opacity-100"
                        />
                      ),
                    }}
                  >
                    <Tab
                      className="text-14 font-semibold min-h-40 min-w-120 Zindex px-12 flex"
                      disableRipple
                      icon={
                        <img
                          className="w-24 h-24"
                          src={`${React.$imgUrl}/images/icon/1.png`}
                        ></img>
                      }
                      iconPosition="start"
                      label={t("home_Casino")}
                      onClick={() => {
                        goRouter(`/game/${casinoData.recommondTag}`);
                      }}
                    />
                    <Tab
                      className="text-14 font-semibold min-h-40 min-w-120 Zindex px-12"
                      disableRipple
                      icon={
                        <img
                          className="w-24 h-24"
                          src={`${React.$imgUrl}/images/icon/2.png`}
                        ></img>
                      }
                      iconPosition="start"
                      label={t("home_Sports")}
                      onClick={() => {
                        if (user.token) {
                          history.push("/sport/europeanView");
                        } else {
                          sportHandleOnLogin();
                        }
                      }}
                    />
                  </Tabs>
                </div>
              </div>
            </Hidden>

            {user.token && (
              <div className="flex flex-1 justifyContent ">
                <div className="walletDi  walletMl flex align-item ">
                  {!isSettingLoading ? (
                    <>
                      <div
                        className="walletTxt pl-12 btnPointer "
                        style={{ fontSize: "17px" }}
                        onClick={() => {
                          onAccount();
                        }}
                      >
                        {userAmount.symbol}
                      </div>
                      <div
                        className="walletTxt px-8 btnPointer"
                        style={{ fontSize: "17px" }}
                        onClick={() => {
                          onAccount();
                        }}
                      >
                        {
                          // userAmount.balance
                          NumToFixed2(userAmount)
                          // NumToFixed(userAmount.balance)
                        }
                      </div>
                      {userAmount.img && (
                        <img
                          style={{
                            borderRadius: "50%",
                          }}
                          className="imgWH-25 btnPointer"
                          src={userAmount.img}
                          onClick={() => {
                            onAccount();
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <div className="px-8">
                      <FuseLoading />
                    </div>
                  )}

                  <img
                    className="height-24 mt-3 btnPointer"
                    src="assets/images/index/jianTou.png"
                    onClick={() => {
                      onAccount();
                    }}
                  />

                  <div
                    className="whitespace-nowrap borderRadius width-100 displayBlock text-14 loginInBtn mr-4 btnGreenColor1 btnPointer funbetTxt"
                    style={{
                      height: "32px",
                      lineHeight: "32px",
                      textAlign: "center",
                      fontSize: "14px"
                    }}
                    onClick={() => {
                      onWallet();
                    }}
                  >
                    {t("home_Wallet")}
                  </div>
                </div>
              </div>
            )}

            <Hidden lgDown>
              {user.token && (
                <div className="walletDi copyMl flex ">
                  {userData.loginType == 1 && (
                    <img src="assets/images/index/xhlIcon.png"></img>
                  )}
                  {userData.loginType == 2 && (
                    <img src="assets/images/index/phone.png"></img>
                  )}
                  {userData.loginType == 3 && (
                    <img src="assets/images/index/email.png"></img>
                  )}
                  <div className="copyTxt  px-6">
                    {userData.loginType == 1 && (
                      <>{formatAddress(userData.user.address, 6)}</>
                    )}
                    {userData.loginType == 2 && (
                      <>
                        {formatAddress("+" + userData.nation + userData.phone)}
                      </>
                    )}
                    {userData.loginType == 3 && (
                      <>{formatAddress(userData.user.email)}</>
                    )}
                  </div>
                  <IconButton
                    className={clsx("w-40 h-40", props.className)}
                    aria-controls="font-size-menu"
                    aria-haspopup="true"
                    // onClick={handleClick}
                    size="large"
                  >
                    <div>
                      <FuseSvgIcon
                        onClick={() => {
                          handleCopyText();
                        }}
                      >
                        heroicons-outline:copyBtn
                      </FuseSvgIcon>
                    </div>
                  </IconButton>
                </div>
              )}
            </Hidden>
          </div>

          {!user.isUserInfoLoading ? (
            <>
              {!user.token || user.token.length === 0 ? (
                <div className="flex items-center px-8 h-full overflow-x-auto ">
                  {isMobile() && <FuseSvgIcon style={{ marginRight: "40px" }} onClick={() => {
                    downLoadApp();
                  }} >
                    heroicons-outline:download
                  </FuseSvgIcon>
                  }
                  <LanguageSwitcher className="mr-10" />
                  <div
                    style={{
                      marginLeft: "8px",
                      minWidth: "90px",
                      maxWidth: "120px",
                      overflow: "hidden",
                      height: "32px",
                      lineHeight: "32px",
                      textAlign: "center",
                    }}
                    className={clsx("borderRadius text-18 btnPointer signUp loginInBtn funbetTxt ", judgeLangFontSize(['tmr']) && "font-15")}
                    onClick={() => {
                      handleOnLogin("signUp");
                    }}
                  >
                    {t("home_SignUp")}
                  </div>

                  <div
                    style={{
                      marginLeft: "8px",
                      minWidth: "90px",
                      maxWidth: "120px",
                      overflow: "hidden",
                      height: "32px",
                      lineHeight: "32px",
                      textAlign: "center",
                    }}
                    className={clsx("whitespace-nowrap borderRadius loginInBtn  btnGreenColor1 btnPointer funbetTxt  ", judgeLangFontSize(['fi', 'tmr', "mm"]) && "font-15")}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      handleOnLogin("login");
                    }}
                  >
                    {t("home_SignIn")}
                  </div>

                </div>
              ) : (
                <div className="flex items-center pr-10 h-full overflow-x-auto ">
                  <div className="flex displayPhoneSwitch2">
                    <LanguageSwitcher className="mr-10" />
                    <FullScreenToggle />
                    <NavigationSearch />
                    <Hidden lgUp>
                      {" "}
                      <ChatPanelToggleButton />{" "}
                    </Hidden>
                    <QuickPanelToggleButton />
                  </div>
                  <UserMenu className="mr-10 " />
                </div>
              )}
            </>
          ) : (
            <div className="mr-20 ">
              <FuseLoading />
            </div>
          )}

          {config.navbar.display && config.navbar.position === "right" && (
            <>
              <Hidden lgDown>
                {!navbar.open && (
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                )}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
