import "../../../styles/game.css";
import * as React from "react";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import utils from "../../util/tools/utils";
import Common_foot from "../common/Common_foot";
import Common_gameReport from "../common/Common_gameReport";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  getTagCasinoGames,
  getCategoryGames,
} from "../../store/casino/casinoThunk";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectCasinoData } from "../../store/casino";
import history from "@history";
import FuseLoading from "@fuse/core/FuseLoading";
import { isMobile } from "../../util/tools/function";
import GameTips from "../cookies/GameTips";
import { selectUserData } from "app/store/user";
import { selectUserSetting } from "app/store/user/userSetting";
import { selectTopGroup } from "app/store/casino/topGroup";
import { selectTags } from "app/store/casino/tags";
import MoneyTips from "../cookies/MoneyTips";

import { selectUserAssets } from "app/store/user/userAssets";
import { selectUserGameScore } from "app/store/user/userGameScore";
import { selectFiats } from "app/store/config/fiats";
import { selectSymbols } from "app/store/config/symbols";
import {
  openWallet,
} from "../../store/user/userThunk";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Game(props) {
  const { t } = useTranslation("mainPage");
  const dispatch = useDispatch();
  const params = useParams();
  const tagID = params.tagID;

  const [initFinish, setInitFinish] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const casinoData = useSelector(selectCasinoData);
  const group = useSelector(selectTopGroup);
  const tags = useSelector(selectTags).tags?.tags;
  const userData = useSelector(selectUserData);
  const userSetting = useSelector(selectUserSetting);
  const [selectedTab, setSelectedTab] = useState(0);
  function handleTabChange(event, value) {
    setSelectedTab(value);
  }
  const [groupData, setGroupData] = useState({});
  const [gameList, setGameList] = useState([]);
  const [inputVal, setInputVal] = useState({
    searchText: "",
  });

  const [showGameTips, setShowGameTips] = useState(false);
  const [showMoneyTips, setShowMoneyTips] = useState(false);
  const [currGame, setCurrGame] = useState({});

  const [userAmount, setUserAmount] = useState({
    symbol: "",
    img: "",
    balance: 0.0,
  });

  const allFiats = useSelector(selectFiats);
  const allSymbols = useSelector(selectSymbols);
  const userAssets = useSelector(selectUserAssets);
  const userGameScore = useSelector(selectUserGameScore);

  const handleChangeInputVal = (prop) => (event) => {
    setInputVal({ ...inputVal, [prop]: event.target.value });
  };

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const motionItem = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const handleTabClick = (tagID, tagName) => {
    if (tagID !== casinoData.recommondTag) {
      history.push(`/gameOther/${tagID}/${tagName}/0`);
    }
  };

  const imgOnLoad = (ref, src) => {
    let dom = document.getElementById(ref);
    if (src) {
      dom.src = src;
    }
  };

  const JdgeUserAmount = (item) => {
    if (
      userSetting.setting &&
      JSON.stringify(allFiats.allFiats) !== "{}" &&
      JSON.stringify(allSymbols.allSymbols) !== "{}" &&
      JSON.stringify(userGameScore.score) !== "{}" &&
      JSON.stringify(userAssets.userFiats) !== "{}" &&
      JSON.stringify(userAssets.userSymbols) !== "{}"
    ) {
      let amount = "";
      let balance = 0;
      if (userSetting.setting.currencyType === 1) {
        balance =
          userAssets.userSymbols[userSetting.setting.symbol]?.balance ||
          0;
        setUserAmount({
          symbol: userSetting.setting.symbol,
          img: allSymbols.allSymbols[userSetting.setting.symbol]?.avatar,
          balance:
            userAssets.userSymbols[
              userSetting.setting.symbol
            ]?.balance.toFixed(6),
        });
      } else {
        balance =
          userAssets.userFiats[userSetting.setting.fiatCode]?.balance ||
          0;
        setUserAmount({
          symbol: userSetting.setting.fiatCode,
          img: allFiats.allFiats[userSetting.setting.fiatCode]?.avatar,
          balance:
            userAssets.userFiats[
              userSetting.setting.fiatCode
            ]?.balance.toFixed(2),
        });
      }
      // userGameScore.score.cashCoupon    //EUSGT(代金券)
      // userGameScore.score.score         //USGT
      // userGameScore.score.currencyScore //当前币种余额
      if (
        userGameScore?.score?.cashCoupon +
        userGameScore?.score?.score +
        userGameScore?.score?.currencyScore <=
        0
      ) {
        if (balance <= 0) {
          setShowMoneyTips(true)
          return;
        }
      }
    }
    history.push(
      `/play/${item.gameChannel}/${item.gameID}/${item.casinoID}`
    );
  }


  const scrollToTop = () => {
    document.documentElement.scrollTo({ top: 0 });
  };

  useEffect(() => {
    scrollToTop()
  }, [userData.token]);


  useEffect(() => {
    // dispatch(getTagCasinoGames({
    //     tagID
    // })).then((res) => {
    //     let result = res.payload;
    //
    //     if (result) {
    //         let tmpData = [];
    //         result.map((item) => {
    //             if (tmpData[item.casinoTagID]) {
    //                 tmpData[item.casinoTagID].push(item)
    //             } else {
    //                 tmpData[item.casinoTagID] = [item];
    //             }
    //         });
    //         setGameList(tmpData);
    //     }
    // })
    setIsLoading(true);
    dispatch(getCategoryGames()).then((res) => {
      var pageSize = 14;
      if (isMobile()) {
        pageSize = 15;
      }

      let result = res.payload;
      if (result) {
        let tmpData = [];
        Object.keys(result).map((index) => {
          tmpData[index] = result[index].slice(0, pageSize);
        });
        setGameList(tmpData);
      }
      setIsLoading(false);
    });
  }, [tagID]);

  useEffect(() => {
    setIsLoading(true);
    if (group.topGroup.length > 0) {
      let tmpGroup = {};
      group.topGroup.map((item) => {
        tmpGroup[item.tagID] = item;
      });
      setGroupData(tmpGroup);
      setIsLoading(false);
    }
  }, [group]);

  useEffect(() => {
    if (Object.keys(groupData).length > 0 && gameList.length > 0) {
      setInitFinish(true);
    }

  }, [groupData, gameList]);
  return (
    <>
      {initFinish ? (
        <div className="flex w-full container">
          <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 p-10 md:p-32 pb-0 md:pb-0 gameHuoDongDing">
            <div className="flex flex-col flex-auto align-item">
              <div className="flex grow shrink-0 flex-col items-center container ">
                <motion.div
                  variants={container}
                  initial="hidden"
                  animate="show"
                  className="activitybanner "
                >
                  <motion.div variants={motionItem} className="min-w-full ">

                    <div className="zuQiu mt-20 displayPhoneSwitch1">
                      <FormControl
                        className="wallet-search"
                        sx={{ width: "100%", borderColor: "#94A3B8" }}
                        variant="outlined"
                      >
                        {/*<FuseSvgIcon*/}
                        {/*    className="text-48 cursor-pointer font-medium wallet-search-icon"*/}
                        {/*    size={24}*/}
                        {/*    color="action"*/}
                        {/*    onClick={handleChangeInputVal('searchText')}*/}
                        {/*>feather:search</FuseSvgIcon>*/}
                        <OutlinedInput
                          sx={{ width: "100%", borderColor: "#94A3B8" }}
                          id="outlined-adornment-address outlined-adornment-address-wallet outlined-adornment-address-wallet-input"
                          value={inputVal.searchText}
                          onChange={handleChangeInputVal("searchText")}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            "aria-label": "searchText",
                          }}
                          placeholder={t("game_search")}
                          className="borderRadius"
                        // onBlur={() => { setSearchInput(false) }}
                        />
                      </FormControl>
                    </div>

                    <div className={clsx("zuQiu", !isMobile() ? 'mt-20' : 'mt-4')}>
                      <div className="activitybanner marginJuZhong">
                        <Tabs
                          value={selectedTab}
                          onChange={handleTabChange}
                          indicatorColor="primary"
                          textColor="inherit"
                          variant="scrollable"
                          scrollButtons={true}
                          className="min-h-40 recommendWidth backGround-foot borderRadius"
                          classes={{
                            indicator:
                              "flex justify-center bg-transparent w-full h-full",
                          }}
                          TabIndicatorProps={{
                            children: (
                              <Box
                                sx={{ bgcolor: "text.disabled" }}
                                className="w-full h-full rounded-full gamesListBtnBackground-Color opacity-100"
                              />
                            ),
                          }}
                        >
                          {tags?.length > 0 &&
                            tags.map((item, index) => {
                              return (
                                <Tab
                                  key={item.tagID}
                                  className="text-14 font-semibold min-h-40 min-w-120 Zindex px-12 flex "
                                  disableRipple
                                  icon={
                                    <LazyLoadImage
                                      className="w-24 h-24"
                                      src={
                                        "assets/images/icon/" +
                                        item.tagName +
                                        ".png"
                                      }
                                    />
                                  }
                                  iconPosition="start"
                                  label={t(
                                    `navigation:${item.tagName
                                      .replace(/\s*/g, "")
                                      .replace(/-/g, "")}`
                                  )}
                                  onClick={() => {
                                    handleTabClick(item.tagID, item.tagName);
                                  }}
                                />
                              );
                            })}
                        </Tabs>
                      </div>
                    </div>

                    {isLoading && gameList.length === 0 && (
                      <div className="mt-20 ">
                        <FuseLoading />
                      </div>
                    )}
                    {gameList.length > 0 &&
                      gameList.map((game, index) => {
                        return (
                          <div key={index}>
                            <div className="zuQiu gameMt1 ">
                              <div className="promotion-list activitybanner marginJuZhong ">
                                <div>
                                  <div className="flex">
                                    <LazyLoadImage
                                      className="w-24 h-24"
                                      src={
                                        "assets/images/icon/" +
                                        groupData[index]?.tagName +
                                        ".png"
                                      }
                                    />
                                    <div className="font-14 mt-4 ml-4 font-weight500 fontColor">
                                      {groupData[index]?.tagName}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="zuQiu gameMt1 ">
                              <motion.div
                                variants={container}
                                initial="hidden"
                                animate="show"
                                className="promotion-list activitybanner marginJuZhong hidden promotion-list2 "
                              >
                                {game.length > 0 &&
                                  game.map((item) => {
                                    return (
                                      <motion.div
                                        variants={motionItem}
                                        className="phoneGameListMg2 imgHidden maxWidth overflowHidden borderRadius-10"
                                        key={item.casinoID}
                                      >
                                        <LazyLoadImage
                                          id={`game-recommend-${item.casinoID}`}
                                          variants={container}
                                          className="btnPointer borderRadius-10 imgMohu imgGameWh "
                                          // src={item.gameIcon}
                                          src={"assets/images/game/default.png"}
                                          onLoad={() => {
                                            imgOnLoad(
                                              `game-recommend-${item.casinoID}`,
                                              item.gameIcon
                                            );
                                          }}
                                          onClick={() => {
                                            console.log(userSetting?.setting?.casinoGame, 'userSetting?.setting?.casinoGame')
                                            return
                                          }}
                                        />

                                        {item.canDemo && <LazyLoadImage
                                          className="jiaoBiaoImg"
                                          src="assets/images/index/jiaoBiao.png"
                                        />}

                                        <LazyLoadImage
                                          className="displayImg index4bigImg "
                                          src="assets/images/index/gameZheZhaoSmall.png"
                                          onClick={() => {
                                            if (userSetting?.setting?.casinoGame) {
                                              setShowGameTips(true)
                                              setCurrGame(item)
                                            } else {
                                              JdgeUserAmount(item)
                                            }
                                          }}
                                        />
                                      </motion.div>
                                    );
                                  })}
                              </motion.div>
                            </div>

                            <div className="width-180 pt-16 marginJuZhong ">
                              <div
                                className="whitespace-nowrap width-180  borderRadius text-18 py-10 funbetTxt btnPointer btnGreenColor1"
                                style={{ textAlign: "center" }}
                                onClick={() => {
                                  var tmpTageName = "Slots";
                                  if (index === casinoData.slots) {
                                    tmpTageName = "Slots";
                                  } else if (index === casinoData.liveCasino) {
                                    tmpTageName = "Live Casino";
                                  } else if (index === casinoData.provablyFair) {
                                    tmpTageName = "Provably Fair";
                                  } else if (index === casinoData.special) {
                                    tmpTageName = "Special";
                                  }
                                  history.push(
                                    `/gameOther/${index}/${tmpTageName}/1`
                                  );
                                }}
                              >
                                {t("game_LoadMore")}
                              </div>
                            </div>

                            {/*<div className="zuQiu mt-20 displayPhoneSwitch">*/}
                            {/*    <div className="promotion-list activitybanner marginJuZhong hidden wrap">*/}
                            {/*        <img className="btnPointer maxWidth borderRadius-10 " src="assets/images/game/1.png" onClick={() => { history.push('/play') }}></img>*/}
                            {/*        <img className="btnPointer maxWidth borderRadius-10 " src="assets/images/game/2.png" onClick={() => { history.push('/play') }}></img>*/}
                            {/*        <img className="btnPointer maxWidth borderRadius-10 " src="assets/images/game/3.png" onClick={() => { history.push('/play') }}></img>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                          </div>
                        );
                      })}
                  </motion.div>
                </motion.div>
              </div>
              <GameTips
                open={showGameTips}
                successFun={() => {
                  setShowGameTips(false)
                  history.push(
                    `/play/${currGame.gameChannel}/${currGame.gameID}/${currGame.casinoID}`
                  );
                }}
                errorFun={() => {
                  setShowGameTips(false)
                }}
              />
              <MoneyTips open={showMoneyTips}
                successFun={() => {
                  dispatch(
                    openWallet({
                      type: "account",
                    })
                  );
                  setShowMoneyTips(false)
                }}
                errorFun={() => {
                  setShowMoneyTips(false)
                }}
              />
              <Common_gameReport />
              <Common_foot />
              <div className="mt-32  promotion-list colorGameListTitle mb-20 displayPhoneSwitch1">
                <div>FuniBet 2022- ALL Rights Reserved</div>
              </div>
            </div>
          </div>
        </div>
      ) : (<>
        <div className="m-auto" >
          <FuseLoading className="phoneLoadHeight" />
        </div>
      </>)}
    </>
  );
}
export default Game;
